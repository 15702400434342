import { ApiDescription } from "@magicware/fetch-api/ApiDescription";
import { ValidationErrorMessage, WellKnownValidationErrorMessage } from "@magicware/fetch-api/errors";
import { getData, postData } from "@magicware/fetch-api/fetch";
import { formApi } from "@magicware/fetch-api/forms";
import { TranslateFunc } from "../../localization";
import { API_BASE_URL, CurrentUser } from "../globals";

function postApi(endpoint: string) {
    const url = `${API_BASE_URL}${endpoint}`;
    return (request: any, signal?: AbortSignal) => postData(url, request, { signal });
}

type PasswordIsTooWeekValidationErrorMessage = ValidationErrorMessage<
    { MinLength: number; MinCharKinds: number },
    "PasswordIsTooWeek"
>;

export function getPasswordIsTooWeekMessage(msg: PasswordIsTooWeekValidationErrorMessage, t: TranslateFunc) {
    return t("password-too-week-message", { minLength: msg.Data.MinLength, charKinds: msg.Data.MinCharKinds });
}

export type Login<Form = any> = ApiDescription<
    { login: string; password: string } | { login: string; twoPhaseAuthToken: string; twoPhaseAuthCode: string },
    { result: "ok"; userId: number } | { result: "2fa"; twoPhaseAuthToken: string },
    | WellKnownValidationErrorMessage
    | ValidationErrorMessage<null, "InvalidLogin">
    | ValidationErrorMessage<null, "LoginLocked">,
    Form,
    "capitalize"
>;
const login: Login["Invoke"] = async (request, signal) => {
    const { userId, twoPhaseAuthToken, loginLocked } = await postData<{
        userId: number | null;
        twoPhaseAuthToken?: string;
        loginLocked?: boolean;
    }>(API_BASE_URL + "administration/user/login-cookie", request, { signal });

    if (twoPhaseAuthToken) return { result: "2fa", twoPhaseAuthToken };

    if (loginLocked) {
        const error: Login["ValidationError"] = {
            Validation: [{ KeyPath: null, Messages: [{ Type: "LoginLocked", Data: null }] }],
        };
        throw error;
    }

    if (!userId) {
        const error: Login["ValidationError"] = {
            Validation: [{ KeyPath: null, Messages: [{ Type: "InvalidLogin", Data: null }] }],
        };
        throw error;
    }

    return { result: "ok", userId };
};

export type ChangePassword<Form = any> = ApiDescription<
    { login: string; oldPassword: string; newPassword: string },
    void,
    WellKnownValidationErrorMessage | PasswordIsTooWeekValidationErrorMessage,
    Form
>;
const changePassword: ChangePassword["Invoke"] = postApi("administration/user/change-password");

export type SendResetPasswordEmail<Form = any> = ApiDescription<
    { login: string },
    void,
    WellKnownValidationErrorMessage,
    Form,
    "capitalize"
>;
const sendResetPasswordEmail: SendResetPasswordEmail["Invoke"] = postApi("administration/user/reset-password");

export type ResetPassword<Form = any> = ApiDescription<
    { resetPasswordHash: string; newPassword: string },
    void,
    WellKnownValidationErrorMessage | PasswordIsTooWeekValidationErrorMessage,
    Form,
    "capitalize"
>;
const resetPassword: ResetPassword["Invoke"] = postApi("administration/user/change-password");

const loadCurrentUser = (signal?: AbortSignal) =>
    getData<CurrentUser>(API_BASE_URL + "users/current", undefined, { signal });

export const userApi = {
    login,
    logout: (signal?: AbortSignal) =>
        postData(API_BASE_URL + "administration/user/logout-cookie", undefined, { signal }),
    loadCurrentUser,
    sendResetPasswordEmail,
    resetPassword,
    changePassword,
    forms: {
        login: formApi<Login>(login),
        loadCurrentUser: formApi(loadCurrentUser),
        sendResetPasswordEmail: formApi<SendResetPasswordEmail>(sendResetPasswordEmail),
        resetPassword: formApi<ResetPassword>(resetPassword),
        changePassword: formApi<ChangePassword>(changePassword),
    },
};
