import { createAsyncFormAutocompleteWithFilter } from "@magicware/forms/mui/FormAutocomplete";
import { deviceThunks } from "../../redux/devices/devicesReducer";
import { RootState } from "../../redux/types";
import { DeviceSelectOption } from "../../services/api/devices";
import { createDefaultProps } from "../forms/formAutocomplete";

export const FormDevicesAutocomplete = createAsyncFormAutocompleteWithFilter(
    {
        selectOptions: (state: RootState) => state.devices.list,
        createAsyncAction: deviceThunks.loadList,
        selectAsyncState: deviceThunks.loadList.selectState,
    },
    createDefaultProps<DeviceSelectOption>(
        (opt) => `${opt.name}`,
        (n, opt) => n.devices.detail.url(opt.id),
    ),
);
