import { createAsyncFormAutocomplete } from "@magicware/forms/mui/FormAutocomplete";
import { isString } from "@magicware/utils/isType";
import { ensureKeywords, loadKeywords } from "../../redux/codebooks/codebooksReducer";
import { AppDispatch, RootState } from "../../redux/types";
import { CodebookOptionModel } from "../../services/api/codebooks";
import { createDefaultProps } from "../forms/formAutocomplete";

export const FormKeywordsAutocomplete = createAsyncFormAutocomplete(
    {
        selectOptions: (state: RootState) => state.codebooks.keywords,
        createAsyncAction: loadKeywords,
        selectAsyncState: loadKeywords.selectState,
    },
    createDefaultProps((opt) => `${opt.name}`),
);

export interface KeywordsForm {
    keywords: (CodebookOptionModel | string)[];
}

export async function keywordsFormToEntities(dispatch: AppDispatch, form: KeywordsForm) {
    const newKeywordsNames = form.keywords.filter(isString);
    if (newKeywordsNames.length > 0) {
        const newKeywords = await dispatch(ensureKeywords({ names: newKeywordsNames })).unwrap();
        let tIdx = 0;
        for (let i = 0; i < form.keywords.length; i++) {
            if (isString(form.keywords[i])) form.keywords[i] = newKeywords[tIdx++];
        }
    }
    return {
        keywordIds: form.keywords.map((t) => (t as CodebookOptionModel).id),
    };
}
