import tw from "twin.macro";
import ErrorBoundary from "./ErrorBoundry";

export const PageContainerBase = tw.div`[width:calc(100vw - 20px)] md:[width:calc(100vw - 60px)] lg:[max-width:690px] xl:[max-width:955px]`;

const PageContainerDiv = tw(PageContainerBase)`md:m-5 md:rounded-xl bg-white`;

export default function PageContainer(props: React.ComponentProps<typeof PageContainerDiv>) {
    return (
        <PageContainerDiv {...props}>
            <ErrorBoundary>{props.children}</ErrorBoundary>
        </PageContainerDiv>
    );
}
