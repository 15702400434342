import { faCrown } from "@fortawesome/free-solid-svg-icons";
import DataIcon from "@magicware/data-lists/DataIcon";
import DataListItem, { MainText, SecondaryText } from "@magicware/data-lists/DataListItem";
import { StyledComponentProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { brandIcon } from "../../navigation/brands";
import { useNavigationClick } from "../../navigation/hooks";
import { useAppSelector } from "../../redux/hooks";

export interface ProjectSetupListItemProps extends StyledComponentProps {
    projectSetupId: number;
}

export default function ProjectSetupListItem({ projectSetupId, ...props }: ProjectSetupListItemProps) {
    const { t } = useTranslation();
    const obj = useAppSelector((state) => state.projectSetups.list?.byId[projectSetupId]);
    const editClickFactory = useNavigationClick();
    if (!obj) return null;
    return (
        <DataListItem {...props} data-id={obj.id} onClick={editClickFactory((n) => n.projectSetups.detail.url(obj.id))}>
            <MainText tw="mr-10">{obj.name}</MainText>
            <div tw="flex items-center space-x-2">
                {obj.isOwn && (
                    <SecondaryText>
                        <DataIcon tw="text-amber-500" icon={faCrown} />
                        {t("ownership-list-text")}
                    </SecondaryText>
                )}
                {obj.brand && (
                    <SecondaryText>
                        <DataIcon icon={brandIcon} />
                        {obj.brand}
                    </SecondaryText>
                )}
            </div>
        </DataListItem>
    );
}
