import { useQuery } from "./useQuery";

export function useQueryParams<Names extends string[]>(...params: Names) {
    const query = useQuery();
    const result = {} as { [K in Names[number]]: string | null };
    for (let i = 0; i < params.length; i++) {
        const param = params[i] as keyof typeof result;
        result[param] = query.get(param);
    }
    return result;
}
