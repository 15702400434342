import ErrorBoundary from "@magicware/dashboard/ErrorBoundry";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import App from "./components/app/App";
import store from "./redux/store";
import { BASE_PATH } from "./services/globals";
import GlobalStyles from "./styles/globalStyles";
import theme from "./styles/muiTheme";
import { BrowserRouter } from "react-router-dom";
import { initLocalization } from "./localization";
import { SnackbarProvider } from "notistack";
import LoginForm from "./components/injected/login/LoginForm";

initLocalization();

const rootElement = document.getElementById("profilog-registry-root");
if (!rootElement) throw new Error("Missing root element");

const root = createRoot(rootElement);

if (rootElement.getAttribute("data-app-type") === "login") {
    root.render(
        <ErrorBoundary>
            <ReduxProvider store={store}>
                <ThemeProvider theme={theme}>
                    <React.StrictMode>
                        <BrowserRouter basename={BASE_PATH}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <LoginForm />
                            </LocalizationProvider>
                        </BrowserRouter>
                    </React.StrictMode>
                </ThemeProvider>
            </ReduxProvider>
        </ErrorBoundary>,
    );
} else {
    root.render(
        <ErrorBoundary>
            <ReduxProvider store={store}>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider maxSnack={20}>
                        <GlobalStyles />
                        <React.StrictMode>
                            <BrowserRouter basename={BASE_PATH}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <App />
                                </LocalizationProvider>
                            </BrowserRouter>
                        </React.StrictMode>
                    </SnackbarProvider>
                </ThemeProvider>
            </ReduxProvider>
        </ErrorBoundary>,
    );
}
