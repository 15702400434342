import DataListItem, { MainText } from "@magicware/data-lists/DataListItem";
import { StyledComponentProps } from "@mui/material";
import { useAppInstaceNavigation } from "../../navigation/appInstances";
import { useNavigationClick } from "../../navigation/hooks";
import { useAppSelector } from "../../redux/hooks";

export interface AppInstanceConnectionListItemProps extends StyledComponentProps {
    appInstanceConnectionId: number;
    navigation: ReturnType<typeof useAppInstaceNavigation>;
}

export default function AppInstanceConnectionListItem({
    appInstanceConnectionId,
    navigation: [parentId, navigation],
    ...props
}: AppInstanceConnectionListItemProps) {
    const obj = useAppSelector((state) => state.appInstanceConnections.list?.byId[appInstanceConnectionId]);
    const editClickFactory = useNavigationClick();
    if (!obj) return null;
    return (
        <DataListItem
            {...props}
            data-id={obj.id}
            onClick={editClickFactory(() =>
                navigation.detail.connections.detail.url(parentId, obj.appInstance.id, obj.id),
            )}
        >
            <div tw="flex items-center">
                {obj.targetAppInstance && (
                    <MainText tw="mr-10">
                        {obj.targetAppInstance.projectSetup.name} - {obj.targetAppInstance.name} (
                        {obj.targetAppInstance.platform})
                    </MainText>
                )}
                {obj.targetConnector && <MainText tw="mr-10">{obj.targetConnector.name}</MainText>}
            </div>
        </DataListItem>
    );
}
