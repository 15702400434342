import Panel from "@magicware/dashboard/Panel";
import { buildForm } from "@magicware/forms/builder";
import Form, { FormImplementationProps } from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import FormSelect from "@magicware/forms/mui/FormSelect";
import FormTextField from "@magicware/forms/mui/FormTextField";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { updateDetail } from "../../redux/libraries/librariesReducer";
import { LibraryModelDetail, LibraryModelNew, LibraryModelUpdate } from "../../services/api/libraries";
import { platformOptions } from "../codebooks/platforms";

export const libraryForm = buildForm<LibraryModelDetail>()(() => ({
    props: {
        remove: ["id", "version"],
        extra: [],
    },
    transformations: {
        toForm: { fromModel: (h, m: LibraryModelDetail) => h.fromModel(m) },
        formTo: {
            new: (h, f): LibraryModelNew => h.toNewEntity(f),
            update: (h, f, m: LibraryModelDetail): LibraryModelUpdate => h.toUpdateEntity(f, m),
        },
    },
    updateReduxModel: updateDetail,
    schema: (_, t) => ({
        name: yup.string().required(t("name-is-required")),
        platform: yup.string().required(t("platform-is-required")),
        baseApp: yup.object(),
        notes: yup.string(),
        url: yup.string().url(t("invalid-url")),
        rating: yup.number(),
    }),
}));

const f = libraryForm.f;

export default function LibraryForm(props: FormImplementationProps<typeof f>) {
    const { t } = useTranslation();

    return (
        <Form validationSchema={libraryForm.validationSchema} {...props}>
            <Panel>
                <FormGrid>
                    <div tw="flex items-center">
                        <FormTextField
                            tw="flex-1"
                            f={f}
                            field="name"
                            label={t("name-label")}
                            autoFocus={props.autoFocus}
                        />
                    </div>
                    <FormSelect f={f} {...platformOptions} field="platform" label={t("platform-label")} />
                    <FormTextField f={f} field="notes" label={t("notes-label")} multiline />
                </FormGrid>
            </Panel>
        </Form>
    );
}
