import { useRouteMatch } from "react-router";
import { allPagesDic } from "./builder";

export function useCurrentPage() {
    const m = useRouteMatch();
    const currentPage = allPagesDic[m.path];
    const currentPageParams: any[] = [];
    const paramNames = currentPage.pattern.match(/:\w+/g) ?? [];
    for (let index = 0; index < paramNames.length; index++) {
        const paramName = paramNames[index].substr(1) as keyof typeof m.params;
        currentPageParams.push(m.params[paramName]);
    }
    return { currentPage, currentPageParams };
}
