import { getData, OnProgressFunc, postData, postDataWithProgress } from "@magicware/fetch-api/fetch";
import { API_BASE_URL, CODEBOOKS } from "../globals";

export interface FileModelBase {
    fileName: string;
    isLogo: boolean;
}

export interface FileModelNew extends FileModelBase {
    ownerId: number;
}

export interface FileModelUpdate extends Partial<FileModelBase> {
    id: number;
}

export interface FileListModel {
    id: number;
    name: string;
    contentType: string;
    url: string;
    thumbUrl?: string;
    isLogo: boolean;
}

export interface FileListFilter {
    ownerId: number;
    fileId?: number;
}

export interface InsertFileReponse {
    fileId: number;
    fileUrl: string;
    previousOwnerVersion: string;
    ownerVersion: string;
}

export type FilesApi = ReturnType<typeof createFilesApi>;

export function createFilesApi(url: string) {
    const baseUrl = `${API_BASE_URL}registry/${url}/files/`;
    return {
        url,
        list: (filter: FileListFilter, signal?: AbortSignal) =>
            getData<FileListModel[]>(baseUrl + "list", filter, { signal }),
        insert: (onProgress: OnProgressFunc, data: Blob, file: FileModelNew, signal?: AbortSignal) => {
            const formData = new FormData();
            formData.append("file", data);
            formData.append("entity", JSON.stringify(convertFlags(file)));
            return postDataWithProgress<InsertFileReponse>(baseUrl + "new", formData, onProgress, { signal });
        },
        update: (ownerId: number, file: FileModelUpdate, signal?: AbortSignal) =>
            postData<void>(baseUrl + "update", { ownerId, entity: convertFlags(file) }, { signal }),
        delete: (ownerId: number, fileId: number, signal?: AbortSignal) =>
            postData<void>(baseUrl + "delete", { ownerId, fileId }, { signal }),
    };
}

function convertFlags<T extends Partial<FileModelBase>>(file: T): Exclude<T, "isLogo"> & { flagIds?: number[] } {
    let result: Partial<T> = { ...file };
    delete result.isLogo;
    return {
        ...(result as Exclude<T, "isLogo">),
        flagIds: file.isLogo === undefined ? undefined : file.isLogo ? [CODEBOOKS.fileFlags.logoId] : [],
    };
}
