/* eslint-disable react-hooks/exhaustive-deps */
import useRefMemo from "@magicware/utils/hooks/useRefMemo";
import { useEffect, useState } from "react";
import { CanelableApiParams } from "./fetch";

type ApiState<TResult> = { apiStatus: "idle" | "pending" | "success" | "failed"; result?: TResult };

export default function useApi<TResult, TParams extends any[]>(
    apiCall: (...rest: TParams) => Promise<TResult>,
    dependencies?: React.DependencyList,
    options?: { disableAutoAbort: boolean },
) {
    const [state, setState] = useState<ApiState<TResult>>({ apiStatus: "idle" });

    const api = useRefMemo(() => {
        let abortController: AbortController | undefined;
        let lastUsedParameters: CanelableApiParams<TParams> | undefined = undefined;
        const invoke = async (...params: CanelableApiParams<TParams>) => {
            const localAbortController = abortController;
            try {
                if (localAbortController?.signal.aborted) return;
                lastUsedParameters = params;
                setState({ apiStatus: "pending" });
                const result = await apiCall(...([...params, localAbortController?.signal] as TParams));
                if (localAbortController?.signal.aborted) return;
                setState({ apiStatus: "success", result });
                return result;
            } catch (error) {
                if (localAbortController?.signal.aborted) return;
                setState({ apiStatus: "failed" });
                throw error;
            }
        };
        return {
            invoke,
            retry: () => {
                if (!lastUsedParameters) throw new Error("Cannot retry. You have to first invoke to retry...");
                return invoke(...lastUsedParameters);
            },
            initEffect: () => {
                //pokud máme vypnuté automatické abortování, tak abortController ani nebudeme vytvářet a klidně si stav volání api převezmeme z remountu
                if (options?.disableAutoAbort) return undefined;

                //jakmile máme zapnutý autoabort, tak musíme řešit případný remount komponenty, kdy se zneužije stav z předešlého mountu
                //stav api se tak při remountu nastaví na idle (kdyby náhodou došlo k reuse stavu díky remountu - v takovém případě se totiž předchozí případné volání stejně abortovalo)
                if (state.apiStatus !== "idle") setState({ apiStatus: "idle" });
                //pokud už nějaký abortController existoval, efekt byl spuštěn 2x (např. ve striktním módu nebo v případě remountu)
                //v takovém případě musíme první abortovat, neb klidně mohl být vyvolán invoke, jehož výsledek nás ale nezajímá
                if (abortController) abortController.abort();
                const localAbortController = new AbortController();
                abortController = localAbortController;
                return () => localAbortController.abort();
            },
        };
    }, dependencies);

    useEffect(api.initEffect, [api.initEffect]);

    return {
        apiStatus: state.apiStatus,
        data: state.result!,
        invoke: api.invoke,
        retry: api.retry,
    };
}
