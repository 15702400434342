import { connectorImplementationThunks } from "../../redux/connectorImplementations/connectorImplementationsReducer";
import { useAppDispatch, useAppSelectorEnsure, useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { connectorImplementationsApi } from "../../services/api/connectorImplementations";
import ConnectorImplementationForm, { connectorImplementationForm } from "./ConnectorImplementationForm";
import AsyncContent from "@magicware/dashboard/AsyncContent";
import { useTranslation } from "react-i18next";
import { useConnectorImplementationId } from "../../navigation/libraries";

const { useToFormFromModel } = connectorImplementationForm.hooks;

export default function ConnectorImplementationDetailTab() {
    const api = useAutoDispatchAsyncThunk(connectorImplementationThunks.load, useConnectorImplementationId());
    return <AsyncContent {...api} component={ConnectorImplementationDetailTabLoaded} />;
}

function ConnectorImplementationDetailTabLoaded() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const connectorImplementationDetail = useAppSelectorEnsure((state) => state.connectorImplementations.detail);
    const initialValues = useToFormFromModel(connectorImplementationDetail);
    const onSubmit = async (form: typeof connectorImplementationForm.f) => {
        const result = await connectorImplementationsApi.forms.update(
            connectorImplementationForm.transform.formTo.update(form, connectorImplementationDetail),
        );
        return connectorImplementationForm.apiUtils.handleUpdateEntityResult(dispatch, result, form);
    };
    return (
        <ConnectorImplementationForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            savePanel={{ successMessage: t("connectorImplementation-has-been-saved") }}
        />
    );
}
