import { useField } from "react-final-form";
import { Fragment } from "react";
import useFieldName from "../hooks/useFieldName";

export type FormTogglePanelProps<
    FormValues,
    Field extends string & keyof FormValues,
    Field2 extends string & keyof FormValues = never,
    Field3 extends string & keyof FormValues = never,
> = {
    f: FormValues;
    field: Field | [Field, Field2] | [Field, Field2, Field3];
    // eslint-disable-next-line @typescript-eslint/ban-types
    visibleOn:
        | FormValues[Field]
        | ((val: FormValues[Field], val2: FormValues[Field2], val3: FormValues[Field3]) => boolean);
};

export default function FormTogglePanel<
    FormValues,
    Field extends string & keyof FormValues,
    Field2 extends string & keyof FormValues = never,
    Field3 extends string & keyof FormValues = never,
>({ field, visibleOn, children }: React.PropsWithChildren<FormTogglePanelProps<FormValues, Field, Field2, Field3>>) {
    const fields: string[] = field instanceof Array ? field : [field];
    // eslint-disable-next-line react-hooks/rules-of-hooks
    for (let i = 0; i < fields.length; i++) fields[i] = useFieldName(fields[i]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const formFields = fields.map((f) => useField(f, { subscription: { value: true } }));
    const values = formFields.map((ff) => ff.input.value);
    const isVisible =
        typeof visibleOn === "function" ? (visibleOn as (...x: any[]) => boolean)(...values) : visibleOn === values[0];
    if (!isVisible) return null;
    return <Fragment>{children}</Fragment>;
}
