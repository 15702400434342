import { AsyncThunkStatus } from "@magicware/redux/thunks";
import { CircularProgress } from "@mui/material";
import { SnackbarKey, useSnackbar } from "notistack";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import tw from "twin.macro";

export type AsyncActionSnackContentMessages = {
    inProgress: string;
    success: string;
    failed: string;
};

export interface AsyncActionSnackContentProps {
    snackKey: SnackbarKey;
    closeDelayMs?: number;
    selectState: (state: unknown) => AsyncThunkStatus;
    cleanupAction?: () => void;
    content: string | AsyncActionSnackContentMessages;
}

export function AsyncActionSnackContent({
    snackKey,
    selectState,
    content,
    cleanupAction,
    closeDelayMs = 3000,
}: AsyncActionSnackContentProps) {
    const asyncState = useSelector(selectState);
    const snack = useSnackbar();
    const hasEnded = asyncState === "idle" || asyncState === "success" || asyncState === undefined;

    useEffect(() => {
        if (hasEnded) setTimeout(() => snack.closeSnackbar(snackKey), closeDelayMs);
    }, [hasEnded]);

    useEffect(() => cleanupAction, []);

    const messages: AsyncActionSnackContentMessages =
        typeof content === "string"
            ? {
                  inProgress: content,
                  success: `${content}: Done`,
                  failed: `${content}: Failed`,
              }
            : content;

    const message =
        asyncState === "pending"
            ? messages.inProgress
            : asyncState === "success" || asyncState === "idle"
            ? messages.success
            : asyncState === "failed"
            ? messages.failed
            : asyncState === "aborted"
            ? `ABORTED: ${messages.inProgress}`
            : "";

    return (
        <div
            tw="flex bg-primary-200 p-2 rounded [min-width:300px]"
            css={[asyncState === "failed" ? tw`bg-red-800 text-white` : undefined]}
        >
            <span tw="mr-2">{message}</span>
            {asyncState === "pending" && (
                <span tw="ml-auto flex items-center">
                    <CircularProgress size={20} />
                </span>
            )}
            {asyncState !== "pending" && (
                <button
                    type="button"
                    tw="ml-auto cursor-pointer hover:bg-primary-100 px-1 rounded"
                    css={[asyncState === "failed" ? tw`hover:bg-red-500` : undefined]}
                    onClick={() => snack.closeSnackbar(snackKey)}
                >
                    OK
                </button>
            )}
        </div>
    );
}
