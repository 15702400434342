import { Route, Switch, useLocation } from "react-router";
import { getTabs } from "./tabs";
import MainContent from "../MainContent";
import PageContainer from "../PageContainer";
import PageHeader from "./PageHeader";
import { Tabs, TabsContainer } from "../Tabs";
import { useCurrentPage } from "./useCurrentPage";
import { TabLink } from "./TabLink";
import useTabNames from "./useTabNames";

export interface GenericTabPageProps {
    header?: React.ReactNode;
}

export function GenericTabPage(props: GenericTabPageProps) {
    const location = useLocation();
    const tabNames = useTabNames();
    const { currentPage, currentPageParams } = useCurrentPage();
    if (!currentPage.tab) return null;
    const tabs = getTabs(currentPage);
    if (tabs.length === 1) {
        const Component = tabs[0].tab.component;
        return (
            <PageContainer>
                {props.header ?? <PageHeader title={tabNames(tabs[0].tab.name)} />}
                <MainContent>
                    <Component />
                </MainContent>
            </PageContainer>
        );
    }
    return (
        <PageContainer>
            {props.header ?? <PageHeader title={tabNames(currentPage.tab.name)} />}
            <TabsContainer tw="mt-6">
                <Tabs value={location.pathname} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                    {tabs.map((t) => (
                        <TabLink
                            key={t.pattern}
                            faIcon={t.tab.icon}
                            label={tabNames(t.tab.name)}
                            value={t.url(...currentPageParams)}
                        />
                    ))}
                </Tabs>
            </TabsContainer>
            <MainContent>
                <Switch>
                    {tabs.map((t) => (
                        <Route key={t.pattern} path={t.pattern} exact component={t.tab.component} />
                    ))}
                </Switch>
            </MainContent>
        </PageContainer>
    );
}
