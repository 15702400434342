import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncThunkStatus } from "./thunks";

export interface CoreState {
    isOldVersion: boolean;
    isUnauthenticated: boolean;
    downloads: Record<string | number, FileDownloadInfo>;
}

export interface FileDownloadInfo {
    downloadKey: string | number;
    status: AsyncThunkStatus;
    percent?: number;
    name: string;
}

const initialState: CoreState = {
    isOldVersion: false,
    isUnauthenticated: false,
    downloads: {},
};

const coreSlice = createSlice({
    name: "core",
    initialState,
    reducers: {
        setIsOldVersion(state) {
            state.isOldVersion = true;
        },
        setIsUnauthenticated(state, action: PayloadAction<boolean | undefined>) {
            state.isUnauthenticated = action.payload ?? true;
        },
        addDownload(state, action: PayloadAction<FileDownloadInfo>) {
            state.downloads[action.payload.downloadKey] = action.payload;
        },
        updateDownload(state, action: PayloadAction<Partial<FileDownloadInfo> & { downloadKey: string | number }>) {
            const current = state.downloads[action.payload.downloadKey];
            if (current) state.downloads[action.payload.downloadKey] = { ...current, ...action.payload };
        },
        removeDownload(state, action: PayloadAction<string | number>) {
            delete state.downloads[action.payload];
        },
    },
});

export const { setIsOldVersion, addDownload, removeDownload, updateDownload, setIsUnauthenticated } = coreSlice.actions;
export default coreSlice.reducer;
