import useNewEntityOnSubmit from "@magicware/forms/hooks/useNewEntityOnSubmit";
import { useTranslation } from "react-i18next";
import { useAppInstaceNavigation, useAppInstanceId } from "../../navigation/appInstances";
import { useNavigationClickReplace } from "../../navigation/hooks";
import { clearList } from "../../redux/appInstanceVersions/appInstanceVersionsReducer";
import { appInstanceVersionsApi } from "../../services/api/appInstanceVersions";
import AppInstanceVersionForm, { appInstanceVersionForm } from "./AppInstanceVersionForm";

const { useDefaults } = appInstanceVersionForm.hooks;

export default function AppInstanceVersionNewTab() {
    const { t } = useTranslation();
    const [parentId, navigation] = useAppInstaceNavigation();
    const appInstanceId = useAppInstanceId();
    const onSubmit = useNewEntityOnSubmit(
        clearList,
        appInstanceVersionsApi.forms.create,
        useNavigationClickReplace(() => navigation.detail.versions.url(parentId, appInstanceId)),
        (f: typeof appInstanceVersionForm.f) => appInstanceVersionForm.transform.formTo.new(f, appInstanceId),
    );
    const initialValues = useDefaults();
    return (
        <AppInstanceVersionForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            autoFocus
            savePanel={{ buttonContent: t("create-new-version"), disableOnPristine: true }}
        />
    );
}
