import TitleTabPage from "@magicware/dashboard/navigation/TitleTabPage";
import { useLibraryId } from "../../navigation/libraries";
import { useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { libraryThunks } from "../../redux/libraries/librariesReducer";
import { RootState } from "../../redux/types";

export default function LibraryTabPage() {
    const libraryId = useLibraryId();
    const api = useAutoDispatchAsyncThunk(libraryThunks.load, libraryId);
    return (
        <TitleTabPage apiStatus={api.apiStatus} titleSelector={(state: RootState) => state.libraries.detail?.name} />
    );
}
