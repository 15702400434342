import Panel from "@magicware/dashboard/Panel";
import { buildForm } from "@magicware/forms/builder";
import Form, { FormImplementationProps } from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import FormTextField from "@magicware/forms/mui/FormTextField";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { updateDetail } from "../../redux/experiences/experiencesReducer";
import { ExperienceModelDetail } from "../../services/api/experiences";
import { FormDevicesAutocomplete } from "../devices/FormDevicesAutocomplete";
import { FormParametersAutocomplete } from "../parameters/FormParametersAutocomplete";
import { FormProjectSetupAutocomplete } from "../projectSetups/FormProjectSetupAutocomplete";

export const experienceForm = buildForm<ExperienceModelDetail>()(() => ({
    props: {
        remove: ["id", "version"],
        extra: [],
    },
    transformations: {
        toForm: { fromModel: (h, m: ExperienceModelDetail) => h.fromModel(m) },
        formTo: {
            new: (h, f) => h.toNewEntity(f),
            update: (h, f, m: ExperienceModelDetail) => h.toUpdateEntity(f, m),
        },
    },
    updateReduxModel: updateDetail,
    schema: (_, t) => ({
        description: yup.string().required(t("description-is-required")),
        device: yup.object().default(undefined),
        parameter: yup.object().default(undefined),
        projectSetup: yup.object().default(undefined),
    }),
}));

const f = experienceForm.f;

export default function ExperienceForm(props: FormImplementationProps<typeof f>) {
    const { t } = useTranslation();
    return (
        <Form validationSchema={experienceForm.validationSchema} {...props}>
            <Panel>
                <FormGrid>
                    <FormDevicesAutocomplete f={f} field="device" fullOption label="Device" filter={{}} />
                    <FormParametersAutocomplete f={f} field="parameter" fullOption label="Prameter" filter={{}} />
                    <FormProjectSetupAutocomplete f={f} field="projectSetup" fullOption label="Project" filter={{}} />
                    <FormTextField f={f} field="description" label={t("description-label")} multiline />
                </FormGrid>
            </Panel>
        </Form>
    );
}
