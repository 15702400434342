import SimplePage from "@magicware/dashboard/navigation/SimplePage";
import useNewEntityOnSubmit from "@magicware/forms/hooks/useNewEntityOnSubmit";
import { useTranslation } from "react-i18next";
import { useNavigationClickReplace } from "../../navigation/hooks";
import { clearList } from "../../redux/projectSetups/projectSetupsReducer";
import { projectSetupsApi } from "../../services/api/projectSetups";
import ProjectSetupForm, { projectSetupForm } from "./ProjectSetupForm";

const { useDefaults } = projectSetupForm.hooks;

export default function ProjectSetupNewPage() {
    const { t } = useTranslation();
    const onSubmit = useNewEntityOnSubmit(
        clearList,
        projectSetupsApi.forms.create,
        useNavigationClickReplace((n) => n.projectSetups.url()),
        projectSetupForm.transform.formTo.new,
    );
    const initialValues = useDefaults();
    return (
        <SimplePage title={t("new-project-title")}>
            <ProjectSetupForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                autoFocus
                savePanel={{ buttonContent: t("create-new-project"), disableOnPristine: true }}
            />
        </SimplePage>
    );
}
