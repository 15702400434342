import { MatchingTypeKeys } from "./types";

export function emptyDataDictionary(): DataDictionary<any, any> {
    return { all: [], byId: {} };
}

export interface DataDictionary<TData, TFilter = undefined> {
    byId: Record<number, TData | undefined>;
    all: number[];
    filter?: TFilter;
    page?: number;
}

export function createDataDictionary<TOption extends { id: number }, TFilter = undefined>(
    all: TOption[],
    filter?: TFilter,
) {
    const dic: DataDictionary<TOption, TFilter> = { byId: {}, all: [], filter: filter };
    for (let i = 0; i < all.length; i++) {
        dic.byId[all[i].id] = all[i];
        dic.all.push(all[i].id);
    }
    return dic;
}

export function appendNewEntities<Item extends { id: number }>(
    dic: DataDictionary<Item, any> | undefined,
    newItems: Item[],
) {
    if (!dic) return;
    for (let i = 0; i < newItems.length; i++) {
        const el = newItems[i];
        if (!dic?.byId[el.id]) {
            dic.all.push(el.id);
            dic.byId[el.id] = el as any;
        }
    }
}

export function removeEntity<Item extends { id: number }>(
    dic: DataDictionary<Item, any> | undefined,
    match: number | ((i: Item) => boolean),
) {
    if (!dic) return;
    if (typeof match === "number") {
        dic.all = dic.all.filter((id) => id !== match);
        delete dic.byId[match];
    } else {
        const item = Object.values(dic.byId).find((i) => !!i && match(i));
        if (item) {
            delete dic.byId[item.id];
            dic.all = dic.all.filter((id) => id !== item.id);
        }
    }
}

export function updateEntity<T>(target: T | undefined, update: Partial<T>) {
    if (!target) return;
    for (const key in update) {
        if (update[key] !== undefined) target[key] = update[key]!;
        else delete target[key];
    }
}

export function updateListEntity<
    TList,
    TDetail extends { id: number },
    TProps extends MatchingTypeKeys<TDetail, TList>,
>(
    targetDic: DataDictionary<TList, any> | undefined,
    detail: TDetail | undefined,
    keys: (TProps & keyof TDetail)[],
    update?: (target: TList, detail: TDetail) => void,
) {
    if (!targetDic || !detail) return;
    const target = targetDic.byId[detail.id];
    if (!target) return;
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        target[key] = detail[key] as any;
    }
    if (update) update(target, detail);
}
