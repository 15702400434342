import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { languages } from ".";

export default function LanguageSwitch(props: { className?: string }) {
    const { i18n } = useTranslation();
    return (
        <div tw="flex flex-nowrap">
            <Button
                {...props}
                variant="text"
                tw="min-w-0 min-h-0 py-0"
                onClick={() => i18n.changeLanguage(languages.cs.id)}
            >
                {languages.cs.short}
            </Button>
            <Button
                {...props}
                variant="text"
                tw="min-w-0 min-h-0 py-0"
                onClick={() => i18n.changeLanguage(languages.en.id)}
            >
                {languages.en.short}
            </Button>
        </div>
    );
}
