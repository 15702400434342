import { useEffect } from "react";
import { CanelableApiParams } from "./fetch";
import useApi from "./useApi";

export default function useAutoLoadApi<TResult, TParams extends any[]>(
    apiCall: (...rest: TParams) => Promise<TResult>,
    ...params: CanelableApiParams<TParams>
) {
    const api = useApi(apiCall);
    useEffect(() => {
        api.invoke(...params);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api.invoke, ...params]);
    return { apiStatus: api.apiStatus, data: api.data, retry: api.retry };
}
