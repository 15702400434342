import Panel from "@magicware/dashboard/Panel";
import { buildForm } from "@magicware/forms/builder";
import Form from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import useNewEntityOnSubmit from "@magicware/forms/hooks/useNewEntityOnSubmit";
import FormTextField from "@magicware/forms/mui/FormTextField";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useNavigationClickReplace } from "../../navigation/hooks";
import { useLibraryId } from "../../navigation/libraries";
import { clearList } from "../../redux/libraryVersions/libraryVersionsReducer";
import { LibraryVersionModelNew, libraryVersionsApi } from "../../services/api/libraryVersions";

export const libraryVersionNewForm = buildForm<LibraryVersionModelNew>()(() => ({
    props: {
        remove: ["libraryId"],
        extra: [],
    },
    transformations: {
        formTo: { new: (_, f, libraryId: number): LibraryVersionModelNew => ({ name: f.name, libraryId }) },
    },
    schema: (_, t) => ({
        name: yup.string().required(t("name-is-required")),
    }),
}));

const {
    f,
    hooks: { useDefaults },
} = libraryVersionNewForm;

export default function LibraryVersionNewTab() {
    const { t } = useTranslation();
    const libraryId = useLibraryId();
    const onSubmit = useNewEntityOnSubmit(
        clearList,
        libraryVersionsApi.forms.create,
        useNavigationClickReplace((n, newId) => n.libraries.detail.versions.detail.url(libraryId, newId)),
        (f: typeof libraryVersionNewForm.f) => libraryVersionNewForm.transform.formTo.new(f, libraryId),
    );
    const initialValues = useDefaults();
    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            savePanel={{ buttonContent: t("create-new-version"), disableOnPristine: true }}
            validationSchema={libraryVersionNewForm.validationSchema}
        >
            <Panel>
                <FormGrid>
                    <FormTextField f={f} field="name" label={t("name-label")} autoFocus />
                </FormGrid>
            </Panel>
        </Form>
    );
}
