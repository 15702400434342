import Panel from "@magicware/dashboard/Panel";
import { buildForm } from "@magicware/forms/builder";
import Form, { FormImplementationProps } from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import FormTextField from "@magicware/forms/mui/FormTextField";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { updateDetail } from "../../redux/baseAppVersions/baseAppVersionsReducer";
import { BaseAppVersionModelDetail } from "../../services/api/baseAppVersions";
import { FormLibraryVersionsAutocomplete } from "../libraryVersions/FormLibraryVersionsAutocomplete";

export const baseAppVersionForm = buildForm<BaseAppVersionModelDetail>()(() => ({
    props: {
        remove: ["id", "version"],
        extra: [],
    },
    transformations: {
        toForm: { fromModel: (h, m: BaseAppVersionModelDetail) => h.fromModel(m) },
        formTo: {
            update: (h, f, m: BaseAppVersionModelDetail) => h.toUpdateEntity(f, m),
        },
    },
    updateReduxModel: updateDetail,
    schema: (_, t) => ({
        name: yup.string().required(t("name-is-required")),
        libraryVersionList: yup.array().default([]),
        notes: yup.string(),
    }),
}));

const f = baseAppVersionForm.f;

export default function BaseAppVersionForm(props: FormImplementationProps<typeof f>) {
    const { t } = useTranslation();
    return (
        <Form validationSchema={baseAppVersionForm.validationSchema} {...props}>
            <Panel>
                <FormGrid>
                    <FormTextField f={f} field="name" label={t("name-label")} autoFocus={props.autoFocus} />
                    <FormLibraryVersionsAutocomplete
                        f={f}
                        field="libraryVersionList"
                        fullOption
                        multiple
                        label={t("libraries-label")}
                        filter={{}}
                    />
                    <FormTextField f={f} field="notes" label={t("notes-label")} multiline />
                </FormGrid>
            </Panel>
        </Form>
    );
}
