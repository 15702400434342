import DataListItem, { MainText } from "@magicware/data-lists/DataListItem";
import { StyledComponentProps } from "@mui/material";
import { useNavigationClick } from "../../navigation/hooks";
import { useAppSelector } from "../../redux/hooks";

export interface ConnectorVersionListItemProps extends StyledComponentProps {
    connectorVersionId: number;
}

export default function ConnectorVersionListItem({ connectorVersionId, ...props }: ConnectorVersionListItemProps) {
    const obj = useAppSelector((state) => state.connectorVersions.list?.byId[connectorVersionId]);
    const editClickFactory = useNavigationClick();
    if (!obj) return null;
    return (
        <DataListItem
            {...props}
            data-id={obj.id}
            onClick={editClickFactory((n) => n.connectors.detail.versions.detail.url(obj.connector.id, obj.id))}
        >
            <div tw="flex items-center">
                <MainText tw="mr-10">{obj.name}</MainText>
            </div>
        </DataListItem>
    );
}
