import createStoreHooks from "@magicware/redux/createStoreHooks";
import { Store } from "./types";
export const {
    useAppDispatch,
    useAppSelector,
    useAppSelectorEnsure,
    useAppState,
    useAutoDispatchAsyncThunk,
    useFormThunk,
    useManualDispatchAsyncThunk,
} = createStoreHooks<Store>();
