import { createEntityAdapter, EntityState } from "@magicware/redux/entities";
import { anchoreThunks, AsyncSliceState } from "@magicware/redux/thunks";
import { createSlice } from "@reduxjs/toolkit";
import {
    entityApi,
    ConnectorVersionModelDetail,
    ConnectorVersionFilter,
    ConnectorVersionModelList,
} from "../../services/api/connectorVersions";
import { RootState } from "../types";

export interface State
    extends EntityState<ConnectorVersionModelDetail, ConnectorVersionModelList, ConnectorVersionFilter>,
        AsyncSliceState {}

const initialState: State = { async: {} };

const adapter = createEntityAdapter("connectorVersions", entityApi, ["name"]);

const extraThunks = {};

const slice = createSlice({
    name: "connectorVersions",
    initialState,
    reducers: adapter.getReducers(),
    extraReducers: (builder) => {
        adapter.addExtraReducers(builder);
        //addAsyncThunkReducers(builder, extraThunks.xxx);
    },
});

export const connectorVersionThunks = anchoreThunks((state: RootState) => state.connectorVersions, {
    ...extraThunks,
    ...adapter.thunks,
});

export const actions = slice.actions;
export const { updateDetail, clearList, removeListItems, updateListItems, setPage } = slice.actions;
export default slice.reducer;
