import DereferedTranslatedText from "@magicware/utils/localization/DereferedTranslatedText";
import { Fragment } from "react";
import { BreadcrumbName } from "./utils";

export interface BreadcrumbNameTextProps {
    name: BreadcrumbName;
}

export default function BreadcrumbNameText({ name }: BreadcrumbNameTextProps) {
    if (typeof name === "function") return <DereferedTranslatedText text={name} />;
    else return <Fragment>{name}</Fragment>;
}
