import { buildSearchForm } from "@magicware/forms/builder/searchForm";
import { SearchForm } from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import FormTextField from "@magicware/forms/mui/FormTextField";
import { useTranslation } from "react-i18next";
import { parameterThunks } from "../../redux/parameters/parametersReducer";
import { RootState } from "../../redux/types";

const { f, useSearchForm } = buildSearchForm(
    parameterThunks.loadList,
    (state: RootState) => state.parameters.list?.filter,
    () => ({}),
);

export default function ParameterSearchForm() {
    const { t } = useTranslation();
    const searchForm = useSearchForm();
    return (
        <SearchForm {...searchForm}>
            <FormGrid tw="md:grid-cols-3">
                <FormTextField f={f} field="name" label={t("name-label")} searchIcon />
                <FormTextField f={f} field="kind" label={t("kind-label")} searchIcon />
                <FormTextField f={f} field="kind" label={t("type-label")} searchIcon />
            </FormGrid>
        </SearchForm>
    );
}
