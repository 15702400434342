import SimplePage from "@magicware/dashboard/navigation/SimplePage";
import useNewEntityOnSubmit from "@magicware/forms/hooks/useNewEntityOnSubmit";
import { useTranslation } from "react-i18next";
import { useNavigationClickReplace } from "../../navigation/hooks";
import { clearList } from "../../redux/experiences/experiencesReducer";
import { experiencesApi } from "../../services/api/experiences";
import ExperienceForm, { experienceForm } from "./ExperienceForm";

const { useDefaults } = experienceForm.hooks;

export default function ExperienceNewPage() {
    const { t } = useTranslation();
    const onSubmit = useNewEntityOnSubmit(
        clearList,
        experiencesApi.forms.create,
        useNavigationClickReplace((n) => n.experiences.url()),
        experienceForm.transform.formTo.new,
    );
    const initialValues = useDefaults();
    return (
        <SimplePage title={t("new-experience-title")}>
            <ExperienceForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                autoFocus
                savePanel={{ buttonContent: t("create-new-experience"), disableOnPristine: true }}
            />
        </SimplePage>
    );
}
