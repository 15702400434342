import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

export default function UnauthenticatedPage() {
    const { t } = useTranslation();
    const handleLogout = () => {
        // eslint-disable-next-line no-self-assign
        window.location.href = window.location.href;
    };
    return (
        <Dialog open fullWidth maxWidth="xs">
            <DialogTitle>{t("login-expired")}</DialogTitle>
            <DialogContent>{t("login-expired-message")}</DialogContent>
            <DialogActions>
                <Button variant="text" onClick={handleLogout}>
                    {t("ok-button")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
