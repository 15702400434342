import { AsyncThunkStatus } from "@magicware/redux/thunks";
import { DataDictionary } from "@magicware/utils/DataDictionary";
import {
    AutocompleteFreeSoloValueMapping,
    AutocompleteProps,
    AutocompleteValue,
    createFilterOptions,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useField, useForm } from "react-final-form";
import { useTranslation } from "react-i18next";
import { FormFieldProps } from "../builder";
import useFieldName from "./useFieldName";

export interface AutocompleteOptionsProps<TOption extends { id: number }> {
    optionsDic: DataDictionary<TOption, any> | undefined;
    optionsLoadStatus: AsyncThunkStatus;
    onLoadOptions: () => Promise<any>;
}

export interface FormAutocompleteProps<
    TValues,
    TOption extends { id: number },
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
> extends FormFieldProps<TValues, number | Record<string, any> | number[] | Record<string, any>[] | undefined>,
        AutocompleteOptionsProps<TOption>,
        Partial<
            Pick<
                AutocompleteProps<TOption, Multiple, DisableClearable, FreeSolo>,
                "getOptionLabel" | "freeSolo" | "multiple" | "filterOptions" | "onChange" | "placeholder"
            >
        > {
    /** V případě, kdy je fíld typu number a nedovoluje undefined hodnotu, nastavením tohoto příznaku se změnícho
     * chování tak, že jako nevybranou hodnotu se bude považovat hodnota 0. */
    zeroAsUndefined?: boolean;
    /** Nutno použít v případě, kdy fíld pro nevybranou hodnotu místo undefined používá null. */
    nullAsUndefined?: boolean;
    fullOption?: boolean;
    nonSearchable?: boolean;
    optionsFilter?: (o: TOption) => boolean;
}

const tautology = () => true;

function isFreeSoloValue<FreeSolo>(val: any): val is AutocompleteFreeSoloValueMapping<FreeSolo> {
    return typeof val === "string";
}

interface NewFreeSoloValue {
    id: -1;
    label: string;
    inputValue: string;
}

export const freeSoloOptionId = -1;

export function isNewFreeSoloValue(val: any): val is NewFreeSoloValue {
    return (
        val &&
        (val as NewFreeSoloValue).id === freeSoloOptionId &&
        typeof (val as NewFreeSoloValue).inputValue === "string"
    );
}

export const defaultFilter = createFilterOptions<any>({ ignoreCase: true, ignoreAccents: true });

export default function useFormAutocomplete<
    TValues,
    TOption extends { id: number },
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
>({
    f: _,
    field,
    optionsDic,
    optionsLoadStatus: optionsLoadState,
    onLoadOptions,
    fullOption = false,
    zeroAsUndefined = false,
    nullAsUndefined = false,
    optionsFilter = tautology,
    getOptionLabel,
    filterOptions,
    ...props
}: FormAutocompleteProps<TValues, TOption, Multiple, DisableClearable, FreeSolo>) {
    field = useFieldName(field);
    const { t } = useTranslation();
    const [isOpened, setIsOpened] = useState(false);
    const [inputVal, setInputVal] = useState(""); //pouziva se jen v multiple rezimu
    const formField = useField(field);
    const form = useForm();
    const allOptions = useMemo(
        () => optionsDic?.all.map((id) => optionsDic.byId[id]!).filter(optionsFilter) ?? [],
        [optionsDic, optionsFilter],
    );

    const emptySelectionValue = zeroAsUndefined && !fullOption ? 0 : nullAsUndefined ? null : undefined;

    let isMissingOption = false;
    let autoCompleteValue: AutocompleteValue<TOption, Multiple, DisableClearable, FreeSolo>;
    if (props.multiple) {
        const value = formField.input.value as
            | (TOption | AutocompleteFreeSoloValueMapping<FreeSolo>)[]
            | (number | AutocompleteFreeSoloValueMapping<FreeSolo>)[];
        let selectedOptions: (TOption | AutocompleteFreeSoloValueMapping<FreeSolo>)[] = [];
        for (let i = 0; i < value.length; i++) {
            const val = value[i];
            if (typeof val === "object") selectedOptions.push(val);
            else if (isFreeSoloValue<FreeSolo>(val)) selectedOptions.push(val);
            else if (optionsDic && optionsDic.byId[val]) selectedOptions.push(optionsDic.byId[val]!);
        }
        if (selectedOptions.length < value.length) {
            selectedOptions = [];
            isMissingOption = true;
        }
        autoCompleteValue = selectedOptions as AutocompleteValue<TOption, Multiple, DisableClearable, FreeSolo>;
    } else {
        let value = formField.input.value as TOption | number | undefined | AutocompleteFreeSoloValueMapping<FreeSolo>;
        let selectedOption: TOption | AutocompleteFreeSoloValueMapping<FreeSolo> | null = null;

        if (value === "") value = emptySelectionValue as any;

        if (value !== emptySelectionValue) {
            if (typeof value === "number" && optionsDic) selectedOption = optionsDic.byId[value] ?? null;
            else if (isFreeSoloValue<FreeSolo>(value)) selectedOption = value;
            else if (typeof value === "object") {
                selectedOption = value;
                if (!fullOption)
                    throw new Error(`Unexpected object for field ${field} in form. Only number is allowed.`);
            }
        }

        isMissingOption = value !== emptySelectionValue && selectedOption === null;
        autoCompleteValue = selectedOption as AutocompleteValue<TOption, Multiple, DisableClearable, FreeSolo>;
    }

    const needsLoading = (isOpened && !optionsDic) || isMissingOption;
    const isLoadingInProgress = needsLoading && optionsLoadState === "pending";

    useEffect(() => {
        if (optionsDic === undefined && optionsLoadState === "idle" && needsLoading) onLoadOptions();
    }, [needsLoading]);

    const onChange: typeof props.onChange = (e, val, reason, details) => {
        let formValue: any;
        if (reason === "createOption") return; //enter nepovolujeme
        if (props.freeSolo) {
            if (isNewFreeSoloValue(val)) val = val.inputValue as any;
            else if (typeof val === "object" && val instanceof Array) {
                val = val.map((obj) => (isNewFreeSoloValue(obj) ? obj.inputValue : obj)) as any;
            }
        }

        if (fullOption) {
            formValue = val;
        } else if (typeof val === "object") {
            if (val instanceof Array) formValue = val.map((obj) => (isFreeSoloValue<FreeSolo>(obj) ? obj : obj.id));
            else formValue = isFreeSoloValue<FreeSolo>(val) ? val : val?.id;
        }
        form.change(field, formValue ?? emptySelectionValue);
        return props.onChange && props.onChange(e, val, reason, details);
    };

    if (getOptionLabel && props.freeSolo) {
        const originalGetOptionLabel = getOptionLabel;
        getOptionLabel = (o) =>
            isFreeSoloValue<FreeSolo>(o) ? o : isNewFreeSoloValue(o) ? o.label : originalGetOptionLabel(o);
    } else if (!getOptionLabel) {
        getOptionLabel = (option) => (isFreeSoloValue<FreeSolo>(option) ? option : (option as any).label);
    }

    if (props.freeSolo) {
        const originalFilterOptions = filterOptions ?? defaultFilter;
        filterOptions = (options: TOption[], params) => {
            const filtered = originalFilterOptions(options, params);

            if (props.freeSolo && getOptionLabel) {
                const { inputValue } = params;
                const isExisting = options.some((option) => inputValue === getOptionLabel!(option));
                if (inputValue !== "" && !isExisting) {
                    const newValue: NewFreeSoloValue = {
                        id: freeSoloOptionId,
                        label: t("add-freesolo-option", { value: inputValue }),
                        inputValue,
                    };
                    filtered.push(newValue);
                }
            }

            return filtered;
        };
    }

    const isEmpty = !autoCompleteValue || (autoCompleteValue instanceof Array && autoCompleteValue.length === 0);

    const inputValue = props.multiple ? inputVal : undefined;
    const onInputChange: AutocompleteProps<TOption, Multiple, DisableClearable, FreeSolo>["onInputChange"] =
        props.multiple ? (e, val) => e && setInputVal(val) : undefined;

    const isOptionEqualToValue: AutocompleteProps<
        TOption,
        Multiple,
        DisableClearable,
        FreeSolo
    >["isOptionEqualToValue"] = (opt, val) => opt.id === val.id;

    return {
        getOptionLabel,
        options: allOptions,
        open: isOpened,
        onOpen: () => setIsOpened(true),
        onClose: () => setIsOpened(false),
        onFocus: formField.input.onFocus,
        onBlur: formField.input.onBlur,
        inputValue: inputValue,
        onInputChange: onInputChange,
        value: autoCompleteValue,
        isOptionEqualToValue,
        onChange: onChange,
        filterOptions: filterOptions,
        loading: isLoadingInProgress,
        extraProps: props,
        textFieldProps: {
            error: formField.meta.touched && Boolean(formField.meta.error || formField.meta.submitError),
            helperText: formField.meta.touched && (formField.meta.error || formField.meta.submitError),
            placeholder: isEmpty ? props.placeholder : undefined,
            //bez tohoto hotfixu při prvním vykreslení animuje label u položek, které jsou vybrané
            value: isEmpty ? "" : "hotfix",
        },
    };
}
