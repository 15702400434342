import { createAsyncFormAutocompleteWithFilter } from "@magicware/forms/mui/FormAutocomplete";
import { parameterThunks } from "../../redux/parameters/parametersReducer";
import { RootState } from "../../redux/types";
import { ParameterSelectOption } from "../../services/api/parameters";
import { createDefaultProps } from "../forms/formAutocomplete";

export const FormParametersAutocomplete = createAsyncFormAutocompleteWithFilter(
    {
        selectOptions: (state: RootState) => state.parameters.list,
        createAsyncAction: parameterThunks.loadList,
        selectAsyncState: parameterThunks.loadList.selectState,
    },
    createDefaultProps<ParameterSelectOption>(
        (opt) => `${opt.name}`,
        (n, opt) => n.parameters.detail.url(opt.id),
    ),
);
