import { AsyncThunkStatus } from "@magicware/redux/thunks";
import { IEmpty } from "@magicware/utils/types";
import { Alert, Button } from "@mui/material";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import Loader from "./Loader";

export interface ApiStatus {
    apiStatus: AsyncThunkStatus;
    retry?: () => void;
}

export interface AsyncContentProps<ComponentProps = IEmpty> extends Omit<ApiStatus, "apiStatus"> {
    apiStatus: AsyncThunkStatus | ApiStatus[];
    component?: React.ComponentType<ComponentProps>;
    loaderComponent?: React.ComponentType;
    children?: React.ReactNode;
    bypassSelector?: (state: unknown) => boolean;
}

export default function AsyncContent<ComponentProps = IEmpty>(
    props: AsyncContentProps<ComponentProps> & ComponentProps,
) {
    const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        apiStatus: _,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        retry: __,
        component: Component,
        loaderComponent: LoaderComponent = Loader,
        children,
        bypassSelector,
        ...componentProps
    } = props;
    const { apiStatus, retry } = getApiStatus(props);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const bypass = bypassSelector && useSelector(bypassSelector);
    if (apiStatus === "success" || bypass) {
        if (Component) return <Component {...(componentProps as any as ComponentProps)} />;
        return <Fragment>{children}</Fragment>;
    }
    if (apiStatus === "pending" || apiStatus === "idle") return <LoaderComponent />;
    if (apiStatus === "aborted")
        return (
            <Alert
                severity="error"
                action={
                    retry && (
                        <Button tw="whitespace-nowrap" variant="text" color="inherit" size="small" onClick={retry}>
                            Try Again
                        </Button>
                    )
                }
            >
                Operation was canceled.
            </Alert>
        );
    if (apiStatus === "failed")
        return (
            <Alert
                severity="error"
                action={
                    retry && (
                        <Button tw="whitespace-nowrap" variant="text" color="inherit" size="small" onClick={retry}>
                            Try Again
                        </Button>
                    )
                }
            >
                Ooops, data were not loaded due to some unexpected error. Please check the internet connection and try
                again.
            </Alert>
        );
    return null;
}

function getApiStatus(props: AsyncContentProps<any>): ApiStatus {
    if (typeof props.apiStatus === "string") return props as ApiStatus;

    for (let i = 0; i < props.apiStatus.length; i++) {
        const status = props.apiStatus[i];
        if (status.apiStatus !== "success") return status;
    }

    return props.apiStatus[props.apiStatus.length - 1];
}
