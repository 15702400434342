import ManualTabPage from "@magicware/dashboard/navigation/ManualTabPage";
import { navigation } from "../../navigation";
import { apiServerInstanceIcon } from "../../navigation/apiServerInstances";
import { apiServerVersionIcon } from "../../navigation/apiServers";
import { baseAppIcon } from "../../navigation/baseApps";
import { brandIcon } from "../../navigation/brands";
import { libraryIcon } from "../../navigation/libraries";
import { parametersIcon } from "../../navigation/parameters";
import ApiServerInstanceListPage from "../apiServerInstances/ApiServerInstanceListPage";
import ApiServerVersionListPage from "../apiServerVersions/ApiServerVersionListPage";
import BaseAppListPage from "../baseApps/BaseAppListPage";
import BrandListPage from "../brands/BrandListPage";
import LibraryListPage from "../libraries/LibraryListPage";
import ParameterListPage from "../parameters/ParameterListPage";

export default function ConfigurationTabPage() {
    return (
        <ManualTabPage
            tabs={[
                { page: navigation.parameters, icon: parametersIcon, component: ParameterListPage },
                { page: navigation.brands, icon: brandIcon, component: BrandListPage },
                { page: navigation.libraries, icon: libraryIcon, component: LibraryListPage },
                { page: navigation.baseApps, icon: baseAppIcon, component: BaseAppListPage },
                { page: navigation.apiServerVersions, icon: apiServerVersionIcon, component: ApiServerVersionListPage },
                {
                    page: navigation.apiServerInstances,
                    icon: apiServerInstanceIcon,
                    component: ApiServerInstanceListPage,
                },
            ]}
        />
    );
}
