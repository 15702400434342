import useNewEntityOnSubmit from "@magicware/forms/hooks/useNewEntityOnSubmit";
import { useTranslation } from "react-i18next";
import { useConnectorId } from "../../navigation/connectors";
import { useNavigationClickReplace } from "../../navigation/hooks";
import { clearList } from "../../redux/connectorVersions/connectorVersionsReducer";
import { connectorVersionsApi } from "../../services/api/connectorVersions";
import ConnectorVersionForm, { connectorVersionForm } from "./ConnectorVersionForm";

const { useDefaults } = connectorVersionForm.hooks;

export default function ConnectorVersionNewTab() {
    const { t } = useTranslation();
    const connectorId = useConnectorId();
    const onSubmit = useNewEntityOnSubmit(
        clearList,
        connectorVersionsApi.forms.create,
        useNavigationClickReplace((n) => n.connectors.detail.versions.url(connectorId)),
        (f: typeof connectorVersionForm.f) => connectorVersionForm.transform.formTo.new(f, connectorId),
    );
    const initialValues = useDefaults();
    return (
        <ConnectorVersionForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            autoFocus
            savePanel={{ buttonContent: t("create-new-version"), disableOnPristine: true }}
        />
    );
}
