import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AsyncContent from "@magicware/dashboard/AsyncContent";
import { INavigatablePage } from "@magicware/dashboard/navigation/utils";
import Panel from "@magicware/dashboard/Panel";
import PanelHeader from "@magicware/dashboard/PanelHeader";
import PanelsGrid from "@magicware/dashboard/PanelsGrid";
import useAutoLoadApi from "@magicware/fetch-api/useAutoLoadApi";
import { ButtonBase } from "@mui/material";
import { useHistory } from "react-router-dom";
import tw from "twin.macro";
import { navigation } from "../../navigation";
import { brandIcon } from "../../navigation/brands";
import { connectorIcon } from "../../navigation/connectors";
import { deviceIcon } from "../../navigation/devices";
import { parametersIcon } from "../../navigation/parameters";
import { projectSetupIcon } from "../../navigation/projectSetups";
import { ObjectSummary, RegistrySummary, statsApi } from "../../services/api/stats";

export default function RegistrySummaries() {
    const api = useAutoLoadApi(statsApi.loadRegistrySummary);

    return <AsyncContent {...api} component={RegistrySummariesLoaded} />;
}

interface RegistrySummariesLoadedProps {
    data: RegistrySummary;
}

function RegistrySummariesLoaded({ data }: RegistrySummariesLoadedProps) {
    return (
        <PanelsGrid tw="sm:grid-cols-2">
            <ObjectSummaryPanel title="Značky" icon={brandIcon} page={navigation.brands} sum={data.brands} />
            <ObjectSummaryPanel title="Zařízení" icon={deviceIcon} page={navigation.devices} sum={data.devices} />
            <ObjectSummaryPanel
                title="Parametry"
                icon={parametersIcon}
                page={navigation.parameters}
                sum={data.prameters}
            />
            <ObjectSummaryPanel
                title="Konektory"
                icon={connectorIcon}
                page={navigation.connectors}
                sum={data.connectors}
            />
            <ObjectSummaryPanel
                title="Projekty"
                icon={projectSetupIcon}
                page={navigation.projectSetups}
                sum={data.projectSetups}
            />
        </PanelsGrid>
    );
}

interface ObjectSummaryPanelProps {
    title: React.ReactNode;
    sum: ObjectSummary;
    icon?: IconProp;
    page: INavigatablePage;
}

const CliclablePanelContainer = tw(ButtonBase)`w-full text-left flex items-center bg-listitem-default rounded-xl p-4`;

function ObjectSummaryPanel({ icon, title, sum, page }: ObjectSummaryPanelProps) {
    const history = useHistory();
    const handleClick = () => history.push(page.url());
    return (
        <Panel>
            <PanelHeader tw="items-center">{title}</PanelHeader>
            <CliclablePanelContainer onClick={handleClick}>
                <span tw="mr-2">Celkem:</span>
                <strong>{sum.totalCount}</strong>
                {icon && <FontAwesomeIcon icon={icon} tw="ml-auto text-primary-400" />}
            </CliclablePanelContainer>
        </Panel>
    );
}
