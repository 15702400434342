import { createEntityApi } from "@magicware/fetch-api/entities";
import { getData } from "@magicware/fetch-api/fetch";
import { IdVersion } from "@magicware/fetch-api/types";
import { replaceEntities } from "@magicware/utils/replaceEntities";
import { API_BASE_URL } from "../globals";
import { BrandSelectOption } from "./brands";
import { DeviceSelectOption } from "./devices";
import { createFilesApi } from "./files";
import { ParameterSelectOption } from "./parameters";
import { convertUseCaseFilters } from "./useCaseFilters";

export interface ProjectSetupSelectOption {
    id: number;
    name: string;
}

export interface ProjectSetupModelBase {
    name: string;
    notes?: string;
    urls?: string;
    isOwn?: boolean;
}

export interface ProjectSetupEntity extends ProjectSetupModelBase {
    brandId?: number;
}

export interface ProjectSetupModelNew extends ProjectSetupEntity {}
export interface ProjectSetupModelUpdate extends ProjectSetupEntity, IdVersion {
    parameterIds: number[];
    excludedParameterIds: number[];
    deviceIds: number[];
}
export interface ProjectSetupModelDetail extends ProjectSetupModelBase, IdVersion {
    brand?: BrandSelectOption;
    parameterList: ParameterSelectOption[];
    excludedParameterList: ParameterSelectOption[];
    deviceList: DeviceSelectOption[];
}

export const toUpdate = (model: ProjectSetupModelDetail): ProjectSetupModelUpdate => ({
    ...replaceEntities(model),
});

export interface ProjectSetupModelList extends ProjectSetupSelectOption {
    isOwn?: boolean;
    brand: string;
}
export interface ProjectSetupFilter {
    name?: string;
    brandId?: number[];
    useCaseFilter?: ProjectSetupUseCaseFilterType[];
}

export type ProjectSetupUseCaseFilterType = "Own" | "NotOwn";

export interface ProjectSetupTabInfo {
    id: number;
    name: string;
}

const baseUrl = `${API_BASE_URL}registry/projectSetups`;

export const entityApi = createEntityApi<
    ProjectSetupModelNew,
    ProjectSetupModelUpdate,
    ProjectSetupModelDetail,
    ProjectSetupModelList,
    ProjectSetupFilter
>("projectSetup", baseUrl, {
    convertFilterToParameters: (f) => convertUseCaseFilters(f, "useCaseFilter"),
});

export const projectSetupsApi = {
    ...entityApi,
    files: createFilesApi("projectSetups"),
    loadTabInfo: (projectSetupId: number, signal?: AbortSignal) =>
        getData<ProjectSetupTabInfo>(`${baseUrl}/tabInfo`, { projectSetupId }, { signal }),
};
