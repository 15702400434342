import { faCloud } from "@fortawesome/free-solid-svg-icons";
import { prepareBreadcrumb } from "@magicware/dashboard/navigation/breadcrumbs";
import { CreatePatternMethod, CreateSimpleMethod } from "@magicware/dashboard/navigation/builder";
import { prepareRootTab } from "@magicware/dashboard/navigation/tabs";
import { createUseIntParam } from "@magicware/dashboard/navigation/useIntParam";
import ApiServerInstanceDetailTabPage from "../components/apiServerInstances/ApiServerInstanceDetailTabPage";
import ApiServerInstanceNewPage from "../components/apiServerInstances/ApiServerInstanceNewPage";
import ApiServerInstanceTabPage from "../components/apiServerInstances/ApiServerInstanceTabPage";
import ConfigurationTabPage from "../components/configuration/ConfigurationTabPage";

export const useApiServerInstanceId = createUseIntParam("apiServerInstanceId");

export const apiServerInstanceIcon = faCloud;

export const buildApiServerInstances = (simple: CreateSimpleMethod, pattern: CreatePatternMethod) => {
    //let tabIndex = 1;
    return simple({
        url: "/apiServerInstances",
        title: (t) => t("apiServerInstances-title"),
        component: ConfigurationTabPage,
        breadcrumb: prepareBreadcrumb((t) => t("apiServerInstances-breadcrumb")),

        new: simple({
            url: "/apiServerInstances/new",
            component: ApiServerInstanceNewPage,
            breadcrumb: prepareBreadcrumb((t) => t("new-apiServerInstance-breadcrumb")),
        }),
        detail: pattern({
            pattern: "/apiServerInstances/:apiServerInstanceId",
            component: ApiServerInstanceTabPage,
            url: (apiServerInstanceId: number) => `/apiServerInstances/${apiServerInstanceId}`,
            tab: prepareRootTab(
                (t) => t("apiServerInstance-detail-tab"),
                apiServerInstanceIcon,
                ApiServerInstanceDetailTabPage,
            ),
            breadcrumb: prepareBreadcrumb((t) => t("apiServerInstance-detail-breadcrumb")),
        }),
    });
};
