import { createEntityAdapter, EntityState } from "@magicware/redux/entities";
import { anchoreThunks, AsyncSliceState } from "@magicware/redux/thunks";
import { createSlice } from "@reduxjs/toolkit";
import {
    entityApi,
    AppInstanceConnectionModelDetail,
    AppInstanceConnectionFilter,
    AppInstanceConnectionModelList,
} from "../../services/api/appInstanceConnections";
import { RootState } from "../types";

export interface State
    extends EntityState<AppInstanceConnectionModelDetail, AppInstanceConnectionModelList, AppInstanceConnectionFilter>,
        AsyncSliceState {}

const initialState: State = { async: {} };

const adapter = createEntityAdapter("appInstanceConnections", entityApi, [
    "isPull",
    "targetAppInstance",
    "targetConnector",
]);

const extraThunks = {};

const slice = createSlice({
    name: "appInstanceConnections",
    initialState,
    reducers: adapter.getReducers(),
    extraReducers: (builder) => {
        adapter.addExtraReducers(builder);
        //addAsyncThunkReducers(builder, extraThunks.xxx);
    },
});

export const appInstanceConnectionThunks = anchoreThunks((state: RootState) => state.appInstanceConnections, {
    ...extraThunks,
    ...adapter.thunks,
});

export const actions = slice.actions;
export const { updateDetail, clearList, removeListItems, updateListItems, setPage } = slice.actions;
export default slice.reducer;
