import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CheckAllCheckbox from "./CheckAllCheckbox";
import { DataListHeaderBulkActions } from "./DataListBulkActionsContext";
import Paging, { PagingState } from "./Paging";
import { useTranslation } from "react-i18next";

export interface DataListHeaderProps {
    pagingState: PagingState;
    checkAllIdsSelector?: (s: unknown) => number[] | undefined;
}

export default function DataListHeader({ pagingState, checkAllIdsSelector, ...props }: DataListHeaderProps) {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
    return (
        <div tw="flex justify-between" {...props}>
            {checkAllIdsSelector && (
                <div tw="flex-grow-0 mr-10 order-1">
                    <CheckAllCheckbox selectItems={checkAllIdsSelector} label="Select All" />
                </div>
            )}
            <div tw="flex-1 flex flex-nowrap order-2 items-center space-x-3">
                <Paging state={pagingState} listSize={isMdUp ? 1 : 0} />
                <span tw="text-gray-500 text-sm">{t("records-count-found", { records: pagingState.records })}</span>
            </div>
            <div tw="flex-grow-0 order-3 flex flex-nowrap items-center">
                <DataListHeaderBulkActions />
            </div>
        </div>
    );
}
