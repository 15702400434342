import { createEntityAdapter, EntityState } from "@magicware/redux/entities";
import { anchoreThunks, AsyncSliceState } from "@magicware/redux/thunks";
import { createSlice } from "@reduxjs/toolkit";
import {
    entityApi,
    BaseAppVersionModelDetail,
    BaseAppVersionFilter,
    BaseAppVersionModelList,
    baseAppVersionsApi,
} from "../../services/api/baseAppVersions";
import addVersionUpdateReducer from "../files/addVersionUpdateReducer";
import { RootState } from "../types";

export interface State
    extends EntityState<BaseAppVersionModelDetail, BaseAppVersionModelList, BaseAppVersionFilter>,
        AsyncSliceState {}

const initialState: State = { async: {} };

const adapter = createEntityAdapter("baseAppVersions", entityApi, ["name"]);

const extraThunks = {};

const slice = createSlice({
    name: "baseAppVersions",
    initialState,
    reducers: adapter.getReducers(),
    extraReducers: (builder) => {
        adapter.addExtraReducers(builder);
        //addAsyncThunkReducers(builder, extraThunks.xxx);
        addVersionUpdateReducer(builder, baseAppVersionsApi.files);
    },
});

export const baseAppVersionThunks = anchoreThunks((state: RootState) => state.baseAppVersions, {
    ...extraThunks,
    ...adapter.thunks,
});

export const actions = slice.actions;
export const { updateDetail, clearList, removeListItems, updateListItems, setPage } = slice.actions;
export default slice.reducer;
