import { appInstanceThunks } from "../../redux/appInstances/appInstancesReducer";
import { useAppDispatch, useAppSelectorEnsure, useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { appInstancesApi } from "../../services/api/appInstances";
import AppInstanceForm, { appInstanceForm } from "./AppInstanceForm";
import AsyncContent from "@magicware/dashboard/AsyncContent";
import { useTranslation } from "react-i18next";
import { useAppInstanceId } from "../../navigation/appInstances";
import { useBaseAppId } from "../../navigation/baseApps";

const { useToFormFromModel } = appInstanceForm.hooks;

export default function AppInstanceDetailTab() {
    const api = useAutoDispatchAsyncThunk(appInstanceThunks.load, useAppInstanceId());
    return <AsyncContent {...api} component={AppInstanceDetailTabLoaded} />;
}

function AppInstanceDetailTabLoaded() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const appInstanceDetail = useAppSelectorEnsure((state) => state.appInstances.detail);
    const baseAppId = useBaseAppId(false);
    const initialValues = useToFormFromModel(appInstanceDetail, baseAppId);
    const onSubmit = async (form: typeof appInstanceForm.f) => {
        const result = await appInstancesApi.forms.update(
            appInstanceForm.transform.formTo.update(form, appInstanceDetail),
        );
        return appInstanceForm.apiUtils.handleUpdateEntityResult(dispatch, result, form);
    };
    return (
        <AppInstanceForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            savePanel={{ successMessage: t("appInstance-has-been-saved") }}
        />
    );
}
