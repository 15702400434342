import { createEntityApi } from "@magicware/fetch-api/entities";
import { IdVersion } from "@magicware/fetch-api/types";
import { API_BASE_URL } from "../globals";
import { createFilesApi } from "./files";
import { ParameterSelectOption } from "./parameters";

export interface LibraryVersionSelectOption {
    id: number;
    name: string;
    library: {
        id: number;
        name: string;
    };
    platform: string;
}

export interface LibraryVersionModelBase {
    name: string;
    notes?: string;
}

export interface LibraryVersionEntity {
    apiParameterIds: number[];
}

export interface LibraryVersionModelNew {
    name: string;
    libraryId: number;
}
export interface LibraryVersionModelUpdate extends LibraryVersionEntity, IdVersion {}
export interface LibraryVersionModelDetail extends LibraryVersionModelBase, IdVersion {
    library: string;
    apiParameterList: ParameterSelectOption[];
}

export interface LibraryVersionModelList extends LibraryVersionSelectOption {}
export interface LibraryVersionFilter {
    name?: string;
    libraryId?: number;
}

export const entityApi = createEntityApi<
    LibraryVersionModelNew,
    LibraryVersionModelUpdate,
    LibraryVersionModelDetail,
    LibraryVersionModelList,
    LibraryVersionFilter
>("libraryVersion", `${API_BASE_URL}registry/libraryVersions`);

export const libraryVersionsApi = {
    ...entityApi,
    files: createFilesApi("libraryVersions"),
};
