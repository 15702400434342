import { createAsyncFormAutocompleteWithFilter } from "@magicware/forms/mui/FormAutocomplete";
import { baseAppThunks } from "../../redux/baseApps/baseAppsReducer";
import { RootState } from "../../redux/types";
import { BaseAppSelectOption } from "../../services/api/baseApps";
import { createDefaultProps } from "../forms/formAutocomplete";

export const FormBaseAppsAutocomplete = createAsyncFormAutocompleteWithFilter(
    {
        selectOptions: (state: RootState) => state.baseApps.list,
        createAsyncAction: baseAppThunks.loadList,
        selectAsyncState: baseAppThunks.loadList.selectState,
    },
    createDefaultProps<BaseAppSelectOption>(
        (opt) => `${opt.name}`,
        (n, opt) => n.baseApps.detail.url(opt.id),
    ),
);
