import { createEntityApi } from "@magicware/fetch-api/entities";
import { IdVersion } from "@magicware/fetch-api/types";
import { API_BASE_URL } from "../globals";

export interface ParameterSelectOption {
    id: number;
    name: string;
}

export interface ParameterModelBase {
    name: string;
    systemName?: string;
    type: string;
    unit: string;
    kind: string;
    description: string;
    notes?: string;
}

export interface ParameterModelNew extends ParameterModelBase {}
export interface ParameterModelUpdate extends ParameterModelBase, IdVersion {}
export interface ParameterModelDetail extends ParameterModelBase, IdVersion {}
export interface ParameterModelList extends ParameterSelectOption {
    type: string;
    unit: string;
    kind: string;
}
export interface ParameterFilter {
    name?: string;
    type?: string;
    kind?: string;
}

export const entityApi = createEntityApi<
    ParameterModelNew,
    ParameterModelUpdate,
    ParameterModelDetail,
    ParameterModelList,
    ParameterFilter
>("parameter", `${API_BASE_URL}registry/parameters`);

export const parametersApi = entityApi;
