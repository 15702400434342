import { EntityApi } from "@magicware/fetch-api/entities";
import { createDataDictionary, DataDictionary, updateEntity, updateListEntity } from "@magicware/utils/DataDictionary";
import { MatchingTypeKeys } from "@magicware/utils/types";
import * as tk from "@reduxjs/toolkit";
import { addAsyncThunkReducers, anchoreThunks, AsyncSliceState, createAppAsyncThunk } from "./thunks";

export interface EntityState<TDetail, TListItem, TFilter> {
    detail?: TDetail;
    list?: DataDictionary<TListItem, TFilter>;
    selected?: DataDictionary<TListItem>;
}
export function createEntityAdapter<
    TApi extends EntityApi<TNew, TUpdate, TDetail, TList, TFilter, TListResult>,
    TCommonProps extends MatchingTypeKeys<TDetail, TList>,
    TNew = TApi extends EntityApi<infer T, any, any, any, any> ? T : never,
    TUpdate = TApi extends EntityApi<any, infer T, any, any, any> ? T : never,
    TDetail extends { id: number } = TApi extends EntityApi<any, any, infer T, any, any>
        ? T extends { id: number }
            ? T
            : never
        : never,
    TList extends { id: number } = TApi extends EntityApi<any, any, any, infer T, any, any>
        ? T extends { id: number }
            ? T
            : never
        : never,
    TFilter = TApi extends EntityApi<any, any, any, any, infer T, any> ? T : never,
    TListResult extends TList[] | { list: TList[]; data: TListData } = ExtractListResult<TList, TApi>,
    TListData = TListResult extends TList[] | { list: TList[]; data: infer T } ? T : never,
>(
    entityName: string,
    api: EntityApi<TNew, TUpdate, TDetail, TList, TFilter, TListResult>,
    commonProps: TCommonProps[],
    options?: {
        onDetailUpdated?: (state: any, payload: Partial<TDetail> & { id: number }) => void;
    },
) {
    type State = EntityState<TDetail, TList, TFilter> & AsyncSliceState;

    const thunks = {
        // create: createVolatileAsyncThunk(entityName + "/create", (newEntity: TNew) => api.create(newEntity)),
        // update: createVolatileAsyncThunk(entityName + "/update", (entity: TUpdate) => api.update(entity)),
        load: createAppAsyncThunk(
            entityName + "/load",
            "exclusive",
            (entityId: number, thunkApi) => api.load(entityId, thunkApi.signal),
            (entityId) => entityId.toString(),
        ),
        loadList: createAppAsyncThunk(
            entityName + "/loadList",
            "exclusive",
            (filter: TFilter, thunkApi) => api.loadList(filter, thunkApi.signal),
            (filter) => JSON.stringify(filter),
        ),
        updateList: createAppAsyncThunk(entityName + "/updateList", (ids: number[], thunkApi) =>
            api.loadByIds(ids, thunkApi.signal),
        ),
        delete: createAppAsyncThunk(
            entityName + "/delete",
            (arg: { entityId: number}, thunkApi) => api.delete([arg.entityId], thunkApi.signal)
        )
    };

    const reducers = {
        updateDetail: (state: State, payload: Partial<TDetail> & { id: number }) => {
            updateEntity(state.detail, payload);
            if (state.detail) updateListEntity(state.list, state.detail, commonProps);
            options?.onDetailUpdated && options.onDetailUpdated(state, payload);
        },
        updateListItems: (state: State, payload: { entities: TList[] }) => {
            if (state.list) {
                for (let i = 0; i < payload.entities.length; i++) {
                    const listItem = payload.entities[i];
                    if (state.list.byId[listItem.id]) state.list.byId[listItem.id] = listItem;
                }
            }
        },
        clearDetail(state: State) {
            delete state.detail;
            delete state.async[thunks.load.typePrefix];
        },
        clearList(state: State) {
            delete state.list;
            delete state.async[thunks.loadList.typePrefix];
        },
        setList(state: State, payload: { entities: TList[]; filter: TFilter }) {
            state.list = createDataDictionary(payload.entities, payload.filter);
        },
        setDetail(state: State, payload: TDetail) {
            state.detail = payload;
        },
        removeListItems(state: State, payload: { entityIds: number[] }) {
            if (state.list) {
                state.list.all = state.list.all.filter((id) => !payload.entityIds.includes(id));
                for (let i = 0; i < payload.entityIds.length; i++) {
                    const id = payload.entityIds[i];
                    delete state.list.byId[id];
                }
            }
        },
        setPage(state: State, payload: { page: number }) {
            if (state.list) state.list.page = payload.page;
        },
        resetSelected(state: State) {
            delete state.selected;
        },
        toggleSelected(state: State, payload: { id: number; selected?: boolean }) {
            const currentSelected = state.selected?.byId[payload.id] !== undefined;
            const selected = payload.selected ?? currentSelected;
            if (selected !== currentSelected) {
                if (!state.selected) state.selected = { byId: {}, all: [] };
                if (selected) {
                    if (state.list?.byId[payload.id]) state.selected.byId[payload.id] = state.list?.byId[payload.id];
                    state.selected.all.push(payload.id);
                } else {
                    delete state.selected.byId[payload.id];
                    state.selected.all = state.selected.all.filter((id) => id !== payload.id);
                }
            }
        },
    };

    return {
        thunks,
        reducers,
        commonProps,
        getReducers: (): EntityAdapterReducers<State, typeof reducers> => {
            const result: EntityAdapterReducers<State, typeof reducers> = {} as any;
            for (const k in reducers) {
                const key = k as string & keyof typeof reducers;
                const reducerLogic = reducers[key];
                if (reducerLogic.length == 1) {
                    result[k as "clearList"] = (state) => {
                        reducerLogic(state, undefined!);
                    };
                } else {
                    result[k as "updateDetail"] = (state, action) => {
                        reducerLogic(state, action.payload as any);
                    };
                }
            }
            return result;
        },
        anchoreThunks: <RootState>(anchor: (state: RootState) => AsyncSliceState) => anchoreThunks(anchor, thunks),
        addExtraReducers: (builder: tk.ActionReducerMapBuilder<State>) => {
            addAsyncThunkReducers(builder, thunks.load, {
                fulfilled: (state, action) => reducers.setDetail(state, action.payload),
            });
            addAsyncThunkReducers(builder, thunks.updateList, {
                pending: (state, action) => {
                    if (state.detail && action.meta.arg.includes(state.detail.id)) reducers.clearDetail(state);
                },
                fulfilled: (state, action) => {
                    const items: TList[] = action.payload instanceof Array ? action.payload : action.payload.list;
                    reducers.updateListItems(state as State, { entities: items });
                },
            });
            addAsyncThunkReducers(builder, thunks.loadList, {
                fulfilled: (state, action) => {
                    const items: TList[] = action.payload instanceof Array ? action.payload : action.payload.list;
                    reducers.setList(state as State, { entities: items, filter: action.meta.arg });
                },
            });
            addAsyncThunkReducers(builder, thunks.delete, {
                fulfilled: (state, action) => {
                    reducers.removeListItems(state, { entityIds: action.payload.deletedIds });
                }
            });
        },
    };
}

export type ExtractListResult<TList, TApi extends EntityApi<any, any, any, TList, any, any>> = TApi extends EntityApi<
    any,
    any,
    any,
    any,
    any,
    infer T
>
    ? T extends TList[] | { list: TList[]; data: any }
        ? T
        : never
    : never;

export type EntityAdapterReducers<
    State,
    TReducers extends { [key in string]: (state: State, payload: any) => void },
> = {
    [key in string & keyof TReducers]: ToEntityAdapterReducer<State, TReducers[key]>;
};

export type ToEntityAdapterReducer<State, Input extends (state: State, payload: any) => void> = Input extends (
    state: State,
) => void
    ? (state: State) => void
    : Input extends (state: State, payload: infer P) => void
    ? (state: State, action: tk.PayloadAction<P>) => void
    : never;
