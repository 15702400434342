import { faMap, faStamp } from "@fortawesome/free-solid-svg-icons";
import { prepareBreadcrumb } from "@magicware/dashboard/navigation/breadcrumbs";
import { CreatePatternMethod, CreateSimpleMethod } from "@magicware/dashboard/navigation/builder";
import { prepareRootTab, prepareTab } from "@magicware/dashboard/navigation/tabs";
import { createUseIntParam, useIntParam } from "@magicware/dashboard/navigation/useIntParam";
import ConnectorCertificationDetailTab from "../components/connectorCertifications/ConnectorCertificationDetailTab";
import ConnectorCertificationListTab from "../components/connectorCertifications/ConnectorCertificationListTab";
import ConnectorCertificationNewTab from "../components/connectorCertifications/ConnectorCertificationNewTab";
import ProjectSetupDetailTab from "../components/projectSetups/ProjectSetupDetailTab";
import ProjectSetupDevicesTab from "../components/projectSetups/ProjectSetupDevicesTab";
import ProjectSetupFilesTabPage from "../components/projectSetups/ProjectSetupFilesTabPage";
import ProjectSetupListPage from "../components/projectSetups/ProjectSetupListPage";
import ProjectSetupNewPage from "../components/projectSetups/ProjectSetupNewPage";
import ProjectSetupParametersTab from "../components/projectSetups/ProjectSetupParametersTab";
import ProjectSetupTabPage from "../components/projectSetups/ProjectSetupTabPage";
import { buildAppInstances } from "./appInstances";
import { deviceIcon } from "./devices";
import { filesIcon } from "./files";
import { parametersIcon } from "./parameters";

export const useProjectSetupId = createUseIntParam("projectSetupId");
export const useConnectorCertificationId = () => useIntParam("connectorCertificationId");

export const projectSetupIcon = faMap;
export const certificationIcon = faStamp;

export const buildProjectSetups = (simple: CreateSimpleMethod, pattern: CreatePatternMethod) => {
    let tabIndex = 1;
    return simple({
        url: "/projects",
        component: ProjectSetupListPage,
        breadcrumb: prepareBreadcrumb((t) => t("projects-breadcrumb")),

        new: simple({
            url: "/projects/new",
            component: ProjectSetupNewPage,
            breadcrumb: prepareBreadcrumb((t) => t("new-project-breadcrumb")),
        }),
        detail: pattern({
            pattern: "/projects/:projectSetupId",
            component: ProjectSetupTabPage,
            url: (projectSetupId: number) => `/projects/${projectSetupId}`,
            breadcrumb: prepareBreadcrumb((t) => t("project-detail-breadcrumb")),
            tab: prepareRootTab((t) => t("project-detail-tab"), projectSetupIcon, ProjectSetupDetailTab),

            parameters: pattern({
                pattern: "/projects/:projectSetupId/parameters",
                component: ProjectSetupTabPage,
                url: (projectSetupId: number) => `/projects/${projectSetupId}/parameters`,
                breadcrumb: prepareBreadcrumb((t) => t("parameters-breadcrumb")),
                tab: prepareTab(tabIndex++, (t) => t("parameters-tab"), parametersIcon, ProjectSetupParametersTab),
            }),

            devices: pattern({
                pattern: "/projects/:projectSetupId/devices",
                component: ProjectSetupTabPage,
                url: (projectSetupId: number) => `/projects/${projectSetupId}/devices`,
                breadcrumb: prepareBreadcrumb((t) => t("devices-breadcrumb")),
                tab: prepareTab(tabIndex++, (t) => t("devices-tab"), deviceIcon, ProjectSetupDevicesTab),
            }),

            certifications: pattern({
                pattern: "/projects/:projectSetupId/certifications",
                component: ProjectSetupTabPage,
                url: (projectSetupId: number) => `/projects/${projectSetupId}/certifications`,
                breadcrumb: prepareBreadcrumb((t) => t("certifications-breadcrumb")),
                tab: prepareTab(
                    tabIndex++,
                    (t) => t("certifications-tab"),
                    certificationIcon,
                    ConnectorCertificationListTab,
                ),

                new: pattern({
                    pattern: "/projects/:projectSetupId/certifications/new",
                    url: (projectSetupId: number) => `/projects/${projectSetupId}/certifications/new`,
                    component: ProjectSetupTabPage,
                    breadcrumb: prepareBreadcrumb((t) => t("new-certification-breadcrumb")),
                    tab: prepareRootTab(
                        (t) => t("new-certification-title"),
                        certificationIcon,
                        ConnectorCertificationNewTab,
                    ),
                }),
                detail: pattern({
                    pattern: "/projects/:projectSetupId/certifications/:connectorCertificationId",
                    component: ProjectSetupTabPage,
                    url: (projectSetupId: number, connectorCertificationId: number) =>
                        `/projects/${projectSetupId}/certifications/${connectorCertificationId}`,
                    breadcrumb: prepareBreadcrumb((t) => t("connector-certification-detail-breadcrumb")),
                    tab: prepareRootTab(
                        (t) => t("certification-detail-title"),
                        certificationIcon,
                        ConnectorCertificationDetailTab,
                    ),
                }),
            }),

            apps: buildAppInstances("projects", "projectSetupId", ProjectSetupTabPage, tabIndex++, simple, pattern),

            files: pattern({
                pattern: "/projects/:projectSetupId/files",
                component: ProjectSetupTabPage,
                url: (projectSetupId: number) => `/projects/${projectSetupId}/files`,
                breadcrumb: prepareBreadcrumb((t) => t("files-breadcrumb")),
                tab: prepareTab(tabIndex++, (t) => t("files-tab"), filesIcon, ProjectSetupFilesTabPage),
            }),
        }),
    });
};
