import { useContext } from "react";
import { NestedFormContext } from "../components/NestedFormContext";

function useFieldName<FormValues, T extends string & keyof FormValues>(_f: FormValues, field: T): T;
function useFieldName<T extends string>(field: T): T;
function useFieldName(tOrField: any, field?: string): string {
    const arrayItemContext = useContext(NestedFormContext);
    if (!field) field = tOrField as string;
    if (!arrayItemContext) return field;
    else return `${arrayItemContext.nestedFieldPrefix}.${field}`;
}

export default useFieldName;
