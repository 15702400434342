import Panel from "@magicware/dashboard/Panel";
import { buildForm } from "@magicware/forms/builder";
import Form, { FormImplementationProps } from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import FormTogglePanel from "@magicware/forms/components/FormTogglePanel";
import FormSelect from "@magicware/forms/mui/FormSelect";
import FormTextField from "@magicware/forms/mui/FormTextField";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { updateDetail } from "../../redux/appInstances/appInstancesReducer";
import { AppInstanceModelDetail, AppInstanceModelNew, AppInstanceModelUpdate } from "../../services/api/appInstances";
import { FormBaseAppsAutocomplete } from "../baseApps/FormBaseAppsAutocomplete";
import { platformOptions } from "../codebooks/platforms";
import { FormProjectSetupAutocomplete } from "../projectSetups/FormProjectSetupAutocomplete";

export const appInstanceForm = buildForm<AppInstanceModelDetail & { fromBaseApp: boolean }>()(() => ({
    props: {
        remove: ["id", "version"],
        extra: [],
    },
    transformations: {
        toForm: {
            fromParams: (h, baseAppId?: number) => ({ ...h.defaults, fromBaseApp: !!baseAppId }),
            fromModel: (h, m: AppInstanceModelDetail, baseAppId?: number) => ({
                ...h.fromModel(m),
                fromBaseApp: !!baseAppId,
            }),
        },
        formTo: {
            new: (h, f, projectSetupId?: number, baseAppId?: number): AppInstanceModelNew => ({
                ...h.toNewEntity(f),
                projectSetupId: f.projectSetup?.id ?? projectSetupId,
                baseAppId: f.baseApp?.id ?? baseAppId,
            }),
            update: (h, f, m: AppInstanceModelDetail): AppInstanceModelUpdate => ({
                ...h.toUpdateEntity(f, m),
                projectSetupId: m.projectSetup.id,
            }),
        },
    },
    updateReduxModel: updateDetail,
    schema: (f, t) => ({
        name: yup.string().required(t("name-is-required")),
        platform: yup.string().required(t("platform-is-required")),
        projectSetup: yup.object().requiredIf(f, "fromBaseApp", t("project-is-required")),
        baseApp: yup.object(),
        notes: yup.string(),
        url: yup.string().url(t("invalid-url")),
        rating: yup.number(),
        fromBaseApp: yup.boolean().default(false),
    }),
}));

const f = appInstanceForm.f;

export default function AppInstanceForm(props: FormImplementationProps<typeof f>) {
    const { t } = useTranslation();

    return (
        <Form validationSchema={appInstanceForm.validationSchema} {...props}>
            <Panel>
                <FormGrid>
                    <div tw="flex items-center">
                        <FormTextField
                            tw="flex-1"
                            f={f}
                            field="name"
                            label={t("name-label")}
                            autoFocus={props.autoFocus}
                        />
                    </div>
                    <FormTogglePanel f={f} visibleOn={true} field="fromBaseApp">
                        <FormProjectSetupAutocomplete
                            f={f}
                            field="projectSetup"
                            fullOption
                            label={t("project-label")}
                            filter={{}}
                        />
                    </FormTogglePanel>
                    <FormTogglePanel f={f} visibleOn={false} field="fromBaseApp">
                        <FormBaseAppsAutocomplete
                            f={f}
                            field="baseApp"
                            fullOption
                            label={t("baseApp-label")}
                            filter={{}}
                        />
                    </FormTogglePanel>
                    <FormSelect f={f} {...platformOptions} field="platform" label={t("platform-label")} />
                    <FormTextField f={f} field="url" label={t("url-label")} />
                    <FormTextField f={f} field="notes" label={t("notes-label")} multiline />
                </FormGrid>
            </Panel>
        </Form>
    );
}
