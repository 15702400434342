import DataListItem, { MainText } from "@magicware/data-lists/DataListItem";
import { StyledComponentProps } from "@mui/material";
import { useNavigationClick } from "../../navigation/hooks";
import { useAppSelector } from "../../redux/hooks";

export interface BrandListItemProps extends StyledComponentProps {
    brandId: number;
}

export default function BrandListItem({ brandId, ...props }: BrandListItemProps) {
    const obj = useAppSelector((state) => state.brands.list?.byId[brandId]);
    const editClickFactory = useNavigationClick();
    if (!obj) return null;
    return (
        <DataListItem {...props} data-id={obj.id} onClick={editClickFactory((n) => n.brands.detail.url(obj.id))}>
            <MainText tw="w-full">{obj.name}</MainText>
        </DataListItem>
    );
}
