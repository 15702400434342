export async function processReponse(response: Response) {
    const errorCode = response.headers.get("X-ErrorCode");
    if (errorCode) {
        const error: ApiError = { errorCode, errorData: undefined };
        throw error;
    }

    if (response.status === 204) return;
    if (response.status !== 200) throw new Error(`Request ended with '${response.status}' status.`);

    return await response.json();
}

export function processXmlHttpReponse(request: XMLHttpRequest) {
    if (request.status === 204) return;
    if (request.status !== 200) throw new Error(`Request ended with '${request.status}' status.`);

    const errorCode = request.getResponseHeader("X-ErrorCode");
    if (errorCode) {
        const error: ApiError = { errorCode, errorData: undefined };
        throw error;
    }
    return request.response;
}

export interface ApiError {
    errorCode: string;
    errorData?: any;
}

export interface OldVersionError extends ApiError {
    errorCode: "OLD_VERSION";
    errorData: undefined;
}

export const isOldVersionError = (error: any): error is OldVersionError => error.errorCode === "OLD_VERSION";

export type ValidationError<Message extends AnyValidationErrorMessage = AnyValidationErrorMessage> = {
    Validation: ValidationErrorItem<Message>[];
};
export type ValidationErrorItem<Message extends AnyValidationErrorMessage = AnyValidationErrorMessage> = {
    KeyPath: string | null;
    Messages: Message[];
};

export type AnyValidationErrorMessage = WellKnownValidationErrorMessage | ValidationErrorMessage;

export type WellKnownValidationErrorMessage =
    | {
          Type:
              | "Missing"
              | "NotFound"
              | "NotUnique"
              | "InvalidValue"
              | "invalidWebUrl"
              | "invalidEmail"
              | "invalidMultiEmail"
              | "VersionDiscord";
          Data?: null;
      }
    | {
          Type: "Text";
          Data: { Text: string };
      }
    | {
          Type: "InvalidLength";
          Data: { MinLength: number | null; MaxLength: number | null };
      }
    | {
          Type: "InvalidRange";
          Data: { IsInverted: boolean };
      }
    | {
          Type: "OutsideRange";
          Data: ValidationErrorOutsideRangeData<number> | ValidationErrorOutsideRangeData<string>;
      };

export type ValidationErrorOutsideRangeData<TValueType> = {
    Minimum: TValueType | null;
    Maximum: TValueType | null;
    IsMinimumExclusive: boolean;
    IsMaximumExclusive: boolean;
};

export type ValidationErrorMessage<Data = unknown, Type extends string = string> = {
    Type: Type;
    Data: Data;
};

export function isMessage<Type extends Message["Type"], Message extends AnyValidationErrorMessage>(
    msg: Message,
    type: Type,
): msg is Message & { Type: Type } {
    return msg.Type === type;
}

export const isValidationError = <T extends ValidationError = ValidationError>(error: any): error is T =>
    (error as T).Validation instanceof Array;

export function getDefaultValidationMessageText(message: AnyValidationErrorMessage) {
    const msg = message as WellKnownValidationErrorMessage;
    if (isMessage(msg, "Text")) return msg.Data.Text;
    if (isMessage(msg, "Missing")) return "Value is missing";
    if (isMessage(msg, "NotFound")) return "Value was not found";
    if (isMessage(msg, "InvalidLength"))
        return `Invalid length. Minimum: ${msg.Data.MinLength ?? "unrestricted"}. Maximum: ${
            msg.Data.MaxLength ?? "unrestricted"
        }`;
    if (isMessage(msg, "InvalidRange")) return "Value is out of range.";
    if (isMessage(msg, "OutsideRange"))
        return `Value is out of range. Minimum: ${msg.Data.Minimum ?? "unrestricted"}. Maximum: ${
            msg.Data.Maximum ?? "unrestricted"
        }`;
    if (isMessage(msg, "NotUnique")) return "Value has to be unique";
    if (isMessage(msg, "invalidWebUrl")) return "Invalid url address";
    if (isMessage(msg, "VersionDiscord"))
        return "The record you are editing has been modified by someone else in the meantime. Please refresh the page and make changes again.";
    if (isMessage(msg, "invalidEmail") || isMessage(msg, "invalidMultiEmail")) return "Invalid email address";
    return "Value is invalid";
}
