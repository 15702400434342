import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { DereferedTranslation } from "@magicware/utils/localization/GenericTranslateFunc";
import React from "react";

export interface INavigatablePageBase extends Partial<ITabPage> {
    title?: BreadcrumbName;
    unrestricted?: boolean;
    component: React.ComponentType<any>;
}

export interface IBreadcrumbPage<Params extends any[] = []> {
    breadcrumb?: (...params: Params) => Breadcrumb;
}

export type BreadcrumbName = string | React.ReactNode | DereferedTranslation;
export type TabName = string | React.ReactNode | DereferedTranslation;

export type Breadcrumb = {
    name: BreadcrumbName;
    url: string;
    next?: Breadcrumb;
};

export interface ITabPage {
    tab: Tab;
}
export function isTabPage(page: Partial<ITabPage>): page is ITabPage {
    return page.tab !== undefined;
}

export type Tab = {
    name: TabName;
    icon: IconProp;
    ord: number;
    component: React.ComponentType<any>;
};

export interface INavigatablePage<Params extends any[] = []> extends INavigatablePageBase, IBreadcrumbPage<Params> {
    pattern: string;
    parent?: INavigatablePage<any[]>;
    url: (...p: Params) => string;
    urlAbsolute: (...p: Params) => string;
}

export function isNavigatablePage(obj: any): obj is INavigatablePage<any[]> {
    return (
        typeof (obj as INavigatablePage).pattern === "string" &&
        typeof (obj as INavigatablePage).url === "function" &&
        (obj as INavigatablePage).component !== undefined
    );
}

export function getSubPages(page: INavigatablePage<any[]>) {
    const subPages: INavigatablePage<any[]>[] = [];
    for (const key in page) {
        if (key !== "parent") {
            const subPage: any = page[key as keyof typeof page];
            if (isNavigatablePage(subPage)) {
                subPages.push(subPage);
            }
        }
    }
    return subPages;
}
