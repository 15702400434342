import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { INavigatablePage, isNavigatablePage } from "@magicware/dashboard/navigation/utils";
import { CircularProgress, ListItemButton, ListItemButtonProps, ListItemIcon, ListItemText } from "@mui/material";
import { forwardRef, ReactNode } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import tw, { css } from "twin.macro";

export interface MainMenuNavLinkProps extends Omit<NavLinkProps, "to"> {
    to: INavigatablePage | NavLinkProps["to"];
    icon: IconProp;
    text: ReactNode;
}

const StyledItem = tw(ListItemButton)`text-white rounded-xl font-semibold py-2`;

//bohužel po upgradu na mui5 je problém s overridováním ListItem viz https://github.com/mui-org/material-ui/issues/14166
const FuckingTypeScriptListItem: any = StyledItem;

export default function MainMenuNavLink({ to, icon, text, ...props }: MainMenuNavLinkProps) {
    if (isNavigatablePage(to)) to = to.pattern;
    return (
        <FuckingTypeScriptListItem component={AppBarNavLink} to={to} {...props}>
            <div tw="w-9 text-center mr-2">
                <FontAwesomeIcon icon={icon} tw="text-menu-icon text-xl" />
            </div>
            {text}
        </FuckingTypeScriptListItem>
    );
}

export function MainMenuButton({
    icon,
    text,
    isLoading = false,
    ...props
}: Pick<MainMenuNavLinkProps, "icon" | "text"> & ListItemButtonProps & { isLoading?: boolean }) {
    return (
        <StyledItem {...props}>
            <div tw="w-9 text-center mr-2">
                <div tw="relative">
                    {isLoading && <CircularProgress size="1.25rem" tw="absolute text-menu-icon" />}
                    <span css={[isLoading && tw`invisible`]}>
                        <FontAwesomeIcon icon={icon} tw="text-menu-icon text-xl" />
                    </span>
                </div>
            </div>
            {text}
        </StyledItem>
    );
}

// eslint-disable-next-line react/display-name
const AppBarNavLinkRefFw = (linkProps: NavLinkProps, ref: React.Ref<HTMLAnchorElement>) => (
    <NavLink ref={ref} activeStyle={tw`bg-menu-active`} {...linkProps} />
);
const AppBarNavLink = forwardRef(AppBarNavLinkRefFw);

const activeIconStyles = css`
    ${tw`[font-size:5px]`}
    .active & {
        ${tw`text-blue-600`}
        ${tw`[font-size:8px]`}
    }
`;

export function SubMenuNavLink({ to, text, ...props }: Omit<MainMenuNavLinkProps, "icon">) {
    if (isNavigatablePage(to)) to = to.pattern;
    return (
        <FuckingTypeScriptListItem button component={AppBarNavLink} to={to} {...props}>
            <ListItemIcon tw="ml-4" css={activeIconStyles}>
                <div tw="w-4 text-center">
                    <FontAwesomeIcon icon={faCircle} tw="text-center" />
                </div>
            </ListItemIcon>
            <ListItemText primary={text} tw="-ml-2" primaryTypographyProps={{ fontSize: "smaller" }} />
        </FuckingTypeScriptListItem>
    );
}
