import AsyncContent from "@magicware/dashboard/AsyncContent";
import DataList from "@magicware/data-lists/DataList";
import { FormStateApiContextProvider } from "@magicware/forms/components/FormStateApiContext";
import { useTranslation } from "react-i18next";
import { useLibraryVersionId } from "../../navigation/libraries";
import {
    connectorImplementationThunks,
    setPage,
} from "../../redux/connectorImplementations/connectorImplementationsReducer";
import { useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { RootState } from "../../redux/types";
import EntityListItemMenuContext from "../entities/EntityListItemMenuContext";
import ConnectorImplementationListItem from "./ConnectorImplementationListItem";
import ConnectorImplementationListMenuContext from "./ConnectorImplementationListMenuContext";

export default function ConnectorImplementationListTab() {
    const libraryVersionId = useLibraryVersionId();
    const api = useAutoDispatchAsyncThunk(connectorImplementationThunks.loadList, { libraryVersionId });
    return <AsyncContent {...api} component={ConnectorImplementationListTabLoaded} />;
}

function ConnectorImplementationListTabLoaded() {
    const { t } = useTranslation();
    return (
        <FormStateApiContextProvider>
            <ConnectorImplementationListMenuContext>
                <EntityListItemMenuContext
                    deleteSnackTitle={t("connectorImplementation-deletion")}
                    deleteThunk={connectorImplementationThunks.delete}
                >
                    <DataList
                        dataSelector={(state: RootState) => state.connectorImplementations.list}
                        setPageActionCreator={setPage}
                        emptyList={t("no-connectorImplementation-message")}
                    >
                        {(id) => <ConnectorImplementationListItem connectorImplementationId={id} />}
                    </DataList>
                </EntityListItemMenuContext>
            </ConnectorImplementationListMenuContext>
        </FormStateApiContextProvider>
    );
}
