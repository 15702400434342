import { getData, postData } from "@magicware/fetch-api/fetch";
import { API_BASE_URL } from "../globals";

export interface CodebookOptionModel {
    id: number;
    name: string;
}

const getList = <TModel = CodebookOptionModel>(urlPart: string, action = "list", filter?: any) =>
    getData<TModel[]>(`${API_BASE_URL}registry/${urlPart}/${action}`, filter);

export const ensureList = async (urlPart: string, names: string[], action = "ensure") => {
    const response = await postData<{ ids: number[] }>(`${API_BASE_URL}registry/${urlPart}/${action}`, { names });
    return response.ids.map<CodebookOptionModel>((id, idx) => ({ id, name: names[idx] }));
};

export const codebooksApi = {
    loadCurrencies: () => getList("codebooks/currencies"),
    loadKeywords: () => getList("codebooks/keywords"),
    ensureKeywords: (names: string[]) => ensureList("codebooks/keywords", names),
};
