import { useTranslation } from "react-i18next";
import { getPasswordIsTooWeekMessage, userApi } from "../../services/api/user";
import { navigation } from "../../navigation";
import { NewPasswordChangeForm, newPasswordChangeFormSchema } from "./newPasswordChangeForm";
import { Typography } from "@mui/material";
import LoginPageContainer from "../loginPage/LoginPageContainer";
import { FORM_ERROR } from "final-form";
import { useQueryParams } from "@magicware/dashboard/navigation/useQueryParams";
import { buildForm } from "@magicware/forms/builder";
import Form from "@magicware/forms/components/Form";
import FormTextField from "@magicware/forms/mui/FormTextField";
import FormSubmitResult from "@magicware/forms/components/FormSubmitResult";
import FormSubmitButton from "@magicware/forms/components/FormSubmitButton";
import ButtonLink from "@magicware/dashboard/navigation/ButtonLink";

const { f, defaults, validationSchema } = buildForm<NewPasswordChangeForm>()(() => ({
    props: {
        extra: [],
        remove: [],
    },
    schema: (_, t) => newPasswordChangeFormSchema(t),
}));

export default function PasswordResetStep2Page() {
    const { t } = useTranslation();
    const { Hash: hash } = useQueryParams("Hash");
    const handleSubmit = (form: typeof f) => {
        const invokeApi = userApi.forms.resetPassword.withServerErrors({
            messages: { PasswordIsTooWeek: (msg) => getPasswordIsTooWeekMessage(msg, t) },
            validationError: t("password-has-not-been-changed-message"),
            map: {
                NewPassword: "newPassword",
                ResetPasswordHash: {
                    key: FORM_ERROR,
                    messages: {
                        InvalidValue: t("passoword-reset-link-expired-message") as string,
                    },
                },
            },
        });
        return invokeApi({
            resetPasswordHash: hash ?? "",
            newPassword: form.newPassword,
        });
    };
    return (
        <LoginPageContainer>
            <div>
                <Typography color="text.secondary">{t("enter-new-password-info-text")}</Typography>
                <div tw="mt-10">
                    <Form initialValues={defaults} validationSchema={validationSchema} onSubmit={handleSubmit}>
                        <div tw="flex flex-col space-y-5 mb-5">
                            <FormTextField f={f} field="newPassword" label={t("new-password-label")} type="password" />
                            <FormTextField
                                f={f}
                                field="newPassword2"
                                label={t("confirm-new-password-label")}
                                type="password"
                            />
                            <FormSubmitResult successMessage={t("password-has-been-set-message")} />
                        </div>
                        <div tw="flex items-center justify-between mt-5">
                            <FormSubmitButton ignoreDirty>{t("reset-password-button")}</FormSubmitButton>
                            <ButtonLink tw="text-right" variant="text" to={navigation.home.url()}>
                                {t("back-to-login-button")}
                            </ButtonLink>
                        </div>
                    </Form>
                </div>
            </div>
        </LoginPageContainer>
    );
}
