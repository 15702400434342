import Panel from "@magicware/dashboard/Panel";
import { buildForm } from "@magicware/forms/builder";
import Form, { FormImplementationProps } from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import useFormFieldValue from "@magicware/forms/hooks/useFormFieldValue";
import FormTextField from "@magicware/forms/mui/FormTextField";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { updateDetail } from "../../redux/connectorCertifications/connectorCertificationsReducer";
import {
    ConnectorCertificationModelDetail,
    ConnectorCertificationModelNew,
} from "../../services/api/connectorCertifications";
import { FormConnectorsAutocomplete } from "../connectors/FormConnectorsAutocomplete";
import { FormParametersScopesAutocomplete } from "../parametersScopes/FormParametersScopesAutocomplete";

export const connectorCertificationForm = buildForm<ConnectorCertificationModelDetail & { projectSetupId?: number }>()(
    () => ({
        props: {
            pick: ["notes", "status", "parametersScopeList"],
            remove: [],
            extra: ["connector"],
        },
        transformations: {
            toForm: {
                fromModel: (h, m: ConnectorCertificationModelDetail) => ({ ...h.fromModel(m), connector: m.connector }),
            },
            formTo: {
                new: (h, f, projectSetupId: number): ConnectorCertificationModelNew => ({
                    ...h.toNewEntity(f),
                    projectSetupId,
                    connectorId: f.connector.id,
                }),
                update: (h, f, m: ConnectorCertificationModelDetail) => h.toUpdateEntity(f, m),
            },
        },
        updateReduxModel: updateDetail,
        schema: (_, t) => ({
            status: yup.string().required(t("status-is-required")),
            parametersScopeList: yup.array().default([]),
            connector: yup.object().required(t("connector-is-required")),
            notes: yup.string(),
        }),
    }),
);

const f = connectorCertificationForm.f;

export default function ConnectorCertificationForm(
    props: FormImplementationProps<typeof f> & { editableConnector?: boolean },
) {
    const { t } = useTranslation();
    return (
        <Form validationSchema={connectorCertificationForm.validationSchema} {...props}>
            <Panel>
                <FormGrid>
                    <FormConnectorsAutocomplete
                        f={f}
                        field="connector"
                        label={t("connector-label")}
                        fullOption
                        filter={{}}
                    />
                    <FormTextField f={f} field="status" label={t("status-label")} />
                    <FormParametersScopesList />
                    <FormTextField f={f} field="notes" label={t("notes-label")} multiline />
                </FormGrid>
            </Panel>
        </Form>
    );
}

function FormParametersScopesList() {
    const connector = useFormFieldValue(f, "connector");
    const { t } = useTranslation();
    return (
        <FormParametersScopesAutocomplete
            f={f}
            field="parametersScopeList"
            fullOption
            multiple
            label={t("scope-label")}
            filter={{ connectorId: connector?.id }}
        />
    );
}
