import { defaultValidationErrorMessage } from "@magicware/fetch-api/forms";
import ScrollToDivOnMount from "@magicware/utils/components/ScrollToDivOnMount";
import Alert from "@mui/material/Alert";
import { FORM_ERROR, ValidationErrors } from "final-form";
import React, { Fragment } from "react";
import { useFormState } from "react-final-form";

export interface FormSubmitResultProps {
    successMessage?: string;
    validationErrorMessage?: string;
    validationErrorsCount?: number;
    autoScrollTo?: boolean;
    component?: React.ComponentType<{ severity: "error" | "success"; children?: React.ReactNode }>;
}

export default function FormSubmitResult({
    successMessage,
    validationErrorMessage = defaultValidationErrorMessage,
    validationErrorsCount = 0,
    autoScrollTo = false,
    component: Component = Alert,
    ...props
}: FormSubmitResultProps) {
    const {
        submitError,
        error,
        errors,
        submitSucceeded,
        modifiedSinceLastSubmit,
        hasSubmitErrors,
        hasValidationErrors,
        submitFailed,
    } = useFormState({
        subscription: {
            hasSubmitErrors: true,
            hasValidationErrors: true,
            submitError: true,
            error: true,
            errors: true,
            submitSucceeded: true,
            modifiedSinceLastSubmit: true,
            submitFailed: true,
        },
    });

    if (modifiedSinceLastSubmit) return null;

    const outerContainerProps = autoScrollTo ? props : {};
    const OuterContainer = autoScrollTo ? ScrollToDivOnMount : Fragment;

    if (hasSubmitErrors || hasValidationErrors) {
        const flattendErrors = FlattenErrors(FlattenErrors(undefined, submitError), error);
        if (flattendErrors && flattendErrors.length > 0) {
            return (
                <OuterContainer {...outerContainerProps}>
                    {flattendErrors.map((err) => (
                        <Component key={err} severity="error">
                            {err}
                        </Component>
                    ))}
                </OuterContainer>
            );
        } else if (submitFailed && hasValidationErrors) {
            const errorMessages: string[] = [];
            if (errors && errors[FORM_ERROR]) errorMessages.push(errors[FORM_ERROR]);
            FillMessages(errorMessages, errors, validationErrorsCount);
            if (errorMessages.length === 0) errorMessages.push(validationErrorMessage);
            return (
                <OuterContainer {...props}>
                    <Component severity="error">
                        {errorMessages.map((err) => (
                            <div key={err}>{err}</div>
                        ))}
                    </Component>
                </OuterContainer>
            );
        }
    } else if (submitSucceeded && successMessage) {
        return (
            <OuterContainer {...props}>
                <Component {...props} severity="success">
                    {successMessage}
                </Component>
            </OuterContainer>
        );
    }

    return null;
}

function FillMessages(target: string[], errors: ValidationErrors, maxCount: number) {
    if (!errors) return;
    for (const key in errors) {
        if (key != FORM_ERROR && Object.prototype.hasOwnProperty.call(errors, key)) {
            const error = errors[key];
            if (typeof error === "object") {
                FillMessages(target, error, maxCount);
            } else if (typeof error === "string") {
                if (!target.includes(error)) {
                    if (target.length < maxCount) target.push(error);
                    else return;
                }
            }
        }
    }
}

function FlattenErrors(errors: any[] | undefined, error: any) {
    if (error && typeof error === "object" && error instanceof Array) {
        return [...(errors ?? []), ...error];
    } else if (error) {
        return [...(errors ?? []), error];
    } else {
        return errors;
    }
}
