import MainContent from "@magicware/dashboard/MainContent";
import PageContainer from "@magicware/dashboard/PageContainer";
import Panel from "@magicware/dashboard/Panel";
import { Alert } from "@mui/material";

export default function PermissionDeniedAlertPage() {
    return (
        <PageContainer>
            <MainContent>
                <Panel>
                    <Alert severity="error">403 - Permission Denied</Alert>
                </Panel>
            </MainContent>
        </PageContainer>
    );
}
