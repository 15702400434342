import TitleTabPage from "@magicware/dashboard/navigation/TitleTabPage";
import { useApiServerVersionId } from "../../navigation/apiServers";
import { useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { apiServerVersionThunks } from "../../redux/apiServerVersions/apiServerVersionsReducer";
import { RootState } from "../../redux/types";

export default function ApiServerVersionTabPage() {
    const apiServerVersionId = useApiServerVersionId();
    const api = useAutoDispatchAsyncThunk(apiServerVersionThunks.load, apiServerVersionId);
    return (
        <TitleTabPage
            apiStatus={api.apiStatus}
            titleSelector={(state: RootState) => state.apiServerVersions.detail?.name}
        />
    );
}
