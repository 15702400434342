import { useField } from "react-final-form";
import useFieldName from "./useFieldName";

export default function useFormFieldValue<FormValues, Field extends string & keyof FormValues>(
    _: FormValues,
    field: Field,
    subscribeValueChange = true,
) {
    field = useFieldName(field);
    const formField = useField(field, { subscription: { value: subscribeValueChange } });
    return formField.input.value as FormValues[Field];
}
