import { createAsyncFormAutocompleteWithFilter } from "@magicware/forms/mui/FormAutocomplete";
import { connectorThunks } from "../../redux/connectors/connectorsReducer";
import { RootState } from "../../redux/types";
import { ConnectorSelectOption } from "../../services/api/connectors";
import { createDefaultProps } from "../forms/formAutocomplete";

export const FormConnectorsAutocomplete = createAsyncFormAutocompleteWithFilter(
    {
        selectOptions: (state: RootState) => state.connectors.list,
        createAsyncAction: connectorThunks.loadList,
        selectAsyncState: connectorThunks.loadList.selectState,
    },
    createDefaultProps<ConnectorSelectOption>(
        (opt) => `${opt.name}`,
        (n, opt) => n.connectors.detail.url(opt.id),
    ),
);
