import { createEntityApi } from "@magicware/fetch-api/entities";
import { IdVersion } from "@magicware/fetch-api/types";
import { API_BASE_URL } from "../globals";

export interface LibrarySelectOption {
    id: number;
    name: string;
    platform: string;
}

export interface LibraryModelBase {
    name: string;
    platform: string;
    notes?: string;
}

export interface LibraryModelNew extends LibraryModelBase {}
export interface LibraryModelUpdate extends LibraryModelBase, IdVersion {}
export interface LibraryModelDetail extends LibraryModelBase, IdVersion {}
export interface LibraryModelList extends LibrarySelectOption {}
export interface LibraryFilter {
    name?: string;
}

export const entityApi = createEntityApi<
    LibraryModelNew,
    LibraryModelUpdate,
    LibraryModelDetail,
    LibraryModelList,
    LibraryFilter
>("library", `${API_BASE_URL}registry/libraries`);

export const librariesApi = entityApi;
