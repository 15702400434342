import { createAsyncFormAutocompleteWithFilter } from "@magicware/forms/mui/FormAutocomplete";
import { parametersScopeThunks } from "../../redux/parametersScopes/parametersScopesReducer";
import { RootState } from "../../redux/types";
import { ParametersScopeSelectOption } from "../../services/api/parametersScopes";
import { createDefaultProps } from "../forms/formAutocomplete";

export const FormParametersScopesAutocomplete = createAsyncFormAutocompleteWithFilter(
    {
        selectOptions: (state: RootState) => state.parametersScopes.list,
        createAsyncAction: parametersScopeThunks.loadList,
        selectAsyncState: parametersScopeThunks.loadList.selectState,
    },
    createDefaultProps<ParametersScopeSelectOption>(
        (opt) => `${opt.brand} - ${opt.name}`,
        (n, opt) => n.parametersScopes.detail.url(opt.id),
    ),
);
