import { faCalendar, faTemperatureQuarter } from "@fortawesome/free-solid-svg-icons";
import DataIcon from "@magicware/data-lists/DataIcon";
import DataListItem, { MainText, SecondaryText } from "@magicware/data-lists/DataListItem";
import { DateFn } from "@magicware/utils/DateFn";
import { StyledComponentProps } from "@mui/material";
import { useNavigationClick } from "../../navigation/hooks";
import { parametersScopeIcon } from "../../navigation/parametersScopes";
import { useAppSelector } from "../../redux/hooks";

export interface ConnectorCertificationListItemProps extends StyledComponentProps {
    connectorCertificationId: number;
}

export default function ConnectorCertificationListItem({
    connectorCertificationId,
    ...props
}: ConnectorCertificationListItemProps) {
    const obj = useAppSelector((state) => state.connectorCertifications.list?.byId[connectorCertificationId]);
    const editClickFactory = useNavigationClick();
    if (!obj) return null;
    return (
        <DataListItem
            {...props}
            data-id={obj.id}
            onClick={editClickFactory((n) =>
                n.projectSetups.detail.certifications.detail.url(obj.projectSetupId, obj.id),
            )}
        >
            <div tw="flex items-center mb-3">
                <MainText tw="mr-10">{obj.connector}</MainText>
                <SecondaryText>
                    <DataIcon icon={parametersScopeIcon} />
                    {obj.parametersScopeList.join(", ")}
                </SecondaryText>
            </div>
            <div tw="flex items-center">
                <SecondaryText tw="w-44">
                    <DataIcon icon={faCalendar} />
                    {DateFn.f_ddMMyyyy(obj.date)}
                </SecondaryText>
                <SecondaryText>
                    <DataIcon icon={faTemperatureQuarter} />
                    {obj.status}
                </SecondaryText>
            </div>
        </DataListItem>
    );
}
