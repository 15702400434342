import { AnyStore, StoreThunkAction } from "@magicware/redux/types";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import useFormDispatchApi from "../hooks/useFormDispatchApi";

export function buildSearchForm<Filter, GetFilter extends (...params: any[]) => Filter, Store extends AnyStore>(
    loadAction: (filter: Filter) => StoreThunkAction<Store, Promise<any>>,
    filterSelector: (state: any, ...params: Parameters<GetFilter>) => Filter | undefined,
    defaultFilter: GetFilter,
) {
    return {
        f: undefined! as Filter,
        useSearchForm: (...params: Parameters<GetFilter>) => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            const filter = useMemo(() => defaultFilter(...params), params);
            return {
                autoSubmitInitialValues: filter,
                initialValues: useSelector((state: Store) => filterSelector(state, ...params)) ?? filter,
                onSubmit: useFormDispatchApi(loadAction),
            };
        },
    };
}
