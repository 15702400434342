import { createAsyncFormAutocomplete } from "@magicware/forms/mui/FormAutocomplete";
import { loadCurrencies } from "../../redux/codebooks/codebooksReducer";
import { RootState } from "../../redux/types";
import { createDefaultProps } from "../forms/formAutocomplete";

export const FormCurrenciesAutocomplete = createAsyncFormAutocomplete(
    {
        selectOptions: (state: RootState) => state.codebooks.currencies,
        createAsyncAction: loadCurrencies,
        selectAsyncState: loadCurrencies.selectState,
    },
    createDefaultProps((opt) => `${opt.name}`),
);
