import { useParam } from "./useParam";

export function useIntParam<TKey extends string, TRequired extends boolean = true>(
    name: TKey,
    required: TRequired = true as TRequired,
): TRequired extends true ? number : number | undefined {
    const result = useParam(name, required);
    return result ? parseInt(result) : undefined!;
}

export function createUseIntParam<TKey extends string>(name: TKey) {
    return <TRequired extends boolean = true>(required: TRequired = true as TRequired) =>
        useIntParam<TKey, TRequired>(name, required);
}
