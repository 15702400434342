import { removeDownload } from "@magicware/redux/coreReducer";
import { StoreDispatch, StoreState } from "@magicware/redux/types";
import { CircularProgress } from "@mui/material";
import { SnackbarContent, SnackbarKey, useSnackbar } from "notistack";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const renderDownloadSnackContent = (key: SnackbarKey) => (
    <SnackbarContent>
        <div tw="flex bg-primary-200 p-2 rounded [min-width:300px]">
            <FileProgressSnackContent snackKey={key} />
        </div>
    </SnackbarContent>
);

export const renderUploadSnackContent = (key: SnackbarKey) => (
    <SnackbarContent>
        <div tw="flex bg-primary-200 p-2 rounded [min-width:300px]">
            <FileProgressSnackContent
                snackKey={key}
                failedMessage={"Failed"}
                preparingMessage={"Preparing"}
                doneMessage={"Uploaded"}
                inProgressMessage={"Uploading"}
            />
        </div>
    </SnackbarContent>
);

interface FileProgressSnackContentProps {
    snackKey: SnackbarKey;
    failedMessage?: string;
    doneMessage?: string;
    preparingMessage?: string;
    inProgressMessage?: string;
}

function FileProgressSnackContent({
    snackKey,
    failedMessage = "Failed",
    preparingMessage = "Preparing",
    doneMessage = "Downloaded",
    inProgressMessage = "Downloading",
}: FileProgressSnackContentProps) {
    const dispatch = useDispatch<StoreDispatch>();
    const downloadInfo = useSelector((state: StoreState) => state.core.downloads[snackKey]);
    const snack = useSnackbar();
    useEffect(
        () => () => {
            dispatch(removeDownload(snackKey));
        },
        [],
    );
    if (!downloadInfo) return null;
    if (downloadInfo.status === "failed" || downloadInfo.status === "aborted")
        return (
            <Fragment>
                <span tw="mr-2">
                    {downloadInfo.name}: {failedMessage}
                </span>
                <button
                    type="button"
                    tw="ml-auto cursor-pointer hover:bg-primary-100 px-1"
                    onClick={() => snack.closeSnackbar(snackKey)}
                >
                    OK
                </button>
            </Fragment>
        );
    if (downloadInfo.status === "success")
        return (
            <span>
                {downloadInfo.name}: {doneMessage}
            </span>
        );
    if (!downloadInfo.percent)
        return (
            <Fragment>
                <span tw="mr-2">
                    {downloadInfo.name}: {preparingMessage}
                </span>
                <span tw="ml-auto flex items-center">
                    <CircularProgress size={20} />
                </span>
            </Fragment>
        );
    return (
        <Fragment>
            <span tw="mr-2">
                {downloadInfo.name}: {inProgressMessage}
            </span>
            <span tw="ml-auto flex items-center">
                {downloadInfo.percent === Infinity ? 100 : downloadInfo.percent}%&nbsp;
                <CircularProgress size={20} />
            </span>
        </Fragment>
    );
}
