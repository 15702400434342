import { createContext, useMemo } from "react";

type NestedFormContextModel = NestedArrayItemFormContextModel | NestedObjectFormContextModel;

export interface NestedArrayItemFormContextModel {
    type: "arrayItem";
    arrayItemIndex: number;
    nestedFieldPrefix: string;
    arrayField: string;
}

interface NestedObjectFormContextModel {
    type: "nestedItem";
    nestedFieldPrefix: string;
}

/** @internal */
export const NestedFormContext = createContext<NestedFormContextModel | undefined>(undefined);

export function NestedFormContextProvider({ children, ...props }: React.PropsWithChildren<NestedFormContextModel>) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const contextValue = useMemo<NestedFormContextModel>(() => props, [props.nestedFieldPrefix]);
    return <NestedFormContext.Provider value={contextValue}>{children}</NestedFormContext.Provider>;
}
