import TitleTabPage from "@magicware/dashboard/navigation/TitleTabPage";
import { useDeviceId } from "../../navigation/devices";
import { useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { deviceThunks } from "../../redux/devices/devicesReducer";
import { RootState } from "../../redux/types";

export default function DeviceTabPage() {
    const deviceId = useDeviceId();
    const api = useAutoDispatchAsyncThunk(deviceThunks.load, deviceId);
    return <TitleTabPage apiStatus={api.apiStatus} titleSelector={(state: RootState) => state.devices.detail?.name} />;
}
