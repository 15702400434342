/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useMemo } from "react";
import { useStore } from "react-redux";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AnyStore, StoreDispatch, StoreState } from "./types";
import { AnchoredAppAsyncThunk, idleState, isAsyncThunkRejectedPromiseResult, OptionalArgParameters } from "./thunks";
import { ApiValidationToFormMap, handleFormApiError } from "@magicware/fetch-api/forms";
import useNonReusableState from "@magicware/utils/hooks/useNonReusableState";

export default function createStoreHooks<Store extends AnyStore>() {
    const useAppDispatch = () => useDispatch<StoreDispatch<Store>>();

    const useAppSelector: TypedUseSelectorHook<StoreState<Store>> = useSelector;

    const useAppSelectorEnsure = <TSelected>(
        selector: (state: StoreState<Store>) => TSelected,
        equalityFn?: (left: TSelected, right: TSelected) => boolean,
    ) => {
        const result = useAppSelector(selector, equalityFn);
        if (!result) throw new Error("Unexpected Missing Data in Redux Store.");
        return result as Exclude<TSelected, undefined>;
    };

    const useAppState = () => useStore().getState as () => StoreState<Store>;

    const useAppSelectorDetached = <TSelected>(selector: (state: StoreState<Store>) => TSelected) =>
        selector(useStore().getState() as StoreState<Store>);

    function useAutoDispatchAsyncThunk<Returned, ThunkArg>(
        ...[thunk, arg]: OptionalArgParameters<
            ThunkArg,
            [thunk: AnchoredAppAsyncThunk<StoreState<Store>, Returned, ThunkArg>]
        >
    ) {
        if (thunk.behavior === "volatile") {
            const [dispatchThunk, state] = useManualDispatchAsyncThunk(thunk);

            const retry = useCallback(() => {
                return dispatchThunk(...([arg] as OptionalArgParameters<ThunkArg>));
            }, [arg]);

            useEffect(() => {
                const promise = retry();
                return () => promise.abort();
            }, []);

            return { apiStatus: state.status, retry, state };
        } else {
            const dispatch = useAppDispatch();
            const state = useAppSelector((state) => thunk.selectState(state, arg!));

            useEffect(() => {
                if (state.status === "idle") dispatch(thunk(arg!));
            }, [state.status === "idle"]);

            const retry = useCallback(() => dispatch(thunk.idle(arg!)), [arg]);

            return { apiStatus: state.status, retry, state };
        }
    }

    function useManualDispatchAsyncThunk<Returned, ThunkArg>(
        thunk: AnchoredAppAsyncThunk<StoreState<Store>, Returned, ThunkArg>,
    ) {
        const dispatch = useAppDispatch();
        const [lastRqId, setLastRqId] = useNonReusableState<string | undefined>(undefined);
        const state = useAppSelector((state) =>
            lastRqId === undefined ? idleState : thunk.selectRqState(state, lastRqId),
        );

        const setIdle = () => setLastRqId(undefined);

        const dispatchInvoke = useMemo(
            () =>
                (...[arg]: OptionalArgParameters<ThunkArg>) => {
                    const promise = dispatch(thunk(arg!));
                    setLastRqId(promise.requestId);
                    return promise;
                },
            [],
        );

        useEffect(() => {
            if (lastRqId && thunk.behavior === "volatile") {
                return () => {
                    dispatch(thunk.cleanup(lastRqId));
                };
            }
        }, [lastRqId]);

        return [dispatchInvoke, state, setIdle] as const;
    }

    function useFormThunk<Returned, ThunkArg>(
        thunk: AnchoredAppAsyncThunk<StoreState<Store>, Returned, ThunkArg>,
        apiErrorsMap?: ApiValidationToFormMap<any, any, any>,
    ) {
        const dispatch = useAppDispatch();
        const [lastRqId, setLastRqId] = useNonReusableState<string | undefined>(undefined);

        const dispatchInvoke = useMemo(
            () =>
                async (...[arg]: OptionalArgParameters<ThunkArg>) => {
                    const promise = dispatch(thunk(arg!));
                    setLastRqId(promise.requestId);
                    const result = await promise;
                    if (isAsyncThunkRejectedPromiseResult(result)) {
                        const submitErrors = handleFormApiError(result.payload, apiErrorsMap ?? {});
                        return { success: false, submitErrors, rejectedData: result.payload };
                    } else {
                        return { success: true, data: result.payload };
                    }
                },
            [],
        );

        useEffect(() => {
            if (lastRqId && thunk.behavior === "volatile") {
                return () => {
                    dispatch(thunk.cleanup(lastRqId));
                };
            }
        }, [lastRqId]);

        return dispatchInvoke;
    }

    return {
        useAppDispatch,
        useAppSelector,
        useAppSelectorEnsure,
        /** Vrací hodnotu ze storu dle předaného selectoru bez detekce změn a vyvolávání rerenderu. */
        useAppSelectorDetached,
        useAppState,
        useAutoDispatchAsyncThunk,
        useManualDispatchAsyncThunk,
        useFormThunk,
    };
}
