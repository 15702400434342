import SimplePage from "@magicware/dashboard/navigation/SimplePage";
import RegistrySummaries from "./RegistrySummaries";

export default function HomePage() {
    return (
        <div tw="h-full pt-1 pb-10">
            <div tw="h-full">
                <SimplePage tw="h-full mb-10">
                    <RegistrySummaries />
                </SimplePage>
            </div>
        </div>
    );
}
