import TitleTabPage from "@magicware/dashboard/navigation/TitleTabPage";
import { useBrandId } from "../../navigation/brands";
import { useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { brandThunks } from "../../redux/brands/brandsReducer";
import { RootState } from "../../redux/types";

export default function BrandTabPage() {
    const brandId = useBrandId();
    const api = useAutoDispatchAsyncThunk(brandThunks.load, brandId);
    return <TitleTabPage apiStatus={api.apiStatus} titleSelector={(state: RootState) => state.brands.detail?.name} />;
}
