import { KeyofOtherType, KeyofType, RemoveSufix } from "./types";

type ReplaceType<T, Sufix extends string, SourceType, TargetType> = Pick<
    T,
    KeyofOtherType<T, SourceType> & KeyofOtherType<T, SourceType | undefined> & KeyofOtherType<T, SourceType[]>
> & {
    [p in string & KeyofType<T, SourceType> as `${p}${Sufix}`]: TargetType;
} & {
    [p in string & KeyofType<T, SourceType | undefined> as `${p}${Sufix}`]?: TargetType;
} & {
    [p in string & KeyofType<T, SourceType[]> as `${RemoveSufix<p, "List">}${Sufix}s`]: TargetType[];
};

export type ReplaceEntities<Entity> = ReplaceType<Entity, "Id", { id: number }, number>;

/** Vytvoří nový objekt kopií předaného až na to, že jsou všechny reference na objekty převedeny na property obsahující jen id.
 * Příklad:
 * { myEntity: { id: number, ... }; myEntityList: {id: number, ...}[] }
 * převede na
 * { myentityId: number; myEntityIds: number[] }
 */
export function replaceEntities<T>(input: T): ReplaceEntities<T> {
    const result: any = { ...input };
    for (const p in result) {
        const val = result[p];
        if (val === undefined) delete result[p];
        else if (typeof val === "object") {
            if (typeof val["id"] === "number") {
                delete result[p];
                result[p + "Id"] = val.id;
            } else if (p.endsWith("List") && val instanceof Array) {
                delete result[p];
                result[p.substr(0, p.length - "List".length) + "Ids"] = val.map((o) => o.id);
            }
        }
    }
    return result;
}
