import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import PageLinkButton from "./PageLinkButton";

export interface PagingProps {
    state: PagingState;
    listSize?: number;
}

export interface PagingState<T = any> {
    page: number;
    records: number;
    pageData: T[];
    pageSize: number;
    lastPage: number;
    setPage: (page: number) => void;
}

export const DefaultPageSize = 25;

export interface PagingSettings<T = any> {
    data: T[];
    pageSize?: number;
    getPage?: () => number | undefined;
    setPage?: (page: number) => void;
}

export function usePaging<T = any>({
    data,
    pageSize = DefaultPageSize,
    getPage,
    setPage,
}: PagingSettings<T>): PagingState<T> {
    const [pageLocal, setPageLocal] = useState(1);
    useEffect(() => setPageLocal(1), [data]);
    const page = getPage && setPage ? getPage() ?? 1 : pageLocal;
    return useMemo(
        () => ({
            page: page,
            pageSize: pageSize,
            records: data.length,
            pageData: data.slice((page - 1) * pageSize, page * pageSize),
            lastPage: Math.ceil(data.length / pageSize),
            setPage: setPage ?? setPageLocal,
        }),
        [data, page],
    );
}

export default function Paging({ state: { lastPage, page: current, setPage: onChange }, listSize = 1 }: PagingProps) {
    if (lastPage === 1) return null;
    if (listSize === 0 && lastPage > 3)
        return (
            <div tw="flex items-center">
                <PageLinkButton
                    tw="mr-1 px-2"
                    onClick={onChange}
                    page={1}
                    current={current}
                    disabled={current === 1}
                    text={<FontAwesomeIcon icon={faAnglesLeft} />}
                />
                <PageLinkButton
                    tw="mr-1 px-2"
                    onClick={onChange}
                    page={current - 1}
                    current={current}
                    disabled={current === 1}
                    text={<FontAwesomeIcon icon={faAngleLeft} />}
                />
                <PageLinkButton onClick={onChange} page={current} current={current} />
                <PageLinkButton
                    tw="ml-1 px-2"
                    onClick={onChange}
                    page={current + 1}
                    current={current}
                    disabled={current === lastPage}
                    text={<FontAwesomeIcon icon={faAngleRight} />}
                />
                <PageLinkButton
                    tw="ml-1 px-2"
                    onClick={onChange}
                    page={lastPage}
                    current={current}
                    disabled={current === lastPage}
                    text={<FontAwesomeIcon icon={faAnglesRight} />}
                />
            </div>
        );

    const pages: number[] = [];
    let start = current - listSize;
    let end = current + listSize;
    if (start <= 1) end += 2 - start;
    if (end >= lastPage) start -= end - lastPage + 1;
    start = Math.max(2, start);
    end = Math.min(lastPage - 1, end);
    for (let i = start; i <= end; i++) pages.push(i);
    return (
        <div tw="flex items-center">
            <PageLinkButton tw="mr-2" onClick={onChange} page={1} current={current} />
            {pages.length > 0 && pages[0] !== 2 && "…"}
            {pages.map((p) => (
                <PageLinkButton key={p} onClick={onChange} page={p} current={current} />
            ))}
            {pages.length > 0 && pages[pages.length - 1] !== lastPage - 1 && "…"}
            <PageLinkButton tw="ml-2" onClick={onChange} page={lastPage} current={current} />
        </div>
    );
}
