import DataListBulkActionsContext from "@magicware/data-lists/DataListBulkActionsContext";
import { MenuAction } from "@magicware/data-lists/DataListContextMenu";
import { useTranslation } from "react-i18next";
import { useAppInstaceNavigation, useAppInstanceId } from "../../navigation/appInstances";
import { useNewItemMenuAction } from "../../navigation/hooks";

export default function AppInstanceVersionListMenuContext(props: { children?: React.ReactNode }) {
    const { t } = useTranslation();
    const actions: MenuAction[] = [];
    const [parentId, navigation] = useAppInstaceNavigation();
    const appInstanceId = useAppInstanceId();
    const addAction = useNewItemMenuAction(t("add-version"), () =>
        navigation.detail.versions.new.url(parentId, appInstanceId),
    );
    return (
        <DataListBulkActionsContext defaultAction={addAction} actions={actions}>
            {props.children}
        </DataListBulkActionsContext>
    );
}
