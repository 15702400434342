import Panel from "@magicware/dashboard/Panel";
import { buildForm } from "@magicware/forms/builder";
import Form, { FormImplementationProps } from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import FormSwitchField from "@magicware/forms/mui/FormSwitchField";
import FormTextField from "@magicware/forms/mui/FormTextField";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { updateDetail } from "../../redux/projectSetups/projectSetupsReducer";
import { ProjectSetupModelDetail } from "../../services/api/projectSetups";
import { FormBrandsAutocomplete } from "../brands/FormBrandsAutocomplete";

export const projectSetupForm = buildForm<ProjectSetupModelDetail>()(() => ({
    props: {
        remove: ["id", "version"],
        extra: [],
    },
    transformations: {
        toForm: { fromModel: (h, m: ProjectSetupModelDetail) => h.fromModel(m) },
        formTo: {
            new: (h, f) => h.toNewEntity(f),
            update: (h, f, m: ProjectSetupModelDetail) => h.toUpdateEntity(f, m),
        },
    },
    updateReduxModel: updateDetail,
    schema: (_, t) => ({
        name: yup.string().required(t("name-is-required")),
        brand: yup.object(),
        parameterList: yup.array().default([]),
        excludedParameterList: yup.array().default([]),
        deviceList: yup.array().default([]),
        notes: yup.string(),
        urls: yup.string(),
        isOwn: yup.boolean().default(false),
    }),
}));

const f = projectSetupForm.f;

export default function ProjectSetupForm(props: FormImplementationProps<typeof f>) {
    const { t } = useTranslation();
    return (
        <Form validationSchema={projectSetupForm.validationSchema} {...props}>
            <Panel>
                <FormGrid>
                    <div tw="flex items-center">
                        <FormTextField
                            tw="flex-1"
                            f={f}
                            field="name"
                            label={t("name-label")}
                            autoFocus={props.autoFocus}
                        />
                        <FormSwitchField tw="ml-3 flex-grow-0" f={f} field="isOwn" label={t("is-own-label")} />
                    </div>
                    <FormBrandsAutocomplete f={f} field="brand" fullOption label={t("brand-label")} filter={{}} />
                    <FormTextField f={f} field="urls" label={t("url-addresses-label")} multiline />
                    <FormTextField f={f} field="notes" label={t("notes-label")} multiline />
                </FormGrid>
            </Panel>
        </Form>
    );
}
