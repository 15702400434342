import { createEntityAdapter, EntityState } from "@magicware/redux/entities";
import { anchoreThunks, AsyncSliceState } from "@magicware/redux/thunks";
import { createSlice } from "@reduxjs/toolkit";
import {
    entityApi,
    AppInstanceVersionModelDetail,
    AppInstanceVersionFilter,
    AppInstanceVersionModelList,
} from "../../services/api/appInstanceVersions";
import { RootState } from "../types";

export interface State
    extends EntityState<AppInstanceVersionModelDetail, AppInstanceVersionModelList, AppInstanceVersionFilter>,
        AsyncSliceState {}

const initialState: State = { async: {} };

const adapter = createEntityAdapter("appInstanceVersions", entityApi, ["name", "baseAppVersion"]);

const extraThunks = {};

const slice = createSlice({
    name: "appInstanceVersions",
    initialState,
    reducers: adapter.getReducers(),
    extraReducers: (builder) => {
        adapter.addExtraReducers(builder);
        //addAsyncThunkReducers(builder, extraThunks.xxx);
    },
});

export const appInstanceVersionThunks = anchoreThunks((state: RootState) => state.appInstanceVersions, {
    ...extraThunks,
    ...adapter.thunks,
});

export const actions = slice.actions;
export const { updateDetail, clearList, removeListItems, updateListItems, setPage } = slice.actions;
export default slice.reducer;
