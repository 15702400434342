import { StoreDispatch } from "@magicware/redux/types";
import { FormApiFunc } from "@magicware/fetch-api/forms";
import { CreateApiResponse } from "@magicware/fetch-api/types";
import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { SubmissionErrors } from "final-form";
import { FormApi } from "final-form";

function useNewEntityOnSubmit<Form>(
    clearList: ActionCreatorWithoutPayload,
    apiCall: FormApiFunc<CreateApiResponse, [model: Form]>,
    onSuccess: (newId: number, model: Form) => void,
): (form: Form) => Promise<SubmissionErrors>;
function useNewEntityOnSubmit<Form, Model>(
    clearList: ActionCreatorWithoutPayload,
    apiCall: FormApiFunc<CreateApiResponse, [model: Model]>,
    onSuccess: (newId: number, model: Model) => void,
    transformForm: (f: Form, formApi: FormApi<Form, Partial<Form>>) => Model | Promise<Model>,
): (form: Form) => Promise<SubmissionErrors>;
function useNewEntityOnSubmit<Form, Model = Form>(
    clearList: ActionCreatorWithoutPayload,
    apiCall: FormApiFunc<CreateApiResponse, [model: any]>,
    onSuccess: (newId: number, model: Model) => void,
    transformForm?: (f: Form, formApi: FormApi<Form, Partial<Form>>) => Model | Promise<Model>,
) {
    const dispatch = useDispatch<StoreDispatch>();
    return async (form: Form, formApi: FormApi<Form, Partial<Form>>) => {
        const modelOrPromise: Model | Promise<Model> = transformForm
            ? transformForm(form, formApi)
            : (form as any as Model);
        const model = await Promise.resolve(modelOrPromise);
        const result = await apiCall(model);
        if (result.success) {
            dispatch(clearList());
            onSuccess(result.data.id, model);
        }
        return result.submitErrors;
    };
}

export default useNewEntityOnSubmit;
