import AsyncContent from "@magicware/dashboard/AsyncContent";
import DataList from "@magicware/data-lists/DataList";
import { FormStateApiContextProvider } from "@magicware/forms/components/FormStateApiContext";
import { useTranslation } from "react-i18next";
import { useAppInstaceNavigation, useAppInstanceId } from "../../navigation/appInstances";
import { appInstanceVersionThunks, setPage } from "../../redux/appInstanceVersions/appInstanceVersionsReducer";
import { useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { RootState } from "../../redux/types";
import EntityListItemMenuContext from "../entities/EntityListItemMenuContext";
import AppInstanceVersionListItem from "./AppInstanceVersionListItem";
import AppInstanceVersionListMenuContext from "./AppInstanceVersionListMenuContext";

export default function AppInstanceVersionListTab() {
    const appInstanceId = useAppInstanceId();
    const api = useAutoDispatchAsyncThunk(appInstanceVersionThunks.loadList, { appInstanceId });
    return <AsyncContent {...api} component={AppInstanceVersionListTabLoaded} />;
}

function AppInstanceVersionListTabLoaded() {
    const { t } = useTranslation();
    const navigation = useAppInstaceNavigation();
    return (
        <FormStateApiContextProvider>
            <AppInstanceVersionListMenuContext>
                <EntityListItemMenuContext
                    deleteSnackTitle={t("appInstanceVersion-deletion")}
                    deleteThunk={appInstanceVersionThunks.delete}
                >
                    <DataList
                        dataSelector={(state: RootState) => state.appInstanceVersions.list}
                        setPageActionCreator={setPage}
                        emptyList={t("no-versions-message")}
                    >
                        {(id) => <AppInstanceVersionListItem appInstanceVersionId={id} navigation={navigation} />}
                    </DataList>
                </EntityListItemMenuContext>
            </AppInstanceVersionListMenuContext>
        </FormStateApiContextProvider>
    );
}
