import { createAsyncFormAutocompleteWithFilter } from "@magicware/forms/mui/FormAutocomplete";
import { baseAppVersionThunks } from "../../redux/baseAppVersions/baseAppVersionsReducer";
import { RootState } from "../../redux/types";
import { BaseAppVersionSelectOption } from "../../services/api/baseAppVersions";
import { createDefaultProps } from "../forms/formAutocomplete";

export const FormBaseAppVersionsAutocomplete = createAsyncFormAutocompleteWithFilter(
    {
        selectOptions: (state: RootState) => state.baseAppVersions.list,
        createAsyncAction: baseAppVersionThunks.loadList,
        selectAsyncState: baseAppVersionThunks.loadList.selectState,
    },
    createDefaultProps<BaseAppVersionSelectOption>(
        (opt) => `${opt.baseApp.name} - ${opt.name}`,
        (n, opt) => n.baseApps.detail.versions.detail.url(opt.baseApp.id, opt.id),
    ),
);
