import AsyncContent from "@magicware/dashboard/AsyncContent";
import { useTranslation } from "react-i18next";
import { useConnectorVersionId } from "../../navigation/connectors";
import { connectorVersionThunks } from "../../redux/connectorVersions/connectorVersionsReducer";
import { useAppDispatch, useAppSelectorEnsure, useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { connectorVersionsApi } from "../../services/api/connectorVersions";
import ConnectorVersionForm, { connectorVersionForm } from "./ConnectorVersionForm";

const { useToFormFromModel } = connectorVersionForm.hooks;

export default function ConnectorVersionDetailTab() {
    const api = useAutoDispatchAsyncThunk(connectorVersionThunks.load, useConnectorVersionId());
    return <AsyncContent {...api} component={ConnectorVersionDetailTabLoaded} />;
}

function ConnectorVersionDetailTabLoaded() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const connectorVersionDetail = useAppSelectorEnsure((state) => state.connectorVersions.detail);
    const initialValues = useToFormFromModel(connectorVersionDetail);
    const onSubmit = async (form: typeof connectorVersionForm.f) => {
        const result = await connectorVersionsApi.forms.update(
            connectorVersionForm.transform.formTo.update(form, connectorVersionDetail),
        );
        return connectorVersionForm.apiUtils.handleUpdateEntityResult(dispatch, result, form);
    };
    return (
        <ConnectorVersionForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            savePanel={{ successMessage: t("connector-version-has-been-saved") }}
        />
    );
}
