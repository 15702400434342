import { deviceThunks } from "../../redux/devices/devicesReducer";
import { useAppDispatch, useAppSelectorEnsure, useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { devicesApi } from "../../services/api/devices";
import DeviceForm, { deviceForm, DeviceFormOnSubmit } from "./DeviceForm";
import AsyncContent from "@magicware/dashboard/AsyncContent";
import { useDeviceId } from "../../navigation/devices";
import { useTranslation } from "react-i18next";

const { useToFormFromModel } = deviceForm.hooks;

export default function DeviceDetailTabPage() {
    const api = useAutoDispatchAsyncThunk(deviceThunks.load, useDeviceId());
    return <AsyncContent {...api} component={DeviceDetailTabPageLoaded} />;
}

function DeviceDetailTabPageLoaded() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const deviceDetail = useAppSelectorEnsure((state) => state.devices.detail);
    const initialValues = useToFormFromModel(deviceDetail);
    const onSubmit: DeviceFormOnSubmit = async (form) => {
        const result = await devicesApi.forms.update(
            await deviceForm.transform.formTo.update(form, dispatch, deviceDetail),
        );
        return deviceForm.apiUtils.handleUpdateEntityResult(dispatch, result, form);
    };
    return (
        <DeviceForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            savePanel={{ successMessage: t("device-has-been-saved") }}
        />
    );
}
