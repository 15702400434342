import { useParams } from "react-router-dom";

export function useParam<TKey extends string, TRequired extends boolean = true>(
    name: TKey,
    required: TRequired = true as TRequired,
) {
    const result = useParams<{ [key in TKey]?: string }>();
    const val = result[name];
    if (!val && required) throw new Error(`Missing ${name} parameter`);
    return val!;
}
