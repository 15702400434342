import { buildSearchForm } from "@magicware/forms/builder/searchForm";
import { SearchForm } from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import FormTextField from "@magicware/forms/mui/FormTextField";
import { useTranslation } from "react-i18next";
import { baseAppThunks } from "../../redux/baseApps/baseAppsReducer";
import { RootState } from "../../redux/types";

const { f, useSearchForm } = buildSearchForm(
    baseAppThunks.loadList,
    (state: RootState) => state.baseApps.list?.filter,
    () => ({}),
);

export default function BaseAppSearchForm() {
    const { t } = useTranslation();
    const searchForm = useSearchForm();
    return (
        <SearchForm {...searchForm}>
            <FormGrid tw="md:grid-cols-2">
                <FormTextField f={f} field="name" label={t("name-label")} searchIcon />
            </FormGrid>
        </SearchForm>
    );
}
