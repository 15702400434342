import SimplePage from "@magicware/dashboard/navigation/SimplePage";
import useNewEntityOnSubmit from "@magicware/forms/hooks/useNewEntityOnSubmit";
import { useTranslation } from "react-i18next";
import { useNavigationClickReplace } from "../../navigation/hooks";
import { clearList } from "../../redux/libraries/librariesReducer";
import { librariesApi } from "../../services/api/libraries";
import LibraryForm, { libraryForm } from "./LibraryForm";

const { useDefaults } = libraryForm.hooks;

export default function LibraryNewPage() {
    const { t } = useTranslation();
    const onSubmit = useNewEntityOnSubmit(
        clearList,
        librariesApi.forms.create,
        useNavigationClickReplace((n, newId) => n.libraries.detail.url(newId)),
        (f: typeof libraryForm.f) => libraryForm.transform.formTo.new(f),
    );
    const initialValues = useDefaults();
    return (
        <SimplePage title={t("new-library-title")}>
            <LibraryForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                autoFocus
                savePanel={{ buttonContent: t("create-new-library"), disableOnPristine: true }}
            />
        </SimplePage>
    );
}
