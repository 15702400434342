import AsyncContent from "@magicware/dashboard/AsyncContent";
import DataList from "@magicware/data-lists/DataList";
import { FormStateApiContextProvider } from "@magicware/forms/components/FormStateApiContext";
import { fileThunks, setPage } from "../../redux/files/filesReducer";
import { useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { RootState } from "../../redux/types";
import { FilesApi } from "../../services/api/files";
import FileListItem from "./FileListItem";
import FileListItemMenuContext from "./FileListItemMenuContext";
import FileListMenuContext from "./FileListMenuContext";

export interface FileListTabPageProps {
    api: FilesApi;
    ownerId: number;
}

export default function FileListTabPage(props: FileListTabPageProps) {
    const api = useAutoDispatchAsyncThunk(fileThunks.loadFiles, { api: props.api, filter: { ownerId: props.ownerId } });
    return <AsyncContent {...api} {...props} component={FileListTabPageLoaded} />;
}

function FileListTabPageLoaded({ api, ownerId }: FileListTabPageProps) {
    return (
        <FormStateApiContextProvider>
            <FileListMenuContext ownerId={ownerId} api={api}>
                <FileListItemMenuContext ownerId={ownerId} api={api}>
                    <DataList
                        dataSelector={(state: RootState) => state.files.list}
                        setPageActionCreator={setPage}
                        emptyList={"No files or images matching your search criteria were found."}
                    >
                        {(id) => <FileListItem fileId={id} />}
                    </DataList>
                </FileListItemMenuContext>
            </FileListMenuContext>
        </FormStateApiContextProvider>
    );
}
