import { faRuler } from "@fortawesome/free-solid-svg-icons";
import { prepareBreadcrumb } from "@magicware/dashboard/navigation/breadcrumbs";
import { CreatePatternMethod, CreateSimpleMethod } from "@magicware/dashboard/navigation/builder";
import { useIntParam } from "@magicware/dashboard/navigation/useIntParam";
import ConfigurationTabPage from "../components/configuration/ConfigurationTabPage";
import ParameterDetailPage from "../components/parameters/ParameterDetailPage";
import ParameterNewPage from "../components/parameters/ParameterNewPage";

export const useParameterId = () => useIntParam("parameterId");

export const parametersIcon = faRuler;

export const buildParameters = (simple: CreateSimpleMethod, pattern: CreatePatternMethod) =>
    simple({
        url: "/parameters",
        title: (t) => t("parameters-title"),
        component: ConfigurationTabPage,
        breadcrumb: prepareBreadcrumb((t) => t("parameters-breadcrumb")),

        new: simple({
            url: "/parameters/new",
            component: ParameterNewPage,
            breadcrumb: prepareBreadcrumb((t) => t("new-parameter-breadcrumb")),
        }),
        detail: pattern({
            pattern: "/parameters/:parameterId",
            component: ParameterDetailPage,
            url: (parameterId: number) => `/parameters/${parameterId}`,
            breadcrumb: prepareBreadcrumb((t) => t("parameter-detail-breadcrumb")),
        }),
    });
