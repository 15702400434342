import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import { Breadcrumb, BreadcrumbName, INavigatablePage, isNavigatablePage } from "./utils";

function isBreadcrumbTmp(obj: any): obj is BreadcrumbTmp {
    return typeof obj === "object" && typeof (obj as BreadcrumbTmp)?.nameTmp !== "undefined";
}

type BreadcrumbTmp = {
    nameTmp: BreadcrumbName;
};

export function prepareBreadcrumbIcon<Params extends any[]>(icon: IconProp, text?: string) {
    if (text) return prepareBreadcrumb<Params>(<Fragment>{text}</Fragment>);
    return prepareBreadcrumb<Params>(<FontAwesomeIcon icon={icon} />);
}

export function prepareBreadcrumb<Params extends any[]>(name: BreadcrumbName): (...params: Params) => Breadcrumb {
    const tmp: BreadcrumbTmp = { nameTmp: name };
    return tmp as any as (...params: Params) => Breadcrumb;
}

export function constructBreadcrumbs(navigator: any) {
    constructBreadcrumbsRecursive(undefined, navigator);
}
function constructBreadcrumbsRecursive(parent: INavigatablePage<any[]> | undefined, obj: any) {
    for (const key in obj) {
        const page = obj[key];
        if (typeof page === "object") {
            if (isNavigatablePage(page)) {
                if (page.breadcrumb !== undefined) {
                    const tmpBreadcrumb = page.breadcrumb as any;
                    if (isBreadcrumbTmp(tmpBreadcrumb))
                        page.breadcrumb = combineBreadcrumbs(parent, page, tmpBreadcrumb.nameTmp);
                    constructBreadcrumbsRecursive(page, page);
                } else {
                    if (parent?.breadcrumb) page.breadcrumb = parent.breadcrumb;
                    constructBreadcrumbsRecursive(parent, page);
                }
            } else {
                constructBreadcrumbsRecursive(parent, page);
            }
        }
    }
}

function combineBreadcrumbs(
    parent: INavigatablePage<any[]> | undefined,
    current: INavigatablePage<any[]>,
    name: BreadcrumbName,
): (...p: any[]) => Breadcrumb {
    return (...params: any[]) => {
        return {
            name: name,
            url: current.url(...params),
            next: parent?.breadcrumb && parent.breadcrumb(...params),
        };
    };
}
