import { buildSearchForm } from "@magicware/forms/builder/searchForm";
import { SearchForm } from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import { experienceThunks } from "../../redux/experiences/experiencesReducer";
import { RootState } from "../../redux/types";
import { FormDevicesAutocomplete } from "../devices/FormDevicesAutocomplete";
import { FormParametersAutocomplete } from "../parameters/FormParametersAutocomplete";
import { FormProjectSetupAutocomplete } from "../projectSetups/FormProjectSetupAutocomplete";

const { f, useSearchForm } = buildSearchForm(
    experienceThunks.loadList,
    (state: RootState) => state.experiences.list?.filter,
    () => ({}),
);

export default function ExperienceSearchForm() {
    const searchForm = useSearchForm();
    return (
        <SearchForm {...searchForm}>
            <FormGrid tw="md:grid-cols-2">
                <FormDevicesAutocomplete f={f} field="deviceId" label="Device" multiple filter={{}} />
                <FormParametersAutocomplete f={f} field="parameterId" label="Prameter" multiple filter={{}} />
                <FormProjectSetupAutocomplete f={f} field="projectSetupId" label="Project" multiple filter={{}} />
            </FormGrid>
        </SearchForm>
    );
}
