import { SearchFormPanel } from "@magicware/dashboard/Panel";
import DataList from "@magicware/data-lists/DataList";
import { FormStateApiContextProvider } from "@magicware/forms/components/FormStateApiContext";
import { useTranslation } from "react-i18next";
import { brandThunks, setPage } from "../../redux/brands/brandsReducer";
import { RootState } from "../../redux/types";
import EntityListItemMenuContext from "../entities/EntityListItemMenuContext";
import BrandListItem from "./BrandListItem";
import BrandListMenuContext from "./BrandListMenuContext";
import BrandSearchForm from "./BrandSearchForm";

export default function BrandListPage() {
    const { t } = useTranslation();
    return (
        <FormStateApiContextProvider>
            <SearchFormPanel>
                <BrandSearchForm />
            </SearchFormPanel>
            <BrandListMenuContext>
                <EntityListItemMenuContext deleteSnackTitle={t("brand-deletion")} deleteThunk={brandThunks.delete}>
                    <DataList
                        dataSelector={(state: RootState) => state.brands.list}
                        setPageActionCreator={setPage}
                        emptyList={t("no-brands-message")}
                    >
                        {(id) => <BrandListItem brandId={id} />}
                    </DataList>
                </EntityListItemMenuContext>
            </BrandListMenuContext>
        </FormStateApiContextProvider>
    );
}
