import { OnProgressFunc } from "@magicware/fetch-api/fetch";
import { addAsyncThunkReducers, anchoreThunks, AsyncSliceState, createAppAsyncThunk } from "@magicware/redux/thunks";
import { createDataDictionary, DataDictionary } from "@magicware/utils/DataDictionary";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FileListFilter, FileListModel, FileModelNew, FileModelUpdate, FilesApi } from "../../services/api/files";
import { RootState } from "../types";

export interface State extends AsyncSliceState {
    apiUrl?: string;
    list?: DataDictionary<FileListModel, FileListFilter>;
}

const initialState: State = { async: {} };

const extraThunks = {
    loadFiles: createAppAsyncThunk(
        "loadFiles",
        "exclusive",
        (arg: { api: FilesApi; filter: FileListFilter }, thunkApi) => {
            return arg.api.list(arg.filter, thunkApi.signal);
        },
        (arg) => `${arg.api.url}_${JSON.stringify(arg.filter)}`,
    ),
    enlistNewFile: createAppAsyncThunk(
        "enlistNewFile",
        (arg: { api: FilesApi; fileId: number; ownerId: number }, thunkApi) => {
            return arg.api.list({ ownerId: arg.ownerId, fileId: arg.fileId }, thunkApi.signal);
        },
    ),
    newFile: createAppAsyncThunk(
        "newFile",
        async (arg: { api: FilesApi; onProgress: OnProgressFunc; data: Blob; file: FileModelNew }, thunkApi) => {
            const response = await arg.api.insert(arg.onProgress, arg.data, arg.file, thunkApi.signal);
            thunkApi.dispatch(
                fileThunks.enlistNewFile({ api: arg.api, fileId: response.fileId, ownerId: arg.file.ownerId }),
            );
            return response;
        },
    ),
    toggleIsLogo: createAppAsyncThunk(
        "toggleIsLogo",
        (arg: { api: FilesApi; ownerId: number; fileId: number }, thunkApi) => {
            const state = thunkApi.getState() as RootState;
            const file = state.files.list?.byId[arg.fileId];
            if (!file) throw new Error("File is not in redux store");
            const fileUpdate: FileModelUpdate = { id: arg.fileId, isLogo: !file.isLogo };
            arg.api.update(arg.ownerId, fileUpdate, thunkApi.signal);
            return !file.isLogo;
        },
    ),
    deleteFile: createAppAsyncThunk(
        "deleteFile",
        (arg: { api: FilesApi; ownerId: number; fileId: number }, thunkApi) => {
            arg.api.delete(arg.ownerId, arg.fileId, thunkApi.signal);
        },
    ),
};

const slice = createSlice({
    name: "files",
    initialState,
    reducers: {
        setPage: (state, action: PayloadAction<{ page: number }>) => {
            if (state.list) state.list.page = action.payload.page;
        },
    },
    extraReducers: (builder) => {
        addAsyncThunkReducers(builder, extraThunks.loadFiles, {
            fulfilled: (state, action) => {
                state.apiUrl = action.meta.arg.api.url;
                state.list = createDataDictionary(action.payload, action.meta.arg.filter);
            },
        });
        addAsyncThunkReducers(builder, extraThunks.newFile);
        addAsyncThunkReducers(builder, extraThunks.toggleIsLogo, {
            fulfilled: (state, action) => {
                const file = state.list?.byId[action.meta.arg.fileId];
                if (file) file.isLogo = action.payload;
            },
        });
        addAsyncThunkReducers(builder, extraThunks.deleteFile, {
            fulfilled: (state, action) => {
                if (state.list?.byId[action.meta.arg.fileId]) {
                    state.list.all = state.list.all.filter((id) => id !== action.meta.arg.fileId);
                    delete state.list.byId[action.meta.arg.fileId];
                }
            },
        });
        addAsyncThunkReducers(builder, extraThunks.enlistNewFile, {
            fulfilled: (state, action) => {
                const file = action.payload[0];
                if (
                    file &&
                    state.apiUrl === action.meta.arg.api.url &&
                    state.list?.filter?.ownerId === action.meta.arg.ownerId &&
                    !state.list.byId[file.id]
                ) {
                    state.list.all.push(file.id);
                    state.list.byId[file.id] = file;
                }
            },
        });
    },
});

export const fileThunks = anchoreThunks((state: RootState) => state.files, extraThunks);

export const actions = slice.actions;
export const { setPage } = slice.actions;
export default slice.reducer;
