import tw from "twin.macro";
import { Fragment, useEffect, useMemo, useRef } from "react";
import { Form as ReactFinalForm } from "react-final-form";
import { FormApi, FORM_ERROR, SubmissionErrors } from "final-form";
import * as yup from "yup";
import FormSubmitButton from "./FormSubmitButton";
import { FormSchema } from "../builder";
import useYupFormValidation from "../hooks/useYupFormValidation";
import FormSubmitResult from "./FormSubmitResult";
import SavePanel, { SavePanelProps } from "./SavePanel";
import FormResetButton from "./FormResetButton";
import { defaultUnknownErrorMessage, FormApiResult } from "@magicware/fetch-api/forms";
import { DisabledFormContextProvider } from "./DisabledFormContext";
import { FormStateApiCapture } from "./FormStateApiContext";
import arrayMutators from "final-form-arrays";
import { useTranslation } from "react-i18next";

export interface FormBaseProps<FormValues> {
    initialValues: FormValues;
    /** Objekt, umožňující automatické odeslání formuláře při první načtení. Používá se primárně u filtračních fomulářů.
     * K odeslání dojde v okamžiku, kdy initialValues je shodné s tímto objektem (stejná reference). */
    autoSubmitInitialValues?: FormValues;
    onSubmit: (
        values: FormValues,
        form: FormApi<FormValues, Partial<FormValues>>,
    ) => SubmissionErrors | Promise<SubmissionErrors> | Promise<FormApiResult> | void;
    validationSchema?: yup.SchemaOf<FormValues> | FormSchema<FormValues>;
    extraValidation?: (schema: yup.SchemaOf<FormValues>) => yup.SchemaOf<FormValues>;
    children: React.ReactNode;
    savePanel?: SavePanelProps;
    selections?: React.ReactNode;
    selectionFormsPanelRef?: React.Ref<HTMLDivElement>;
    withAutocomplete?: boolean;
}

export interface FormProps<FormValues> extends FormBaseProps<FormValues> {
    initialSelection?: string & keyof FormValues;
    disabled?: boolean;
    autoFocus?: boolean;
}

export default function Form<FormValues>({ initialSelection, disabled, ...props }: FormProps<FormValues>) {
    return (
        <DisabledFormContextProvider disabled={disabled}>
            <FormInSelectionContext {...props} />
        </DisabledFormContextProvider>
    );
}

export type FormImplementationProps<RealForm> = Omit<FormProps<RealForm>, "validationSchema" | "children">;

export function SearchForm<FormValues>({
    children,
    extraButtons,
    ...props
}: FormBaseProps<FormValues> & { extraButtons?: React.ReactNode }) {
    const { t } = useTranslation();
    return (
        <Form {...props}>
            {children}
            <div tw="mt-3 flex space-x-5">
                <FormSubmitButton ignoreDirty variant="outlined">
                    {t("search-button")}
                </FormSubmitButton>
                {props.autoSubmitInitialValues && (
                    <FormResetButton resetValue={props.autoSubmitInitialValues}>
                        {t("clear-filter-button")}
                    </FormResetButton>
                )}
                {extraButtons}
            </div>
            <FormSubmitResult tw="mt-3" />
        </Form>
    );
}

function FormInSelectionContext<FormValues>({
    onSubmit,
    validationSchema,
    extraValidation,
    initialValues,
    autoSubmitInitialValues,
    withAutocomplete,
    savePanel,
    children,
}: FormBaseProps<FormValues>) {
    const handleValidate = useYupFormValidation(validationSchema, extraValidation);
    const handleSubmit = useMemo(
        () => async (values: FormValues, form: FormApi<FormValues, Partial<FormValues>>) => {
            try {
                const result = await onSubmit(values, form);
                if (result && typeof result.success === "boolean") return result.submitErrors;
                return result;
            } catch (err) {
                console.error("Unexpected error has occured in form submit handler: %o", err);
                return { [FORM_ERROR]: defaultUnknownErrorMessage };
            }
        },
        [onSubmit],
    );

    const formRef = useRef<HTMLFormElement>(null);

    const autoSubmit = initialValues === autoSubmitInitialValues;
    useEffect(() => {
        if (formRef.current !== undefined && autoSubmit)
            formRef.current!.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    }, [autoSubmit, formRef]);

    return (
        <ReactFinalForm<FormValues>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={handleValidate}
            subscription={{}}
            mutators={{ ...arrayMutators }}
        >
            {({ handleSubmit }) => (
                <Fragment>
                    <form ref={formRef} onSubmit={handleSubmit} autoComplete={withAutocomplete ? undefined : "off"}>
                        {children}
                        {savePanel && <SavePanel {...savePanel} />}
                    </form>
                    <FormStateApiCapture />
                </Fragment>
            )}
        </ReactFinalForm>
    );
}
