import { useField } from "react-final-form";
import { FormControlLabel, FormControlLabelProps, FormHelperText, Checkbox, CheckboxProps } from "@mui/material";
import useDisabled from "../hooks/useDisabled";
import useFieldName from "../hooks/useFieldName";
import { FormFieldProps } from "../builder";

export interface FormCheckBoxFieldProps {
    label?: FormControlLabelProps["label"];
}

export default function FormCheckBoxField<FormValues>({
    f: _,
    field,
    label,
    disabled,
    ...props
}: FormFieldProps<FormValues, boolean | undefined> & CheckboxProps & FormCheckBoxFieldProps) {
    field = useFieldName(field);
    disabled = useDisabled(disabled);
    const formField = useField(field, { type: "checkbox" });
    const isError = formField.meta.touched && Boolean(formField.meta.error || formField.meta.submitError);
    const switchControl = <Checkbox id={field} {...props} {...formField.input} disabled={disabled} />;
    const control = label ? (
        <FormControlLabel control={switchControl} label={label} disabled={disabled} />
    ) : (
        switchControl
    );
    return (
        <div>
            {control}
            <FormHelperText error={isError}>
                {isError && (formField.meta.error || formField.meta.submitError)}
            </FormHelperText>
        </div>
    );
}
