/* eslint-disable react-hooks/rules-of-hooks */
import { StoreDispatch } from "@magicware/redux/types";
import { FormApiResult } from "@magicware/fetch-api/forms";
import { IdVersion, UpdateApiResponse } from "@magicware/fetch-api/types";
import { replaceEntities, ReplaceEntities } from "@magicware/utils/replaceEntities";
import { IEmpty, KeyofType, OmitFirstParameter } from "@magicware/utils/types";
import { initYupExtensions } from "@magicware/utils/yup/yupExtensions";
import { SubmissionErrors } from "final-form";
import { useMemo } from "react";
import { AnyAction } from "redux";
import * as yup from "yup";

initYupExtensions();

type TranslateFunc = (key: string) => string;

export type FormSchema<T> = {
    [k in keyof Required<T>]: T[k] extends boolean | undefined
        ? yup.BooleanSchema | yup.BaseSchema<boolean>
        : T[k] extends string | undefined
        ? yup.StringSchema | yup.BaseSchema<string> | yup.DateSchema
        : T[k] extends number | undefined
        ? yup.NumberSchema | yup.BaseSchema<number>
        : T[k] extends Array<any> | undefined
        ? yup.ArraySchema<any>
        : yup.AnyObjectSchema;
};

export interface FormFieldProps<FormValues, PropType = any> {
    f: FormValues;
    field: string & KeyofType<FormValues, PropType>;
}

export type FormUtils<
    RealForm,
    Transformations extends TransformationsDef,
    UpdateActionCreator extends undefined | UpdateActionCreatorFunc,
> = {
    f: RealForm;
    /** Objekt formuláře obsahující výchozí hodnoty dle schématu.
     * Jedná se o singleton, je tedy nutné vždy používat kopii!! */
    defaults: RealForm;
    validationSchema: FormSchema<RealForm>;
    transform: FormTransformations<RealForm, Transformations>;
    hooks: FormHooks<RealForm, Transformations>;
    apiUtils: FormApiUtils<RealForm, UpdateActionCreator, Transformations>;
};

export function buildForm<Form>() {
    return <
        PickProps extends PropsType<Form>,
        ExtraProps extends PropsType<Form>,
        RemoveProps extends PropsType<Form>,
        Transformations extends TransformationsDef<Form, PickProps, ExtraProps, RemoveProps>,
        UpdateActionCreator extends
            | undefined
            | UpdateActionCreatorFunc<ToModel<Form, PickProps, ExtraProps, RemoveProps>> = undefined,
    >(
        optionsBuilder: (
            defaults: Defaults<Form>,
        ) => FormOptions<Form, PickProps, ExtraProps, RemoveProps, Transformations, UpdateActionCreator>,
    ): FormUtils<ToForm<Form, PickProps, ExtraProps, RemoveProps>, Transformations, UpdateActionCreator> => {
        type T = ToForm<Form, PickProps, ExtraProps, RemoveProps>;

        const defaultOptions = createDefaults<Form>();

        const options = optionsBuilder(defaultOptions as any);

        const fakeTranslate: TranslateFunc = (key) => key;

        const schema =
            typeof options.schema === "function" ? options.schema(undefined! as T, fakeTranslate) : options.schema;

        const defaults = yup.object(schema as any).getDefault() as T;

        const transformator = createTransformator(defaults, options.props);

        const transformations = createTransformations(options, transformator);

        const hooks = createHooks(transformations, defaults);

        const toReduxUpdateModel =
            options.transformations?.toReduxUpdateModel && options.transformations
                ? (f: T, ...args: any[]) => options.transformations!.toReduxUpdateModel!(transformator, f, ...args)
                : transformator.removeExtra;

        const utils: FormUtils<T, Transformations, UpdateActionCreator> = {
            f: {} as any,
            defaults: { ...defaults },
            validationSchema: schema,
            transform: transformations,
            hooks: hooks,
            apiUtils: createApiUtils(options.updateReduxModel, toReduxUpdateModel),
        };

        return utils;
    };
}

type FormOptions<
    Form,
    PickProps extends PropsType<Form>,
    ExtraProps extends PropsType<Form>,
    RemoveProps extends PropsType<Form>,
    Transformations extends TransformationsDef<Form, PickProps, ExtraProps, RemoveProps>,
    UpdateActionCreator extends undefined | UpdateActionCreatorFunc<ToModel<Form, PickProps, ExtraProps, RemoveProps>>,
> = {
    props: FormPropsDef<Form, PickProps, ExtraProps, RemoveProps>;
    schema:
        | FormSchema<ToForm<Form, PickProps, ExtraProps, RemoveProps>>
        | ((
              f: ToForm<Form, PickProps, ExtraProps, RemoveProps>,
              t: TranslateFunc,
          ) => FormSchema<ToForm<Form, PickProps, ExtraProps, RemoveProps>>);
    transformations?: Transformations;
    updateReduxModel?: UpdateActionCreator;
};

type TransformationsDef<
    Form = any,
    PickProps extends PropsType<Form> = any,
    ExtraProps extends PropsType<Form> = any,
    RemoveProps extends PropsType<Form> = any,
> = {
    formTo?: {
        [p in string]: (
            t: Transformator<Form, PickProps, ExtraProps, RemoveProps>,
            f: ToForm<Form, PickProps, ExtraProps, RemoveProps>,
            ...p: any[]
        ) => any;
    };
    toForm?: {
        [p in string]: (
            t: Transformator<Form, PickProps, ExtraProps, RemoveProps>,
            ...p: any[]
        ) => ToForm<Form, PickProps, ExtraProps, RemoveProps>;
    };
    toReduxUpdateModel?: (
        t: Transformator<Form, PickProps, ExtraProps, RemoveProps>,
        f: ToForm<Form, PickProps, ExtraProps, RemoveProps>,
        ...p: any[]
    ) => any;
};

type PropsType<Form> = string & keyof Form;

type FormPropsDef<
    Form,
    PickProps extends PropsType<Form>,
    ExtraProps extends PropsType<Form>,
    RemoveProps extends PropsType<Form>,
> = {
    pick?: PickProps[];
    remove?: RemoveProps[];
    extra?: ExtraProps[];
};

export type Defaults<Form> = {
    oneToOne: {
        props: {
            remove: [];
            extra: [];
        };
        transformations: {
            toForm: {
                fromModel: (h: Transformator<Form>, m: Form) => ToForm<Form>;
            };
            formTo: {
                model: (h: Transformator<Form>, f: ToForm<Form>) => ToModel<Form>;
            };
        };
    };
    entity: {
        props: {
            remove: ["id", "version"];
            extra: [];
        };
        //transformations: TransformationsDef<Form & IdVersion, PropsType<Form>, never, keyof IdVersion>;
        //Transformator<Form, PickProps, ExtraProps, RemoveProps>
        // transformations: DefaultTransformations<
        //     Form,
        //     Form & IdVersion,
        //     PropsType<Form>,
        //     never,
        //     keyof Form & keyof IdVersion
        // >;
        transformations: {
            toForm: {
                fromModel: (
                    h: Transformator<Form, PropsType<Form>, never, keyof Form & keyof IdVersion>,
                    m: Form,
                ) => ToForm<Form, PropsType<Form>, never, keyof Form & keyof IdVersion>;
            };
            formTo: {
                new: (
                    h: Transformator<Form, PropsType<Form>, never, keyof Form & keyof IdVersion>,
                    f: ToForm<Form, PropsType<Form>, never, keyof Form & keyof IdVersion>,
                ) => ReplaceEntities<ToModel<Form, PropsType<Form>, never, keyof Form & keyof IdVersion>>;
                update: (
                    h: Transformator<Form, PropsType<Form>, never, keyof Form & keyof IdVersion>,
                    f: ToForm<Form, PropsType<Form>, never, keyof Form & keyof IdVersion>,
                    m: Form & IdVersion,
                ) => IdVersion & ReplaceEntities<ToModel<Form, PropsType<Form>, never, keyof Form & keyof IdVersion>>;
            };
        };
    };
};
function createDefaults<Form>(): Defaults<Form> {
    return {
        entity: {
            props: {
                remove: ["id", "version"],
                extra: [],
            },
            transformations: {
                toForm: { fromModel: (h, m) => h.fromModel(m) },
                formTo: {
                    new: (h, f) => h.toNewEntity(f),
                    update: (h, f, m) => h.toUpdateEntity(f, m),
                },
            },
        },
        oneToOne: {
            props: {
                remove: [],
                extra: [],
            },
            transformations: {
                toForm: { fromModel: (h, m) => h.fromModel(m) },
                formTo: { model: (h, f) => h.removeExtra(f) },
            },
        },
    };
}

type ToForm<
    Form,
    PickProps extends PropsType<Form> = PropsType<Form>,
    ExtraProps extends PropsType<Form> = never,
    RemoveProps extends PropsType<Form> = never,
> = Pick<Form, Exclude<PickProps | ExtraProps, RemoveProps>>;

type ToModel<
    Form,
    PickProps extends PropsType<Form> = PropsType<Form>,
    ExtraProps extends PropsType<Form> = never,
    RemoveProps extends PropsType<Form> = never,
> = Pick<Form, Exclude<Exclude<PickProps, ExtraProps>, RemoveProps>>;

type Transformator<
    Form,
    PickProps extends PropsType<Form> = PropsType<Form>,
    ExtraProps extends PropsType<Form> = never,
    RemoveProps extends PropsType<Form> = never,
> = {
    /** Formulářový objekt vytvořený čistě z výchozích hodnot (dle schématu).
     *
     * Jedná se o singleton, takže je nutno používat pouze jeho kopie!!
     */
    defaults: ToForm<Form, PickProps, ExtraProps, RemoveProps>;
    /** Vytvoří nový objekt formuláře z výchozích hodnot (dle schématu) a nastaví do něj všechny
     * vlastnosti z modelu, které nejsou v EXTRA propertách. Je tedy zaručeno, že výsledný
     * objekt obsahuje právě a jen property dle definice formuláře.
     */
    fromModel: <Model>(model: Model) => ToForm<Form, PickProps, ExtraProps, RemoveProps>;
    /** Vytvoří nový objekt kopií předaného formuláře, kde jsou odebrány všechny EXTRA property.
     *
     * Nový objekt je ve skutečnosti nejprve vytvořen z výchozích hodnot (dle schématu), do kterého
     * jsou následně nakopírovány hodnoty předaného a následně odebrány EXTRA property. Je tak zaručeno
     * že ve výsledném objektu jsou opravdu právě a jen hodnoty dle definoce formuláře i kdyby
     * předaný formulářovy objekt obsahoval cokoliv navíc a nebo naopak v něm cokoliv chybělo.
     */
    removeExtra: (
        form: ToForm<Form, PickProps, ExtraProps, RemoveProps>,
    ) => ToModel<Form, PickProps, ExtraProps, RemoveProps>;
    /** Volá removeExtra a zároveň výsledek převádí na entitu pomocí replaceEntities
     *
     * @see Transformator.removeExtra
     * @see replaceEntities
     * @example <caption>Příklad převodu pomocí replaceEntities.</caption>
     * Z formulářového objektu
     * { myEntity: { id: number, ... }; myEntityList: {id: number, ...}[] }
     * vytvoří
     * { myEntityId: number; myEntityIds: number[] }
     */
    toNewEntity: (
        form: ToForm<Form, PickProps, ExtraProps, RemoveProps>,
    ) => ReplaceEntities<ToModel<Form, PickProps, ExtraProps, RemoveProps>>;
    /** Volá removeExtra a zároveň výsledek převádí na entitu pomocí replaceEntities a zároveň do výsledku přidá id a version z předaného objektu.
     *
     * @see Transformator.removeExtra
     * @see replaceEntities
     * @example <caption>Příklad převodu pomocí replaceEntities.</caption>
     * Z formulářového objektu
     * { myEntity: { id: number, ... }; myEntityList: {id: number, ...}[] }
     * vytvoří
     * { myEntityId: number; myEntityIds: number[] }
     */
    toUpdateEntity: (
        form: ToForm<Form, PickProps, ExtraProps, RemoveProps>,
        idVersion: IdVersion,
    ) => IdVersion & ReplaceEntities<ToModel<Form, PickProps, ExtraProps, RemoveProps>>;
};
function createTransformator<
    Form,
    PickProps extends PropsType<Form>,
    ExtraProps extends PropsType<Form>,
    RemoveProps extends PropsType<Form>,
>(
    defaults: ToForm<Form, PickProps, ExtraProps, RemoveProps>,
    props: FormPropsDef<Form, PickProps, ExtraProps, RemoveProps>,
): Transformator<Form, PickProps, ExtraProps, RemoveProps> {
    const transformator = {} as Transformator<Form, PickProps, ExtraProps, RemoveProps>;

    const removeExtra = (f: any): ToModel<Form, PickProps, ExtraProps, RemoveProps> => {
        const result = { ...defaults };
        for (const key in defaults) {
            const p = key as keyof typeof defaults;
            result[p] = f[p];
        }
        if (props.extra) {
            for (let i = 0; i < props.extra.length; i++) {
                const extraProp = props.extra[i] as keyof typeof defaults;
                delete result[extraProp];
            }
        }
        return result;
    };
    transformator.defaults = defaults;
    transformator.fromModel = (m: any) => {
        const result = { ...defaults };
        for (const key in defaults) {
            const p = key as keyof typeof defaults;
            const isExtra = props.extra && props.extra.includes(p as any);
            if (!isExtra) result[p] = m[p];
        }
        return result;
    };
    transformator.removeExtra = removeExtra;
    transformator.toNewEntity = (f) => replaceEntities(removeExtra(f));
    transformator.toUpdateEntity = (f, idVersion) => {
        const result: any = replaceEntities(removeExtra(f));
        (result as IdVersion).id = idVersion.id;
        (result as IdVersion).version = idVersion.version;
        return result;
    };
    return transformator;
}

type FormTransformations<RealForm, Transformations extends TransformationsDef> = {
    formTo: {
        [p in keyof Transformations["formTo"]]: Transformations["formTo"][p] extends (
            h: any,
            ...p: infer Params
        ) => infer R
            ? (...p: Params) => R
            : never;
    };
    toForm: {
        [p in keyof Transformations["toForm"]]: Transformations["toForm"][p] extends (
            h: any,
            ...p: infer Params
        ) => RealForm
            ? (...p: Params) => RealForm
            : never;
    };
} & (Transformations extends { toReduxUpdateModel: (t: any, ...p: infer P) => infer R }
    ? FormReduxTransformations<P, R>
    : IEmpty);

type FormReduxTransformations<Params extends any[], Result> = {
    toReduxUpdateModel: (...p: Params) => Result;
};

function createTransformations<
    Form,
    PickProps extends PropsType<Form>,
    ExtraProps extends PropsType<Form>,
    RemoveProps extends PropsType<Form>,
    Transformations extends TransformationsDef<Form, PickProps, ExtraProps, RemoveProps>,
    UpdateActionCreator extends undefined | UpdateActionCreatorFunc<ToModel<Form, PickProps, ExtraProps, RemoveProps>>,
>(
    options: FormOptions<Form, PickProps, ExtraProps, RemoveProps, Transformations, UpdateActionCreator>,
    t: Transformator<Form, PickProps, ExtraProps, RemoveProps>,
): FormTransformations<ToForm<Form, PickProps, ExtraProps, RemoveProps>, Transformations> {
    const formTo: any = {};
    const toForm: any = {};
    let reduxTransformations: any = {};
    if (options.transformations?.formTo) {
        for (const p in options.transformations.formTo) {
            const transFunc = options.transformations.formTo[p];
            formTo[p] = (...args: OmitFirstParameter<typeof transFunc>) => transFunc(t, ...args);
        }
    }
    if (options.transformations?.toForm) {
        for (const p in options.transformations.toForm) {
            const transFunc = options.transformations.toForm[p];
            toForm[p] = (...args: OmitFirstParameter<typeof transFunc>) => transFunc(t, ...args);
        }
    }
    if (options.transformations?.toReduxUpdateModel) {
        const transFunc = options.transformations.toReduxUpdateModel;
        const aux: FormReduxTransformations<any, any> = {
            toReduxUpdateModel: (...args: OmitFirstParameter<typeof transFunc>) => transFunc(t, ...args),
        };
        reduxTransformations = aux;
    }
    return { formTo: formTo, toForm: toForm, ...reduxTransformations };
}

type FormHooks<RealForm, Transformations extends TransformationsDef> = {
    useDefaults: <Params extends any[]>(
        init?: (defaults: RealForm, ...p: Params) => RealForm,
        ...p: Params
    ) => RealForm;
} & {
    /** Transformační funkce formuláře obalená hookem useMemo. */
    [p in string &
        keyof Transformations["toForm"] as `useToForm${Capitalize<p>}`]: Transformations["toForm"][p] extends (
        h: any,
        ...p: infer Params
    ) => RealForm
        ? (...p: Params) => RealForm
        : never;
};

function createHooks<RealForm, Transformations extends TransformationsDef>(
    trans: FormTransformations<RealForm, Transformations>,
    defaults: RealForm,
): FormHooks<RealForm, Transformations> {
    const result = {} as FormHooks<RealForm, Transformations>;
    result.useDefaults = (init, ...args: OmitFirstParameter<typeof init>) => {
        return useMemo(() => {
            if (!init) return { ...defaults };
            return init(defaults, ...args);
        }, args);
    };
    for (const p in trans.toForm) {
        const transFunc = trans.toForm[p];
        const hookName = `useToForm${p[0].toUpperCase()}${p.substring(1)}` as keyof typeof result;
        result[hookName] = ((...args: Parameters<typeof transFunc>) => {
            return useMemo(() => transFunc(...args), args);
        }) as any;
    }
    return result;
}

type UpdateActionCreatorFunc<Model = any> = (update: Partial<Model> & { id: number }) => AnyAction;

type FormApiUtils<
    RealForm,
    UpdateActionCreator extends undefined | UpdateActionCreatorFunc,
    Transformations extends TransformationsDef,
> = {
    handleUpdateEntityResult: UpdateActionCreator extends undefined
        ? undefined
        : (
              dispatch: StoreDispatch,
              apiResult: FormApiResult<UpdateApiResponse>,
              form: RealForm,
              ...p: GetReduxTransformParams<Transformations>
          ) => undefined | SubmissionErrors;
};
type GetReduxTransformParams<Transformations> = Transformations extends {
    toReduxUpdateModel: (t: any, f: any, ...p: infer P) => any;
}
    ? P
    : [];

function createApiUtils<
    RealForm,
    UpdateActionCreator extends undefined | UpdateActionCreatorFunc,
    Transformations extends TransformationsDef,
>(
    updateReduxModel: UpdateActionCreator,
    toReduxUpdateModel: (f: RealForm, ...p: GetReduxTransformParams<Transformations>) => any,
): FormApiUtils<RealForm, UpdateActionCreator, Transformations> {
    const apiUtils = {} as FormApiUtils<RealForm, UpdateActionCreator, Transformations>;

    if (updateReduxModel) {
        const handleUpdateEntityResult: FormApiUtils<
            RealForm,
            UpdateActionCreatorFunc,
            Transformations
        >["handleUpdateEntityResult"] = (dispatch, apiResult, form, ...args) => {
            if (apiResult.success && updateReduxModel) {
                const updatePartial = toReduxUpdateModel(form, ...args);
                dispatch(
                    updateReduxModel({ ...updatePartial, id: apiResult.data.id, version: apiResult.data.version }),
                );
            }
            return apiResult.submitErrors;
        };
        apiUtils.handleUpdateEntityResult = handleUpdateEntityResult as any;
    }

    return apiUtils;
}
