import { faArrowUp19, faCircleNodes } from "@fortawesome/free-solid-svg-icons";
import { prepareBreadcrumb } from "@magicware/dashboard/navigation/breadcrumbs";
import { CreatePatternMethod, CreateSimpleMethod } from "@magicware/dashboard/navigation/builder";
import { prepareRootTab, prepareTab } from "@magicware/dashboard/navigation/tabs";
import { useIntParam } from "@magicware/dashboard/navigation/useIntParam";
import ConnectorDetailTab from "../components/connectors/ConnectorDetailTab";
import ConnectorFilesTabPage from "../components/connectors/ConnectorFilesTabPage";
import ConnectorListTabPage from "../components/connectors/ConnectorListTabPage";
import ConnectorNewPage from "../components/connectors/ConnectorNewPage";
import ConnectorTabPage from "../components/connectors/ConnectorTabPage";
import ConnectorVersionDetailTab from "../components/connectorVersions/ConnectorVersionDetailTab";
import ConnectorVersionListTab from "../components/connectorVersions/ConnectorVersionListTab";
import ConnectorVersionNewTab from "../components/connectorVersions/ConnectorVersionNewTab";
import { filesIcon } from "./files";

export const useConnectorId = () => useIntParam("connectorId");
export const useConnectorVersionId = () => useIntParam("connectorVersionId");

export const connectorIcon = faCircleNodes;
export const connectorVersionIcon = faArrowUp19;

export const buildConnectors = (simple: CreateSimpleMethod, pattern: CreatePatternMethod) => {
    let tabIndex = 1;
    return simple({
        url: "/connectors",
        title: (t) => t("connectors-title"),
        component: ConnectorListTabPage,
        breadcrumb: prepareBreadcrumb((t) => t("connectors-breadcrumb")),

        new: simple({
            url: "/connectors/new",
            component: ConnectorNewPage,
            breadcrumb: prepareBreadcrumb((t) => t("new-connector-breadcrumb")),
        }),
        detail: pattern({
            pattern: "/connectors/:connectorId",
            component: ConnectorTabPage,
            url: (connectorId: number) => `/connectors/${connectorId}`,
            breadcrumb: prepareBreadcrumb((t) => t("connector-detail-breadcrumb")),
            tab: prepareRootTab((t) => t("connector-detail-tab"), connectorIcon, ConnectorDetailTab),

            versions: pattern({
                pattern: "/connectors/:connectorId/versions",
                component: ConnectorTabPage,
                url: (connectorId: number) => `/connectors/${connectorId}/versions`,
                breadcrumb: prepareBreadcrumb((t) => t("versions-breadcrumb")),
                tab: prepareTab(
                    tabIndex++,
                    (t) => t("connector-versions-tab"),
                    connectorVersionIcon,
                    ConnectorVersionListTab,
                ),

                new: pattern({
                    pattern: "/connectors/:connectorId/versions/new",
                    url: (connectorId: number) => `/connectors/${connectorId}/versions/new`,
                    component: ConnectorTabPage,
                    breadcrumb: prepareBreadcrumb((t) => t("new-version-breadcrumb")),
                    tab: prepareRootTab(
                        (t) => t("new-connector-version-title"),
                        connectorVersionIcon,
                        ConnectorVersionNewTab,
                    ),
                }),
                detail: pattern({
                    pattern: "/connectors/:connectorId/versions/:connectorVersionId",
                    component: ConnectorTabPage,
                    url: (connectorId: number, connectorVersionId: number) =>
                        `/connectors/${connectorId}/versions/${connectorVersionId}`,
                    breadcrumb: prepareBreadcrumb((t) => t("connector-version-detail-breadcrumb")),
                    tab: prepareRootTab(
                        (t) => t("connector-version-detail-title"),
                        connectorVersionIcon,
                        ConnectorVersionDetailTab,
                    ),
                }),
            }),

            files: pattern({
                pattern: "/connectors/:connectorId/files",
                component: ConnectorTabPage,
                url: (connectorId: number) => `/connectors/${connectorId}/files`,
                breadcrumb: prepareBreadcrumb((t) => t("files-breadcrumb")),
                tab: prepareTab(tabIndex++, (t) => t("files-tab"), filesIcon, ConnectorFilesTabPage),
            }),
        }),
    });
};
