import { KeyofType } from "@magicware/utils/types";

export function convertUseCaseFilters<T>(filter: T, useCaseFilterField: KeyofType<T, string[] | undefined>) {
    const result: any = { ...filter };
    const useCaseFilters = filter[useCaseFilterField] as unknown as string[] | undefined;
    if (useCaseFilters) {
        delete result.useCaseFilter;
        for (let i = 0; i < useCaseFilters.length; i++) {
            const type = useCaseFilters[i];
            result["Filter_" + type] = true;
        }
    }
    return result;
}

export interface UseCaseFilterOption<TOption> {
    type: TOption;
    label: string;
    group: string;
}
