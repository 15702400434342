import Panel from "@magicware/dashboard/Panel";
import { buildForm } from "@magicware/forms/builder";
import Form, { FormImplementationProps } from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import FormTogglePanel from "@magicware/forms/components/FormTogglePanel";
import FormRadioGroupField from "@magicware/forms/mui/FormRadioGroupField";
import FormTextField from "@magicware/forms/mui/FormTextField";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { updateDetail } from "../../redux/appInstanceConnections/appInstanceConnectionsReducer";
import {
    AppInstanceConnectionModelDetail,
    AppInstanceConnectionModelNew,
} from "../../services/api/appInstanceConnections";
import { FormAppInstancesAutocomplete } from "../appInstances/FormAppInstancesAutocomplete";
import { FormConnectorsAutocomplete } from "../connectors/FormConnectorsAutocomplete";

export const appInstanceConnectionForm = buildForm<
    AppInstanceConnectionModelDetail & { connectionType?: "AppInstance" | "Connector" }
>()(() => ({
    props: {
        remove: ["id", "version"],
        extra: ["connectionType"],
    },
    transformations: {
        toForm: {
            fromModel: (h, m: AppInstanceConnectionModelDetail) => ({
                ...h.fromModel(m),
                connectionType: m.targetConnector ? "Connector" : "AppInstance",
            }),
        },
        formTo: {
            new: (h, f, appInstanceId: number): AppInstanceConnectionModelNew => ({
                ...h.toNewEntity(f),
                appInstanceId,
            }),
            update: (h, f, m: AppInstanceConnectionModelDetail) => h.toUpdateEntity(f, m),
        },
    },
    updateReduxModel: updateDetail,
    schema: (f, t) => ({
        isPull: yup.boolean().default(false),
        connectionType: yup.string().default("Connector").required("Connection type is required"),
        targetConnector: yup
            .object()
            .requiredIf(f, "connectionType", (t) => t === "Connector", t("connector-is-required")),
        targetAppInstance: yup
            .object()
            .requiredIf(f, "connectionType", (t) => t === "AppInstance", t("app-is-required")),
        notes: yup.string(),
    }),
}));

const f = appInstanceConnectionForm.f;

export default function AppInstanceConnectionForm(props: FormImplementationProps<typeof f>) {
    const { t } = useTranslation();
    return (
        <Form validationSchema={appInstanceConnectionForm.validationSchema} {...props}>
            <Panel>
                <FormGrid>
                    <FormRadioGroupField
                        f={f}
                        field="connectionType"
                        options={[
                            ["Connector", t("connected-to-connector-label")],
                            ["AppInstance", t("connected-to-other-app-label")],
                        ]}
                    />
                    <FormTogglePanel f={f} field="connectionType" visibleOn={"AppInstance"}>
                        <FormAppInstancesAutocomplete
                            f={f}
                            field="targetAppInstance"
                            fullOption
                            label={t("appInstance-label")}
                            filter={{}}
                        />
                    </FormTogglePanel>
                    <FormTogglePanel f={f} field="connectionType" visibleOn={"Connector"}>
                        <FormConnectorsAutocomplete
                            f={f}
                            field="targetConnector"
                            fullOption
                            label={t("targetConnector-label")}
                            filter={{}}
                        />
                    </FormTogglePanel>
                    <FormRadioGroupField
                        f={f}
                        field="isPull"
                        row={false}
                        options={[
                            [false, t("is-push-label")],
                            [true, t("is-pull-label")],
                        ]}
                    />
                    <FormTextField f={f} field="notes" label={t("notes-label")} multiline />
                </FormGrid>
            </Panel>
        </Form>
    );
}
