import DataIcon from "@magicware/data-lists/DataIcon";
import DataListItem, { MainText, SecondaryText } from "@magicware/data-lists/DataListItem";
import { StyledComponentProps } from "@mui/material";
import { brandIcon } from "../../navigation/brands";
import { useNavigationClick } from "../../navigation/hooks";
import { parametersIcon } from "../../navigation/parameters";
import { useAppSelector } from "../../redux/hooks";

export interface DeviceListItemProps extends StyledComponentProps {
    deviceId: number;
}

export default function DeviceListItem({ deviceId, ...props }: DeviceListItemProps) {
    const obj = useAppSelector((state) => state.devices.list?.byId[deviceId]);
    const editClickFactory = useNavigationClick();
    if (!obj) return null;
    return (
        <DataListItem {...props} data-id={obj.id} onClick={editClickFactory((n) => n.devices.detail.url(obj.id))}>
            <MainText tw="mr-10">{obj.name}</MainText>
            <div tw="flex flex-wrap items-center gap-2">
                <SecondaryText>
                    <DataIcon icon={brandIcon} />
                    {obj.brand}
                </SecondaryText>
                {obj.parameterList.map((par) => (
                    <SecondaryText key={par.id} tw="whitespace-nowrap">
                        <DataIcon icon={parametersIcon} />
                        {par.name}
                    </SecondaryText>
                ))}
            </div>
        </DataListItem>
    );
}
