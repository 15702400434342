import SimplePage from "@magicware/dashboard/navigation/SimplePage";
import { SearchFormPanel } from "@magicware/dashboard/Panel";
import DataList from "@magicware/data-lists/DataList";
import { FormStateApiContextProvider } from "@magicware/forms/components/FormStateApiContext";
import { useTranslation } from "react-i18next";
import { experienceThunks, setPage } from "../../redux/experiences/experiencesReducer";
import { RootState } from "../../redux/types";
import EntityListItemMenuContext from "../entities/EntityListItemMenuContext";
import ExperienceListItem from "./ExperienceListItem";
import ExperienceListMenuContext from "./ExperienceListMenuContext";
import ExperienceSearchForm from "./ExperienceSearchForm";

export default function ExperienceListPage() {
    const { t } = useTranslation();
    return (
        <SimplePage title={t("experiences-title")}>
            <FormStateApiContextProvider>
                <SearchFormPanel>
                    <ExperienceSearchForm />
                </SearchFormPanel>
                <ExperienceListMenuContext>
                    <EntityListItemMenuContext
                        deleteSnackTitle={t("experience-deletion")}
                        deleteThunk={experienceThunks.delete}
                    >
                        <DataList
                            dataSelector={(state: RootState) => state.experiences.list}
                            setPageActionCreator={setPage}
                            emptyList={t("no-experiences-message")}
                        >
                            {(id) => <ExperienceListItem experienceId={id} />}
                        </DataList>
                    </EntityListItemMenuContext>
                </ExperienceListMenuContext>
            </FormStateApiContextProvider>
        </SimplePage>
    );
}
