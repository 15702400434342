import { createContext, useContext, useMemo } from "react";
import { useForm } from "react-final-form";

type FormStateApi = {
    /** Vrací aktuální model formuláře, pokud je validní.
     * Vrací undefiend, pokud aktuální formuláře validní není. */
    getValidState: <Form = Record<string, any>>() => Form | undefined;
    /** Vrací aktuální model formuláře bez kontroly, zdali je validní. */
    getState: <Form = Record<string, any>>() => Form | undefined;
    /** Provede odeslání formuláře.  Vrací promise, který se resolvne
     * do modelu formuláře, pokud vše prošlo hladce a validace prošly.
     * Nebo se resolvne do undefined, pokud došlo k validační chybě
     * a nebo odeslání selhalo na chybě.  */
    submit: <Form = Record<string, any>>() => Promise<Form | undefined>;
};
type FormContextValue = FormStateApi & { initDependency?: any };

/** @internal */
export const FormStateContext = createContext<FormContextValue>(undefined!);

const throwMissingFormError = () => {
    throw new Error("Form is missing");
};

export function FormStateApiContextProvider(props: { children: React.ReactNode }) {
    const value = useMemo<FormContextValue>(
        () => ({
            getValidState: throwMissingFormError,
            getState: throwMissingFormError,
            submit: throwMissingFormError,
            initDependency: null,
        }),
        [],
    );
    return <FormStateContext.Provider value={value}>{props.children}</FormStateContext.Provider>;
}

export function FormStateApiCapture() {
    const formStateApi = useContext(FormStateContext);
    const form = useForm();

    if (formStateApi && formStateApi.initDependency !== form) {
        formStateApi.getValidState = function getValidState<Form>() {
            const state = form.getState();
            if (!state.valid) return undefined;
            return state.values as Form;
        };
        formStateApi.getState = function getState<Form>() {
            const state = form.getState();
            return state.values as Form;
        };
        formStateApi.submit = async function submit() {
            await form.submit();
            return formStateApi.getValidState();
        };
    }
    return null;
}
