import { SearchFormPanel } from "@magicware/dashboard/Panel";
import DataList from "@magicware/data-lists/DataList";
import { FormStateApiContextProvider } from "@magicware/forms/components/FormStateApiContext";
import { useTranslation } from "react-i18next";
import { baseAppThunks, setPage } from "../../redux/baseApps/baseAppsReducer";
import { RootState } from "../../redux/types";
import EntityListItemMenuContext from "../entities/EntityListItemMenuContext";
import BaseAppListItem from "./BaseAppListItem";
import BaseAppListMenuContext from "./BaseAppListMenuContext";
import BaseAppSearchForm from "./BaseAppSearchForm";

export default function BaseAppListPage() {
    const { t } = useTranslation();
    return (
        <FormStateApiContextProvider>
            <SearchFormPanel>
                <BaseAppSearchForm />
            </SearchFormPanel>
            <BaseAppListMenuContext>
                <EntityListItemMenuContext deleteSnackTitle={t("baseApp-deletion")} deleteThunk={baseAppThunks.delete}>
                    <DataList
                        dataSelector={(state: RootState) => state.baseApps.list}
                        setPageActionCreator={setPage}
                        emptyList={t("no-baseApps-message")}
                    >
                        {(id) => <BaseAppListItem appId={id} />}
                    </DataList>
                </EntityListItemMenuContext>
            </BaseAppListMenuContext>
        </FormStateApiContextProvider>
    );
}
