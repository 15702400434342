import * as tk from "@reduxjs/toolkit";
import { Dispatch } from "redux";
import {
    AsyncThunkFulfilledActionCreator,
    AsyncThunkRejectedActionCreator,
} from "@reduxjs/toolkit/dist/createAsyncThunk";
import { IEmpty } from "@magicware/utils/types";
import { isValidationError } from "@magicware/fetch-api/errors";

export type AsyncThunkConfig = {
    state?: unknown;
    dispatch?: Dispatch;
    extra?: unknown;
    rejectValue?: unknown;
    serializedErrorType?: unknown;
    pendingMeta?: unknown;
    fulfilledMeta?: unknown;
    rejectedMeta?: unknown;
};

export type AsyncThunkRejectedPromiseResult = ReturnType<AsyncThunkRejectedActionCreator<any, IEmpty>>;
export function isAsyncThunkRejectedPromiseResult(result: any): result is AsyncThunkRejectedPromiseResult {
    const r = result as AsyncThunkRejectedPromiseResult;
    return typeof r.type === "string" && typeof r.meta === "object" && r.meta.requestStatus === "rejected";
}

export type AsyncThunkFulfilledPromiseResult = ReturnType<AsyncThunkFulfilledActionCreator<any, IEmpty>>;
export function isAsyncThunkFulfilledPromiseResult(result: any): result is AsyncThunkFulfilledPromiseResult {
    const r = result as AsyncThunkFulfilledPromiseResult;
    return typeof r.type === "string" && typeof r.meta === "object" && r.meta.requestStatus === "fulfilled";
}

export type SynchronizedThunkBehavior = "exclusive" | "persistent" | "exclusiveOverriding" | "persistentOverriding";

export type AsyncThunkStatus = "idle" | "pending" | "success" | "failed" | "aborted";
export type AsyncThunkState = {
    status: AsyncThunkStatus;
    errorData?: any;
    error?: tk.SerializedError;
};
export type AsyncThunkStateDic = Record<string, AsyncThunkState>;
export interface AsyncSliceState {
    async: Record<string, AsyncThunkStateDic | undefined>;
}
export const idleState: AsyncThunkState = { status: "idle" };
export const successState: AsyncThunkState = { status: "success" };
export const pendingState: AsyncThunkState = { status: "pending" };

export type OptionalArgParameters<ThunkArg, Params extends any[] = []> = void extends ThunkArg
    ? Params
    : [...params: Params, arg: ThunkArg];

export type AppAsyncThunk<Returned, ThunkArg = void> = tk.AsyncThunk<Returned, ThunkArg, IEmpty> &
    AppAsyncThunkExtension<ThunkArg>;

export type AppAsyncThunkWithBehavior<
    Behavior extends "volatile" | SynchronizedThunkBehavior,
    Returned,
    ThunkArg = void,
> = tk.AsyncThunk<Returned, ThunkArg, IEmpty> & AppAsyncThunkExtension<ThunkArg> & { behavior: Behavior };

export type AppAsyncThunkExtension<ThunkArg> =
    | {
          behavior: "volatile";
          cleanup: tk.ActionCreatorWithPreparedPayload<
              [requestId: string],
              undefined,
              string,
              never,
              { requestId: string; requestStatus: "cleanup" }
          >;
      }
    | {
          behavior: SynchronizedThunkBehavior;
          idle: tk.ActionCreatorWithPreparedPayload<
              [arg: ThunkArg],
              undefined,
              string,
              never,
              { arg: ThunkArg; requestId: string; requestStatus: "idle" }
          >;
          getState: (slice: AsyncSliceState, arg: ThunkArg) => AsyncThunkState;
      };

export function isAppThunk<ThunkArg>(
    thunk: tk.AsyncThunk<any, ThunkArg, any> | AppAsyncThunkExtension<ThunkArg>,
): thunk is AppAsyncThunkExtension<ThunkArg> {
    const behavior = (thunk as AppAsyncThunkExtension<ThunkArg>).behavior;
    return (
        behavior === "volatile" ||
        behavior === "exclusive" ||
        behavior === "persistent" ||
        behavior === "exclusiveOverriding" ||
        behavior === "persistentOverriding"
    );
}
export function createAppAsyncThunk<Returned, ThunkArg = void, ThunkConfig extends AsyncThunkConfig = IEmpty>(
    typePrefix: string,
    payloadCreator: tk.AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkConfig>,
): AppAsyncThunk<Returned, ThunkArg> & { behavior: "volatile" };
export function createAppAsyncThunk<
    Behavior extends SynchronizedThunkBehavior,
    Returned,
    ThunkArg = void,
    ThunkConfig extends AsyncThunkConfig = IEmpty,
>(
    typePrefix: string,
    behavior: Behavior,
    payloadCreator: tk.AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkConfig>,
    idGenerator: (arg: ThunkArg) => string,
): AppAsyncThunk<Returned, ThunkArg> & { behavior: Behavior };

export function createAppAsyncThunk<Behavior extends SynchronizedThunkBehavior, Returned, ThunkArg = void>(
    ...args:
        | [typePrefix: string, payloadCreator: tk.AsyncThunkPayloadCreator<Returned, ThunkArg>]
        | [
              typePrefix: string,
              behavior: Behavior,
              payloadCreator: tk.AsyncThunkPayloadCreator<Returned, ThunkArg>,
              idGenerator: (arg: ThunkArg) => string,
          ]
): AppAsyncThunk<Returned, ThunkArg> {
    if (args.length === 2) {
        const [typePrefix, payloadCreator] = args;
        const thunk = tk.createAsyncThunk(typePrefix, handleValidationErrors(payloadCreator));
        const cleanupActionCreator = tk.createAction(typePrefix + "/cleanup", (requestId: string) => ({
            payload: undefined,
            meta: {
                requestId: requestId,
                requestStatus: "cleanup" as const,
            },
        }));

        const ext: AppAsyncThunkExtension<ThunkArg> = {
            behavior: "volatile",
            cleanup: cleanupActionCreator,
        };
        return Object.assign(thunk, ext);
    } else {
        const [typePrefix, behavior, payloadCreator, idGenerator] = args;
        const thunk = tk.createAsyncThunk(typePrefix, handleValidationErrors(payloadCreator), {
            idGenerator: idGenerator,
        });

        const idleActionCreator = tk.createAction(typePrefix + "/idle", (arg: ThunkArg) => ({
            payload: undefined,
            meta: {
                arg,
                requestId: idGenerator(arg),
                requestStatus: "idle" as const,
            },
        }));

        const ext: AppAsyncThunkExtension<ThunkArg> = {
            behavior,
            idle: idleActionCreator,
            getState: (slice: AsyncSliceState, arg: ThunkArg): AsyncThunkState => {
                const state = slice.async[typePrefix];
                const requestId = idGenerator(arg);
                return (state && state[requestId]) ?? idleState;
            },
        };

        if (behavior === "exclusive" || behavior === "exclusiveOverriding") {
            const pendingRef: { current?: { requestId: string; abort: (reason?: string) => void } } = {};
            const actionCreator = (arg: ThunkArg) => {
                return (dispatch: tk.ThunkDispatch<any, any, any>) => {
                    const requestId = idGenerator(arg);
                    if (pendingRef.current) {
                        if (behavior === "exclusive" && pendingRef.current.requestId === requestId)
                            return pendingRef.current as typeof promise;
                        else pendingRef.current.abort();
                    }
                    const promise = dispatch(thunk(arg));
                    pendingRef.current = promise;
                    const resultPromise = promise.finally(() => {
                        if (pendingRef.current?.requestId === requestId) pendingRef.current = undefined;
                    });
                    (resultPromise as typeof promise).requestId = promise.requestId;
                    return resultPromise;
                };
            };
            return Object.assign(actionCreator, thunk, ext);
        } else {
            const pendingDic: Record<string, unknown> = {};

            const actionCreator = (arg: ThunkArg) => {
                return (dispatch: tk.ThunkDispatch<any, any, any>) => {
                    const requestId = idGenerator(arg);
                    const pendingPromise = pendingDic[requestId] as typeof promise | undefined;
                    if (pendingPromise) {
                        if (behavior === "persistent") return pendingPromise;
                        else pendingPromise.abort();
                    }
                    const promise = dispatch(thunk(arg));
                    pendingDic[requestId] = promise;
                    const resultPromise = promise.finally(() => {
                        delete pendingDic[requestId];
                    });
                    (resultPromise as typeof promise).requestId = promise.requestId;
                    return resultPromise;
                };
            };
            return Object.assign(actionCreator, thunk, ext);
        }
    }
}

/** Obalí volání tak, aby se validační chyby použily jako payload fail akce. */
function handleValidationErrors<Returned, ThunkArg = void>(
    payloadCreator: tk.AsyncThunkPayloadCreator<Returned, ThunkArg>,
): tk.AsyncThunkPayloadCreator<Returned, ThunkArg> {
    return async (arg, thunkAPI) => {
        try {
            return await payloadCreator(arg, thunkAPI);
        } catch (error) {
            if (isValidationError(error)) return thunkAPI.rejectWithValue(error) as any;
            throw error;
        }
    };
}

export function addAsyncThunkReducers<State extends AsyncSliceState, Thunk extends tk.AsyncThunk<any, any, IEmpty>>(
    builder: tk.ActionReducerMapBuilder<State>,
    thunk: Thunk,
    reducers?: {
        pending?: (state: State, action: ReturnType<Thunk["pending"]>) => void;
        fulfilled?: (state: State, action: ReturnType<Thunk["fulfilled"]>) => void;
        rejected?: (state: State, action: ReturnType<Thunk["rejected"]>) => void;
    },
) {
    if (isAppThunk(thunk)) {
        if (thunk.behavior === "volatile") {
            builder.addCase(thunk.cleanup, (state, action) => {
                const thunkState = state.async[thunk.typePrefix];
                if (thunkState) delete thunkState[action.meta.requestId];
            });
        } else {
            builder.addCase(thunk.idle, (state, action) => {
                const thunkState = state.async[thunk.typePrefix];
                if (thunkState) delete thunkState[action.meta.requestId];
            });
        }
    }
    builder.addCase(thunk.pending, (state, action) => {
        reduceAsyncState(thunk, state, action as any);
        reducers?.pending && reducers.pending(state as State, action as any);
    });
    builder.addCase(thunk.fulfilled, (state, action) => {
        reduceAsyncState(thunk, state, action as any);
        reducers?.fulfilled && reducers.fulfilled(state as State, action as any);
    });
    builder.addCase(thunk.rejected, (state, action) => {
        reduceAsyncState(thunk, state, action as any);
        reducers?.rejected && reducers.rejected(state as State, action as any);
    });
}

function reduceAsyncState<Thunk extends tk.AsyncThunk<any, any, IEmpty>>(
    thunk: Thunk,
    state: AsyncSliceState,
    action: ReturnType<Thunk["pending"]> | ReturnType<Thunk["rejected"]> | ReturnType<Thunk["fulfilled"]>,
) {
    const behavior: AppAsyncThunk<any>["behavior"] = isAppThunk(thunk) ? thunk.behavior : "volatile";

    let thunkState = state.async[thunk.typePrefix];
    if (!thunkState) state.async[thunk.typePrefix] = thunkState = {};

    if (action.meta.requestStatus === "fulfilled") {
        if (behavior === "exclusive" || behavior === "exclusiveOverriding") {
            state.async[thunk.typePrefix] = { [action.meta.requestId]: successState };
        } else if (behavior === "volatile") {
            delete thunkState[action.meta.requestId];
            if (Object.keys(thunkState).length === 0) delete state.async[thunk.typePrefix];
        } else {
            thunkState[action.meta.requestId] = successState;
        }
    } else if (action.meta.requestStatus === "pending") {
        if (behavior === "exclusive" || behavior === "exclusiveOverriding") {
            state.async[thunk.typePrefix] = { [action.meta.requestId]: pendingState };
        } else {
            thunkState[action.meta.requestId] = pendingState;
        }
    } else {
        const a = action as ReturnType<Thunk["rejected"]>;
        thunkState[action.meta.requestId] = {
            status: action.meta.aborted ? "aborted" : "failed",
            errorData: action.payload,
            error: a.error,
        };
    }
}

export function anchoreThunks<RootState, Thunks extends AsyncThunks>(
    anchor: (state: RootState) => AsyncSliceState,
    thunks: Thunks,
): AnchoredAsyncThunks<RootState, Thunks> {
    const result: AnchoredAsyncThunks<RootState, Thunks> = {} as any;
    for (const key in thunks) result[key] = anchoreThunk(anchor, thunks[key]);
    return result;
}

export function anchoreThunk<RootState, Thunk extends tk.AsyncThunk<any, any, any>>(
    anchor: (state: RootState) => AsyncSliceState,
    thunk: Thunk,
): AnchoredAsyncThunk<RootState, Thunk> {
    const result: AnchoredAsyncThunk<RootState, Thunk> = Object.assign((arg: any) => thunk(arg), thunk) as any;
    if (isAppThunk(thunk) && thunk.behavior !== "volatile") {
        const typeResult: AnchoredAsyncThunk<RootState, AppAsyncThunk<any, string> & { behavior: "exclusive" }> =
            result as any;
        typeResult.selectState = (state: RootState, arg: any) => thunk.getState(anchor(state), arg);
    }
    const notFoundState: AsyncThunkState =
        isAppThunk(thunk) && thunk.behavior === "volatile" ? { status: "success" } : idleState;
    result.selectRqState = (state: RootState, requestId: string) => {
        const thunkState = anchor(state).async[thunk.typePrefix];
        return (thunkState && thunkState[requestId]) ?? notFoundState;
    };
    return result;
}

export type AsyncThunks = { [key in string]: tk.AsyncThunk<any, any, any> };
export type AnchoredAsyncThunks<RootState, Thunks extends AsyncThunks> = {
    [key in string & keyof Thunks]: AnchoredAsyncThunk<RootState, Thunks[key]>;
};

export type AnchoredAsyncThunk<RootState, Thunk extends tk.AsyncThunk<any, any, any>> = Thunk extends AppAsyncThunk<
    infer Returned,
    infer ThunkArg
>
    ? AnchoredAppAsyncThunk<RootState, Returned, ThunkArg, Thunk>
    : Thunk & {
          selectRqState: (state: RootState, requestId: string) => AsyncThunkState;
      };

export type AnchoredAppAsyncThunk<
    RootState,
    Returned,
    ThunkArg,
    Thunk extends AppAsyncThunk<Returned, ThunkArg> = AppAsyncThunk<Returned, ThunkArg>,
> = Thunk & {
    selectRqState: (state: RootState, requestId: string) => AsyncThunkState;
} & (
        | {
              behavior: SynchronizedThunkBehavior;
              selectState: void extends ThunkArg
                  ? (state: RootState) => AsyncThunkState
                  : (state: RootState, arg: ThunkArg) => AsyncThunkState;
          }
        | { behavior: "volatile" }
    );
