import TitleTabPage from "@magicware/dashboard/navigation/TitleTabPage";
import { useBaseAppId } from "../../navigation/baseApps";
import { useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { baseAppThunks } from "../../redux/baseApps/baseAppsReducer";
import { RootState } from "../../redux/types";

export default function BaseAppTabPage() {
    const baseAppId = useBaseAppId();
    const api = useAutoDispatchAsyncThunk(baseAppThunks.load, baseAppId);
    return <TitleTabPage apiStatus={api.apiStatus} titleSelector={(state: RootState) => state.baseApps.detail?.name} />;
}
