import { faGear } from "@fortawesome/free-solid-svg-icons";
import { prepareBreadcrumb } from "@magicware/dashboard/navigation/breadcrumbs";
import { CreatePatternMethod, CreateSimpleMethod } from "@magicware/dashboard/navigation/builder";
import { useIntParam } from "@magicware/dashboard/navigation/useIntParam";
import ConfigurationTabPage from "../components/configuration/ConfigurationTabPage";

export const useAppId = () => useIntParam("appId");

export const configurationIcon = faGear;

export const buildConfiguration = (simple: CreateSimpleMethod, _pattern: CreatePatternMethod) => {
    return simple({
        url: "/configuration",
        component: ConfigurationTabPage,
        breadcrumb: prepareBreadcrumb((t) => t("configuration-breadcrumb")),
    });
};
