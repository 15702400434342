import { IconProp } from "@fortawesome/fontawesome-svg-core";
import MainContent from "@magicware/dashboard/MainContent";
import BreadcrumbNameText from "@magicware/dashboard/navigation/BreadcrumbNameText";
import PageHeader from "@magicware/dashboard/navigation/PageHeader";
import { TabLink } from "@magicware/dashboard/navigation/TabLink";
import { useCurrentPage } from "@magicware/dashboard/navigation/useCurrentPage";
import PageContainer from "@magicware/dashboard/PageContainer";
import { Tabs, TabsContainer } from "@magicware/dashboard/Tabs";
import { Route, Switch, useLocation } from "react-router-dom";
import { INavigatablePage } from "./utils";

export interface ManualTab {
    page: INavigatablePage;
    icon: IconProp;
    component: React.ComponentType<any>;
}

export interface ManualTabPageProps {
    tabs: ManualTab[];
}

export default function ManualTabPage({ tabs }: ManualTabPageProps) {
    const location = useLocation();
    const currentPage = useCurrentPage();
    return (
        <PageContainer>
            <PageHeader title={<BreadcrumbNameText name={currentPage.currentPage.title} />} />
            <TabsContainer tw="mt-6">
                <Tabs value={location.pathname} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                    {tabs.map((t) => (
                        <TabLink
                            key={t.page.pattern}
                            faIcon={t.icon}
                            label={<BreadcrumbNameText name={t.page.title} />}
                            value={t.page.url()}
                        />
                    ))}
                </Tabs>
            </TabsContainer>
            <MainContent>
                <Switch>
                    {tabs.map((t) => (
                        <Route key={t.page.pattern} path={t.page.pattern} exact component={t.component} />
                    ))}
                </Switch>
            </MainContent>
        </PageContainer>
    );
}
