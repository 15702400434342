import { createEntityAdapter, EntityState } from "@magicware/redux/entities";
import { addAsyncThunkReducers, anchoreThunks, AsyncSliceState, createAppAsyncThunk } from "@magicware/redux/thunks";
import { createSlice } from "@reduxjs/toolkit";
import {
    entityApi,
    AppInstanceModelDetail,
    AppInstanceFilter,
    AppInstanceModelList,
    appInstancesApi,
    AppInstanceTabInfo,
} from "../../services/api/appInstances";
import { RootState } from "../types";

export interface State
    extends EntityState<AppInstanceModelDetail, AppInstanceModelList, AppInstanceFilter>,
        AsyncSliceState {
    tabInfo?: AppInstanceTabInfo;
}

const initialState: State = { async: {} };

const adapter = createEntityAdapter("appInstances", entityApi, ["name", "platform"]);

const extraThunks = {
    loadTabInfo: createAppAsyncThunk(
        "loadTabInfo",
        "exclusive",
        (arg: { appInstanceId: number }, thunkApi) => appInstancesApi.loadTabInfo(arg.appInstanceId, thunkApi.signal),
        (arg) => arg.appInstanceId.toString(),
    ),
};

const slice = createSlice({
    name: "appInstances",
    initialState,
    reducers: adapter.getReducers(),
    extraReducers: (builder) => {
        adapter.addExtraReducers(builder);
        addAsyncThunkReducers(builder, extraThunks.loadTabInfo, {
            fulfilled: (state, action) => {
                state.tabInfo = action.payload;
            },
        });
        //addAsyncThunkReducers(builder, extraThunks.xxx);
    },
});

export const appInstanceThunks = anchoreThunks((state: RootState) => state.appInstances, {
    ...extraThunks,
    ...adapter.thunks,
});

export const actions = slice.actions;
export const { updateDetail, clearList, removeListItems, updateListItems, setPage } = slice.actions;
export default slice.reducer;
