import MainContent from "../MainContent";
import PageContainer from "../PageContainer";
import PageHeader from "./PageHeader";

export interface SimplePageProps {
    title?: React.ReactNode;
    backButton?: boolean;
    children?: React.ReactNode;
}

export default function SimplePage({ title, children, backButton = false, ...props }: SimplePageProps) {
    return (
        <PageContainer {...props}>
            <PageHeader title={title} backButton={backButton} />
            <MainContent>{children}</MainContent>
        </PageContainer>
    );
}
