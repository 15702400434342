import Panel from "@magicware/dashboard/Panel";
import { buildForm } from "@magicware/forms/builder";
import Form, { FormImplementationProps } from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import FormTextField from "@magicware/forms/mui/FormTextField";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { updateDetail } from "../../redux/baseApps/baseAppsReducer";
import { BaseAppModelDetail } from "../../services/api/baseApps";

export const baseAppForm = buildForm<BaseAppModelDetail>()(() => ({
    props: {
        remove: ["id", "version"],
        extra: [],
    },
    transformations: {
        toForm: { fromModel: (h, m: BaseAppModelDetail) => ({ ...h.fromModel(m) }) },
        formTo: {
            new: async (h, f) => ({
                ...h.toNewEntity(f),
            }),
            update: async (h, f, m: BaseAppModelDetail) => ({
                ...h.toUpdateEntity(f, m),
            }),
        },
        toReduxUpdateModel: (h, f): Partial<BaseAppModelDetail> => ({
            ...h.removeExtra(f),
        }),
    },
    updateReduxModel: updateDetail,
    schema: (_, t) => ({
        name: yup.string().required(t("name-is-required")),
        notes: yup.string(),
    }),
}));

const f = baseAppForm.f;

export type BaseAppFormOnSubmit = FormImplementationProps<typeof f>["onSubmit"];

export default function BaseAppForm(props: FormImplementationProps<typeof f>) {
    const { t } = useTranslation();
    return (
        <Form validationSchema={baseAppForm.validationSchema} {...props}>
            <Panel>
                <FormGrid>
                    <FormTextField f={f} field="name" label={t("name-label")} autoFocus={props.autoFocus} />
                    <FormTextField f={f} field="notes" label={t("notes-label")} multiline />
                </FormGrid>
            </Panel>
        </Form>
    );
}
