function handleStopPropagation(e: React.SyntheticEvent) {
    e.stopPropagation();
}

export default function ClickStopDiv(props: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div
            tw="relative"
            {...props}
            onTouchStart={handleStopPropagation}
            onTouchEnd={handleStopPropagation}
            onMouseDown={handleStopPropagation}
            onMouseUp={handleStopPropagation}
            onClick={handleStopPropagation}
        >
            <div tw="absolute -top-2 -bottom-2 -left-2 -right-2"></div>
            {[props.children]}
        </div>
    );
}
