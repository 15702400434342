import { faMobileScreen } from "@fortawesome/free-solid-svg-icons";
import DataIcon from "@magicware/data-lists/DataIcon";
import DataListItem, { MainText, SecondaryText } from "@magicware/data-lists/DataListItem";
import { StyledComponentProps } from "@mui/material";
import { useNavigationClick } from "../../navigation/hooks";
import { useAppSelector } from "../../redux/hooks";

export interface LibraryListItemProps extends StyledComponentProps {
    libraryId: number;
}

export default function LibraryListItem({ libraryId, ...props }: LibraryListItemProps) {
    const obj = useAppSelector((state) => state.libraries.list?.byId[libraryId]);
    const editClickFactory = useNavigationClick();
    if (!obj) return null;
    return (
        <DataListItem {...props} data-id={obj.id} onClick={editClickFactory((n) => n.libraries.detail.url(obj.id))}>
            <MainText tw="mr-10">{obj.name}</MainText>
            <div tw="flex items-center space-x-2">
                <SecondaryText>
                    <DataIcon icon={faMobileScreen} />
                    {obj.platform}
                </SecondaryText>
            </div>
        </DataListItem>
    );
}
