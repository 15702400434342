import { Autocomplete, AutocompleteProps, CircularProgress, TextField } from "@mui/material";
import { Fragment, useRef } from "react";
import { useField, useForm } from "react-final-form";
import { FormFieldProps } from "../builder";
import useDisabled from "../hooks/useDisabled";
import useFieldName from "../hooks/useFieldName";
import useControlId from "../hooks/useControlId";

export interface FormCustomAutocompleteProps<TOption, FormValues, TValue, Multiple extends boolean | undefined = false>
    extends FormCustomAutocompleteBaseProps<TOption, FormValues, TValue, Multiple> {
    getOptionValue: (opt: TOption) => TValue;
    getOption: (val: TValue) => TOption | undefined;
    nonSearchable?: boolean;
}

export interface FormCustomAutocompleteBaseProps<
    TOption,
    FormValues,
    TValue,
    Multiple extends boolean | undefined = false,
> extends FormFieldProps<FormValues, Multiple extends true ? TValue[] | undefined : TValue>,
        Partial<AutocompleteProps<TOption, Multiple, boolean, false>> {
    label: string;
    autoSelectOnBlur?: TValue extends string ? boolean : false;
    getSelectedAdorment?: (o: Multiple extends true ? TOption[] : TOption) => React.ReactNode | undefined;
}

export default function FormCustomAutocomplete<
    TOption,
    FormValues,
    TValue,
    Multiple extends boolean | undefined = false,
>({
    f: _,
    field,
    disabled,
    label,
    options,
    nonSearchable,
    getOptionValue,
    getOption,
    getSelectedAdorment,
    autoSelectOnBlur: autoSelect = false,
    ...props
}: FormCustomAutocompleteProps<TOption, FormValues, TValue, Multiple>) {
    field = useFieldName(field);
    disabled = useDisabled(disabled);
    const form = useForm();
    const formField = useField(field);
    const rndId = useControlId();
    const inputValRef = useRef("");
    const onChange: typeof props.onChange = (e, val, reason, details) => {
        if (props.multiple) {
            if (val instanceof Array) {
                form.change(field, val.map(getOptionValue));
                props.onChange && props.onChange(e, val, reason, details);
            }
        } else {
            val && form.change(field, getOptionValue(val as TOption));
            props.onChange && props.onChange(e, val, reason, details);
        }
    };
    const onBlur: typeof props.onBlur = autoSelect
        ? (e) => {
              const opt =
                  inputValRef.current &&
                  options &&
                  options.find((opt) => (getOptionValue(opt) as any) === inputValRef.current);
              opt && form.change(field, getOptionValue(opt));
              formField.input.onBlur(e);
          }
        : formField.input.onBlur;
    const isLoadingInProgress = options === undefined;

    let selectedOption: any = undefined;
    let isEmpty = true;

    if (props.multiple) {
        if (formField.input.value instanceof Array) {
            const arr = formField.input.value.map(getOption);
            selectedOption = arr;
            isEmpty = arr.length > 0;
        } else {
            selectedOption = [];
            isEmpty = true;
        }
    } else {
        selectedOption = getOption(formField.input.value);
        isEmpty = !selectedOption;
    }

    const startAdornment: React.ReactNode | undefined =
        getSelectedAdorment && selectedOption && getSelectedAdorment(selectedOption as any);

    return (
        <Autocomplete<TOption, Multiple, boolean, false>
            {...props}
            disabled={disabled}
            options={options ?? []}
            onFocus={formField.input.onFocus}
            onBlur={onBlur}
            value={(selectedOption as any) ?? null}
            isOptionEqualToValue={(opt, val) => getOptionValue(opt) === getOptionValue(val)}
            onInputChange={(_, v, r) => {
                if (r !== "reset") inputValRef.current = v;
            }}
            onChange={onChange}
            loading={isLoadingInProgress}
            id={rndId}
            renderInput={(params) => (
                <TextField
                    value={
                        isEmpty
                            ? ""
                            : "hotfix" /* bez tohoto hotfixu při prvním vykreslení animuje label u položek, které jsou vybrané  */
                    }
                    {...params}
                    placeholder={isEmpty ? props.placeholder : undefined}
                    label={label}
                    error={formField.meta.touched && Boolean(formField.meta.error || formField.meta.submitError)}
                    helperText={formField.meta.touched && (formField.meta.error || formField.meta.submitError)}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                    }}
                    InputProps={{
                        readOnly: nonSearchable,
                        ...params.InputProps,
                        startAdornment: params.InputProps.startAdornment ?? startAdornment,
                        endAdornment: (
                            <Fragment>
                                {isLoadingInProgress ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
