import {
    FormLabel,
    FormControl,
    RadioGroup,
    FormHelperText,
    FormControlProps,
    FormControlLabel,
    Radio,
    FormControlLabelProps,
    SxProps,
} from "@mui/material";
import { useField, useForm } from "react-final-form";
import { FormFieldProps } from "../builder";
import useDisabled from "../hooks/useDisabled";
import useFieldName from "../hooks/useFieldName";

export interface FormRadioGroupFieldProps<Value extends string | number | boolean | undefined = string> {
    label?: FormControlLabelProps["label"];
    helperText?: React.ReactNode;
    row?: boolean;
    options?: [Value, FormControlLabelProps["label"]][];
    radioSx?: SxProps;
}

export default function FormRadioGroupField<FormValues, Value extends string | number | boolean | undefined = string>({
    f: _,
    field,
    label,
    helperText,
    options,
    radioSx,
    children,
    disabled,
    row = true,
    ...props
}: FormFieldProps<FormValues, Value | undefined> & FormControlProps<"fieldset"> & FormRadioGroupFieldProps<Value>) {
    field = useFieldName(field);
    disabled = useDisabled(disabled);
    const formField = useField(field);
    const form = useForm();
    const isError = formField.meta.touched && Boolean(formField.meta.error || formField.meta.submitError);
    if (options) {
        children = options.map((o) => (
            <FormControlLabel
                key={typeof o[0] === "boolean" ? o[0].toString() : o[0] ?? "undefined"}
                value={o[0]}
                control={<Radio sx={radioSx} />}
                label={o[1]}
            />
        ));
    }
    return (
        <FormControl {...props} disabled={disabled} component="fieldset" error={isError}>
            {label && <FormLabel component="legend">{label}</FormLabel>}
            <RadioGroup row={row} {...formField.input} onChange={(_, value) => form.change(field, value)}>
                {children}
            </RadioGroup>
            {isError && <FormHelperText error>{formField.meta.error || formField.meta.submitError}</FormHelperText>}
            {!isError && helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}
