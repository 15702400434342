export function downloadFile(data: string | Blob, fileName: string) {
    const a = document.createElement("a");
    a.download = fileName;
    if (data instanceof Blob) {
        a.href = URL.createObjectURL(data);
        a.click();
        URL.revokeObjectURL(a.href);
    } else {
        a.href = data;
        a.click();
    }
}
