import DataListItem, { MainText } from "@magicware/data-lists/DataListItem";
import { StyledComponentProps } from "@mui/material";
import { useNavigationClick } from "../../navigation/hooks";
import { useAppSelector } from "../../redux/hooks";

export interface BaseAppListItemProps extends StyledComponentProps {
    appId: number;
}

export default function BaseAppListItem({ appId, ...props }: BaseAppListItemProps) {
    const obj = useAppSelector((state) => state.baseApps.list?.byId[appId]);
    const editClickFactory = useNavigationClick();
    if (!obj) return null;
    return (
        <DataListItem {...props} data-id={obj.id} onClick={editClickFactory((n) => n.baseApps.detail.url(obj.id))}>
            <MainText tw="mr-10">{obj.name}</MainText>
        </DataListItem>
    );
}
