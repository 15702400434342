import { parameterThunks } from "../../redux/parameters/parametersReducer";
import { useAppDispatch, useAppSelectorEnsure, useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { parametersApi } from "../../services/api/parameters";
import ParameterForm, { parameterForm } from "./ParameterForm";
import SimplePage from "@magicware/dashboard/navigation/SimplePage";
import AsyncContent from "@magicware/dashboard/AsyncContent";
import { useParameterId } from "../../navigation/parameters";
import { useTranslation } from "react-i18next";

const { useToFormFromModel } = parameterForm.hooks;

export default function ParameterDetailPage() {
    const { t } = useTranslation();
    const api = useAutoDispatchAsyncThunk(parameterThunks.load, useParameterId());
    return (
        <SimplePage title={t("parameter-detail-title")}>
            <AsyncContent {...api} component={ParameterDetailPageLoaded} />
        </SimplePage>
    );
}

function ParameterDetailPageLoaded() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const parameterDetail = useAppSelectorEnsure((state) => state.parameters.detail);
    const initialValues = useToFormFromModel(parameterDetail);
    const onSubmit = async (form: typeof parameterForm.f) => {
        const result = await parametersApi.forms.update(parameterForm.transform.formTo.update(form, parameterDetail));
        return parameterForm.apiUtils.handleUpdateEntityResult(dispatch, result, form);
    };
    return (
        <ParameterForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            savePanel={{ successMessage: t("parameter-has-been-saved") }}
        />
    );
}
