import TitleTabPage from "@magicware/dashboard/navigation/TitleTabPage";
import { useBaseAppVersionId } from "../../navigation/baseApps";
import { baseAppVersionThunks } from "../../redux/baseAppVersions/baseAppVersionsReducer";
import { useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { RootState } from "../../redux/types";

export default function BaseAppVersionTabPage() {
    const baseAppVersionId = useBaseAppVersionId();
    const api = useAutoDispatchAsyncThunk(baseAppVersionThunks.load, baseAppVersionId);
    return (
        <TitleTabPage
            apiStatus={api.apiStatus}
            titleSelector={(state: RootState) => state.baseAppVersions.detail?.name}
        />
    );
}
