import SimplePage from "@magicware/dashboard/navigation/SimplePage";
import useNewEntityOnSubmit from "@magicware/forms/hooks/useNewEntityOnSubmit";
import { useTranslation } from "react-i18next";
import { useNavigationClickReplace } from "../../navigation/hooks";
import { clearList } from "../../redux/devices/devicesReducer";
import { useAppDispatch } from "../../redux/hooks";
import { devicesApi } from "../../services/api/devices";
import DeviceForm, { deviceForm } from "./DeviceForm";

const { useDefaults } = deviceForm.hooks;

export default function DeviceNewPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const onSubmit = useNewEntityOnSubmit(
        clearList,
        devicesApi.forms.create,
        useNavigationClickReplace((n) => n.devices.url()),
        (f: typeof deviceForm.f) => deviceForm.transform.formTo.new(f, dispatch),
    );
    const initialValues = useDefaults();
    return (
        <SimplePage title={t("new-device-title")}>
            <DeviceForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                autoFocus
                savePanel={{ buttonContent: t("create-new-device"), disableOnPristine: true }}
            />
        </SimplePage>
    );
}
