import { libraryVersionThunks } from "../../redux/libraryVersions/libraryVersionsReducer";
import { useAppDispatch, useAppSelectorEnsure, useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { libraryVersionsApi } from "../../services/api/libraryVersions";
import LibraryVersionForm, { libraryVersionForm } from "./LibraryVersionForm";
import AsyncContent from "@magicware/dashboard/AsyncContent";
import { useTranslation } from "react-i18next";
import { useLibraryVersionId } from "../../navigation/libraries";

const { useToFormFromModel } = libraryVersionForm.hooks;

export default function LibraryVersionDetailTab() {
    const api = useAutoDispatchAsyncThunk(libraryVersionThunks.load, useLibraryVersionId());
    return <AsyncContent {...api} component={LibraryVersionDetailTabLoaded} />;
}

function LibraryVersionDetailTabLoaded() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const libraryVersionDetail = useAppSelectorEnsure((state) => state.libraryVersions.detail);
    const initialValues = useToFormFromModel(libraryVersionDetail);
    const onSubmit = async (form: typeof libraryVersionForm.f) => {
        const result = await libraryVersionsApi.forms.update(
            libraryVersionForm.transform.formTo.update(form, libraryVersionDetail),
        );
        return libraryVersionForm.apiUtils.handleUpdateEntityResult(dispatch, result, form);
    };
    return (
        <LibraryVersionForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            savePanel={{ successMessage: t("libraryVersion-has-been-saved") }}
        />
    );
}
