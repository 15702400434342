import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { SendResetPasswordEmail, userApi } from "../../services/api/user";
import { Typography } from "@mui/material";
import Form from "@magicware/forms/components/Form";
import FormTextField from "@magicware/forms/mui/FormTextField";
import FormSubmitResult from "@magicware/forms/components/FormSubmitResult";
import FormSubmitButton from "@magicware/forms/components/FormSubmitButton";
import ButtonLink from "@magicware/dashboard/navigation/ButtonLink";
import { navigation } from "../../navigation";
import LoginPageContainer from "../loginPage/LoginPageContainer";
import { buildForm } from "@magicware/forms/builder";

const { f, defaults, validationSchema } = buildForm<SendResetPasswordEmail["Request"]>()(() => ({
    props: {
        extra: [],
        remove: [],
    },
    schema: (_, t) => ({
        login: yup.string().required(t("email-address-is-required")),
    }),
}));

export default function PasswordResetStep1Page() {
    const { t } = useTranslation();
    const handleSubmit = (form: typeof f) =>
        userApi.forms.sendResetPasswordEmail.withServerErrors({ autoMap: "capitalize" })(form);
    return (
        <LoginPageContainer>
            <div>
                <Typography color="text.secondary">
                    {t("to-reset-your-password-first-enter-your-email-address-info-text")}
                </Typography>
                <div tw="mt-10">
                    <Form initialValues={defaults} validationSchema={validationSchema} onSubmit={handleSubmit}>
                        <div tw="flex flex-col space-y-5 mb-5">
                            <FormTextField f={f} field="login" label={t("email-label")} />
                            <FormSubmitResult successMessage={t("reset-password-email-sent-message")} />
                        </div>
                        <div tw="flex items-center justify-between mt-5">
                            <FormSubmitButton ignoreDirty>{t("reset-password-button")}</FormSubmitButton>
                            <ButtonLink tw="text-right" variant="text" to={navigation.home.url()}>
                                {t("back-to-login-button")}
                            </ButtonLink>
                        </div>
                    </Form>
                </div>
            </div>
        </LoginPageContainer>
    );
}
