import { getNewItemMenuAction } from "@magicware/data-lists/DataListBulkActionsContext";
import { useHistory } from "react-router-dom";
import { Navigation, navigation } from ".";

export function useNewItemMenuAction(name: string, getUrl: (n: typeof navigation) => string, isAllowed = true) {
    const history = useHistory();
    return getNewItemMenuAction(name, () => history.push(getUrl(navigation)), isAllowed);
}

export function useNavigationClick(): (getUrl: (n: Navigation) => string) => () => void;
export function useNavigationClick<TParameters extends any[]>(
    getUrl: (n: Navigation, ...parameters: TParameters) => string,
): (...parameters: TParameters) => void;
export function useNavigationClick<TParameters extends any[]>(
    getUrl?: (n: Navigation, ...parameters: TParameters) => string,
) {
    const history = useHistory();
    if (getUrl) return (...parameters: TParameters) => history.push(getUrl(navigation, ...parameters));
    else return (getUrl: (n: Navigation) => string) => () => history.push(getUrl(navigation));
}

export function useNavigationClickReplace(): (getUrl: (n: Navigation) => string) => () => void;
export function useNavigationClickReplace<TParameters extends any[]>(
    getUrl: (n: Navigation, ...parameters: TParameters) => string,
): (...parameters: TParameters) => void;
export function useNavigationClickReplace<TParameters extends any[]>(
    getUrl?: (n: Navigation, ...parameters: TParameters) => string,
) {
    const history = useHistory();
    if (getUrl) return (...parameters: TParameters) => history.replace(getUrl(navigation, ...parameters));
    else return (getUrl: (n: Navigation) => string) => () => history.replace(getUrl(navigation));
}
