import { SearchFormPanel } from "@magicware/dashboard/Panel";
import DataList from "@magicware/data-lists/DataList";
import { FormStateApiContextProvider } from "@magicware/forms/components/FormStateApiContext";
import { useTranslation } from "react-i18next";
import { useProjectSetupId } from "../../navigation/projectSetups";
import {
    connectorCertificationThunks,
    setPage,
} from "../../redux/connectorCertifications/connectorCertificationsReducer";
import { connectorCertificationListForProjectSetup } from "../../redux/connectorCertifications/selectors";
import { RootState } from "../../redux/types";
import EntityListItemMenuContext from "../entities/EntityListItemMenuContext";
import ConnectorCertificationListItem from "./ConnectorCertificationListItem";
import ConnectorCertificationListMenuContext from "./ConnectorCertificationListMenuContext";
import ConnectorCertificationSearchForm from "./ConnectorCertificationSearchForm";

export default function ConnectorCertificationListTab() {
    const { t } = useTranslation();
    const projectSetupId = useProjectSetupId();
    return (
        <FormStateApiContextProvider>
            <SearchFormPanel>
                <ConnectorCertificationSearchForm projectSetupId={projectSetupId} />
            </SearchFormPanel>
            <ConnectorCertificationListMenuContext>
                <EntityListItemMenuContext
                    deleteSnackTitle={t("connectorCertification-deletion")}
                    deleteThunk={connectorCertificationThunks.delete}
                >
                    <DataList
                        dataSelector={(state: RootState) =>
                            connectorCertificationListForProjectSetup(state, projectSetupId)
                        }
                        setPageActionCreator={setPage}
                        emptyList={t("no-certifications-message")}
                    >
                        {(id) => <ConnectorCertificationListItem connectorCertificationId={id} />}
                    </DataList>
                </EntityListItemMenuContext>
            </ConnectorCertificationListMenuContext>
        </FormStateApiContextProvider>
    );
}
