import { faWeightScale } from "@fortawesome/free-solid-svg-icons";
import { prepareBreadcrumb } from "@magicware/dashboard/navigation/breadcrumbs";
import { CreatePatternMethod, CreateSimpleMethod } from "@magicware/dashboard/navigation/builder";
import { prepareRootTab, prepareTab } from "@magicware/dashboard/navigation/tabs";
import { useIntParam } from "@magicware/dashboard/navigation/useIntParam";
import DeviceDetailTabPage from "../components/devices/DeviceDetailTabPage";
import DeviceFilesTabPage from "../components/devices/DeviceFilesTabPage";
import DeviceListPage from "../components/devices/DeviceListPage";
import DeviceNewPage from "../components/devices/DeviceNewPage";
import DeviceTabPage from "../components/devices/DeviceTabPage";
import { filesIcon } from "./files";

export const useDeviceId = () => useIntParam("deviceId");

export const deviceIcon = faWeightScale;

export const buildDevices = (simple: CreateSimpleMethod, pattern: CreatePatternMethod) => {
    let tabIndex = 1;
    return simple({
        url: "/devices",
        component: DeviceListPage,
        breadcrumb: prepareBreadcrumb((t) => t("devices-breadcrumb")),

        new: simple({
            url: "/devices/new",
            component: DeviceNewPage,
            breadcrumb: prepareBreadcrumb((t) => t("new-device-breadcrumb")),
        }),
        detail: pattern({
            pattern: "/devices/:deviceId",
            component: DeviceTabPage,
            url: (deviceId: number) => `/devices/${deviceId}`,
            tab: prepareRootTab((t) => t("device-detail-tab"), deviceIcon, DeviceDetailTabPage),
            breadcrumb: prepareBreadcrumb((t) => t("device-detail-breadcrumb")),

            files: pattern({
                pattern: "/devices/:deviceId/files",
                component: DeviceTabPage,
                url: (deviceId: number) => `/devices/${deviceId}/files`,
                breadcrumb: prepareBreadcrumb((t) => t("files-breadcrumb")),
                tab: prepareTab(tabIndex++, (t) => t("files-tab"), filesIcon, DeviceFilesTabPage),
            }),
        }),
    });
};
