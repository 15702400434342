import { createTupleOptions } from "@magicware/forms/mui/FormSelect";

export const platformOptions = createTupleOptions([
    ["iOS", "iOS"],
    ["Android", "Android"],
    ["Huawei", "Huawei"],
    ["Android Library", "Android Library"],
    ["iOS Library", "iOS Library"],
    ["Cloud Service", "Cloud Service"],
]);
