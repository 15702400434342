import AsyncContent from "@magicware/dashboard/AsyncContent";
import { useTranslation } from "react-i18next";
import { useConnectorCertificationId } from "../../navigation/projectSetups";
import { connectorCertificationThunks } from "../../redux/connectorCertifications/connectorCertificationsReducer";
import { useAppDispatch, useAppSelectorEnsure, useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { connectorCertificationsApi } from "../../services/api/connectorCertifications";
import ConnectorCertificationForm, { connectorCertificationForm } from "./ConnectorCertificationForm";

const { useToFormFromModel } = connectorCertificationForm.hooks;

export default function ConnectorCertificationDetailTab() {
    const api = useAutoDispatchAsyncThunk(connectorCertificationThunks.load, useConnectorCertificationId());
    return <AsyncContent {...api} component={ConnectorCertificationDetailTabLoaded} />;
}

function ConnectorCertificationDetailTabLoaded() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const connectorCertificationDetail = useAppSelectorEnsure((state) => state.connectorCertifications.detail);
    const initialValues = useToFormFromModel(connectorCertificationDetail);
    const onSubmit = async (form: typeof connectorCertificationForm.f) => {
        const result = await connectorCertificationsApi.forms.update(
            connectorCertificationForm.transform.formTo.update(form, connectorCertificationDetail),
        );
        return connectorCertificationForm.apiUtils.handleUpdateEntityResult(dispatch, result, form);
    };
    return (
        <ConnectorCertificationForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            savePanel={{ successMessage: t("connector-certification-has-been-saved") }}
        />
    );
}
