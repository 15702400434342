import { addAsyncThunkReducers, anchoreThunks, AsyncSliceState, createAppAsyncThunk } from "@magicware/redux/thunks";
import { ParameterAt } from "@magicware/utils/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userApi } from "../../services/api/user";
import { CurrentUser, INITIALLY_LOGGED_USER } from "../../services/globals";
import { RootState } from "../types";

export interface State extends AsyncSliceState {
    loggedUser?: CurrentUser;
}

const initialState: State = {
    async: {},
    loggedUser: INITIALLY_LOGGED_USER,
};

const extraThunks = {
    logout: createAppAsyncThunk("ui/logout", async (arg: { reloadUrl: string }, thunkApi) => {
        await userApi.logout(thunkApi.signal);
        window.location.href = arg.reloadUrl;
    }),

    login: createAppAsyncThunk("ui/login", async (arg: ParameterAt<typeof userApi.login, 0>, thunkApi) => {
        const response = await userApi.login(arg, thunkApi.signal);
        if (response.result === "ok") {
            const currentUser = await userApi.loadCurrentUser(thunkApi.signal);
            return { ...response, user: currentUser };
        } else if (response.result === "2fa") {
            return response;
        }
    }),
};

const slice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        setCurrentUser(state, action: PayloadAction<CurrentUser>) {
            state.loggedUser = action.payload;
        },
    },
    extraReducers: (builder) => {
        addAsyncThunkReducers(builder, extraThunks.logout);
        addAsyncThunkReducers(builder, extraThunks.login, {
            fulfilled: (state, action) => {
                if (action.payload?.result === "ok") state.loggedUser = action.payload.user;
            },
        });
    },
});

export const uiThunks = anchoreThunks((state: RootState) => state.ui, extraThunks);

export default slice.reducer;
