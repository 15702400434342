import { createEntityApi } from "@magicware/fetch-api/entities";
import { IdVersion } from "@magicware/fetch-api/types";
import { API_BASE_URL } from "../globals";
import { ApiServerVersionSelectOption } from "./apiServerVersions";
import { ProjectSetupSelectOption } from "./projectSetups";

export interface ApiServerInstanceModelBase {
    name: string;
    url?: string;
    environment?: string;
    notes?: string;
}

export interface ApiServerInstanceEntity {
    apiServerVersionId: number;
    projectSetupIds: number[];
}

export interface ApiServerInstanceModelNew extends ApiServerInstanceEntity {
    apiServerId: number;
}
export interface ApiServerInstanceModelUpdate extends ApiServerInstanceEntity, IdVersion {}
export interface ApiServerInstanceModelDetail extends ApiServerInstanceModelBase, IdVersion {
    apiServerVersion: ApiServerVersionSelectOption;
    projectSetupList: ProjectSetupSelectOption[];
}

export interface ApiServerInstanceModelList {
    id: number;
    name: string;
}
export interface ApiServerInstanceFilter {
    name?: string;
    url?: string;
}

export const entityApi = createEntityApi<
    ApiServerInstanceModelNew,
    ApiServerInstanceModelUpdate,
    ApiServerInstanceModelDetail,
    ApiServerInstanceModelList,
    ApiServerInstanceFilter
>("apiServerInstance", `${API_BASE_URL}registry/apiServerInstances`);

export const apiServerInstancesApi = entityApi;
