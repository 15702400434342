export function isDefined<T>(obj: T): obj is Exclude<T, undefined | null> {
    return obj !== undefined && obj !== null;
}
export function isUndefinedOrNull<T>(obj: T | undefined | null): obj is undefined | null {
    return obj === undefined || obj === null;
}
export function isString<T>(obj: T | string): obj is string {
    return typeof obj === "string";
}
export function isNumber<T>(obj: T | number): obj is number {
    return typeof obj === "number";
}
export function isBigint<T>(obj: T | bigint): obj is bigint {
    return typeof obj === "bigint";
}
export function isBoolean<T>(obj: T | boolean): obj is boolean {
    return typeof obj === "boolean";
}
export function isSymbol<T>(obj: T | symbol): obj is symbol {
    return typeof obj === "symbol";
}
export function isUndefined<T>(obj: T | undefined): obj is undefined {
    return typeof obj === "undefined";
}
export function isObject<T>(obj: T | object): obj is object {
    return typeof obj === "object";
}
export function isFunction<T>(obj: T | ((...p: any[]) => any)): obj is (...p: any[]) => any {
    return typeof obj === "function";
}
