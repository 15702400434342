import { useField } from "react-final-form";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { FormFieldProps } from "../builder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import useDisabled from "../hooks/useDisabled";
import useControlId from "../hooks/useControlId";
import { Fragment } from "react";
import useFieldName from "../hooks/useFieldName";

export interface FormTextFieldProps<FormValues> extends FormFieldProps<FormValues, string | undefined> {
    searchIcon?: boolean;
}

export default function FormTextField<FormValues>({
    f: _,
    field,
    searchIcon,
    InputProps,
    disabled,
    type = "text",
    name,
    ...props
}: FormTextFieldProps<FormValues> & TextFieldProps) {
    field = useFieldName(field);
    disabled = useDisabled(disabled);
    const formField = useField(field, { type: type });
    const rndId = useControlId();
    if (searchIcon)
        InputProps = { ...InputProps, startAdornment: <FontAwesomeIcon icon={faSearch} tw="text-gray-400 mr-1" /> };

    const isError = formField.meta.touched && Boolean(formField.meta.error || formField.meta.submitError);
    return (
        <TextField
            autoComplete="new-password"
            {...props}
            {...formField.input}
            id={name ?? rndId}
            name={name ?? rndId}
            disabled={disabled}
            type={type}
            error={isError}
            helperText={
                isError ? <Fragment>{formField.meta.error || formField.meta.submitError}</Fragment> : props.helperText
            }
            InputProps={InputProps}
        />
    );
}
