import { experienceThunks } from "../../redux/experiences/experiencesReducer";
import { useAppDispatch, useAppSelectorEnsure, useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { experiencesApi } from "../../services/api/experiences";
import ExperienceForm, { experienceForm } from "./ExperienceForm";
import SimplePage from "@magicware/dashboard/navigation/SimplePage";
import AsyncContent from "@magicware/dashboard/AsyncContent";
import { useExperienceId } from "../../navigation/experiences";
import { useTranslation } from "react-i18next";

const { useToFormFromModel } = experienceForm.hooks;

export default function ExperienceDetailPage() {
    const { t } = useTranslation();
    const api = useAutoDispatchAsyncThunk(experienceThunks.load, useExperienceId());
    return (
        <SimplePage title={t("experience-detail-title")}>
            <AsyncContent {...api} component={ExperienceDetailPageLoaded} />
        </SimplePage>
    );
}

function ExperienceDetailPageLoaded() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const experienceDetail = useAppSelectorEnsure((state) => state.experiences.detail);
    const initialValues = useToFormFromModel(experienceDetail);
    const onSubmit = async (form: typeof experienceForm.f) => {
        const result = await experiencesApi.forms.update(
            experienceForm.transform.formTo.update(form, experienceDetail),
        );
        return experienceForm.apiUtils.handleUpdateEntityResult(dispatch, result, form);
    };
    return (
        <ExperienceForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            savePanel={{ successMessage: t("experience-has-been-saved") }}
        />
    );
}
