import { Fragment } from "react";
import LoginForm from "./LoginForm";
import LoginPageContainer from "./LoginPageContainer";

export default function LoginFormPage() {
    return (
        <Fragment>
            <LoginPageContainer>
                <div tw="mt-10">
                    <LoginForm />
                </div>
            </LoginPageContainer>
        </Fragment>
    );
}
