import { createEntityApi } from "@magicware/fetch-api/entities";
import { IdVersion } from "@magicware/fetch-api/types";
import { API_BASE_URL } from "../globals";
import { ParameterSelectOption } from "./parameters";

export interface ApiServerVersionSelectOption {
    id: number;
    name: string;
}

export interface ApiServerVersionModelBase {
    name: string;
    notes?: string;
}

export interface ApiServerVersionEntity {
    parameterIds: number[];
}

export interface ApiServerVersionModelNew {
    name: string;
    apiServerId: number;
}
export interface ApiServerVersionModelUpdate extends ApiServerVersionEntity, IdVersion {}
export interface ApiServerVersionModelDetail extends ApiServerVersionModelBase, IdVersion {
    parameterList: ParameterSelectOption[];
}

export interface ApiServerVersionModelList extends ApiServerVersionSelectOption {
    apiServerId: number;
}
export interface ApiServerVersionFilter {
    name?: string;
    apiServerId?: number;
}

export const entityApi = createEntityApi<
    ApiServerVersionModelNew,
    ApiServerVersionModelUpdate,
    ApiServerVersionModelDetail,
    ApiServerVersionModelList,
    ApiServerVersionFilter
>("apiServerVersion", `${API_BASE_URL}registry/apiServerVersions`);

export const apiServerVersionsApi = entityApi;
