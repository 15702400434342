import { getData } from "@magicware/fetch-api/fetch";
import { API_BASE_URL } from "../globals";

export interface ObjectSummary {
    totalCount: number;
}
export interface RegistrySummary {
    brands: ObjectSummary;
    projectSetups: ObjectSummary;
    prameters: ObjectSummary;
    connectors: ObjectSummary;
    devices: ObjectSummary;
}

const baseUrl = `${API_BASE_URL}registry/stats`;

export const statsApi = {
    loadRegistrySummary: (signal?: AbortSignal) =>
        getData<RegistrySummary>(`${baseUrl}/registrySummary`, undefined, { signal }),
};
