import { createEntityApi } from "@magicware/fetch-api/entities";
import { IdVersion } from "@magicware/fetch-api/types";
import { API_BASE_URL } from "../globals";
import { DeviceSelectOption } from "./devices";
import { ParameterSelectOption } from "./parameters";

export interface ConnectorVersionSelectOption {
    id: number;
    name: string;
    connector: {
        id: number;
        name: string;
    };
}

export interface ConnectorVersionModelBase {
    name: string;
    notes?: string;
}

export interface ConnectorVersionEntity {
    parameterIds: number[];
    deviceIds: number[];
}

export interface ConnectorVersionModelNew extends ConnectorVersionEntity {
    connectorId: number;
}
export interface ConnectorVersionModelUpdate extends ConnectorVersionEntity, IdVersion {}
export interface ConnectorVersionModelDetail extends ConnectorVersionModelBase, IdVersion {
    parameterList: ParameterSelectOption[];
    deviceList: DeviceSelectOption[];
}

export interface ConnectorVersionModelList extends ConnectorVersionSelectOption {}
export interface ConnectorVersionFilter {
    name?: string;
    connectorId?: number;
}

export const entityApi = createEntityApi<
    ConnectorVersionModelNew,
    ConnectorVersionModelUpdate,
    ConnectorVersionModelDetail,
    ConnectorVersionModelList,
    ConnectorVersionFilter
>("connectorVersion", `${API_BASE_URL}registry/connectorVersions`);

export const connectorVersionsApi = entityApi;
