import { AnchoredAppAsyncThunk } from "@magicware/redux/thunks";
import { StoreDispatch } from "@magicware/redux/types";
import { SnackbarContent, useSnackbar } from "notistack";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { AsyncActionSnackContent, AsyncActionSnackContentMessages } from "../AsyncActionSnackContent";

export function useManualDispatchAsyncThunkWithSnackbar<Returned, ThunkArg>(
    thunk: AnchoredAppAsyncThunk<any, Returned, ThunkArg>,
) {
    const dispatch = useDispatch<StoreDispatch>();
    const snack = useSnackbar();
    const dispatchInvoke = useMemo(
        () => (snackContent: string | AsyncActionSnackContentMessages, arg: ThunkArg) => {
            const { requestId } = dispatch(thunk(arg));
            snack.enqueueSnackbar(undefined, {
                persist: true,
                content: (key) => (
                    <SnackbarContent>
                        <AsyncActionSnackContent
                            snackKey={key}
                            content={snackContent}
                            selectState={(s) => thunk.selectRqState(s, requestId).status}
                            cleanupAction={
                                thunk.behavior === "volatile" ? () => dispatch(thunk.cleanup(requestId)) : undefined
                            }
                        />
                    </SnackbarContent>
                ),
            });
        },
        [],
    );
    return dispatchInvoke;
}
