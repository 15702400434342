import { faRulerCombined } from "@fortawesome/free-solid-svg-icons";
import { prepareBreadcrumb } from "@magicware/dashboard/navigation/breadcrumbs";
import { CreatePatternMethod, CreateSimpleMethod } from "@magicware/dashboard/navigation/builder";
import { useIntParam } from "@magicware/dashboard/navigation/useIntParam";
import ConnectorListTabPage from "../components/connectors/ConnectorListTabPage";
import ParametersScopeDetailPage from "../components/parametersScopes/ParametersScopeDetailPage";
import ParametersScopeNewPage from "../components/parametersScopes/ParametersScopeNewPage";

export const useParametersScopeId = () => useIntParam("parametersScopeId");

export const parametersScopeIcon = faRulerCombined;

export const buildParametersScopes = (simple: CreateSimpleMethod, pattern: CreatePatternMethod) => {
    return simple({
        url: "/parametersscopes",
        title: (t) => t("parameter-scopes-title"),
        component: ConnectorListTabPage,
        breadcrumb: prepareBreadcrumb((t) => t("parameters-scopes-breadcrumb")),

        new: simple({
            url: "/parametersscopes/new",
            component: ParametersScopeNewPage,
            breadcrumb: prepareBreadcrumb((t) => t("new-parameters-scope-breadcrumb")),
        }),
        detail: pattern({
            pattern: "/parametersscopes/:parametersScopeId",
            component: ParametersScopeDetailPage,
            url: (parametersScopeId: number) => `/parametersscopes/${parametersScopeId}`,
            breadcrumb: prepareBreadcrumb((t) => t("parameters-scope-detail-breadcrumb")),
        }),
    });
};
