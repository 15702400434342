import { createEntityAdapter, EntityState } from "@magicware/redux/entities";
import { anchoreThunks, AsyncSliceState } from "@magicware/redux/thunks";
import { createSlice } from "@reduxjs/toolkit";
import {
    entityApi,
    BaseAppModelDetail,
    BaseAppFilter,
    BaseAppModelList,
    baseAppsApi,
} from "../../services/api/baseApps";
import addVersionUpdateReducer from "../files/addVersionUpdateReducer";
import { RootState } from "../types";

export interface State extends EntityState<BaseAppModelDetail, BaseAppModelList, BaseAppFilter>, AsyncSliceState {}

const initialState: State = { async: {} };

const adapter = createEntityAdapter("baseApp", entityApi, ["name"]);

const extraThunks = {};

const slice = createSlice({
    name: "baseApps",
    initialState,
    reducers: adapter.getReducers(),
    extraReducers: (builder) => {
        adapter.addExtraReducers(builder);
        //addAsyncThunkReducers(builder, extraThunks.xxx);
        addVersionUpdateReducer(builder, baseAppsApi.files);
    },
});

export const baseAppThunks = anchoreThunks((state: RootState) => state.baseApps, {
    ...extraThunks,
    ...adapter.thunks,
});

export const actions = slice.actions;
export const { updateDetail, clearList, removeListItems, updateListItems, setPage } = slice.actions;
export default slice.reducer;
