import { faServer } from "@fortawesome/free-solid-svg-icons";
import { prepareBreadcrumb } from "@magicware/dashboard/navigation/breadcrumbs";
import { CreatePatternMethod, CreateSimpleMethod } from "@magicware/dashboard/navigation/builder";
import { prepareRootTab } from "@magicware/dashboard/navigation/tabs";
import { createUseIntParam } from "@magicware/dashboard/navigation/useIntParam";
import ApiServerVersionDetailTabPage from "../components/apiServerVersions/ApiServerVersionDetailTabPage";
import ApiServerVersionNewPage from "../components/apiServerVersions/ApiServerVersionNewPage";
import ApiServerVersionTabPage from "../components/apiServerVersions/ApiServerVersionTabPage";
import ConfigurationTabPage from "../components/configuration/ConfigurationTabPage";

export const useApiServerVersionId = createUseIntParam("apiServerVersionId");

export const apiServerVersionIcon = faServer;

export const buildApiServerVersions = (simple: CreateSimpleMethod, pattern: CreatePatternMethod) => {
    //let tabIndex = 1;
    return simple({
        url: "/apiServerVersions",
        title: (t) => t("apiServerVersions-title"),
        component: ConfigurationTabPage,
        breadcrumb: prepareBreadcrumb((t) => t("apiServerVersions-breadcrumb")),

        new: simple({
            url: "/apiServerVersions/new",
            component: ApiServerVersionNewPage,
            breadcrumb: prepareBreadcrumb((t) => t("new-apiServerVersion-breadcrumb")),
        }),
        detail: pattern({
            pattern: "/apiServerVersions/:apiServerVersionId",
            component: ApiServerVersionTabPage,
            url: (apiServerVersionId: number) => `/apiServerVersions/${apiServerVersionId}`,
            tab: prepareRootTab(
                (t) => t("apiServerVersion-detail-tab"),
                apiServerVersionIcon,
                ApiServerVersionDetailTabPage,
            ),
            breadcrumb: prepareBreadcrumb((t) => t("apiServerVersion-detail-breadcrumb")),
        }),
    });
};
