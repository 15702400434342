import { Route, Switch } from "react-router";
import { RouteProps } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import NotFoundAlertPage from "./NotFoundAlertPage";
import PermissionDeniedAlertPage from "./PermissionDeniedAlertPage";
import UnauthenticatedPage from "./UnauthenticatedPage";
import OldVersionAlert from "./OldVersionAlert";
import { Fragment } from "react";
import { CurrentUser } from "../../services/globals";
import { restrictedPages, unrestrictedPages } from "../../navigation";
import LoginFormPage from "../loginPage/LoginFormPage";
import MainMenu from "../mainMenu/MainMenu";
import UserHasNoPersonPage from "./UserHasNoPersonPage";

export default function App() {
    const loggedUser = useAppSelector((state) => state.ui.loggedUser);
    return (
        <Fragment>
            <OldVersionAlert />
            <Switch>
                {unrestrictedPages.map((p) => (
                    <Route key={p.pattern} path={p.pattern} exact component={p.component} />
                ))}
                <SwitchWithMainMenu user={loggedUser}>
                    {restrictedPages.map((p) => (
                        <RestrictedRoute
                            key={p.pattern}
                            loggedUser={loggedUser}
                            path={p.pattern}
                            exact
                            component={p.component}
                        />
                    ))}
                </SwitchWithMainMenu>
            </Switch>
        </Fragment>
    );
}

function RestrictedRoute({
    loggedUser,
    condition,
    ...props
}: RouteProps & { loggedUser?: CurrentUser; condition?: (usr: CurrentUser) => boolean }) {
    if (!loggedUser) return <Route {...props} component={LoginFormPage} />;
    if (condition && !condition(loggedUser)) return <Route {...props} component={PermissionDeniedAlertPage} />;
    return <Route {...props} />;
}

function SwitchWithMainMenu(props: { user?: CurrentUser; children?: React.ReactNode }) {
    const isUnauthenticated = useAppSelector((state) => state.core.isUnauthenticated);

    if (!!props.user && !props.user.person) return <UserHasNoPersonPage />;

    if (isUnauthenticated) return <UnauthenticatedPage />;

    if (!!props.user) {
        return (
            <div tw="flex">
                <div tw="flex relative mx-auto">
                    <MainMenu />
                    <main tw="mt-14 lg:mt-0">
                        <Switch>
                            {props.children}
                            <Route component={NotFoundAlertPage} />
                        </Switch>
                    </main>
                </div>
            </div>
        );
    }

    return (
        <Switch>
            {props.children}
            <Route component={NotFoundAlertPage} />
        </Switch>
    );
}
