import { faCaretRight, faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumbs, ButtonBase } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { HeaderPanel } from "../Panel";
import BreadcrumbNameText from "./BreadcrumbNameText";
import { useCurrentPage } from "./useCurrentPage";
import { Breadcrumb } from "./utils";

export interface PageHeaderProps {
    title?: React.ReactNode;
    backButton?: boolean;
}

export default function PageHeader({ title, backButton = false }: PageHeaderProps) {
    const location = useLocation();
    const { currentPage, currentPageParams } = useCurrentPage();
    const titleNode = title && <h1 tw="[font-size:x-large] text-primary-500 font-extrabold">{title}</h1>;
    const crumbs =
        currentPage &&
        currentPage.breadcrumb &&
        currentPageParams &&
        createBreadcrumbs([], currentPage.breadcrumb(...currentPageParams), location.pathname);
    if (crumbs && crumbs.length > 1) {
        return (
            <HeaderPanel tw="mx-3 md:mx-10">
                <BackButton />
                {titleNode}
                <div tw="mt-3">
                    <div tw="flex">
                        <Breadcrumbs separator={<FontAwesomeIcon icon={faCaretRight} size="xs" />}>
                            {crumbs}
                        </Breadcrumbs>
                    </div>
                </div>
            </HeaderPanel>
        );
    }

    if (!titleNode && backButton)
        return (
            <HeaderPanel tw="mx-3 md:mx-10 -mb-8">
                <BackButton />
            </HeaderPanel>
        );

    return (
        <HeaderPanel tw="mx-3 md:mx-10">
            {backButton && <BackButton />}
            {titleNode}
        </HeaderPanel>
    );
}

function BackButton() {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <ButtonBase tw="text-xs text-primary-500 mb-5" onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faCircleChevronLeft} tw="mr-1" /> {t("go-back-button")}
        </ButtonBase>
    );
}

function createBreadcrumbs(crumbs: React.ReactNode[], def: Breadcrumb, currentUrl: string) {
    if (def.next) createBreadcrumbs(crumbs, def.next, currentUrl);
    if (def.url === currentUrl) {
        crumbs.push(
            <span key={def.url} tw="text-xs">
                <BreadcrumbNameText name={def.name} />
            </span>,
        );
    } else {
        crumbs.push(
            <NavLink tw="hover:underline text-xs" key={def.url} to={def.url}>
                <BreadcrumbNameText name={def.name} />
            </NavLink>,
        );
    }

    return crumbs;
}
