import SimplePage from "@magicware/dashboard/navigation/SimplePage";
import useNewEntityOnSubmit from "@magicware/forms/hooks/useNewEntityOnSubmit";
import { useTranslation } from "react-i18next";
import { useNavigationClickReplace } from "../../navigation/hooks";
import { clearList } from "../../redux/baseApps/baseAppsReducer";
import { baseAppsApi } from "../../services/api/baseApps";
import BaseAppForm, { baseAppForm } from "./BaseAppForm";

const { useDefaults } = baseAppForm.hooks;

export default function BaseAppNewPage() {
    const { t } = useTranslation();
    const onSubmit = useNewEntityOnSubmit(
        clearList,
        baseAppsApi.forms.create,
        useNavigationClickReplace((n, newId: number) => n.baseApps.detail.url(newId)),
        (f: typeof baseAppForm.f) => baseAppForm.transform.formTo.new(f),
    );
    const initialValues = useDefaults();
    return (
        <SimplePage title={t("new-baseApp-title")}>
            <BaseAppForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                autoFocus
                savePanel={{ buttonContent: t("create-new-baseApp"), disableOnPristine: true }}
            />
        </SimplePage>
    );
}
