import TitleTabPage from "@magicware/dashboard/navigation/TitleTabPage";
import { useAppInstanceId } from "../../navigation/appInstances";
import { appInstanceThunks } from "../../redux/appInstances/appInstancesReducer";
import { useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { RootState } from "../../redux/types";

export default function AppInstanceVersionTabPage() {
    const appInstanceId = useAppInstanceId();
    const api = useAutoDispatchAsyncThunk(appInstanceThunks.load, appInstanceId);
    return (
        <TitleTabPage
            apiStatus={api.apiStatus}
            titleSelector={(state: RootState) => `${state.appInstances.detail?.name} - Version`}
        />
    );
}
