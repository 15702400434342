import { TwStyle } from "twin.macro";
import clsProps from "@magicware/utils/clsProps";
import { DataDictionary } from "@magicware/utils/DataDictionary";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataListEmptyListBulkActions } from "./DataListBulkActionsContext";
import DataListHeader from "./DataListHeader";
import { PagingSettings, usePaging } from "./Paging";

export interface ReduxDataListProps {
    dataSelector: (s: any) => undefined | DataDictionary<any, any>;
    setPageActionCreator: ActionCreatorWithPayload<{ page: number }>;
    children: (id: number) => React.ReactNode;
    emptyList?: React.ReactNode;
    checkAll?: boolean;
    gridTw?: TwStyle[];
}

export const getIdKey = (id: number) => id;

export default function DataList({
    dataSelector,
    children,
    checkAll = false,
    setPageActionCreator,
    ...props
}: ReduxDataListProps) {
    const dispatch = useDispatch();
    const data = useSelector(dataSelector);
    if (!data) return null;
    const checkAllIdsSelector = checkAll ? (state: any) => dataSelector(state)?.all : undefined;
    return (
        <GenericDataList
            {...props}
            data={data.all}
            getPage={() => data?.page}
            setPage={(page: number) => dispatch(setPageActionCreator({ page }))}
            getItemKey={getIdKey}
            checkAllIdsSelector={checkAllIdsSelector}
        >
            {children}
        </GenericDataList>
    );
}

export interface GenericDataListProps<T> extends PagingSettings<T> {
    getItemKey: (item: T) => string | number;
    children: (item: T) => React.ReactNode;
    controlledPaging?: { getPage: () => number; setPage: () => number };
    emptyList?: React.ReactNode;
    checkAllIdsSelector?: (s: any) => number[] | undefined;
    gridTw?: TwStyle[];
}
export function GenericDataList<T>({
    getItemKey,
    children,
    emptyList,
    checkAllIdsSelector,
    gridTw,
    ...props
}: GenericDataListProps<T>) {
    const paging = usePaging(props);

    if (paging.pageData.length === 0)
        return (
            <div tw="mt-5 space-y-6" {...clsProps(props)}>
                <div tw="ml-1">{emptyList}</div>
                <DataListEmptyListBulkActions />
            </div>
        );

    return (
        <div {...clsProps(props)}>
            <DataListHeader tw="mb-2" pagingState={paging} checkAllIdsSelector={checkAllIdsSelector} />
            <div tw="space-y-4" css={gridTw}>
                {paging.pageData.map((item) => (
                    <Fragment key={getItemKey(item)}>{children(item)}</Fragment>
                ))}
            </div>
            {paging.pageData.length > 2 && (
                <DataListHeader tw="mt-2" pagingState={paging} checkAllIdsSelector={checkAllIdsSelector} />
            )}
        </div>
    );
}
