import { createEntityApi } from "@magicware/fetch-api/entities";
import { IdVersion } from "@magicware/fetch-api/types";
import { API_BASE_URL } from "../globals";
import { BrandSelectOption } from "./brands";
import { ParameterSelectOption } from "./parameters";

export interface ParametersScopeSelectOption {
    id: number;
    brand: string;
    name: string;
}

export interface ParametersScopeModelBase {
    name: string;
    type?: string;
    hasPaidCertification: boolean;
    notes?: string;
}
export interface ParametersScopeEntity extends ParametersScopeModelBase {
    brandId: number;
    parameterIds: number[];
}

export interface ParametersScopeModelNew extends ParametersScopeEntity {}
export interface ParametersScopeModelUpdate extends ParametersScopeEntity, IdVersion {}
export interface ParametersScopeModelDetail extends ParametersScopeModelBase, IdVersion {
    brand: BrandSelectOption;
    parameterList: ParameterSelectOption[];
}
export interface ParametersScopeModelList extends ParametersScopeSelectOption {
    type?: string;
}
export interface ParametersScopeFilter {
    name?: string;
    type?: string;
    brandId?: number[];
    connectorId?: number;
}

export const entityApi = createEntityApi<
    ParametersScopeModelNew,
    ParametersScopeModelUpdate,
    ParametersScopeModelDetail,
    ParametersScopeModelList,
    ParametersScopeFilter
>("parametersScope", `${API_BASE_URL}registry/parametersScopes`);

export const parametersScopesApi = entityApi;
