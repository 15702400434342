import SimplePage from "@magicware/dashboard/navigation/SimplePage";
import { SearchFormPanel } from "@magicware/dashboard/Panel";
import DataList from "@magicware/data-lists/DataList";
import { FormStateApiContextProvider } from "@magicware/forms/components/FormStateApiContext";
import { useTranslation } from "react-i18next";
import { projectSetupThunks, setPage } from "../../redux/projectSetups/projectSetupsReducer";
import { RootState } from "../../redux/types";
import EntityListItemMenuContext from "../entities/EntityListItemMenuContext";
import ProjectSetupListItem from "./ProjectSetupListItem";
import ProjectSetupListMenuContext from "./ProjectSetupListMenuContext";
import ProjectSetupSearchForm from "./ProjectSetupSearchForm";

export default function ProjectSetupListPage() {
    const { t } = useTranslation();
    return (
        <SimplePage title={t("projects-title")}>
            <FormStateApiContextProvider>
                <SearchFormPanel>
                    <ProjectSetupSearchForm />
                </SearchFormPanel>
                <ProjectSetupListMenuContext>
                    <EntityListItemMenuContext
                        deleteSnackTitle={t("projectSetup-deletion")}
                        deleteThunk={projectSetupThunks.delete}
                    >
                        <DataList
                            dataSelector={(state: RootState) => state.projectSetups.list}
                            setPageActionCreator={setPage}
                            emptyList={t("no-projects-message")}
                        >
                            {(id) => <ProjectSetupListItem projectSetupId={id} />}
                        </DataList>
                    </EntityListItemMenuContext>
                </ProjectSetupListMenuContext>
            </FormStateApiContextProvider>
        </SimplePage>
    );
}
