import { buildSearchForm } from "@magicware/forms/builder/searchForm";
import { SearchForm } from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import FormTextField from "@magicware/forms/mui/FormTextField";
import { useTranslation } from "react-i18next";
import { apiServerInstanceThunks } from "../../redux/apiServerInstances/apiServerInstancesReducer";
import { RootState } from "../../redux/types";

const { f, useSearchForm } = buildSearchForm(
    apiServerInstanceThunks.loadList,
    (state: RootState) => state.apiServerInstances.list?.filter,
    () => ({}),
);

export default function ApiServerInstanceSearchForm() {
    const { t } = useTranslation();
    const searchForm = useSearchForm();
    return (
        <SearchForm {...searchForm}>
            <FormGrid tw="md:grid-cols-2">
                <FormTextField f={f} field="name" label={t("name-label")} searchIcon />
                <FormTextField f={f} field="url" label={t("url-label")} searchIcon />
            </FormGrid>
        </SearchForm>
    );
}
