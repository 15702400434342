export default function getClosestDataAttr(el: HTMLElement, dataAttrSufix: string): string {
    const result = el.closest(`[data-${dataAttrSufix}]`)?.getAttribute(`data-${dataAttrSufix}`);
    if (!result) throw new Error(`Missing data-${dataAttrSufix} attribute`);
    return result;
}

export function getClosestDataIntAttr(el: HTMLElement, dataAttrSufix = "id"): number {
    const result = el.closest(`[data-${dataAttrSufix}]`)?.getAttribute(`data-${dataAttrSufix}`);
    if (!result) throw new Error(`Missing data-${dataAttrSufix} attribute`);
    return parseInt(result);
}
