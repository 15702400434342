import { createEntityAdapter, EntityState } from "@magicware/redux/entities";
import { anchoreThunks, AsyncSliceState } from "@magicware/redux/thunks";
import { createSlice } from "@reduxjs/toolkit";
import {
    entityApi,
    ConnectorCertificationModelDetail,
    ConnectorCertificationFilter,
    ConnectorCertificationModelList,
} from "../../services/api/connectorCertifications";
import { RootState } from "../types";

export interface State
    extends EntityState<
            ConnectorCertificationModelDetail,
            ConnectorCertificationModelList,
            ConnectorCertificationFilter
        >,
        AsyncSliceState {}

const initialState: State = { async: {} };

const adapter = createEntityAdapter("connectorCertifications", entityApi, []);

const extraThunks = {};

const slice = createSlice({
    name: "connectorCertifications",
    initialState,
    reducers: adapter.getReducers(),
    extraReducers: (builder) => {
        adapter.addExtraReducers(builder);
        //addAsyncThunkReducers(builder, extraThunks.xxx);
    },
});

export const connectorCertificationThunks = anchoreThunks((state: RootState) => state.connectorCertifications, {
    ...extraThunks,
    ...adapter.thunks,
});

export const actions = slice.actions;
export const { updateDetail, clearList, removeListItems, updateListItems, setPage } = slice.actions;
export default slice.reducer;
