import AsyncContent from "@magicware/dashboard/AsyncContent";
import DataList from "@magicware/data-lists/DataList";
import { FormStateApiContextProvider } from "@magicware/forms/components/FormStateApiContext";
import { useTranslation } from "react-i18next";
import { useConnectorId } from "../../navigation/connectors";
import { connectorVersionThunks, setPage } from "../../redux/connectorVersions/connectorVersionsReducer";
import { useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { RootState } from "../../redux/types";
import { ConnectorVersionFilter } from "../../services/api/connectorVersions";
import EntityListItemMenuContext from "../entities/EntityListItemMenuContext";
import ConnectorVersionListItem from "./ConnectorVersionListItem";
import ConnectorVersionListMenuContext from "./ConnectorVersionListMenuContext";

export default function ConnectorVersionListTab() {
    const connectorId = useConnectorId();
    const filter: ConnectorVersionFilter = { connectorId: connectorId };
    const api = useAutoDispatchAsyncThunk(connectorVersionThunks.loadList, filter);
    return <AsyncContent {...api} component={ConnectorVersionListTabLoaded} />;
}

function ConnectorVersionListTabLoaded() {
    const { t } = useTranslation();
    return (
        <FormStateApiContextProvider>
            <ConnectorVersionListMenuContext>
                <EntityListItemMenuContext
                    deleteSnackTitle={t("connectorVersion-deletion")}
                    deleteThunk={connectorVersionThunks.delete}
                >
                    <DataList
                        dataSelector={(state: RootState) => state.connectorVersions.list}
                        setPageActionCreator={setPage}
                        emptyList={t("no-versions-message")}
                    >
                        {(id) => <ConnectorVersionListItem connectorVersionId={id} />}
                    </DataList>
                </EntityListItemMenuContext>
            </ConnectorVersionListMenuContext>
        </FormStateApiContextProvider>
    );
}
