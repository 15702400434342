import { createEntityApi } from "@magicware/fetch-api/entities";
import { IdVersion } from "@magicware/fetch-api/types";
import { API_BASE_URL } from "../globals";
import { ensureList } from "./codebooks";
import { createFilesApi } from "./files";

export interface BrandSelectOption {
    id: number;
    name: string;
}

export interface BrandModelBase {
    name: string;
    notes?: string;
}

export interface BrandModelNew extends BrandModelBase {}
export interface BrandModelUpdate extends BrandModelBase, IdVersion {}
export interface BrandModelDetail extends BrandModelBase, IdVersion {}
export interface BrandModelList extends BrandSelectOption {}
export interface BrandFilter {
    name?: string;
}

export const entityApi = createEntityApi<
    BrandModelNew,
    BrandModelUpdate,
    BrandModelDetail,
    BrandModelList,
    BrandFilter
>("brand", `${API_BASE_URL}registry/brands`);

export const brandsApi = {
    ...entityApi,
    files: createFilesApi("brands"),
    ensure: (names: string[]): Promise<BrandModelList[]> => ensureList("brands", names),
};
