import { createEntityApi } from "@magicware/fetch-api/entities";
import { getData } from "@magicware/fetch-api/fetch";
import { IdVersion } from "@magicware/fetch-api/types";
import { API_BASE_URL } from "../globals";
import { BrandSelectOption } from "./brands";
import { createFilesApi } from "./files";

export interface ConnectorSelectOption {
    id: number;
    name: string;
}

export interface ConnectorModelBase {
    name: string;
    form?: string;
    notes?: string;
}

export interface ConnectorEntity extends ConnectorModelBase {
    brandId: number;
}

export interface ConnectorModelNew extends ConnectorEntity {}
export interface ConnectorModelUpdate extends ConnectorEntity, IdVersion {}
export interface ConnectorModelDetail extends ConnectorModelBase, IdVersion {
    brand: BrandSelectOption;
}
export interface ConnectorModelList extends ConnectorSelectOption {
    brand: string;
}
export interface ConnectorFilter {
    name?: string;
    brandId?: number[];
}

export interface ConnectorTabInfo {
    id: number;
    name: string;
}

const baseUrl = `${API_BASE_URL}registry/connectors`;

export const entityApi = createEntityApi<
    ConnectorModelNew,
    ConnectorModelUpdate,
    ConnectorModelDetail,
    ConnectorModelList,
    ConnectorFilter
>("connector", baseUrl);

export const connectorsApi = {
    ...entityApi,
    files: createFilesApi("connectors"),
    loadTabInfo: (connectorId: number, signal?: AbortSignal) =>
        getData<ConnectorTabInfo>(`${baseUrl}/tabInfo`, { connectorId }, { signal }),
};
