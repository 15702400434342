import { createEntityApi } from "@magicware/fetch-api/entities";
import { IdVersion } from "@magicware/fetch-api/types";
import { API_BASE_URL } from "../globals";
import { DeviceSelectOption } from "./devices";
import { ParameterSelectOption } from "./parameters";
import { ProjectSetupSelectOption } from "./projectSetups";

export interface ExperienceModelBase {
    description: string;
    parameterId?: number;
    deviceId?: number;
    projectSetupId?: number;
}

export interface ExperienceModelNew extends ExperienceModelBase {}
export interface ExperienceModelUpdate extends ExperienceModelBase, IdVersion {}
export interface ExperienceModelDetail extends IdVersion {
    description: string;
    parameter?: ParameterSelectOption;
    device?: DeviceSelectOption;
    projectSetup?: ProjectSetupSelectOption;
}
export interface ExperienceModelList {
    id: number;
    parameter?: ParameterSelectOption;
    device?: DeviceSelectOption;
    projectSetup?: ProjectSetupSelectOption;
}
export interface ExperienceFilter {
    parameterId?: number[];
    deviceId?: number[];
    projectSetupId?: number[];
}

export const entityApi = createEntityApi<
    ExperienceModelNew,
    ExperienceModelUpdate,
    ExperienceModelDetail,
    ExperienceModelList,
    ExperienceFilter
>("experience", `${API_BASE_URL}registry/experiences`);

export const experiencesApi = entityApi;
