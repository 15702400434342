import { SearchFormPanel } from "@magicware/dashboard/Panel";
import DataList from "@magicware/data-lists/DataList";
import { FormStateApiContextProvider } from "@magicware/forms/components/FormStateApiContext";
import { useTranslation } from "react-i18next";
import { libraryThunks, setPage } from "../../redux/libraries/librariesReducer";
import { RootState } from "../../redux/types";
import EntityListItemMenuContext from "../entities/EntityListItemMenuContext";
import LibraryListItem from "./LibraryListItem";
import LibraryListMenuContext from "./LibraryListMenuContext";
import LibrarySearchForm from "./LibrarySearchForm";

export default function LibraryListPage() {
    const { t } = useTranslation();
    return (
        <FormStateApiContextProvider>
            <SearchFormPanel>
                <LibrarySearchForm />
            </SearchFormPanel>
            <LibraryListMenuContext>
                <EntityListItemMenuContext deleteSnackTitle={t("library-deletion")} deleteThunk={libraryThunks.delete}>
                    <DataList
                        dataSelector={(state: RootState) => state.libraries.list}
                        setPageActionCreator={setPage}
                        emptyList={t("no-libraries-message")}
                    >
                        {(id) => <LibraryListItem libraryId={id} />}
                    </DataList>
                </EntityListItemMenuContext>
            </LibraryListMenuContext>
        </FormStateApiContextProvider>
    );
}
