import { createAsyncFormAutocompleteWithFilter } from "@magicware/forms/mui/FormAutocomplete";
import { connectorVersionThunks } from "../../redux/connectorVersions/connectorVersionsReducer";
import { RootState } from "../../redux/types";
import { ConnectorVersionSelectOption } from "../../services/api/connectorVersions";
import { createDefaultProps } from "../forms/formAutocomplete";

export const FormConnectorVersionsAutocomplete = createAsyncFormAutocompleteWithFilter(
    {
        selectOptions: (state: RootState) => state.connectorVersions.list,
        createAsyncAction: connectorVersionThunks.loadList,
        selectAsyncState: connectorVersionThunks.loadList.selectState,
    },
    createDefaultProps<ConnectorVersionSelectOption>(
        (opt) => `${opt.connector.name} - ${opt.name}`,
        (n, opt) => n.connectors.detail.versions.detail.url(opt.connector.id, opt.id),
    ),
);
