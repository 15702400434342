import {
    add,
    addYears,
    differenceInCalendarDays,
    eachWeekOfInterval,
    format,
    parseISO,
    set,
    startOfDay,
    subDays,
    subYears,
} from "date-fns";
import en from "date-fns/locale/en-US";
import { ParameterAt } from "./types";

export type Dt = Date | string | undefined | null;
type Ret<D, Ret = string> = D extends Date | string ? Ret : undefined;

function formatLoc<D extends Dt>(date: D, formatString: string): Ret<D> {
    if (date) return format(new Date(date), formatString, { locale: en }) as any;
    return undefined as any;
}
function fmt(date: Date, formatString: string): string {
    return format(date, formatString, { locale: en });
}

export class DateFn {
    static parse<D extends Dt>(date: D): Ret<D, Date> {
        if (typeof date === "string") return parseISO(date) as any;
        if (date instanceof Date) return date as any;
        return undefined as any;
    }
    static stringify<D extends Dt>(date: D): Ret<D> {
        return formatLoc(date, new Date(date as any).getTime() > 0 ? "yyyy-MM-dd'T'HH:mm:ss" : "yyyy-MM-dd");
    }
    static f_dd<D extends Dt>(date: D): Ret<D> {
        return formatLoc(date, "dd");
    }
    static f_ddd<D extends Dt>(date: D): Ret<D> {
        return formatLoc(date, "eeeeee");
    }
    static f_dddd<D extends Dt>(date: D): Ret<D> {
        return formatLoc(date, "eeee");
    }
    static f_HHmm<D extends Dt>(date: D): Ret<D> {
        return formatLoc(date, "HH:mm");
    }
    static f_MMMM<D extends Dt>(date: D): Ret<D> {
        return formatLoc(date, "LLLL");
    }
    static f_MMMM_yyyy<D extends Dt>(date: D): Ret<D> {
        return formatLoc(date, "LLLL yyyy");
    }
    static f_ddMM<D extends Dt>(date: D): Ret<D> {
        return formatLoc(date, "dd.MM.");
    }
    static f_ddMMyyyy<D extends Dt>(date: D): Ret<D> {
        return formatLoc(date, "dd.MM.yyyy");
    }
    static f_ddMMyyyy_HHmm<D extends Dt>(date: D): Ret<D> {
        return formatLoc(date, "dd.MM.yyyy HH:mm");
    }
    static f_ddMM_HHmm<D extends Dt>(date: D): Ret<D> {
        return formatLoc(date, "dd.MM. HH:mm");
    }
    static f_ddMMyyy_ddd<D extends Dt>(date: D): Ret<D> {
        if (date) return format(new Date(date), "dd.MM.yyyy (eeeeee)", { locale: en }) as any;
        return undefined as any;
    }
    static f_rangeFull<D extends Dt>(start: D, end?: D): Ret<D> {
        const s = DateFn.parse(start);
        const e = DateFn.parse(end);
        if (s) {
            if (e) {
                const diff = differenceInCalendarDays(e, s);
                if (diff === 0) return fmt(s, "dd.MM.yyyy (eeeeee)") as any;
                else
                    return `${fmt(s, "dd.MM.")} - ${fmt(e, "dd.MM.yyyy")} ${fmt(s, "(eeeeee")} - ${fmt(
                        e,
                        "eeeeee)",
                    )}` as any;
            } else {
                return fmt(s, "dd.MM.yyyy (eeeeee)") as any;
            }
        }
        return undefined as any;
    }
    static f_range<D extends Dt>(start: D, end?: D): Ret<D> {
        const s = DateFn.parse(start);
        const e = DateFn.parse(end);
        if (s) {
            if (e) {
                const diff = differenceInCalendarDays(e, s);
                if (diff === 0) return fmt(s, "dd.MM.yyyy (eeeeee)") as any;
                if (diff < 7) return `${fmt(s, "dd.MM.yyyy (eeeeee")} - ${fmt(e, "eeeeee")})` as any;
                else
                    return `${fmt(s, "dd.MM.")} - ${fmt(e, "dd.MM.yyyy")} ${fmt(s, "(eeeeee")} - ${fmt(
                        e,
                        "eeeeee)",
                    )}` as any;
            } else {
                return fmt(s, "dd.MM.yyyy (eeeeee)") as any;
            }
        }
        return undefined as any;
    }
    static f_yyyyMMdd(date: Date) {
        return format(date, "yyyy-MM-dd");
    }
    static daysDiff<D extends Dt>(start: D, end: D): Ret<D, number> {
        const s = DateFn.parse(start);
        const e = DateFn.parse(end);
        if (s && e) return differenceInCalendarDays(e, s) as any;
        return undefined as any;
    }
    static dateOnly<D extends Dt>(date: D): Ret<D, Date> {
        const d = DateFn.parse(date);
        if (d) return startOfDay(d) as any;
        return undefined as any;
    }
    static today(daysToAdd?: number) {
        return daysToAdd ? DateFn.addDays(startOfDay(new Date()), daysToAdd) : startOfDay(new Date());
    }
    static addDays<D extends Dt>(date: D, val: number): Ret<D, Date> {
        const d = DateFn.parse(date);
        if (d) return add(d, { days: val }) as any;
        return undefined as any;
    }
    static addYears<D extends Dt>(date: D, val: number): Ret<D, Date> {
        const d = DateFn.parse(date);
        if (d) return addYears(d, val) as any;
        return undefined as any;
    }
    static add<D extends Dt>(date: D, duration: ParameterAt<typeof add, 1>): Ret<D, Date> {
        const d = DateFn.parse(date);
        if (d) return add(d, duration) as any;
        return undefined as any;
    }
    static set<D extends Dt>(date: D, values: ParameterAt<typeof set, 1>): Ret<D, Date> {
        const d = DateFn.parse(date);
        if (d) return set(d, values) as any;
        return undefined as any;
    }
    static monthWeeks<D extends Dt>(date: D): Ret<D, Date[]> {
        const d = DateFn.parse(date);
        if (!d) return undefined as any;
        const month = {
            start: set(d, { date: 1 }),
            end: add(add(set(d, { date: 1 }), { months: 1 }), { days: -1 }),
        };
        return eachWeekOfInterval(month, { weekStartsOn: 1 }) as any;
    }
    static subYears<D extends Dt>(date: D, amount: number) {
        const d = DateFn.parse(date);
        if (d) return subYears(d, amount);
        return undefined as any;
    }
    static subDays<D extends Dt>(date: D, amount: number) {
        const d = DateFn.parse(date);
        if (d) return subDays(d, amount);
        return undefined as any;
    }
}
