import SimplePage from "@magicware/dashboard/navigation/SimplePage";
import useNewEntityOnSubmit from "@magicware/forms/hooks/useNewEntityOnSubmit";
import { useTranslation } from "react-i18next";
import { useNavigationClickReplace } from "../../navigation/hooks";
import { clearList } from "../../redux/parametersScopes/parametersScopesReducer";
import { parametersScopesApi } from "../../services/api/parametersScopes";
import ParametersScopeForm, { parametersScopeForm } from "./ParametersScopeForm";

const { useDefaults } = parametersScopeForm.hooks;

export default function ParametersScopeNewPage() {
    const { t } = useTranslation();
    const onSubmit = useNewEntityOnSubmit(
        clearList,
        parametersScopesApi.forms.create,
        useNavigationClickReplace((n) => n.parametersScopes.url()),
        parametersScopeForm.transform.formTo.new,
    );
    const initialValues = useDefaults();
    return (
        <SimplePage title={t("new-scope-title")}>
            <ParametersScopeForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                autoFocus
                savePanel={{ buttonContent: t("create-new-scope"), disableOnPristine: true }}
            />
        </SimplePage>
    );
}
