import AsyncContent from "@magicware/dashboard/AsyncContent";
import { useProjectSetupId } from "../../navigation/projectSetups";
import { useAppDispatch, useAppSelectorEnsure, useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { projectSetupThunks } from "../../redux/projectSetups/projectSetupsReducer";
import { projectSetupsApi, toUpdate } from "../../services/api/projectSetups";
import { buildForm } from "@magicware/forms/builder";
import * as yup from "yup";
import { updateDetail } from "../../redux/projectSetups/projectSetupsReducer";
import { ProjectSetupModelDetail } from "../../services/api/projectSetups";
import Form from "@magicware/forms/components/Form";
import Panel from "@magicware/dashboard/Panel";
import FormGrid from "@magicware/forms/components/FormGrid";
import { FormDevicesAutocomplete } from "../devices/FormDevicesAutocomplete";
import { useTranslation } from "react-i18next";

export const projectDevicesForm = buildForm<ProjectSetupModelDetail>()(() => ({
    props: {
        pick: ["deviceList"],
        remove: [],
        extra: [],
    },
    transformations: {
        toForm: { fromModel: (h, m: ProjectSetupModelDetail) => h.fromModel(m) },
        formTo: {
            update: (h, f, m: ProjectSetupModelDetail) => ({
                ...toUpdate(m),
                ...h.toUpdateEntity(f, m),
            }),
        },
    },
    updateReduxModel: updateDetail,
    schema: {
        deviceList: yup.array().default([]),
    },
}));

const f = projectDevicesForm.f;

export default function ProjectSetupDevicesTab() {
    const api = useAutoDispatchAsyncThunk(projectSetupThunks.load, useProjectSetupId());
    return <AsyncContent {...api} component={ProjectSetupDevicesTabLoaded} />;
}

function ProjectSetupDevicesTabLoaded() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const projectSetupDetail = useAppSelectorEnsure((state) => state.projectSetups.detail);
    const initialValues = projectDevicesForm.hooks.useToFormFromModel(projectSetupDetail);
    const onSubmit = async (form: typeof projectDevicesForm.f) => {
        const result = await projectSetupsApi.forms.update(
            projectDevicesForm.transform.formTo.update(form, projectSetupDetail),
        );
        return projectDevicesForm.apiUtils.handleUpdateEntityResult(dispatch, result, form);
    };
    return (
        <Form
            validationSchema={projectDevicesForm.validationSchema}
            onSubmit={onSubmit}
            initialValues={initialValues}
            savePanel={{ successMessage: t("project-has-been-saved") }}
        >
            <Panel>
                <FormGrid>
                    <FormDevicesAutocomplete
                        f={f}
                        field="deviceList"
                        fullOption
                        multiple
                        label={t("devices-label")}
                        filter={{}}
                    />
                </FormGrid>
            </Panel>
        </Form>
    );
}
