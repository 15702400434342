import { baseAppThunks } from "../../redux/baseApps/baseAppsReducer";
import { useAppDispatch, useAppSelectorEnsure, useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { baseAppsApi } from "../../services/api/baseApps";
import BaseAppForm, { baseAppForm, BaseAppFormOnSubmit } from "./BaseAppForm";
import AsyncContent from "@magicware/dashboard/AsyncContent";
import { useBaseAppId } from "../../navigation/baseApps";
import { useTranslation } from "react-i18next";

const { useToFormFromModel } = baseAppForm.hooks;

export default function BaseAppDetailTabPage() {
    const api = useAutoDispatchAsyncThunk(baseAppThunks.load, useBaseAppId());
    return <AsyncContent {...api} component={BaseAppDetailTabPageLoaded} />;
}

function BaseAppDetailTabPageLoaded() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const baseAppDetail = useAppSelectorEnsure((state) => state.baseApps.detail);
    const initialValues = useToFormFromModel(baseAppDetail);
    const onSubmit: BaseAppFormOnSubmit = async (form) => {
        const result = await baseAppsApi.forms.update(await baseAppForm.transform.formTo.update(form, baseAppDetail));
        return baseAppForm.apiUtils.handleUpdateEntityResult(dispatch, result, form);
    };
    return (
        <BaseAppForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            savePanel={{ successMessage: t("baseApp-has-been-saved") }}
        />
    );
}
