import { apiServerVersionThunks } from "../../redux/apiServerVersions/apiServerVersionsReducer";
import { useAppDispatch, useAppSelectorEnsure, useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { apiServerVersionsApi } from "../../services/api/apiServerVersions";
import ApiServerVersionForm, { apiServerVersionForm, ApiServerVersionFormOnSubmit } from "./ApiServerVersionForm";
import AsyncContent from "@magicware/dashboard/AsyncContent";
import { useApiServerVersionId } from "../../navigation/apiServers";
import { useTranslation } from "react-i18next";

const { useToFormFromModel } = apiServerVersionForm.hooks;

export default function ApiServerVersionDetailTabPage() {
    const api = useAutoDispatchAsyncThunk(apiServerVersionThunks.load, useApiServerVersionId());
    return <AsyncContent {...api} component={ApiServerVersionDetailTabPageLoaded} />;
}

function ApiServerVersionDetailTabPageLoaded() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const apiServerVersionDetail = useAppSelectorEnsure((state) => state.apiServerVersions.detail);
    const initialValues = useToFormFromModel(apiServerVersionDetail);
    const onSubmit: ApiServerVersionFormOnSubmit = async (form) => {
        const result = await apiServerVersionsApi.forms.update(
            await apiServerVersionForm.transform.formTo.update(form, apiServerVersionDetail),
        );
        return apiServerVersionForm.apiUtils.handleUpdateEntityResult(dispatch, result, form);
    };
    return (
        <ApiServerVersionForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            savePanel={{ successMessage: t("apiServerVersion-has-been-saved") }}
        />
    );
}
