import { faCopyright } from "@fortawesome/free-solid-svg-icons";
import { prepareBreadcrumb } from "@magicware/dashboard/navigation/breadcrumbs";
import { CreatePatternMethod, CreateSimpleMethod } from "@magicware/dashboard/navigation/builder";
import { prepareRootTab, prepareTab } from "@magicware/dashboard/navigation/tabs";
import { useIntParam } from "@magicware/dashboard/navigation/useIntParam";
import BrandDetailTabPage from "../components/brands/BrandDetailTabPage";
import BrandFilesTabPage from "../components/brands/BrandFilesTabPage";
import BrandNewPage from "../components/brands/BrandNewPage";
import BrandTabPage from "../components/brands/BrandTabPage";
import ConfigurationTabPage from "../components/configuration/ConfigurationTabPage";
import { filesIcon } from "./files";

export const useBrandId = () => useIntParam("brandId");

export const brandIcon = faCopyright;

export const buildBrands = (simple: CreateSimpleMethod, pattern: CreatePatternMethod) => {
    let tabIndex = 1;
    return simple({
        url: "/brands",
        title: (t) => t("brands-title"),
        component: ConfigurationTabPage,
        breadcrumb: prepareBreadcrumb((t) => t("brands-breadcrumb")),

        new: simple({
            url: "/brands/new",
            component: BrandNewPage,
            breadcrumb: prepareBreadcrumb((t) => t("new-brand-breadcrumb")),
        }),
        detail: pattern({
            pattern: "/brands/:brandId",
            component: BrandTabPage,
            url: (brandId: number) => `/brands/${brandId}`,
            tab: prepareRootTab((t) => t("brand-detail-tab"), brandIcon, BrandDetailTabPage),
            breadcrumb: prepareBreadcrumb((t) => t("brand-detail-breadcrumb")),

            files: pattern({
                pattern: "/brands/:brandId/files",
                component: BrandTabPage,
                url: (brandId: number) => `/brands/${brandId}/files`,
                breadcrumb: prepareBreadcrumb((t) => t("files-breadcrumb")),
                tab: prepareTab(tabIndex++, (t) => t("files-tab"), filesIcon, BrandFilesTabPage),
            }),
        }),
    });
};
