import { createEntityAdapter, EntityState } from "@magicware/redux/entities";
import { anchoreThunks, AsyncSliceState } from "@magicware/redux/thunks";
import { createSlice } from "@reduxjs/toolkit";
import { entityApi, ParameterModelDetail, ParameterFilter, ParameterModelList } from "../../services/api/parameters";
import { RootState } from "../types";

export interface State
    extends EntityState<ParameterModelDetail, ParameterModelList, ParameterFilter>,
        AsyncSliceState {}

const initialState: State = { async: {} };

const adapter = createEntityAdapter("parameter", entityApi, ["name", "kind", "type", "unit"]);

const extraThunks = {};

const slice = createSlice({
    name: "parameters",
    initialState,
    reducers: adapter.getReducers(),
    extraReducers: (builder) => {
        adapter.addExtraReducers(builder);
        //addAsyncThunkReducers(builder, extraThunks.xxx);
    },
});

export const parameterThunks = anchoreThunks((state: RootState) => state.parameters, {
    ...extraThunks,
    ...adapter.thunks,
});

export const actions = slice.actions;
export const { updateDetail, clearList, removeListItems, updateListItems, setPage } = slice.actions;
export default slice.reducer;
