import SimplePage from "@magicware/dashboard/navigation/SimplePage";
import useNewEntityOnSubmit from "@magicware/forms/hooks/useNewEntityOnSubmit";
import { useTranslation } from "react-i18next";
import { useNavigationClickReplace } from "../../navigation/hooks";
import { clearList } from "../../redux/connectors/connectorsReducer";
import { connectorsApi } from "../../services/api/connectors";
import ConnectorForm, { connectorForm } from "./ConnectorForm";

const { useDefaults } = connectorForm.hooks;

export default function ConnectorNewPage() {
    const { t } = useTranslation();
    const onSubmit = useNewEntityOnSubmit(
        clearList,
        connectorsApi.forms.create,
        useNavigationClickReplace((n) => n.connectors.url()),
        connectorForm.transform.formTo.new,
    );
    const initialValues = useDefaults();
    return (
        <SimplePage title={t("new-connector-title")}>
            <ConnectorForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                autoFocus
                savePanel={{ buttonContent: t("create-new-connector"), disableOnPristine: true }}
            />
        </SimplePage>
    );
}
