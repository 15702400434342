import { createEntityApi } from "@magicware/fetch-api/entities";
import { getData } from "@magicware/fetch-api/fetch";
import { IdVersion } from "@magicware/fetch-api/types";
import { API_BASE_URL } from "../globals";
import { BaseAppSelectOption } from "./baseApps";
import { createFilesApi } from "./files";
import { ProjectSetupSelectOption } from "./projectSetups";

export interface AppInstanceSelectOption {
    id: number;
    name: string;
    platform: string;
    projectSetup: ProjectSetupSelectOption;
}

export interface AppInstanceModelBase {
    name: string;
    platform: string;
    url?: string;
    rating?: number;
    notes?: string;
}

export interface AppInstanceEntity extends AppInstanceModelBase {
    projectSetupId: number;
    baseAppId?: number;
}

export interface AppInstanceModelNew extends AppInstanceEntity {}
export interface AppInstanceModelUpdate extends AppInstanceEntity, IdVersion {}
export interface AppInstanceModelDetail extends AppInstanceModelBase, IdVersion {
    baseApp?: BaseAppSelectOption;
    projectSetup: ProjectSetupSelectOption;
}

export interface AppInstanceModelList extends AppInstanceSelectOption {
    baseApp?: string;
    rating?: number;
}
export interface AppInstanceFilter {
    name?: string;
    projectSetupId?: number;
    baseAppId?: number;
}

export interface AppInstanceTabInfo {
    id: number;
    name: string;
}

const baseUrl = `${API_BASE_URL}registry/appInstances`;

export const entityApi = createEntityApi<
    AppInstanceModelNew,
    AppInstanceModelUpdate,
    AppInstanceModelDetail,
    AppInstanceModelList,
    AppInstanceFilter
>("appInstance", baseUrl);

export const appInstancesApi = {
    ...entityApi,
    files: createFilesApi("appInstances"),
    loadTabInfo: (appInstanceId: number, signal?: AbortSignal) =>
        getData<AppInstanceTabInfo>(`${baseUrl}/tabInfo`, { appInstanceId }, { signal }),
};
