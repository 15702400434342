import { isAsyncThunkRejectedPromiseResult } from "@magicware/redux/thunks";
import { AnyStore, StoreDispatch, StoreThunkAction } from "@magicware/redux/types";
import { defaultUnknownErrorMessage } from "@magicware/fetch-api/forms";
import { FORM_ERROR } from "final-form";
import { useDispatch } from "react-redux";

export default function useFormDispatchApi<TParams extends any[]>(
    createAsyncAction: (...rest: TParams) => StoreThunkAction<AnyStore, Promise<any>>,
    errorMessage = defaultUnknownErrorMessage,
) {
    const dispatch = useDispatch<StoreDispatch>();
    return async (...params: Parameters<typeof createAsyncAction>) => {
        const result = await dispatch(createAsyncAction(...params));
        if (isAsyncThunkRejectedPromiseResult(result)) return { [FORM_ERROR]: errorMessage };
    };
}
