import React from "react";
import PanelAlert from "./PanelAlert";

interface ChildrenProps {
    children?: React.ReactNode;
}

export default class ErrorBoundary extends React.Component<ChildrenProps, { hasError: boolean }> {
    constructor(props: { children?: React.ReactNode }) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return (
                <PanelAlert severity="error">
                    Ooops, something went wrong due to some unexpected error. Please reload the page and try again.
                </PanelAlert>
            );
        }
        return this.props.children;
    }
}
