import DataListItem, { MainText } from "@magicware/data-lists/DataListItem";
import { StyledComponentProps } from "@mui/material";
import { useAppInstaceNavigation } from "../../navigation/appInstances";
import { useNavigationClick } from "../../navigation/hooks";
import { useAppSelector } from "../../redux/hooks";

export interface AppInstanceVersionListItemProps extends StyledComponentProps {
    appInstanceVersionId: number;
    navigation: ReturnType<typeof useAppInstaceNavigation>;
}

export default function AppInstanceVersionListItem({
    appInstanceVersionId,
    navigation: [parentId, navigation],
    ...props
}: AppInstanceVersionListItemProps) {
    const obj = useAppSelector((state) => state.appInstanceVersions.list?.byId[appInstanceVersionId]);
    const editClickFactory = useNavigationClick();
    if (!obj) return null;
    return (
        <DataListItem
            {...props}
            data-id={obj.id}
            onClick={editClickFactory(() =>
                navigation.detail.versions.detail.url(parentId, obj.appInstance.id, obj.id),
            )}
        >
            <div tw="flex items-center">
                <MainText tw="mr-10">{obj.name}</MainText>
            </div>
        </DataListItem>
    );
}
