import { faRegistered, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { useManualDispatchAsyncThunkWithSnackbar } from "@magicware/dashboard/hooks/useManualDispatchAsyncThunkWithSnackbar";
import {
    DataListContextMenu,
    DataListContextMenuProvider,
    MenuAction,
} from "@magicware/data-lists/DataListContextMenu";
import { getClosestDataIntAttr } from "@magicware/utils/html/getClosestDataAttr";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import { fileThunks } from "../../redux/files/filesReducer";
import { useAppState } from "../../redux/hooks";
import { FilesApi } from "../../services/api/files";

export interface FileListItemMenuContextProps {
    api: FilesApi;
    ownerId: number;
    children?: React.ReactNode;
}

export default function FileListItemMenuContext({ api, ownerId, children }: FileListItemMenuContextProps) {
    const getState = useAppState();
    const { t } = useTranslation();
    const dispatchToggleIsLogo = useManualDispatchAsyncThunkWithSnackbar(fileThunks.toggleIsLogo);
    const dispatchDeleteFile = useManualDispatchAsyncThunkWithSnackbar(fileThunks.deleteFile);
    const actions: MenuAction[] = [
        {
            icon: faRegistered,
            name: "toggleLogo",
            getText: (el) => {
                return el && getState().files.list?.byId[getClosestDataIntAttr(el)]?.isLogo
                    ? t("remove-logo")
                    : t("set-logo");
            },
            onClick: (trigger) => {
                const fileId = getClosestDataIntAttr(trigger);
                dispatchToggleIsLogo("Changing logo", { api, fileId, ownerId });
            },
        },
        {
            icon: faTrashAlt,
            iconTw: tw`text-red-500`,
            name: t("delete-file"),
            onClick: (trigger) => {
                const fileId = getClosestDataIntAttr(trigger);
                dispatchDeleteFile("File deletion", { api, fileId, ownerId });
            },
        },
    ];
    if (actions.length === 0) return <Fragment>{children}</Fragment>;
    return (
        <DataListContextMenuProvider>
            <DataListContextMenu actions={actions} />
            {children}
        </DataListContextMenuProvider>
    );
}
