import { SearchFormPanel } from "@magicware/dashboard/Panel";
import DataList from "@magicware/data-lists/DataList";
import { FormStateApiContextProvider } from "@magicware/forms/components/FormStateApiContext";
import { useTranslation } from "react-i18next";
import { connectorThunks, setPage } from "../../redux/connectors/connectorsReducer";
import { RootState } from "../../redux/types";
import EntityListItemMenuContext from "../entities/EntityListItemMenuContext";
import ConnectorListItem from "./ConnectorListItem";
import ConnectorListMenuContext from "./ConnectorListMenuContext";
import ConnectorSearchForm from "./ConnectorSearchForm";

export default function ConnectorListPage() {
    const { t } = useTranslation();
    return (
        <FormStateApiContextProvider>
            <SearchFormPanel>
                <ConnectorSearchForm />
            </SearchFormPanel>
            <ConnectorListMenuContext>
                <EntityListItemMenuContext
                    deleteSnackTitle={t("connector-deletion")}
                    deleteThunk={connectorThunks.delete}
                >
                    <DataList
                        dataSelector={(state: RootState) => state.connectors.list}
                        setPageActionCreator={setPage}
                        emptyList={t("no-connectors-message")}
                    >
                        {(id) => <ConnectorListItem connectorId={id} />}
                    </DataList>
                </EntityListItemMenuContext>
            </ConnectorListMenuContext>
        </FormStateApiContextProvider>
    );
}
