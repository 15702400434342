import { createEntityApi } from "@magicware/fetch-api/entities";
import { IdVersion } from "@magicware/fetch-api/types";
import { API_BASE_URL } from "../globals";
import { createFilesApi } from "./files";

export interface BaseAppSelectOption {
    id: number;
    name: string;
}

export interface BaseAppModelBase {
    name: string;
    notes?: string;
}
export interface BaseAppEntity extends BaseAppModelBase {}

export interface BaseAppModelNew extends BaseAppEntity {}
export interface BaseAppModelUpdate extends BaseAppEntity, IdVersion {}
export interface BaseAppModelDetail extends BaseAppModelBase, IdVersion {}
export interface BaseAppModelList extends BaseAppSelectOption {
    name: string;
}
export interface BaseAppFilter {
    name?: string;
    appInstanceId?: number;
}

export const entityApi = createEntityApi<
    BaseAppModelNew,
    BaseAppModelUpdate,
    BaseAppModelDetail,
    BaseAppModelList,
    BaseAppFilter
>("baseApp", `${API_BASE_URL}registry/baseApps`);

export const baseAppsApi = {
    ...entityApi,
    files: createFilesApi("baseApps"),
};
