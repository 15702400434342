import useNewEntityOnSubmit from "@magicware/forms/hooks/useNewEntityOnSubmit";
import { useTranslation } from "react-i18next";
import { useNavigationClickReplace } from "../../navigation/hooks";
import { useProjectSetupId } from "../../navigation/projectSetups";
import { clearList } from "../../redux/connectorCertifications/connectorCertificationsReducer";
import { connectorCertificationsApi } from "../../services/api/connectorCertifications";
import ConnectorCertificationForm, { connectorCertificationForm } from "./ConnectorCertificationForm";

const { useDefaults } = connectorCertificationForm.hooks;

export default function ConnectorCertificationNewTab() {
    const { t } = useTranslation();
    const projectSetupId = useProjectSetupId();
    const onSubmit = useNewEntityOnSubmit(
        clearList,
        connectorCertificationsApi.forms.create,
        useNavigationClickReplace((n) => n.projectSetups.detail.certifications.url(projectSetupId)),
        (f: typeof connectorCertificationForm.f) => connectorCertificationForm.transform.formTo.new(f, projectSetupId),
    );
    const initialValues = useDefaults();
    return (
        <ConnectorCertificationForm
            editableConnector
            initialValues={initialValues}
            onSubmit={onSubmit}
            autoFocus
            savePanel={{ buttonContent: t("create-new-certification"), disableOnPristine: true }}
        />
    );
}
