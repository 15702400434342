import SimplePage from "@magicware/dashboard/navigation/SimplePage";
import useNewEntityOnSubmit from "@magicware/forms/hooks/useNewEntityOnSubmit";
import { useTranslation } from "react-i18next";
import { useNavigationClickReplace } from "../../navigation/hooks";
import { clearList } from "../../redux/apiServerInstances/apiServerInstancesReducer";
import { apiServerInstancesApi } from "../../services/api/apiServerInstances";
import ApiServerInstanceForm, { apiServerInstanceForm } from "./ApiServerInstanceForm";

const { useDefaults } = apiServerInstanceForm.hooks;

export default function ApiServerInstanceNewPage() {
    const { t } = useTranslation();
    const onSubmit = useNewEntityOnSubmit(
        clearList,
        apiServerInstancesApi.forms.create,
        useNavigationClickReplace((n, newId: number) => n.apiServerInstances.detail.url(newId)),
        (f: typeof apiServerInstanceForm.f) => apiServerInstanceForm.transform.formTo.new(f),
    );
    const initialValues = useDefaults();
    return (
        <SimplePage title={t("new-apiServerInstance-title")}>
            <ApiServerInstanceForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                autoFocus
                savePanel={{ buttonContent: t("create-new-apiServerInstance"), disableOnPristine: true }}
            />
        </SimplePage>
    );
}
