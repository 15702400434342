import { faBars, faChartSimple, faKey, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IEmpty } from "@magicware/utils/types";
import { AppBar, Drawer, IconButton, Toolbar } from "@mui/material";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import LanguageSwitch from "../../localization/LanguageSwitch";
import { navigation } from "../../navigation";
import { configurationIcon } from "../../navigation/codebooks";
import { connectorIcon } from "../../navigation/connectors";
import { deviceIcon } from "../../navigation/devices";
import { experiencesIcon } from "../../navigation/experiences";
import { projectSetupIcon } from "../../navigation/projectSetups";
import { useAppSelector, useManualDispatchAsyncThunk } from "../../redux/hooks";
import { uiThunks } from "../../redux/ui/uiReducer";
import LogoSrc from "../images/logo.png";
import MainMenuNavLink, { MainMenuButton } from "./MainMenuNavLink";

const MenuList = tw.ul`space-y-3`;

export default function MainMenu() {
    const [mobileOpen, setMobileOpen] = useState(false);
    const handleCloseDrawer = () => {
        setMobileOpen(false);
    };
    return (
        <Fragment>
            <AppBar tw="lg:hidden" position="fixed">
                <Toolbar>
                    <IconButton
                        tw="mr-2"
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={() => setMobileOpen((t) => !t)}
                    >
                        <FontAwesomeIcon icon={faBars} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <nav tw="lg:hidden">
                <Drawer
                    variant="temporary"
                    anchor="left"
                    open={mobileOpen}
                    onClick={handleCloseDrawer}
                    onClose={handleCloseDrawer}
                    ModalProps={{ keepMounted: true }}
                >
                    <MenuContent />
                </Drawer>
            </nav>
            <nav tw="hidden lg:block sticky top-0 h-screen ml-5 py-5 [min-height:650px]">
                <MenuContent tw="rounded-xl" />
            </nav>
        </Fragment>
    );
}

function MenuContent(props: IEmpty) {
    const { t } = useTranslation();
    const currentUser = useAppSelector((state) => state.ui.loggedUser);
    const [dispatchLogout, logoutState] = useManualDispatchAsyncThunk(uiThunks.logout);
    const handleLogout = () => dispatchLogout({ reloadUrl: "/" });

    return (
        <div tw="[width:250px] bg-menu-bg h-full relative flex flex-col" {...props}>
            <div tw="flex rounded-full text-white bg-menu-bg mx-auto items-center justify-around text-center relative pt-10">
                <img src={LogoSrc} width={170} height={170} alt="" />
            </div>
            <div tw="absolute pt-2 pl-2 ">
                <LanguageSwitch tw="text-white" />
            </div>
            <div tw="mt-12 px-5">
                <MenuList>
                    <MainMenuNavLink to={navigation.home} exact icon={faChartSimple} text={"Přehled"} />
                    <MainMenuNavLink to={navigation.devices} exact icon={deviceIcon} text={t("devices-menu")} />
                    <MainMenuNavLink
                        to={navigation.connectors}
                        exact
                        icon={connectorIcon}
                        text={t("connectors-menu")}
                    />
                    <MainMenuNavLink
                        to={navigation.projectSetups}
                        exact
                        icon={projectSetupIcon}
                        text={t("projects-menu")}
                    />
                    <MainMenuNavLink
                        to={navigation.experiences}
                        exact
                        icon={experiencesIcon}
                        text={t("experiences-menu")}
                    />
                    <MainMenuNavLink
                        to={navigation.parameters}
                        exact
                        icon={configurationIcon}
                        text={t("configuration-menu")}
                    />
                </MenuList>
            </div>
            <div tw="mt-7 flex-1 flex items-end w-full">
                <div tw="w-full">
                    <div tw="ml-11 mb-5 flex items-center">
                        <div tw="text-menu-icon text-lg">
                            {currentUser?.person.firstName} <strong>{currentUser?.person.lastName}</strong>
                        </div>
                    </div>
                    <div tw="px-5 mb-10">
                        <MenuList tw="space-y-1">
                            <MainMenuNavLink to={navigation.profile} icon={faKey} text={t("my-password-menu")} />
                            <MainMenuButton
                                icon={faRightFromBracket}
                                text={t("log-out-menu")}
                                onClick={handleLogout}
                                isLoading={logoutState.status !== "idle"}
                            />
                        </MenuList>
                    </div>
                    <div tw="hidden lg:block bg-menu-bottom h-3 w-full rounded-b-xl"></div>
                </div>
            </div>
        </div>
    );
}
