import { createEntityApi } from "@magicware/fetch-api/entities";
import { IdVersion } from "@magicware/fetch-api/types";
import { API_BASE_URL } from "../globals";
import { ConnectorSelectOption } from "./connectors";
import { ParametersScopeSelectOption } from "./parametersScopes";

export interface ConnectorCertificationModelBase {
    status: string;
    notes?: string;
}

export interface ConnectorCertificationEntity extends ConnectorCertificationModelBase {
    parametersScopeIds: number[];
}

export interface ConnectorCertificationModelNew extends ConnectorCertificationEntity {
    projectSetupId: number;
    connectorId: number;
}
export interface ConnectorCertificationModelUpdate extends ConnectorCertificationEntity, IdVersion {}
export interface ConnectorCertificationModelDetail extends ConnectorCertificationModelBase, IdVersion {
    connector: ConnectorSelectOption;
    parametersScopeList: ParametersScopeSelectOption[];
}

export interface ConnectorCertificationModelList {
    id: number;
    date: string;
    status: string;
    connector: string;
    projectSetupId: number;
    parametersScopeList: string[];
}
export interface ConnectorCertificationFilter {
    status?: string;
    projectSetupId?: number;
    connectorId?: number;
}

export const entityApi = createEntityApi<
    ConnectorCertificationModelNew,
    ConnectorCertificationModelUpdate,
    ConnectorCertificationModelDetail,
    ConnectorCertificationModelList,
    ConnectorCertificationFilter
>("connectorCertification", `${API_BASE_URL}registry/connectorCertifications`);

export const connectorCertificationsApi = entityApi;
