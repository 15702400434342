import { faLandmark } from "@fortawesome/free-solid-svg-icons";
import DataIcon from "@magicware/data-lists/DataIcon";
import DataListItem, { MainText, SecondaryText } from "@magicware/data-lists/DataListItem";
import { StyledComponentProps } from "@mui/material";
import { useNavigationClick } from "../../navigation/hooks";
import { useAppSelector } from "../../redux/hooks";

export interface ParameterListItemProps extends StyledComponentProps {
    parameterId: number;
}

export default function ParameterListItem({ parameterId, ...props }: ParameterListItemProps) {
    const obj = useAppSelector((state) => state.parameters.list?.byId[parameterId]);
    const editClickFactory = useNavigationClick();
    if (!obj) return null;
    return (
        <DataListItem {...props} data-id={obj.id} onClick={editClickFactory((n) => n.parameters.detail.url(obj.id))}>
            <MainText tw="w-full">{obj.name}</MainText>
            {obj.type && (
                <SecondaryText>
                    <DataIcon icon={faLandmark} />
                    {obj.type}
                </SecondaryText>
            )}
        </DataListItem>
    );
}
