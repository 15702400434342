import { createEntityAdapter, EntityState } from "@magicware/redux/entities";
import { addAsyncThunkReducers, anchoreThunks, AsyncSliceState, createAppAsyncThunk } from "@magicware/redux/thunks";
import { createSlice } from "@reduxjs/toolkit";
import {
    entityApi,
    ConnectorModelDetail,
    ConnectorFilter,
    ConnectorModelList,
    ConnectorTabInfo,
    connectorsApi,
} from "../../services/api/connectors";
import addVersionUpdateReducer from "../files/addVersionUpdateReducer";
import { RootState } from "../types";

export interface State extends EntityState<ConnectorModelDetail, ConnectorModelList, ConnectorFilter>, AsyncSliceState {
    tabInfo?: ConnectorTabInfo;
}

const initialState: State = { async: {} };

const adapter = createEntityAdapter("connector", entityApi, ["name"], {
    onDetailUpdated: (state: State, payload) => {
        if (payload.name && state.tabInfo?.id === payload.id) state.tabInfo.name = payload.name;
    },
});

const extraThunks = {
    loadTabInfo: createAppAsyncThunk(
        "loadTabInfo",
        "exclusive",
        (arg: { connectorId: number }, thunkApi) => connectorsApi.loadTabInfo(arg.connectorId, thunkApi.signal),
        (arg) => arg.connectorId.toString(),
    ),
};

const slice = createSlice({
    name: "connectors",
    initialState,
    reducers: adapter.getReducers(),
    extraReducers: (builder) => {
        adapter.addExtraReducers(builder);
        addVersionUpdateReducer(builder, connectorsApi.files);
        addAsyncThunkReducers(builder, extraThunks.loadTabInfo, {
            fulfilled: (state, action) => {
                state.tabInfo = action.payload;
            },
        });
    },
});

export const connectorThunks = anchoreThunks((state: RootState) => state.connectors, {
    ...extraThunks,
    ...adapter.thunks,
});

export const actions = slice.actions;
export const { updateDetail, clearList, removeListItems, updateListItems, setPage } = slice.actions;
export default slice.reducer;
