import { createAsyncFormAutocompleteWithFilter } from "@magicware/forms/mui/FormAutocomplete";
import { libraryVersionThunks } from "../../redux/libraryVersions/libraryVersionsReducer";
import { RootState } from "../../redux/types";
import { LibraryVersionSelectOption } from "../../services/api/libraryVersions";
import { createDefaultProps } from "../forms/formAutocomplete";

export const FormLibraryVersionsAutocomplete = createAsyncFormAutocompleteWithFilter(
    {
        selectOptions: (state: RootState) => state.libraryVersions.list,
        createAsyncAction: libraryVersionThunks.loadList,
        selectAsyncState: libraryVersionThunks.loadList.selectState,
    },

    createDefaultProps<LibraryVersionSelectOption>(
        (opt) => `${opt.library.name} - ${opt.name} (${opt.platform})`,
        (n, opt) => n.libraries.detail.versions.detail.url(opt.library.id, opt.id),
    ),
);
