import TitleTabPage from "@magicware/dashboard/navigation/TitleTabPage";
import { useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { connectorImplementationThunks } from "../../redux/connectorImplementations/connectorImplementationsReducer";
import { RootState } from "../../redux/types";
import { useConnectorImplementationId } from "../../navigation/libraries";

export default function ConnectorImplementationTabPage() {
    const connectorImplementationId = useConnectorImplementationId();
    const api = useAutoDispatchAsyncThunk(connectorImplementationThunks.load, connectorImplementationId);
    return (
        <TitleTabPage
            apiStatus={api.apiStatus}
            titleSelector={(state: RootState) => {
                const obj = state.connectorImplementations.detail;
                if (!obj) return "";
                return `${obj.libraryVersion.library.name} ${obj.libraryVersion.name} - ${obj.connectorVersion.connector.name}`;
            }}
        />
    );
}
