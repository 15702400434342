import { createEntityAdapter, EntityState } from "@magicware/redux/entities";
import { anchoreThunks, AsyncSliceState } from "@magicware/redux/thunks";
import { createSlice } from "@reduxjs/toolkit";
import {
    entityApi,
    LibraryVersionModelDetail,
    LibraryVersionFilter,
    LibraryVersionModelList,
    libraryVersionsApi,
} from "../../services/api/libraryVersions";
import addVersionUpdateReducer from "../files/addVersionUpdateReducer";
import { RootState } from "../types";

export interface State
    extends EntityState<LibraryVersionModelDetail, LibraryVersionModelList, LibraryVersionFilter>,
        AsyncSliceState {}

const initialState: State = { async: {} };

const adapter = createEntityAdapter("libraryVersions", entityApi, ["name"]);

const extraThunks = {};

const slice = createSlice({
    name: "libraryVersions",
    initialState,
    reducers: adapter.getReducers(),
    extraReducers: (builder) => {
        adapter.addExtraReducers(builder);
        //addAsyncThunkReducers(builder, extraThunks.xxx);
        addVersionUpdateReducer(builder, libraryVersionsApi.files);
    },
});

export const libraryVersionThunks = anchoreThunks((state: RootState) => state.libraryVersions, {
    ...extraThunks,
    ...adapter.thunks,
});

export const actions = slice.actions;
export const { updateDetail, clearList, removeListItems, updateListItems, setPage } = slice.actions;
export default slice.reducer;
