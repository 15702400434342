import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { getSubPages, INavigatablePage, isTabPage, ITabPage, Tab, TabName } from "./utils";

export function prepareRootTab(name: TabName, icon: IconProp, component: React.ComponentType<any>): Tab {
    return {
        ord: 0,
        name,
        icon,
        component,
    };
}

export function prepareTab(ord: number, name: TabName, icon: IconProp, component: React.ComponentType<any>): Tab {
    return {
        ord,
        name,
        icon,
        component,
    };
}

export function getTabs(currentPage: INavigatablePage<any[]>) {
    const tabs: (INavigatablePage<any[]> & ITabPage)[] = [];
    const rootTab = getRootTabPage(currentPage);
    if (rootTab) {
        tabs.push(rootTab);
        const subPages = getSubPages(rootTab);
        for (let i = 0; i < subPages.length; i++) {
            const subPage = subPages[i];
            if (isTabPage(subPage)) tabs.push(subPage);
        }
        tabs.sort((a, b) => a.tab.ord - b.tab.ord);
    }
    return tabs;
}

function getRootTabPage(rootPage: INavigatablePage<any[]>): (INavigatablePage<any[]> & ITabPage) | undefined {
    if (isTabPage(rootPage) && rootPage.tab.ord === 0) return rootPage;
    else return rootPage.parent && getRootTabPage(rootPage.parent);
}
