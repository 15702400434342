import { CanelableApiParams, OnProgressFunc } from "@magicware/fetch-api/fetch";
import { addDownload, updateDownload } from "@magicware/redux/coreReducer";
import { StoreDispatch } from "@magicware/redux/types";
import { SnackbarKey, SnackbarMessage, useSnackbar } from "notistack";
import { useDispatch } from "react-redux";

export function useProgressApi<TParams extends any[], TResult>(
    apiCall: (onProgress: OnProgressFunc, ...rest: TParams) => Promise<TResult>,
    snackContent: (key: SnackbarKey, message: SnackbarMessage, cancel?: () => void) => React.ReactNode,
) {
    const dispatch = useDispatch<StoreDispatch>();
    const snack = useSnackbar();
    const invokeDownload = async (
        downloadName: string,
        ...params: CanelableApiParams<TParams>
    ): Promise<TResult | undefined> => {
        const abortController = new AbortController();
        const snackKey = snack.enqueueSnackbar(undefined, {
            persist: true,
            content: (key, message) => snackContent(key, message, abortController.abort),
        });
        try {
            dispatch(
                addDownload({
                    downloadKey: snackKey,
                    name: downloadName,
                    status: "pending",
                }),
            );
            const result = await apiCall((percent) => {
                dispatch(updateDownload({ downloadKey: snackKey, percent }));
            }, ...([...params, abortController.signal] as TParams));
            dispatch(updateDownload({ downloadKey: snackKey, status: "success" }));
            snack.closeSnackbar(snackKey);
            return result;
        } catch {
            dispatch(updateDownload({ downloadKey: snackKey, status: "failed" }));
        }
    };

    return invokeDownload;
}
