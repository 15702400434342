import { SearchFormPanel } from "@magicware/dashboard/Panel";
import DataList from "@magicware/data-lists/DataList";
import { FormStateApiContextProvider } from "@magicware/forms/components/FormStateApiContext";
import { useTranslation } from "react-i18next";
import { parametersScopeThunks, setPage } from "../../redux/parametersScopes/parametersScopesReducer";
import { RootState } from "../../redux/types";
import EntityListItemMenuContext from "../entities/EntityListItemMenuContext";
import ParametersScopeListItem from "./ParametersScopeListItem";
import ParametersScopeListMenuContext from "./ParametersScopeListMenuContext";
import ParametersScopeSearchForm from "./ParametersScopeSearchForm";

export default function ParametersScopeListPage() {
    const { t } = useTranslation();
    return (
        <FormStateApiContextProvider>
            <SearchFormPanel>
                <ParametersScopeSearchForm />
            </SearchFormPanel>
            <ParametersScopeListMenuContext>
                <EntityListItemMenuContext
                    deleteSnackTitle={t("parameterScope-deletion")}
                    deleteThunk={parametersScopeThunks.delete}
                >
                    <DataList
                        dataSelector={(state: RootState) => state.parametersScopes.list}
                        setPageActionCreator={setPage}
                        emptyList={t("no-parameter-scopes-message")}
                    >
                        {(id) => <ParametersScopeListItem parametersScopeId={id} />}
                    </DataList>
                </EntityListItemMenuContext>
            </ParametersScopeListMenuContext>
        </FormStateApiContextProvider>
    );
}
