import { TwStyle } from "twin.macro";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { createContext, useContext, useRef, useState } from "react";

export interface MenuAction {
    icon?: IconProp;
    iconTw?: TwStyle;
    customIcon?: React.ReactNode;
    disabled?: boolean;
    name: string;
    isEmptyListAction?: boolean;
    onClick: (trigger: HTMLElement) => void;
    getText?: (el: HTMLElement | null) => string;
    getDisabled?: (trigger: HTMLElement) => boolean;
    getVisible?: (trigger: HTMLElement) => boolean;
}

interface DataListMenuContextDef {
    setAnchorEl: (el: null | HTMLElement) => void;
    anchorEl: null | HTMLElement;
}
interface DataListMenuTriggerContextDef {
    handleOpenMenu: (event: React.SyntheticEvent<HTMLElement>) => void;
}

function createMenuContexts() {
    return {
        menuContext: createContext<DataListMenuContextDef | undefined>(undefined),
        menuTriggerContext: createContext<DataListMenuTriggerContextDef | undefined>(undefined),
    };
}

const contexts: Record<string, undefined | ReturnType<typeof createMenuContexts>> = {};

function getMenuContexts(menuName = "default") {
    let result = contexts[menuName];
    if (!result) contexts[menuName] = result = createMenuContexts();
    return result;
}

export function DataListContextMenuProvider(props: { menuName?: string; children?: React.ReactNode }) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const triggerContextRef = useRef<DataListMenuTriggerContextDef | undefined>(undefined);
    if (!triggerContextRef.current) triggerContextRef.current = { handleOpenMenu: (e) => setAnchorEl(e.currentTarget) };
    const menuContexts = getMenuContexts(props.menuName);
    return (
        <menuContexts.menuContext.Provider value={{ anchorEl, setAnchorEl }}>
            <menuContexts.menuTriggerContext.Provider value={triggerContextRef.current}>
                {props.children}
            </menuContexts.menuTriggerContext.Provider>
        </menuContexts.menuContext.Provider>
    );
}

export function useOpenContextMenu(menuName?: string) {
    const menuContext = getMenuContexts(menuName);
    return useContext(menuContext.menuTriggerContext)?.handleOpenMenu;
}

export interface DataItemContextMenuProps {
    actions: MenuAction[] | (MenuAction | undefined)[];
    menuName?: string;
}

export function DataListContextMenu(props: DataItemContextMenuProps) {
    const menuContexts = getMenuContexts(props.menuName);
    const menuContext = useContext(menuContexts.menuContext);
    if (!menuContext) throw new Error("Missing DataListMenuContextProvider");
    return (
        <Menu
            anchorEl={menuContext.anchorEl}
            open={Boolean(menuContext.anchorEl)}
            onClose={() => menuContext.setAnchorEl(null)}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
        >
            {props.actions.map((action) => {
                if (!action) return null;
                return (
                    <MenuActionItem
                        key={action.name}
                        action={action}
                        anchorEl={menuContext.anchorEl}
                        setAnchorEl={menuContext.setAnchorEl}
                    />
                );
            })}
        </Menu>
    );
}

export interface MenuActionItemProps {
    action: MenuAction;
    anchorEl: HTMLElement | null;
    setAnchorEl: (el: HTMLElement | null) => void;
}

export function MenuActionItem({ action, anchorEl, setAnchorEl, ...props }: MenuActionItemProps) {
    const visibilityRef = useRef<{ visible?: boolean; disabled?: boolean; text?: string; anchorEl: typeof anchorEl }>({
        anchorEl,
    });
    if (anchorEl) {
        visibilityRef.current.disabled = action?.getDisabled && action.getDisabled(anchorEl);
        visibilityRef.current.visible = action?.getVisible && action.getVisible(anchorEl);
        visibilityRef.current.text = action?.getText && action.getText(anchorEl);
    }
    if (visibilityRef.current.visible === false) return null;
    return (
        <MenuItem
            disabled={visibilityRef.current.disabled}
            {...props}
            onClick={(e) => {
                action.onClick(anchorEl ?? e.currentTarget);
                setAnchorEl(null);
            }}
        >
            <ListItemIcon>
                {action.icon ? (
                    <FontAwesomeIcon tw="text-primary-500" icon={action.icon} style={action.iconTw} />
                ) : (
                    action.customIcon
                )}
            </ListItemIcon>
            <ListItemText>
                {anchorEl && action.getText ? action.getText(anchorEl) : visibilityRef.current.text ?? action.name}
            </ListItemText>
        </MenuItem>
    );
}
