import DataIcon from "@magicware/data-lists/DataIcon";
import DataListItem, { MainText, SecondaryText } from "@magicware/data-lists/DataListItem";
import { StyledComponentProps } from "@mui/material";
import { brandIcon } from "../../navigation/brands";
import { useNavigationClick } from "../../navigation/hooks";
import { useAppSelector } from "../../redux/hooks";

export interface ParametersScopeListItemProps extends StyledComponentProps {
    parametersScopeId: number;
}

export default function ParametersScopeListItem({ parametersScopeId, ...props }: ParametersScopeListItemProps) {
    const obj = useAppSelector((state) => state.parametersScopes.list?.byId[parametersScopeId]);
    const editClickFactory = useNavigationClick();
    if (!obj) return null;
    return (
        <DataListItem
            {...props}
            data-id={obj.id}
            onClick={editClickFactory((n) => n.parametersScopes.detail.url(obj.id))}
        >
            <div tw="flex items-center mb-2">
                <MainText tw="mr-10">{obj.name}</MainText>
                <SecondaryText tw="mr-10">{obj.type}</SecondaryText>
            </div>
            <SecondaryText>
                <DataIcon icon={brandIcon} />
                {obj.brand}
            </SecondaryText>
        </DataListItem>
    );
}
