import tw, { GlobalStyles as BaseStyles } from "twin.macro";
import { Global, css } from "@emotion/react";
import { Fragment } from "react";

const GlobalStyles = () => (
    <Fragment>
        <BaseStyles />
        <Global
            styles={css`
                html {
                    scrollbar-gutter: stable;
                }
                body {
                    ${tw`antialiased min-h-screen bg-white md:bg-body`}
                }
            `}
        />
    </Fragment>
);

export default GlobalStyles;
