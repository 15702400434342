import { buildSearchForm } from "@magicware/forms/builder/searchForm";
import { SearchForm } from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import FormTextField from "@magicware/forms/mui/FormTextField";
import { useTranslation } from "react-i18next";
import { deviceThunks } from "../../redux/devices/devicesReducer";
import { RootState } from "../../redux/types";
import { FormBrandsAutocomplete } from "../brands/FormBrandsAutocomplete";
import { FormParametersAutocomplete } from "../parameters/FormParametersAutocomplete";

const { f, useSearchForm } = buildSearchForm(
    deviceThunks.loadList,
    (state: RootState) => state.devices.list?.filter,
    () => ({}),
);

export default function DeviceSearchForm() {
    const { t } = useTranslation();
    const searchForm = useSearchForm();
    return (
        <SearchForm {...searchForm}>
            <FormGrid tw="md:grid-cols-3">
                <FormTextField f={f} field="name" label={t("name-label")} searchIcon />
                <FormBrandsAutocomplete
                    f={f}
                    field="brandId"
                    label={t("brand-label")}
                    searchIcon
                    multiple
                    filter={{}}
                />
                <FormParametersAutocomplete
                    f={f}
                    field="parameterId"
                    label={t("parameters-label")}
                    searchIcon
                    multiple
                    filter={{}}
                />
            </FormGrid>
        </SearchForm>
    );
}
