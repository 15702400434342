import Panel from "@magicware/dashboard/Panel";
import { buildForm } from "@magicware/forms/builder";
import Form, { FormImplementationProps } from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import FormTextField from "@magicware/forms/mui/FormTextField";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { updateDetail } from "../../redux/parametersScopes/parametersScopesReducer";
import { ParametersScopeModelDetail } from "../../services/api/parametersScopes";
import { FormBrandsAutocomplete } from "../brands/FormBrandsAutocomplete";
import { FormParametersAutocomplete } from "../parameters/FormParametersAutocomplete";

export const parametersScopeForm = buildForm<ParametersScopeModelDetail>()(() => ({
    props: {
        remove: ["id", "version"],
        extra: [],
    },
    transformations: {
        toForm: { fromModel: (h, m: ParametersScopeModelDetail) => h.fromModel(m) },
        formTo: {
            new: (h, f) => h.toNewEntity(f),
            update: (h, f, m: ParametersScopeModelDetail) => h.toUpdateEntity(f, m),
        },
    },
    updateReduxModel: updateDetail,
    schema: (_, t) => ({
        name: yup.string().required(t("name-is-required")),
        type: yup.string().required(t("type-is-required")),
        brand: yup.object(),
        hasPaidCertification: yup.boolean().default(false),
        parameterList: yup.array().default([]).min(1, t("at-least-one-parameter-is-required")),
        notes: yup.string(),
    }),
}));

const f = parametersScopeForm.f;

export default function ParametersScopeForm(props: FormImplementationProps<typeof f>) {
    const { t } = useTranslation();
    return (
        <Form validationSchema={parametersScopeForm.validationSchema} {...props}>
            <Panel>
                <FormGrid>
                    <FormTextField f={f} field="name" label={t("name-label")} autoFocus={props.autoFocus} />
                    <FormBrandsAutocomplete f={f} field="brand" fullOption label={t("brand-label")} filter={{}} />
                    <FormTextField f={f} field="type" label={t("type-label")} />
                    <FormParametersAutocomplete
                        f={f}
                        field="parameterList"
                        fullOption
                        multiple
                        label={t("parameters-label")}
                        filter={{}}
                    />
                    <FormTextField f={f} field="notes" label={t("notes-label")} multiline />
                </FormGrid>
            </Panel>
        </Form>
    );
}
