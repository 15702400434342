import { faPlus } from "@fortawesome/free-solid-svg-icons";
import MoreVertIcon from "@mui/icons-material/MoreHorizOutlined";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { createContext, Fragment, useContext, useState } from "react";
import tw from "twin.macro";
import { MenuAction, MenuActionItem } from "./DataListContextMenu";

export interface DataListBulkActionsContextProps {
    defaultAction?: MenuAction;
    actions: MenuAction[];
    children?: React.ReactNode;
}

interface ContextModel {
    defaultAction?: MenuAction;
    actions: MenuAction[];
}

const Context = createContext<ContextModel | undefined>(undefined);

export function getNewItemMenuAction(name: string, action: () => void, isAllowed = true): MenuAction | undefined {
    if (!isAllowed) return undefined;
    return {
        name,
        icon: faPlus,
        isEmptyListAction: true,
        onClick: action,
    };
}

export default function DataListBulkActionsContext({
    defaultAction,
    actions,
    children,
}: DataListBulkActionsContextProps) {
    if (!defaultAction && actions.length === 0) return <Fragment>{children}</Fragment>;
    return <Context.Provider value={{ defaultAction, actions }}>{children}</Context.Provider>;
}

export function DataListEmptyListBulkActions() {
    const context = useContext(Context);
    if (!context) return null;
    const actions: MenuAction[] = [];
    if (context.defaultAction?.isEmptyListAction) actions.push(context.defaultAction);
    actions.push(...context.actions.filter((a) => !!a.isEmptyListAction));
    if (actions.length === 0) return null;
    return (
        <div>
            {actions.map((a) => {
                return (
                    <Button
                        key={a.name}
                        tw="mr-3 [text-transform:none]"
                        variant="text"
                        onClick={(e) => a.onClick(e.currentTarget)}
                    >
                        {a.getText ? a.getText(null) : a.name}
                    </Button>
                );
            })}
        </div>
    );
}

export function DataListHeaderBulkActions() {
    const context = useContext(Context);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    if (!context) return null;
    const { defaultAction, actions } = context;
    const isDefaultActionOnly = !!defaultAction && actions.length === 0;
    return (
        <div>
            {defaultAction && (
                <Button
                    tw="hidden md:inline mr-3 [text-transform:none]"
                    variant="text"
                    onClick={(e) => defaultAction.onClick(e.currentTarget)}
                >
                    {defaultAction.name}
                </Button>
            )}
            {(!!defaultAction || actions.length > 0) && (
                <Fragment>
                    <IconButton
                        css={[isDefaultActionOnly ? tw`md:hidden` : undefined]}
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={!!anchorEl}
                        onClose={() => setAnchorEl(null)}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                    >
                        {defaultAction && (
                            <MenuActionItem
                                tw="md:hidden"
                                action={defaultAction}
                                anchorEl={anchorEl}
                                setAnchorEl={setAnchorEl}
                            />
                        )}
                        {actions.map((action) => (
                            <MenuActionItem
                                key={action.name}
                                action={action}
                                anchorEl={anchorEl}
                                setAnchorEl={setAnchorEl}
                            />
                        ))}
                    </Menu>
                </Fragment>
            )}
        </div>
    );
}
