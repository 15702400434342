import TitleTabPage from "@magicware/dashboard/navigation/TitleTabPage";
import { useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { libraryVersionThunks } from "../../redux/libraryVersions/libraryVersionsReducer";
import { RootState } from "../../redux/types";
import { useLibraryVersionId } from "../../navigation/libraries";

export default function LibraryVersionTabPage() {
    const libraryVersionId = useLibraryVersionId();
    const api = useAutoDispatchAsyncThunk(libraryVersionThunks.load, libraryVersionId);
    return (
        <TitleTabPage
            apiStatus={api.apiStatus}
            titleSelector={(state: RootState) =>
                `${state.libraryVersions.detail?.library} - ${state.libraryVersions.detail?.name}`
            }
        />
    );
}
