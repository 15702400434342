import Panel from "@magicware/dashboard/Panel";
import { buildForm } from "@magicware/forms/builder";
import Form, { FormImplementationProps } from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import FormTextField from "@magicware/forms/mui/FormTextField";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { updateDetail } from "../../redux/parameters/parametersReducer";
import { ParameterModelDetail } from "../../services/api/parameters";

export const parameterForm = buildForm<ParameterModelDetail>()(() => ({
    props: {
        remove: ["id", "version"],
        extra: [],
    },
    transformations: {
        toForm: { fromModel: (h, m: ParameterModelDetail) => h.fromModel(m) },
        formTo: {
            new: (h, f) => h.toNewEntity(f),
            update: (h, f, m: ParameterModelDetail) => h.toUpdateEntity(f, m),
        },
    },
    updateReduxModel: updateDetail,
    schema: (_, t) => ({
        name: yup.string().required(t("name-is-required")),
        type: yup.string(),
        unit: yup.string(),
        kind: yup.string(),
        description: yup.string(),
        systemName: yup.string().max(100, t("max-length-is-100-characters")),
        notes: yup.string(),
    }),
}));

const f = parameterForm.f;

export default function ParameterForm(props: FormImplementationProps<typeof f>) {
    const { t } = useTranslation();
    return (
        <Form validationSchema={parameterForm.validationSchema} {...props}>
            <Panel>
                <FormGrid>
                    <FormTextField f={f} field="name" label={t("name-label")} autoFocus={props.autoFocus} />
                    <FormTextField f={f} field="systemName" label={t("system-name-label")} />
                    <FormTextField f={f} field="type" label={t("type-label")} />
                    <FormTextField f={f} field="kind" label={t("kind-label")} />
                    <FormTextField f={f} field="unit" label={t("unit-label")} />
                    <FormTextField f={f} field="description" label={t("description-label")} />
                    <FormTextField f={f} field="notes" label={t("notes-label")} multiline />
                </FormGrid>
            </Panel>
        </Form>
    );
}
