import AsyncContent from "@magicware/dashboard/AsyncContent";
import DataList from "@magicware/data-lists/DataList";
import { FormStateApiContextProvider } from "@magicware/forms/components/FormStateApiContext";
import { useTranslation } from "react-i18next";
import { useBaseAppId } from "../../navigation/baseApps";
import { baseAppVersionThunks, setPage } from "../../redux/baseAppVersions/baseAppVersionsReducer";
import { useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { RootState } from "../../redux/types";
import EntityListItemMenuContext from "../entities/EntityListItemMenuContext";
import BaseAppVersionListItem from "./BaseAppVersionListItem";
import BaseAppVersionListMenuContext from "./BaseAppVersionListMenuContext";

export default function BaseAppVersionListTab() {
    const baseAppId = useBaseAppId();
    const api = useAutoDispatchAsyncThunk(baseAppVersionThunks.loadList, { baseAppId });
    return <AsyncContent {...api} component={BaseAppVersionListTabLoaded} />;
}

function BaseAppVersionListTabLoaded() {
    const { t } = useTranslation();
    return (
        <FormStateApiContextProvider>
            <BaseAppVersionListMenuContext>
                <EntityListItemMenuContext
                    deleteSnackTitle={t("baseAppVersion-deletion")}
                    deleteThunk={baseAppVersionThunks.delete}
                >
                    <DataList
                        dataSelector={(state: RootState) => state.baseAppVersions.list}
                        setPageActionCreator={setPage}
                        emptyList={t("no-versions-message")}
                    >
                        {(id) => <BaseAppVersionListItem baseAppVersionId={id} />}
                    </DataList>
                </EntityListItemMenuContext>
            </BaseAppVersionListMenuContext>
        </FormStateApiContextProvider>
    );
}
