import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import { useSelectedItems, useSelection } from "./SelectionContext";
import { useDispatch, useSelector } from "react-redux";
import { StoreDispatch } from "@magicware/redux/types";

export interface CheckAllCheckboxProps extends CheckboxProps {
    selectItems: (state: unknown) => any[] | undefined;
    label?: FormControlLabelProps["label"];
}

export default function CheckAllCheckbox({ selectItems, label, ...props }: CheckAllCheckboxProps) {
    const dispatch = useDispatch<StoreDispatch>();
    const itemsCount = useSelector((state) => selectItems(state)?.length) ?? 0;
    const selection = useSelection();
    const selectedItems = useSelectedItems();

    if (itemsCount <= 1) return null;

    const checked = selectedItems.length === itemsCount;

    const checkBox = (
        <Checkbox
            {...props}
            checked={checked}
            indeterminate={selectedItems.length > 0 && selectedItems.length < itemsCount}
            onChange={(_, checked) => {
                dispatch((_, getState) => {
                    const state = getState();
                    const allItems = selectItems(state);
                    if (allItems) selection.toggle(allItems, checked);
                });
            }}
        />
    );

    if (!label) return checkBox;

    return (
        <div>
            <FormControlLabel sx={{ margin: 0 }} control={checkBox} label={label} />
        </div>
    );
}
