import { AnyAction, configureStore as reduxConfigureStore, ReducersMapObject } from "@reduxjs/toolkit";
import coreReducer from "./coreReducer";

export default function configureStore<S, A extends AnyAction>(appReducers: ReducersMapObject<S, A>) {
    const reducers = {
        ...appReducers,
        core: coreReducer,
    };
    const store = reduxConfigureStore({
        devTools: process.env.NODE_ENV !== "production",
        enhancers: [],
        reducer: reducers,
    });

    return store;
}
