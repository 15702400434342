import { parametersScopeThunks } from "../../redux/parametersScopes/parametersScopesReducer";
import { useAppDispatch, useAppSelectorEnsure, useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { parametersScopesApi } from "../../services/api/parametersScopes";
import ParametersScopeForm, { parametersScopeForm } from "./ParametersScopeForm";
import SimplePage from "@magicware/dashboard/navigation/SimplePage";
import AsyncContent from "@magicware/dashboard/AsyncContent";
import { useParametersScopeId } from "../../navigation/parametersScopes";
import { useTranslation } from "react-i18next";

const { useToFormFromModel } = parametersScopeForm.hooks;

export default function ParametersScopeDetailPage() {
    const { t } = useTranslation();
    const api = useAutoDispatchAsyncThunk(parametersScopeThunks.load, useParametersScopeId());
    return (
        <SimplePage title={t("scope-detail-title")}>
            <AsyncContent {...api} component={ParametersScopeDetailPageLoaded} />
        </SimplePage>
    );
}

function ParametersScopeDetailPageLoaded() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const parametersScopeDetail = useAppSelectorEnsure((state) => state.parametersScopes.detail);
    const initialValues = useToFormFromModel(parametersScopeDetail);
    const onSubmit = async (form: typeof parametersScopeForm.f) => {
        const result = await parametersScopesApi.forms.update(
            parametersScopeForm.transform.formTo.update(form, parametersScopeDetail),
        );
        return parametersScopeForm.apiUtils.handleUpdateEntityResult(dispatch, result, form);
    };
    return (
        <ParametersScopeForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            savePanel={{ successMessage: t("parametersscope-has-been-saved") }}
        />
    );
}
