import { faCodeBranch, faGears } from "@fortawesome/free-solid-svg-icons";
import { prepareBreadcrumb } from "@magicware/dashboard/navigation/breadcrumbs";
import { CreatePatternMethod, CreateSimpleMethod } from "@magicware/dashboard/navigation/builder";
import { prepareRootTab, prepareTab } from "@magicware/dashboard/navigation/tabs";
import { createUseIntParam } from "@magicware/dashboard/navigation/useIntParam";
import ConfigurationTabPage from "../components/configuration/ConfigurationTabPage";
import ConnectorImplementationDetailTab from "../components/connectorImplementations/ConnectorImplementationDetailTab";
import ConnectorImplementationListTab from "../components/connectorImplementations/ConnectorImplementationListTab";
import ConnectorImplementationNewTab from "../components/connectorImplementations/ConnectorImplementationNewTab";
import ConnectorImplementationTabPage from "../components/connectorImplementations/ConnectorImplementationTabPage";
import LibraryDetailTab from "../components/libraries/LibraryDetailTab";
import LibraryNewPage from "../components/libraries/LibraryNewPage";
import LibraryTabPage from "../components/libraries/LibraryTabPage";
import LibraryVersionDetailTab from "../components/libraryVersions/LibraryVersionDetailTab";
import LibraryVersionFilesTabPage from "../components/libraryVersions/LibraryVersionFilesTabPage";
import LibraryVersionListTab from "../components/libraryVersions/LibraryVersionListTab";
import LibraryVersionNewTab from "../components/libraryVersions/LibraryVersionNewTab";
import LibraryVersionTabPage from "../components/libraryVersions/LibraryVersionTabPage";
import { connectorIcon } from "./connectors";
import { filesIcon } from "./files";

export const useLibraryId = createUseIntParam("libraryId");
export const useLibraryVersionId = createUseIntParam("libraryVersionId");
export const useConnectorImplementationId = createUseIntParam("connectorImplementationId");

export const libraryIcon = faGears;
export const libraryVersionIcon = faCodeBranch;
export const connectorImplementationIcon = connectorIcon;

export const buildLibraries = (simple: CreateSimpleMethod, pattern: CreatePatternMethod) => {
    let tabIndex = 1;
    return simple({
        url: "/libraries",
        title: (t) => t("libraries-title"),
        component: ConfigurationTabPage,
        breadcrumb: prepareBreadcrumb((t) => t("libraries-breadcrumb")),

        new: simple({
            url: "/libraries/new",
            component: LibraryNewPage,
            breadcrumb: prepareBreadcrumb((t) => t("new-library-breadcrumb")),
        }),
        detail: pattern({
            pattern: "/libraries/:libraryId",
            component: LibraryTabPage,
            url: (libraryId: number) => `/libraries/${libraryId}`,
            tab: prepareRootTab((t) => t("library-detail-tab"), libraryIcon, LibraryDetailTab),
            breadcrumb: prepareBreadcrumb((t) => t("library-detail-breadcrumb")),

            versions: pattern({
                pattern: "/libraries/:libraryId/versions",
                component: LibraryTabPage,
                url: (libraryId: number) => `/libraries/${libraryId}/versions`,
                breadcrumb: prepareBreadcrumb((t) => t("libraryVersions-breadcrumb")),
                tab: prepareTab(tabIndex++, (t) => t("libraryVersions-tab"), libraryVersionIcon, LibraryVersionListTab),

                new: pattern({
                    pattern: "/libraries/:libraryId/versions/new",
                    url: (libraryId: number) => `/libraries/${libraryId}/versions/new`,
                    component: LibraryTabPage,
                    breadcrumb: prepareBreadcrumb((t) => t("new-libraryVersion-breadcrumb")),
                    tab: prepareRootTab((t) => t("new-libraryVersion-title"), libraryVersionIcon, LibraryVersionNewTab),
                }),
                detail: pattern({
                    pattern: "/libraries/:libraryId/versions/:libraryVersionId",
                    component: LibraryVersionTabPage,
                    url: (libraryId: number, libraryVersionId: number) =>
                        `/libraries/${libraryId}/versions/${libraryVersionId}`,
                    breadcrumb: prepareBreadcrumb((t) => t("libraryVersion-detail-breadcrumb")),
                    tab: prepareRootTab(
                        (t) => t("libraryVersion-detail-title"),
                        libraryVersionIcon,
                        LibraryVersionDetailTab,
                    ),
                    
                    files: pattern({
                        pattern: "/libraries/:libraryId/versions/:libraryVersionId/files",
                        component: LibraryVersionTabPage,
                        url: (libraryId: number, libraryVersionId: number) =>
                            `/libraries/${libraryId}/versions/${libraryVersionId}/files`,
                        breadcrumb: prepareBreadcrumb((t) => t("files-breadcrumb")),
                        tab: prepareTab(tabIndex++, (t) => t("files-tab"), filesIcon, LibraryVersionFilesTabPage),
                    }),

                    connectors: pattern({
                        pattern: "/libraries/:libraryId/versions/:libraryVersionId/connectors",
                        component: LibraryVersionTabPage,
                        url: (libraryId: number, libraryVersionId: number) =>
                            `/libraries/${libraryId}/versions/${libraryVersionId}/connectors`,
                        breadcrumb: prepareBreadcrumb((t) => t("connectorImplementations-breadcrumb")),
                        tab: prepareTab(
                            tabIndex++,
                            (t) => t("connectorImplementations-tab"),
                            connectorImplementationIcon,
                            ConnectorImplementationListTab,
                        ),

                        new: pattern({
                            pattern: "/libraries/:libraryId/versions/:libraryVersionId/connectors/new",
                            url: (libraryId: number, libraryVersionId: number) =>
                                `/libraries/${libraryId}/versions/${libraryVersionId}/connectors/new`,
                            component: LibraryVersionTabPage,
                            breadcrumb: prepareBreadcrumb((t) => t("new-connectorImplementation-breadcrumb")),
                            tab: prepareRootTab(
                                (t) => t("new-connectorImplementation-title"),
                                connectorImplementationIcon,
                                ConnectorImplementationNewTab,
                            ),
                        }),
                        detail: pattern({
                            pattern:
                                "/libraries/:libraryId/versions/:libraryVersionId/connectors/:connectorImplementationId",
                            component: ConnectorImplementationTabPage,
                            url: (libraryId: number, libraryVersionId: number, connectorImplementationId: number) =>
                                `/libraries/${libraryId}/versions/${libraryVersionId}/connectors/${connectorImplementationId}`,
                            breadcrumb: prepareBreadcrumb((t) => t("connectorImplementation-detail-breadcrumb")),
                            tab: prepareRootTab(
                                (t) => t("connectorImplementation-detail-title"),
                                connectorImplementationIcon,
                                ConnectorImplementationDetailTab,
                            ),
                        }),
                    }),
                }),
            }),
        }),
    });
};
