import { useEffect, useMemo, useRef, useState } from "react";

/** useState hook, který zabraňuje reusování stavu při znovupoužití komponenty při remountu komponenty. */
export default function useNonReusableState<S>(initialState: S | (() => S)) {
    const [state, setState] = useState(initialState);
    const initState = useMemo(() => state, []);

    const isReusedRef = useRef(false);

    useEffect(() => {
        if (isReusedRef.current) {
            isReusedRef.current = false;
            setState(initState);
        }
        return () => {
            isReusedRef.current = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return [isReusedRef.current ? initState : state, setState] as const;
}
