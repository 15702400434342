import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { DereferedTranslation } from "./GenericTranslateFunc";

export interface DereferedTranslatedTextProps {
    text: DereferedTranslation;
}

export default function DereferedTranslatedText({ text }: DereferedTranslatedTextProps) {
    const { t } = useTranslation();
    return <Fragment>{text(t)}</Fragment>;
}
