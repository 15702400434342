import { libraryThunks } from "../../redux/libraries/librariesReducer";
import { useAppDispatch, useAppSelectorEnsure, useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { librariesApi } from "../../services/api/libraries";
import LibraryForm, { libraryForm } from "./LibraryForm";
import AsyncContent from "@magicware/dashboard/AsyncContent";
import { useTranslation } from "react-i18next";
import { useLibraryId } from "../../navigation/libraries";

const { useToFormFromModel } = libraryForm.hooks;

export default function LibraryDetailTab() {
    const api = useAutoDispatchAsyncThunk(libraryThunks.load, useLibraryId());
    return <AsyncContent {...api} component={LibraryDetailTabLoaded} />;
}

function LibraryDetailTabLoaded() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const libraryDetail = useAppSelectorEnsure((state) => state.libraries.detail);
    const initialValues = useToFormFromModel(libraryDetail);
    const onSubmit = async (form: typeof libraryForm.f) => {
        const result = await librariesApi.forms.update(libraryForm.transform.formTo.update(form, libraryDetail));
        return libraryForm.apiUtils.handleUpdateEntityResult(dispatch, result, form);
    };
    return (
        <LibraryForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            savePanel={{ successMessage: t("library-has-been-saved") }}
        />
    );
}
