import { IdVersion } from "@magicware/fetch-api/types";
import { EntityState } from "@magicware/redux/entities";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { FilesApi } from "../../services/api/files";
import { fileThunks } from "./filesReducer";

export default function addVersionUpdateReducer<TEntity extends IdVersion>(
    builder: ActionReducerMapBuilder<EntityState<TEntity, any, any>>,
    api: FilesApi,
) {
    builder.addCase(fileThunks.newFile.fulfilled, (state, action) => {
        if (
            action.meta.arg.api.url === api.url &&
            state.detail?.id === action.meta.arg.file.ownerId &&
            state.detail.version === action.payload.previousOwnerVersion
        ) {
            state.detail.version = action.payload.ownerVersion;
        }
    });
}
