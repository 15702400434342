import SimplePage from "@magicware/dashboard/navigation/SimplePage";
import { SearchFormPanel } from "@magicware/dashboard/Panel";
import DataList from "@magicware/data-lists/DataList";
import { FormStateApiContextProvider } from "@magicware/forms/components/FormStateApiContext";
import { useTranslation } from "react-i18next";
import { deviceThunks, setPage } from "../../redux/devices/devicesReducer";
import { RootState } from "../../redux/types";
import EntityListItemMenuContext from "../entities/EntityListItemMenuContext";
import DeviceListItem from "./DeviceListItem";
import DeviceListMenuContext from "./DeviceListMenuContext";
import DeviceSearchForm from "./DeviceSearchForm";

export default function DeviceListPage() {
    const { t } = useTranslation();
    return (
        <SimplePage title={t("devices-title")}>
            <FormStateApiContextProvider>
                <SearchFormPanel>
                    <DeviceSearchForm />
                </SearchFormPanel>
                <DeviceListMenuContext>
                    <EntityListItemMenuContext
                        deleteSnackTitle={t("device-deletion")}
                        deleteThunk={deviceThunks.delete}
                    >
                        <DataList
                            dataSelector={(state: RootState) => state.devices.list}
                            setPageActionCreator={setPage}
                            emptyList={t("no-devices-message")}
                        >
                            {(id) => <DeviceListItem deviceId={id} />}
                        </DataList>
                    </EntityListItemMenuContext>
                </DeviceListMenuContext>
            </FormStateApiContextProvider>
        </SimplePage>
    );
}
