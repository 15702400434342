import DataIcon from "@magicware/data-lists/DataIcon";
import DataListItem, { MainText } from "@magicware/data-lists/DataListItem";
import { StyledComponentProps } from "@mui/material";
import { deviceIcon } from "../../navigation/devices";
import { useNavigationClick } from "../../navigation/hooks";
import { parametersIcon } from "../../navigation/parameters";
import { projectSetupIcon } from "../../navigation/projectSetups";
import { useAppSelector } from "../../redux/hooks";

export interface ExperienceListItemProps extends StyledComponentProps {
    experienceId: number;
}

export default function ExperienceListItem({ experienceId, ...props }: ExperienceListItemProps) {
    const obj = useAppSelector((state) => state.experiences.list?.byId[experienceId]);
    const editClickFactory = useNavigationClick();
    if (!obj) return null;
    return (
        <DataListItem {...props} data-id={obj.id} onClick={editClickFactory((n) => n.experiences.detail.url(obj.id))}>
            {obj.device && (
                <MainText tw="w-full">
                    <DataIcon icon={deviceIcon} /> {obj.device.name}
                </MainText>
            )}
            {obj.parameter && (
                <MainText tw="w-full">
                    <DataIcon icon={parametersIcon} /> {obj.parameter.name}
                </MainText>
            )}
            {obj.projectSetup && (
                <MainText tw="w-full">
                    <DataIcon icon={projectSetupIcon} /> {obj.projectSetup.name}
                </MainText>
            )}
        </DataListItem>
    );
}
