import DataListBulkActionsContext from "@magicware/data-lists/DataListBulkActionsContext";
import { MenuAction } from "@magicware/data-lists/DataListContextMenu";
import { useTranslation } from "react-i18next";
import { useNewItemMenuAction } from "../../navigation/hooks";

export default function ConnectorListMenuContext(props: { children?: React.ReactNode }) {
    const { t } = useTranslation();
    const actions: MenuAction[] = [];
    const addAction = useNewItemMenuAction(t("add-connector"), (n) => n.connectors.new.url());
    return (
        <DataListBulkActionsContext defaultAction={addAction} actions={actions}>
            {props.children}
        </DataListBulkActionsContext>
    );
}
