import AsyncContent from "@magicware/dashboard/AsyncContent";
import DataList from "@magicware/data-lists/DataList";
import { FormStateApiContextProvider } from "@magicware/forms/components/FormStateApiContext";
import { useTranslation } from "react-i18next";
import { useLibraryId } from "../../navigation/libraries";
import { useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { libraryVersionThunks, setPage } from "../../redux/libraryVersions/libraryVersionsReducer";
import { RootState } from "../../redux/types";
import EntityListItemMenuContext from "../entities/EntityListItemMenuContext";
import LibraryVersionListItem from "./LibraryVersionListItem";
import LibraryVersionListMenuContext from "./LibraryVersionListMenuContext";

export default function LibraryVersionListTab() {
    const libraryId = useLibraryId();
    const api = useAutoDispatchAsyncThunk(libraryVersionThunks.loadList, { libraryId });
    return <AsyncContent {...api} component={LibraryVersionListTabLoaded} />;
}

function LibraryVersionListTabLoaded() {
    const { t } = useTranslation();
    return (
        <FormStateApiContextProvider>
            <LibraryVersionListMenuContext>
                <EntityListItemMenuContext
                    deleteSnackTitle={t("library-version-deletion")}
                    deleteThunk={libraryVersionThunks.delete}
                >
                    <DataList
                        dataSelector={(state: RootState) => state.libraryVersions.list}
                        setPageActionCreator={setPage}
                        emptyList={t("no-libraryVersion-message")}
                    >
                        {(id) => <LibraryVersionListItem libraryVersionId={id} />}
                    </DataList>
                </EntityListItemMenuContext>
            </LibraryVersionListMenuContext>
        </FormStateApiContextProvider>
    );
}
