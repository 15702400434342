import SimplePage from "@magicware/dashboard/navigation/SimplePage";
import useNewEntityOnSubmit from "@magicware/forms/hooks/useNewEntityOnSubmit";
import { useTranslation } from "react-i18next";
import { useNavigationClickReplace } from "../../navigation/hooks";
import { clearList } from "../../redux/brands/brandsReducer";
import { brandsApi } from "../../services/api/brands";
import BrandForm, { brandForm } from "./BrandForm";

const { useDefaults } = brandForm.hooks;

export default function BrandNewPage() {
    const { t } = useTranslation();
    const onSubmit = useNewEntityOnSubmit(
        clearList,
        brandsApi.forms.create,
        useNavigationClickReplace((n) => n.brands.url()),
        brandForm.transform.formTo.new,
    );
    const initialValues = useDefaults();
    return (
        <SimplePage title={t("new-brand-title")}>
            <BrandForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                autoFocus
                savePanel={{ buttonContent: t("create-new-brand"), disableOnPristine: true }}
            />
        </SimplePage>
    );
}
