import configureStore from "@magicware/redux/configureStore";
import { setIsOldVersion, setIsUnauthenticated } from "@magicware/redux/coreReducer";
import { initReduxErrorsInterceptor } from "@magicware/fetch-api/fetch";
import uiReducer from "./ui/uiReducer";
import parametersReducer from "./parameters/parametersReducer";
import brandsReducer from "./brands/brandsReducer";
import connectorsReducer from "./connectors/connectorsReducer";
import parametersScopesReducer from "./parametersScopes/parametersScopesReducer";
import projectSetupsReducer from "./projectSetups/projectSetupsReducer";
import devicesReducer from "./devices/devicesReducer";
import connectorVersionsReducer from "./connectorVersions/connectorVersionsReducer";
import connectorCertificationsReducer from "./connectorCertifications/connectorCertificationsReducer";
import filesReducer from "./files/filesReducer";
import codebooksReducer from "./codebooks/codebooksReducer";
import experiencesReducer from "./experiences/experiencesReducer";
import baseAppsReducer from "./baseApps/baseAppsReducer";
import appInstancesReducer from "./appInstances/appInstancesReducer";
import librariesReducer from "./libraries/librariesReducer";
import libraryVersionsReducer from "./libraryVersions/libraryVersionsReducer";
import connectorImplementationsReducer from "./connectorImplementations/connectorImplementationsReducer";
import baseAppVersionsReducer from "./baseAppVersions/baseAppVersionsReducer";
import apiServerVersionsReducer from "./apiServerVersions/apiServerVersionsReducer";
import apiServerInstancesReducer from "./apiServerInstances/apiServerInstancesReducer";
import appInstanceVersionsReducer from "./appInstanceVersions/appInstanceVersionsReducer";
import appInstanceConnectionsReducer from "./appInstanceConnections/appInstanceConnectionsReducer";

export const store = configureStore({
    ui: uiReducer,
    codebooks: codebooksReducer,
    brands: brandsReducer,
    files: filesReducer,
    devices: devicesReducer,
    parameters: parametersReducer,
    parametersScopes: parametersScopesReducer,
    projectSetups: projectSetupsReducer,
    connectors: connectorsReducer,
    connectorVersions: connectorVersionsReducer,
    connectorCertifications: connectorCertificationsReducer,
    experiences: experiencesReducer,
    baseApps: baseAppsReducer,
    libraries: librariesReducer,
    libraryVersions: libraryVersionsReducer,
    connectorImplementations: connectorImplementationsReducer,
    baseAppVersions: baseAppVersionsReducer,
    apiServerVersions: apiServerVersionsReducer,
    apiServerInstances: apiServerInstancesReducer,
    appInstances: appInstancesReducer,
    appInstanceVersions: appInstanceVersionsReducer,
    appInstanceConnections: appInstanceConnectionsReducer,
});
export default store;

initReduxErrorsInterceptor(
    () => store.dispatch(setIsOldVersion()),
    () => store.dispatch(setIsUnauthenticated()),
);
