import useNewEntityOnSubmit from "@magicware/forms/hooks/useNewEntityOnSubmit";
import { useTranslation } from "react-i18next";
import { useAppInstaceNavigation, useAppInstanceId } from "../../navigation/appInstances";
import { useNavigationClickReplace } from "../../navigation/hooks";
import { clearList } from "../../redux/appInstanceConnections/appInstanceConnectionsReducer";
import { appInstanceConnectionsApi } from "../../services/api/appInstanceConnections";
import AppInstanceConnectionForm, { appInstanceConnectionForm } from "./AppInstanceConnectionForm";

const { useDefaults } = appInstanceConnectionForm.hooks;

export default function AppInstanceConnectionNewTab() {
    const { t } = useTranslation();
    const [parentId, navigation] = useAppInstaceNavigation();

    const appInstanceId = useAppInstanceId();
    const onSubmit = useNewEntityOnSubmit(
        clearList,
        appInstanceConnectionsApi.forms.create,
        useNavigationClickReplace(() => navigation.detail.connections.url(parentId, appInstanceId)),
        (f: typeof appInstanceConnectionForm.f) => appInstanceConnectionForm.transform.formTo.new(f, appInstanceId),
    );
    const initialValues = useDefaults();
    return (
        <AppInstanceConnectionForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            autoFocus
            savePanel={{ buttonContent: t("create-new-appInstanceConnection"), disableOnPristine: true }}
        />
    );
}
