import DataIcon from "@magicware/data-lists/DataIcon";
import DataListItem, { MainText, SecondaryText } from "@magicware/data-lists/DataListItem";
import { StyledComponentProps } from "@mui/material";
import { brandIcon } from "../../navigation/brands";
import { useNavigationClick } from "../../navigation/hooks";
import { useAppSelector } from "../../redux/hooks";

export interface ConnectorListItemProps extends StyledComponentProps {
    connectorId: number;
}

export default function ConnectorListItem({ connectorId, ...props }: ConnectorListItemProps) {
    const obj = useAppSelector((state) => state.connectors.list?.byId[connectorId]);
    const editClickFactory = useNavigationClick();
    if (!obj) return null;
    return (
        <DataListItem {...props} data-id={obj.id} onClick={editClickFactory((n) => n.connectors.detail.url(obj.id))}>
            <div tw="flex items-center">
                <MainText tw="mr-10">{obj.name}</MainText>
                <SecondaryText>
                    <DataIcon icon={brandIcon} />
                    {obj.brand}
                </SecondaryText>
            </div>
        </DataListItem>
    );
}
