import { CreateApiResponse, DeleteApiResponse, UpdateApiResponse } from "./types";
import { getData, postData } from "./fetch";
import { formApi, FormApi } from "./forms";

export type EntityApi<TNew, TUpdate, TDetail, TList, TFilter, TListResult = TList[]> = {
    create: (newEntity: TNew, signal?: AbortSignal) => Promise<CreateApiResponse>;
    update: (entity: TUpdate, signal?: AbortSignal) => Promise<UpdateApiResponse>;
    delete: (ids: number[], signal?: AbortSignal) => Promise<DeleteApiResponse>;
    load: (entityId: number, signal?: AbortSignal) => Promise<TDetail>;
    loadList: (filter: TFilter, signal?: AbortSignal) => Promise<TListResult>;
    loadByIds: (ids: number[], signal?: AbortSignal) => Promise<TListResult>;
    forms: {
        create: FormApi<CreateApiResponse, [newEntity: TNew, signal?: AbortSignal]>;
        update: FormApi<UpdateApiResponse, [entity: TUpdate, signal?: AbortSignal]>;
        delete: FormApi<DeleteApiResponse, [ids: number[], signal?: AbortSignal]>;
        load: FormApi<TDetail, [entityId: number, signal?: AbortSignal]>;
        loadList: FormApi<TListResult, [filter: TFilter, signal?: AbortSignal]>;
        loadByIds: FormApi<TListResult, [ids: number[], signal?: AbortSignal]>;
    };
};

/** Vytvoří funkce create, update, load a loadList pro vytváření, editaci a výpis dané entity.
 * @param name Název entity (začátek malým) - používá se jako názvy url prametrů v get a list requestech.
 */
export function createEntityApi<TNew, TUpdate, TDetail, TList, TFilter, TListResult = TList[]>(
    name: string,
    baseUrl: string,
    options?: {
        postName?: string;
        convertFilterToParameters?: (filter: TFilter) => any;
    },
): EntityApi<TNew, TUpdate, TDetail, TList, TFilter, TListResult> {
    const { convertFilterToParameters = (f: TFilter) => f, postName = "entity" } = options ?? {};
    const create = (newEntity: TNew, signal?: AbortSignal) =>
        postData<CreateApiResponse>(`${baseUrl}/new`, { [postName]: newEntity }, { signal });
    const update = (entity: TUpdate, signal?: AbortSignal) =>
        postData<UpdateApiResponse>(`${baseUrl}/update`, { [postName]: entity }, { signal });
    const deleteMethod = (ids: number[], signal?: AbortSignal) =>
        postData<DeleteApiResponse>(`${baseUrl}/delete`, { [postName + "Ids"]: ids }, { signal });
    const load = (entityId: number, signal?: AbortSignal) =>
        getData<TDetail>(`${baseUrl}/detail`, { [name + "Id"]: entityId }, { signal });
    const loadByIds = (ids: number[], signal?: AbortSignal) =>
        getData<TListResult>(`${baseUrl}/list`, { [name + "Ids"]: ids }, { signal });
    const loadList = (filter: TFilter, signal?: AbortSignal) =>
        getData<TListResult>(`${baseUrl}/list`, convertFilterToParameters(filter), { signal });

    return {
        create,
        update,
        delete: deleteMethod,
        load,
        loadList,
        loadByIds,
        forms: {
            create: formApi(create),
            update: formApi(update),
            delete: formApi(deleteMethod),
            load: formApi(load),
            loadList: formApi(loadList),
            loadByIds: formApi(loadByIds),
        },
    };
}
