import tw from "twin.macro";
import ErrorBoundary from "./ErrorBoundry";
const MainContentDiv = tw.div`px-3 md:px-10 py-10`;
export default function MainContent(props: React.ComponentProps<typeof MainContentDiv>) {
    return (
        <MainContentDiv {...props}>
            <ErrorBoundary>{props.children}</ErrorBoundary>
        </MainContentDiv>
    );
}
