import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { useIsSelected, useSelection } from "./SelectionContext";

export interface SelectionCheckBoxProps extends CheckboxProps {
    selectionItem: any;
}

export default function SelectionCheckBox({ selectionItem, ...props }: SelectionCheckBoxProps) {
    const isSelected = useIsSelected(selectionItem);
    const selection = useSelection();
    return (
        <Checkbox {...props} checked={isSelected} onChange={(_, checked) => selection.toggle(selectionItem, checked)} />
    );
}
