import ManualTabPage from "@magicware/dashboard/navigation/ManualTabPage";
import { navigation } from "../../navigation";
import { connectorIcon } from "../../navigation/connectors";
import { parametersScopeIcon } from "../../navigation/parametersScopes";
import ParametersScopeListPage from "../parametersScopes/ParametersScopeListPage";
import ConnectorListPage from "./ConnectorListPage";

export default function ConnectorListTabPage() {
    return (
        <ManualTabPage
            tabs={[
                { page: navigation.connectors, icon: connectorIcon, component: ConnectorListPage },
                { page: navigation.parametersScopes, icon: parametersScopeIcon, component: ParametersScopeListPage },
            ]}
        />
    );
}
