import DataListItem, { MainText } from "@magicware/data-lists/DataListItem";
import { StyledComponentProps } from "@mui/material";
import { useNavigationClick } from "../../navigation/hooks";
import { useAppSelector } from "../../redux/hooks";

export interface LibraryVersionListItemProps extends StyledComponentProps {
    libraryVersionId: number;
}

export default function LibraryVersionListItem({ libraryVersionId, ...props }: LibraryVersionListItemProps) {
    const obj = useAppSelector((state) => state.libraryVersions.list?.byId[libraryVersionId]);
    const editClickFactory = useNavigationClick();
    if (!obj) return null;
    return (
        <DataListItem
            {...props}
            data-id={obj.id}
            onClick={editClickFactory((n) => n.libraries.detail.versions.detail.url(obj.library.id, obj.id))}
        >
            <MainText tw="mr-10">{obj.name}</MainText>
        </DataListItem>
    );
}
