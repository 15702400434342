import useNewEntityOnSubmit from "@magicware/forms/hooks/useNewEntityOnSubmit";
import { useTranslation } from "react-i18next";
import { useNavigationClickReplace } from "../../navigation/hooks";
import { useProjectSetupId } from "../../navigation/projectSetups";
import { useBaseAppId } from "../../navigation/baseApps";
import { clearList } from "../../redux/appInstances/appInstancesReducer";
import { appInstancesApi } from "../../services/api/appInstances";
import AppInstanceForm, { appInstanceForm } from "./AppInstanceForm";
import { useAppInstaceNavigation } from "../../navigation/appInstances";

const { useToFormFromParams } = appInstanceForm.hooks;

export default function AppInstanceNewTab() {
    const { t } = useTranslation();
    const baseAppId = useBaseAppId(false);
    const projectSetupId = useProjectSetupId(!baseAppId);
    const [parentId, navigation] = useAppInstaceNavigation();
    const onSubmit = useNewEntityOnSubmit(
        clearList,
        appInstancesApi.forms.create,
        useNavigationClickReplace((_, newId) => navigation.detail.url(parentId, newId)),
        (f: typeof appInstanceForm.f) => appInstanceForm.transform.formTo.new(f, projectSetupId, baseAppId),
    );
    const initialValues = useToFormFromParams(baseAppId);
    return (
        <AppInstanceForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            autoFocus
            savePanel={{ buttonContent: t("create-new-app"), disableOnPristine: true }}
        />
    );
}
