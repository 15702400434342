import { buildSearchForm } from "@magicware/forms/builder/searchForm";
import { SearchForm } from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import FormCustomAutocomplete from "@magicware/forms/mui/FormCustomAutocomplete";
import FormTextField from "@magicware/forms/mui/FormTextField";
import { useTranslation } from "react-i18next";
import { TranslateFunc } from "../../localization";
import { projectSetupThunks } from "../../redux/projectSetups/projectSetupsReducer";
import { RootState } from "../../redux/types";
import { ProjectSetupUseCaseFilterType } from "../../services/api/projectSetups";
import { UseCaseFilterOption } from "../../services/api/useCaseFilters";
import { FormBrandsAutocomplete } from "../brands/FormBrandsAutocomplete";

const { f, useSearchForm } = buildSearchForm(
    projectSetupThunks.loadList,
    (state: RootState) => state.projectSetups.list?.filter,
    () => ({}),
);

export default function ProjectSetupSearchForm() {
    const { t } = useTranslation();
    const searchForm = useSearchForm();
    const useCaseOptions = getUseCaseFilterOptions(t);
    return (
        <SearchForm {...searchForm}>
            <FormGrid tw="md:grid-cols-2">
                <FormTextField f={f} field="name" label={t("name-label")} searchIcon />
                <FormBrandsAutocomplete
                    f={f}
                    field="brandId"
                    label={t("brand-label")}
                    searchIcon
                    multiple
                    filter={{}}
                />
                <FormCustomAutocomplete
                    f={f}
                    multiple
                    field="useCaseFilter"
                    options={useCaseOptions}
                    getOption={(val: ProjectSetupUseCaseFilterType) => useCaseOptions.find((o) => o.type === val)}
                    getOptionValue={(opt) => opt.type}
                    getOptionLabel={(opt) => opt.label}
                    groupBy={(opt) => opt.group}
                    nonSearchable
                    label={"Filter"}
                />
            </FormGrid>
        </SearchForm>
    );
}

function getUseCaseFilterOptions(t: TranslateFunc) {
    const useCaseOptions: UseCaseFilterOption<ProjectSetupUseCaseFilterType>[] = [];

    useCaseOptions.push(
        { type: "Own", label: t("ownership-own"), group: t("ownership-group") },
        { type: "NotOwn", label: t("ownership-others"), group: t("ownership-group") },
    );

    return useCaseOptions;
}
