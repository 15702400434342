export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? "/api/v1/";
export const IMG_BASE_URL = process.env.REACT_APP_IMG_BASE_URL ?? "/images/";

// VŠECHNY TYTO KONFIGURACE SE NASTAVUJÍ V HOSTOVANÉM PROSTŘEDÍ přímo do window.appData
// V development prostředí je to public/index.html

export interface CurrentUser {
    id: number;
    name: string;
    person: {
        id: number;
        name: string;
        firstName: string;
        lastName: string;
    };
}

type ExternalConfiguration = {
    BASE_PATH: string;
    appData: {
        serverFeatures: {
            deviceCertification?: boolean;
        };
        codebooks: {
            apiServerId: number;
            fileFlags: {
                logoId: number;
            };
        };
        user?: CurrentUser;
    };
};

const externalConfig = window as any as ExternalConfiguration;

if (!externalConfig.appData) throw new Error("Missing external configuration at window.appData.");

export const {
    BASE_PATH = "/",
    appData: { serverFeatures: SERVER_FEATURES, codebooks: CODEBOOKS, user: INITIALLY_LOGGED_USER },
} = externalConfig;
