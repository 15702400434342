import TitleTabPage from "@magicware/dashboard/navigation/TitleTabPage";
import { useConnectorId } from "../../navigation/connectors";
import { useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { connectorThunks } from "../../redux/connectors/connectorsReducer";
import { RootState } from "../../redux/types";

export default function ConnectorTabPage() {
    const connectorId = useConnectorId();
    const api = useAutoDispatchAsyncThunk(connectorThunks.loadTabInfo, { connectorId });
    return (
        <TitleTabPage apiStatus={api.apiStatus} titleSelector={(state: RootState) => state.connectors.tabInfo?.name} />
    );
}
