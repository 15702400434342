import { useAppSelector } from "../../redux/hooks";

// eslint-disable-next-line no-restricted-globals
const handleReload = () => location.reload();

export default function OldVersionAlert() {
    const isOldVersion = useAppSelector((state) => state.core.isOldVersion);
    if (!isOldVersion) return null;
    return (
        <div tw="w-screen absolute [z-index:1900] h-0 flex flex-col items-center">
            <div tw="bg-red-600 text-white pt-1 pb-2 px-3 rounded-b-2xl">
                You have an old version. Please
                <span tw="mx-2 cursor-pointer underline" onClick={handleReload}>
                    reload
                </span>
                the page.
            </div>
        </div>
    );
}
