import AsyncContent from "@magicware/dashboard/AsyncContent";
import DataList from "@magicware/data-lists/DataList";
import { FormStateApiContextProvider } from "@magicware/forms/components/FormStateApiContext";
import { useTranslation } from "react-i18next";
import { useAppInstaceNavigation } from "../../navigation/appInstances";
import { useBaseAppId } from "../../navigation/baseApps";
import { useProjectSetupId } from "../../navigation/projectSetups";
import { appInstanceThunks, setPage } from "../../redux/appInstances/appInstancesReducer";
import { useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { RootState } from "../../redux/types";
import { AppInstanceFilter } from "../../services/api/appInstances";
import EntityListItemMenuContext from "../entities/EntityListItemMenuContext";
import AppInstanceListItem from "./AppInstanceListItem";
import AppInstanceListMenuContext from "./AppInstanceListMenuContext";

export default function AppInstanceListTab() {
    const baseAppId = useBaseAppId(false);
    const projectSetupId = useProjectSetupId(!baseAppId);
    const filter: AppInstanceFilter = { projectSetupId: projectSetupId, baseAppId: baseAppId };
    const api = useAutoDispatchAsyncThunk(appInstanceThunks.loadList, filter);
    return <AsyncContent {...api} component={AppInstanceListTabLoaded} />;
}

function AppInstanceListTabLoaded() {
    const { t } = useTranslation();
    const navigation = useAppInstaceNavigation();
    return (
        <FormStateApiContextProvider>
            <AppInstanceListMenuContext>
                <EntityListItemMenuContext
                    deleteSnackTitle={t("appInstance-deletion")}
                    deleteThunk={appInstanceThunks.delete}
                >
                    <DataList
                        dataSelector={(state: RootState) => state.appInstances.list}
                        setPageActionCreator={setPage}
                        emptyList={t("no-app-message")}
                    >
                        {(id) => <AppInstanceListItem appInstanceId={id} navigation={navigation} />}
                    </DataList>
                </EntityListItemMenuContext>
            </AppInstanceListMenuContext>
        </FormStateApiContextProvider>
    );
}
