import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExtendButtonBase, Tab as MuiTab, Tabs as MuiTabs, tabsClasses, TabsTypeMap, TabTypeMap } from "@mui/material";
import { styled } from "@mui/material/styles";
import tw from "twin.macro";

const SpanTabs = (props: TabsTypeMap["props"]) => <MuiTabs {...props} TabIndicatorProps={{ children: <span /> }} />;

export const TabsContainer = tw.div`bg-tabs pb-3 md:px-5`;

export const Tabs = styled(SpanTabs)({
    [`& .${tabsClasses.scrollButtons}`]: {
        "&.Mui-disabled": { opacity: 0.3 },
    },
    [`& .${tabsClasses.indicator}`]: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
        "& > span": {
            width: "100%",
            marginLeft: "12px",
            marginRight: "12px",
            backgroundColor: "#635ee7",
        },
    },
});

const StyledTab = styled(MuiTab)(() => ({
    ...tw`[text-transform:none] pb-0 font-normal mr-1 text-black`,
    "&.Mui-selected": tw`text-black`,
})) as typeof MuiTab;

export type IconTabTypeMap = TabTypeMap<{ faIcon?: IconProp }>;
export type IconTabProps = TabTypeMap<{ faIcon?: IconProp }>["props"];

export const Tab: ExtendButtonBase<IconTabTypeMap> = (props: IconTabProps) => {
    const { faIcon, label, ...restProps } = props;
    const labelWithIcon =
        faIcon && label ? (
            <span>
                <FontAwesomeIcon icon={faIcon} /> {label}
            </span>
        ) : faIcon ? (
            <FontAwesomeIcon icon={faIcon} />
        ) : (
            label
        );
    return <StyledTab label={labelWithIcon} {...restProps} />;
};
