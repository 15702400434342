import { FORM_ERROR, ARRAY_ERROR, setIn } from "final-form";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { FormSchema } from "../builder";

export default function useYupFormValidation<FormValues>(
    validationSchema?: yup.SchemaOf<FormValues> | FormSchema<FormValues>,
    extraValidation?: (schema: yup.SchemaOf<FormValues>) => yup.SchemaOf<FormValues>,
) {
    const { t, i18n } = useTranslation();
    return useMemo(
        () => createYupValidate<FormValues>(validationSchema, t, extraValidation),
        [validationSchema, extraValidation, t, i18n.language],
    );
}

function createYupValidate<FormValues>(
    schema: yup.BaseSchema | FormSchema<FormValues> | undefined,
    t: (key: string) => string,
    extraValidation?: (schema: yup.SchemaOf<FormValues>) => yup.SchemaOf<FormValues>,
) {
    let objectSchema: yup.BaseSchema = yup.isSchema(schema) ? schema : yup.object(schema);
    if (extraValidation) objectSchema = extraValidation(objectSchema as yup.SchemaOf<FormValues>);
    return async (values: FormValues) => {
        try {
            await objectSchema.validate(values, { abortEarly: false });
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                //console.log("Validation result: %s", err.message);
                const result = err.inner.reduce((errors, error) => {
                    const path = error.path === undefined || error.path === "" ? FORM_ERROR : error.path;
                    let value: any = t(error.message as any);
                    if (typeof error.value === "object" && error.value instanceof Array) {
                        const arrayError: any = [];
                        arrayError[ARRAY_ERROR] = value;
                        value = arrayError;
                    }
                    return setIn(errors, path, value);
                }, {});
                return result;
            }
            throw err;
        }
    };
}
