import { createEntityAdapter, EntityState } from "@magicware/redux/entities";
import { anchoreThunks, AsyncSliceState } from "@magicware/redux/thunks";
import { createSlice } from "@reduxjs/toolkit";
import { entityApi, LibraryFilter, LibraryModelDetail, LibraryModelList } from "../../services/api/libraries";
import { RootState } from "../types";

export interface State extends EntityState<LibraryModelDetail, LibraryModelList, LibraryFilter>, AsyncSliceState {}

const initialState: State = { async: {} };

const adapter = createEntityAdapter("library", entityApi, ["name"]);

const extraThunks = {};

const slice = createSlice({
    name: "libraries",
    initialState,
    reducers: adapter.getReducers(),
    extraReducers: (builder) => {
        adapter.addExtraReducers(builder);
    },
});

export const libraryThunks = anchoreThunks((state: RootState) => state.libraries, {
    ...extraThunks,
    ...adapter.thunks,
});

export const actions = slice.actions;
export const { updateDetail, clearList, removeListItems, updateListItems, setPage } = slice.actions;
export default slice.reducer;
