import { faFlask } from "@fortawesome/free-solid-svg-icons";
import { prepareBreadcrumb } from "@magicware/dashboard/navigation/breadcrumbs";
import { CreatePatternMethod, CreateSimpleMethod } from "@magicware/dashboard/navigation/builder";
import { useIntParam } from "@magicware/dashboard/navigation/useIntParam";
import ExperienceDetailPage from "../components/experiences/ExperienceDetailPage";
import ExperienceListPage from "../components/experiences/ExperienceListPage";
import ExperienceNewPage from "../components/experiences/ExperienceNewPage";

export const useExperienceId = () => useIntParam("experienceId");

export const experiencesIcon = faFlask;

export const buildExperiences = (simple: CreateSimpleMethod, pattern: CreatePatternMethod) =>
    simple({
        url: "/experiences",
        component: ExperienceListPage,
        breadcrumb: prepareBreadcrumb((t) => t("experiences-breadcrumb")),

        new: simple({
            url: "/experiences/new",
            component: ExperienceNewPage,
            breadcrumb: prepareBreadcrumb((t) => t("new-experience-breadcrumb")),
        }),
        detail: pattern({
            pattern: "/experiences/:experienceId",
            component: ExperienceDetailPage,
            url: (experienceId: number) => `/experiences/${experienceId}`,
            breadcrumb: prepareBreadcrumb((t) => t("experience-detail-breadcrumb")),
        }),
    });
