import { buildForm } from "@magicware/forms/builder";
import Form from "@magicware/forms/components/Form";
import FormSubmitResult from "@magicware/forms/components/FormSubmitResult";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { navigation } from "../../../navigation";
import { useFormThunk } from "../../../redux/hooks";
import { uiThunks } from "../../../redux/ui/uiReducer";
import { Login } from "../../../services/api/user";
import LoginAlert from "./LoginAlert";
import SubmitButton from "./SubmitButton";

type LoginFormModel = {
    login: string;
    password: string;
};

const { f, defaults, validationSchema } = buildForm<LoginFormModel>()(() => ({
    props: {
        extra: [],
        remove: [],
    },
    schema: (_, t) => ({
        login: yup.string().required(t("email-is-required")),
        password: yup.string(),
    }),
}));

export default function LoginForm() {
    const { t } = useTranslation();
    const validationsMap: Login<typeof f>["ValidationMap"] = {
        messages: {
            InvalidLogin: t("login-or-password-does-not-match-message"),
            LoginLocked: t("username-is-temporarily-locked-message"),
        },
        autoMap: "capitalize",
    };
    const dispatchLogin = useFormThunk(uiThunks.login, validationsMap);

    const handleOnSubmit = async (form: typeof f) => {
        const response = await dispatchLogin(form);
        if (response.success && response.data?.result === "ok") window.location.href = navigation.home.url();
        return response;
    };

    return (
        <Form initialValues={defaults} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
            <div>
                <div tw="flex items-center justify-between">
                    <Field name="login" component="input" type="text" placeholder="Jméno" />
                    <Field name="password" component="input" type="password" placeholder="Heslo" />
                    <SubmitButton />
                </div>
                <div tw="relative">
                    <div tw="absolute">
                        <FormSubmitResult component={LoginAlert} />
                    </div>
                </div>
            </div>
        </Form>
    );
}
