import createCodebooksSlice from "@magicware/redux/createCodebooksSlice";
import { anchoreThunks } from "@magicware/redux/thunks";
import { codebooksApi } from "../../services/api/codebooks";
import { RootState } from "../types";

const codebooks = createCodebooksSlice(
    //bez filtru, bez možnosti přidávání
    {
        currencies: codebooksApi.loadCurrencies,
    },
    //s filtrem, bez možnosti přidávání
    {},
    //bez filtru, s možností přidávání
    {
        keywords: { load: codebooksApi.loadKeywords, ensure: codebooksApi.ensureKeywords },
    },
    (_builder) => {
        // builder.addCase(deviceActions.updateDetail, (state, action) => {
        //     if (
        //         action.payload.keywordList &&
        //         action.payload.keywordList.findIndex((val) => typeof val === "string") >= 0
        //     )
        //         delete state.keywords;
        // });
    },
);

export const { loadKeywords, ensureKeywords, loadCurrencies } = anchoreThunks(
    (state: RootState) => state.codebooks,
    codebooks.asyncActions,
);

export type State = ReturnType<typeof codebooks.slice.getInitialState>;

export default codebooks.slice.reducer;
