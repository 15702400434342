import { createContext } from "react";

/** @internal */
export const DisabledFormContext = createContext(false);

export function DisabledFormContextProvider(props: React.PropsWithChildren<{ disabled?: boolean }>) {
    return (
        <DisabledFormContext.Provider value={props.disabled ?? false}>{props.children}</DisabledFormContext.Provider>
    );
}
