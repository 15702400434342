import { createAsyncFormAutocompleteWithFilter } from "@magicware/forms/mui/FormAutocomplete";
import { appInstanceThunks } from "../../redux/appInstances/appInstancesReducer";
import { RootState } from "../../redux/types";
import { AppInstanceSelectOption } from "../../services/api/appInstances";
import { createDefaultProps } from "../forms/formAutocomplete";

export const FormAppInstancesAutocomplete = createAsyncFormAutocompleteWithFilter(
    {
        selectOptions: (state: RootState) => state.appInstances.list,
        createAsyncAction: appInstanceThunks.loadList,
        selectAsyncState: appInstanceThunks.loadList.selectState,
    },
    createDefaultProps<AppInstanceSelectOption>(
        (opt) => `${opt.projectSetup.name} - ${opt.name} (${opt.platform})`,
        (n, opt) => n.projectSetups.detail.apps.detail.url(opt.projectSetup.id, opt.id),
    ),
);
