import { constructBreadcrumbs } from "./breadcrumbs";
import { LocationDescriptor } from "history";
import { IBreadcrumbPage, INavigatablePage, INavigatablePageBase, isNavigatablePage } from "./utils";

export const allPages: INavigatablePage<any[]>[] = [];
export const allPagesDic: Record<string, INavigatablePage<any[]>> = {};

export function buildNavigation<T>(
    BASE_PATH: string,
    builder: (simple: CreateSimpleMethod, pattern: CreatePatternMethod) => T,
) {
    const createSimple: CreateSimpleMethod = (page) => {
        const url = page.url;
        return {
            ...page,
            pattern: url,
            url: () => url,
            urlAbsolute: () => toAbsolute(url),
        };
    };

    const createPattern: CreatePatternMethod = (page) => {
        const url = page.url;
        return {
            ...page,
            urlAbsolute: (...params) => toAbsolute(url(...params)),
            urlWithScroll: (scrollToId, ...params) => withScrollTo(scrollToId, url(...params)),
        };
    };

    const withScrollTo = (scrollToId: number | undefined, path: string): LocationDescriptor => ({
        pathname: path,
        state: scrollToId && { scrollToId },
    });

    const toAbsolute = (path: string) => {
        if (path.startsWith("/") && BASE_PATH.endsWith("/")) return BASE_PATH.substr(0, BASE_PATH.length - 1) + path;
        else return BASE_PATH + path;
    };

    const navigation = builder(createSimple, createPattern);

    constructBreadcrumbs(navigation);

    initNavigationRecursive(allPages, navigation, undefined);
    allPages.sort((a, b) => b.pattern.localeCompare(a.pattern));

    for (let i = 0; i < allPages.length; i++) {
        allPagesDic[allPages[i].pattern] = allPages[i];
    }

    return navigation;
}

function initNavigationRecursive(
    pages: INavigatablePage<any[]>[],
    navigator: any,
    parent: INavigatablePage<any[]> | undefined,
) {
    for (const propName in navigator) {
        if (Object.prototype.hasOwnProperty.call(navigator, propName)) {
            const val = navigator[propName];
            if (typeof val === "object") {
                if (isNavigatablePage(val)) {
                    pages.push(val);
                    initNavigationRecursive(pages, val, val);
                    val.parent = parent;
                } else {
                    initNavigationRecursive(pages, val, parent);
                }
            }
        }
    }
}

export type CreateSimpleMethod = <
    Extension extends Record<string, any>,
    NavigatablePage extends { url: string } & INavigatablePageBase & IBreadcrumbPage & Extension,
>(
    page: NavigatablePage,
) => INavigatablePage &
    Omit<NavigatablePage, "url"> & {
        pattern: string;
        url: () => string;
        urlAbsolute: () => string;
    };

export type CreatePatternMethod = <
    Extension extends Record<string, any>,
    NavigatablePage extends PatternPageDefinition<Params> & Extension,
    Params extends any[] = NavigatablePage extends { url: infer U }
        ? U extends (...p: infer P) => string
            ? P
            : never
        : never,
>(
    page: NavigatablePage,
) => INavigatablePage<Params> &
    NavigatablePage & {
        urlAbsolute: (...params: Params) => string;
        urlWithScroll: (scrollToId: number, ...params: Params) => LocationDescriptor;
    };

interface PatternPageDefinition<Params extends any[]> extends INavigatablePageBase, IBreadcrumbPage<Params> {
    pattern: string;
    url: (...p: Params) => string;
}
