import SimplePage from "@magicware/dashboard/navigation/SimplePage";
import Panel from "@magicware/dashboard/Panel";
import { buildForm } from "@magicware/forms/builder";
import Form from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import useNewEntityOnSubmit from "@magicware/forms/hooks/useNewEntityOnSubmit";
import FormTextField from "@magicware/forms/mui/FormTextField";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useNavigationClickReplace } from "../../navigation/hooks";
import { clearList } from "../../redux/apiServerVersions/apiServerVersionsReducer";
import { ApiServerVersionModelNew, apiServerVersionsApi } from "../../services/api/apiServerVersions";
import { CODEBOOKS } from "../../services/globals";

export const apiServerVersionNewForm = buildForm<ApiServerVersionModelNew>()(() => ({
    props: {
        remove: ["apiServerId"],
        extra: [],
    },
    transformations: {
        formTo: { new: (_, f): ApiServerVersionModelNew => ({ name: f.name, apiServerId: CODEBOOKS.apiServerId }) },
    },
    schema: (_, t) => ({
        name: yup.string().required(t("name-is-required")),
    }),
}));

const {
    f,
    hooks: { useDefaults },
} = apiServerVersionNewForm;

export default function ApiServerVersionNewTab() {
    const { t } = useTranslation();
    const onSubmit = useNewEntityOnSubmit(
        clearList,
        apiServerVersionsApi.forms.create,
        useNavigationClickReplace((n, newId) => n.apiServerVersions.detail.url(newId)),
        (f: typeof apiServerVersionNewForm.f) => apiServerVersionNewForm.transform.formTo.new(f),
    );
    const initialValues = useDefaults();
    return (
        <SimplePage title={t("new-apiServerVersion-title")}>
            <Form
                initialValues={initialValues}
                onSubmit={onSubmit}
                savePanel={{ buttonContent: t("create-new-version"), disableOnPristine: true }}
                validationSchema={apiServerVersionNewForm.validationSchema}
            >
                <Panel>
                    <FormGrid>
                        <FormTextField f={f} field="name" label={t("name-label")} autoFocus />
                    </FormGrid>
                </Panel>
            </Form>
        </SimplePage>
    );
}
