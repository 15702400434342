import { createEntityAdapter, EntityState } from "@magicware/redux/entities";
import { anchoreThunks, AsyncSliceState } from "@magicware/redux/thunks";
import { createSlice } from "@reduxjs/toolkit";
import {
    entityApi,
    ExperienceModelDetail,
    ExperienceFilter,
    ExperienceModelList,
} from "../../services/api/experiences";
import { RootState } from "../types";

export interface State
    extends EntityState<ExperienceModelDetail, ExperienceModelList, ExperienceFilter>,
        AsyncSliceState {}

const initialState: State = { async: {} };

const adapter = createEntityAdapter("experience", entityApi, [], {
    onDetailUpdated() {
        //todo: prenastavit parametr, device, projekt
    },
});

const extraThunks = {};

const slice = createSlice({
    name: "experiences",
    initialState,
    reducers: adapter.getReducers(),
    extraReducers: (builder) => {
        adapter.addExtraReducers(builder);
        //addAsyncThunkReducers(builder, extraThunks.xxx);
    },
});

export const experienceThunks = anchoreThunks((state: RootState) => state.experiences, {
    ...extraThunks,
    ...adapter.thunks,
});

export const actions = slice.actions;
export const { updateDetail, clearList, removeListItems, updateListItems, setPage } = slice.actions;
export default slice.reducer;
