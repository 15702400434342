import MainContent from "@magicware/dashboard/MainContent";
import PageContainer from "@magicware/dashboard/PageContainer";
import Panel from "@magicware/dashboard/Panel";
import Alert from "@mui/material/Alert";

export default function NotFoundAlertPage() {
    return (
        <PageContainer>
            <MainContent>
                <Panel>
                    <Alert severity="error">404 - Not Found</Alert>
                </Panel>
            </MainContent>
        </PageContainer>
    );
}
