import { useField, useForm } from "react-final-form";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useState } from "react";
import useDisabled from "../hooks/useDisabled";
import useFieldName from "../hooks/useFieldName";
import { FormFieldProps } from "../builder";
import useControlId from "../hooks/useControlId";

export interface FormNumberFieldProps<FormValues> extends FormFieldProps<FormValues, number | number[] | undefined> {
    searchIcon?: boolean;
}

export default function FormNumberField<FormValues>({
    f: _,
    field,
    searchIcon,
    InputProps,
    disabled,
    ...props
}: FormNumberFieldProps<FormValues> & Omit<TextFieldProps, "type">) {
    field = useFieldName(field);
    disabled = useDisabled(disabled);
    const formField = useField(field, { type: "number" });
    const form = useForm();
    const [textValue, setTextValue] = useState<string>(formField.input.value ?? "");
    const rndId = useControlId();

    if (searchIcon)
        InputProps = { ...InputProps, startAdornment: <FontAwesomeIcon icon={faSearch} tw="text-gray-400 mr-1" /> };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        form.change(field, e.target.value === "" ? undefined : Number(e.target.value));
        setTextValue(e.target.value);
    };

    const isError = formField.meta.touched && Boolean(formField.meta.error || formField.meta.submitError);
    return (
        <TextField
            autoComplete="new-password"
            {...props}
            {...formField.input}
            id={rndId}
            name={rndId}
            disabled={disabled}
            value={textValue}
            onChange={handleOnChange}
            type="text"
            error={isError}
            helperText={
                isError ? <Fragment>{formField.meta.error || formField.meta.submitError}</Fragment> : props.helperText
            }
            InputProps={InputProps}
            inputProps={{ inputMode: "numeric" }}
        />
    );
}
