import { createEntityAdapter, EntityState } from "@magicware/redux/entities";
import { anchoreThunks, AsyncSliceState } from "@magicware/redux/thunks";
import { createSlice } from "@reduxjs/toolkit";
import { entityApi, DeviceModelDetail, DeviceFilter, DeviceModelList, devicesApi } from "../../services/api/devices";
import addVersionUpdateReducer from "../files/addVersionUpdateReducer";
import { RootState } from "../types";

export interface State extends EntityState<DeviceModelDetail, DeviceModelList, DeviceFilter>, AsyncSliceState {}

const initialState: State = { async: {} };

const adapter = createEntityAdapter("device", entityApi, ["name"]);

const extraThunks = {};

const slice = createSlice({
    name: "devices",
    initialState,
    reducers: adapter.getReducers(),
    extraReducers: (builder) => {
        adapter.addExtraReducers(builder);
        //addAsyncThunkReducers(builder, extraThunks.xxx);
        addVersionUpdateReducer(builder, devicesApi.files);
    },
});

export const deviceThunks = anchoreThunks((state: RootState) => state.devices, {
    ...extraThunks,
    ...adapter.thunks,
});

export const actions = slice.actions;
export const { updateDetail, clearList, removeListItems, updateListItems, setPage } = slice.actions;
export default slice.reducer;
