import AsyncContent from "@magicware/dashboard/AsyncContent";
import { useTranslation } from "react-i18next";
import { useAppInstanceConnectionId } from "../../navigation/appInstances";
import { appInstanceConnectionThunks } from "../../redux/appInstanceConnections/appInstanceConnectionsReducer";
import { useAppDispatch, useAppSelectorEnsure, useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { appInstanceConnectionsApi } from "../../services/api/appInstanceConnections";
import AppInstanceConnectionForm, { appInstanceConnectionForm } from "./AppInstanceConnectionForm";

const { useToFormFromModel } = appInstanceConnectionForm.hooks;

export default function AppInstanceConnectionDetailTab() {
    const api = useAutoDispatchAsyncThunk(appInstanceConnectionThunks.load, useAppInstanceConnectionId());
    return <AsyncContent {...api} component={AppInstanceConnectionDetailTabLoaded} />;
}

function AppInstanceConnectionDetailTabLoaded() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const appInstanceConnectionDetail = useAppSelectorEnsure((state) => state.appInstanceConnections.detail);
    const initialValues = useToFormFromModel(appInstanceConnectionDetail);
    const onSubmit = async (form: typeof appInstanceConnectionForm.f) => {
        const result = await appInstanceConnectionsApi.forms.update(
            appInstanceConnectionForm.transform.formTo.update(form, appInstanceConnectionDetail),
        );
        return appInstanceConnectionForm.apiUtils.handleUpdateEntityResult(dispatch, result, form);
    };
    return (
        <AppInstanceConnectionForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            savePanel={{ successMessage: t("appInstanceConnection-has-been-saved") }}
        />
    );
}
