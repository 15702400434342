import Panel from "@magicware/dashboard/Panel";
import { buildForm } from "@magicware/forms/builder";
import Form, { FormImplementationProps } from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import FormSelect from "@magicware/forms/mui/FormSelect";
import FormTextField from "@magicware/forms/mui/FormTextField";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { updateDetail } from "../../redux/apiServerInstances/apiServerInstancesReducer";
import {
    ApiServerInstanceModelDetail,
    ApiServerInstanceModelNew,
    ApiServerInstanceModelUpdate,
} from "../../services/api/apiServerInstances";
import { CODEBOOKS } from "../../services/globals";
import { FormApiServerVersionsAutocomplete } from "../apiServerVersions/FormApiServerVersionsAutocomplete";
import { environmentOptions } from "../codebooks/environments";
import { FormProjectSetupAutocomplete } from "../projectSetups/FormProjectSetupAutocomplete";

export const apiServerInstanceForm = buildForm<ApiServerInstanceModelDetail>()(() => ({
    props: {
        remove: ["id", "version"],
        extra: [],
    },
    transformations: {
        toForm: { fromModel: (h, m: ApiServerInstanceModelDetail) => ({ ...h.fromModel(m) }) },
        formTo: {
            new: (h, f): ApiServerInstanceModelNew => ({
                ...h.toNewEntity(f),
                apiServerId: CODEBOOKS.apiServerId,
            }),
            update: (h, f, m: ApiServerInstanceModelDetail): ApiServerInstanceModelUpdate => ({
                ...h.toUpdateEntity(f, m),
            }),
        },
        toReduxUpdateModel: (h, f): Partial<ApiServerInstanceModelDetail> => ({
            ...h.removeExtra(f),
        }),
    },
    updateReduxModel: updateDetail,
    schema: (_, t) => ({
        name: yup.string().required(t("name-is-required")),
        environment: yup.string().required(t("environment-is-required")),
        apiServerVersion: yup.object().required(t("version-is-required")),
        projectSetupList: yup.array().default([]),
        url: yup.string(),
        notes: yup.string(),
    }),
}));

const f = apiServerInstanceForm.f;

export type ApiServerInstanceFormOnSubmit = FormImplementationProps<typeof f>["onSubmit"];

export default function ApiServerInstanceForm(props: FormImplementationProps<typeof f>) {
    const { t } = useTranslation();
    return (
        <Form validationSchema={apiServerInstanceForm.validationSchema} {...props}>
            <Panel>
                <FormGrid>
                    <FormTextField f={f} field="name" label={t("name-label")} autoFocus={props.autoFocus} />
                    <FormTextField f={f} field="url" label={t("url-label")} />
                    <FormSelect f={f} {...environmentOptions} field="environment" label={t("environment-label")} />
                    <FormApiServerVersionsAutocomplete
                        f={f}
                        field="apiServerVersion"
                        label={t("version-label")}
                        filter={{}}
                        multiple
                        fullOption
                    />
                    <FormProjectSetupAutocomplete
                        f={f}
                        field="projectSetupList"
                        label={t("projectSetups-label")}
                        filter={{}}
                        multiple
                        fullOption
                    />
                    <FormTextField f={f} field="notes" label={t("notes-label")} multiline />
                </FormGrid>
            </Panel>
        </Form>
    );
}
