import { createEntityApi } from "@magicware/fetch-api/entities";
import { IdVersion } from "@magicware/fetch-api/types";
import { API_BASE_URL } from "../globals";
import { AppInstanceSelectOption } from "./appInstances";
import { ConnectorSelectOption } from "./connectors";

export interface AppInstanceConnectionModelBase {
    isPull: boolean;
    notes?: string;
}

export interface AppInstanceConnectionEntity {
    targetAppInstanceId?: number;
    targetConnectorId?: number;
}

export interface AppInstanceConnectionModelNew extends AppInstanceConnectionEntity {
    appInstanceId: number;
}
export interface AppInstanceConnectionModelUpdate extends AppInstanceConnectionEntity, IdVersion {}
export interface AppInstanceConnectionModelDetail extends AppInstanceConnectionModelBase, IdVersion {
    targetAppInstance?: AppInstanceSelectOption;
    targetConnector?: ConnectorSelectOption;
}

export interface AppInstanceConnectionModelList {
    id: number;
    isPull: boolean;
    appInstance: AppInstanceSelectOption;
    targetAppInstance?: AppInstanceSelectOption;
    targetConnector?: ConnectorSelectOption;
}
export interface AppInstanceConnectionFilter {
    name?: string;
    appInstanceId?: number;
}

export const entityApi = createEntityApi<
    AppInstanceConnectionModelNew,
    AppInstanceConnectionModelUpdate,
    AppInstanceConnectionModelDetail,
    AppInstanceConnectionModelList,
    AppInstanceConnectionFilter
>("appInstanceConnection", `${API_BASE_URL}registry/appInstanceConnections`);

export const appInstanceConnectionsApi = entityApi;
