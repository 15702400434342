import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { useManualDispatchAsyncThunkWithSnackbar } from "@magicware/dashboard/hooks/useManualDispatchAsyncThunkWithSnackbar";
import { DataListContextMenu, DataListContextMenuProvider, MenuAction } from "@magicware/data-lists/DataListContextMenu";
import { AnchoredAppAsyncThunk } from "@magicware/redux/thunks";
import { getClosestDataIntAttr } from "@magicware/utils/html/getClosestDataAttr";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import { RootState } from "../../redux/types";

export interface EntityListItemMenuContextProps {
    deleteSnackTitle: string;
    deleteThunk: AnchoredAppAsyncThunk<RootState, any, { entityId: number }>
    children: React.ReactNode;
}

export default function EntityListItemMenuContext({ deleteSnackTitle, deleteThunk, children}: EntityListItemMenuContextProps) {
    const { t } = useTranslation();
    const dispatchDelete = useManualDispatchAsyncThunkWithSnackbar(deleteThunk);
    const actions: MenuAction[] = [
        {
            icon: faTrashAlt,
            iconTw: tw`text-red-500`,
            name: t("delete"),
            onClick: (trigger) => {
                const entityId = getClosestDataIntAttr(trigger);
                dispatchDelete(deleteSnackTitle, { entityId });
            },
        },
    ];
    return (
        <DataListContextMenuProvider>
            <DataListContextMenu actions={actions} />
            {children}
        </DataListContextMenuProvider>
    );
}