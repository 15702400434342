import SimplePage from "@magicware/dashboard/navigation/SimplePage";
import useNewEntityOnSubmit from "@magicware/forms/hooks/useNewEntityOnSubmit";
import { useTranslation } from "react-i18next";
import { useNavigationClickReplace } from "../../navigation/hooks";
import { clearList } from "../../redux/parameters/parametersReducer";
import { parametersApi } from "../../services/api/parameters";
import ParameterForm, { parameterForm } from "./ParameterForm";

const { useDefaults } = parameterForm.hooks;

export default function ParameterNewPage() {
    const { t } = useTranslation();
    const onSubmit = useNewEntityOnSubmit(
        clearList,
        parametersApi.forms.create,
        useNavigationClickReplace((n) => n.parameters.url()),
        parameterForm.transform.formTo.new,
    );
    const initialValues = useDefaults();
    return (
        <SimplePage title={t("new-parameter-title")}>
            <ParameterForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                autoFocus
                savePanel={{ buttonContent: t("create-new-parameter"), disableOnPristine: true }}
            />
        </SimplePage>
    );
}
