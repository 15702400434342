import DataListBulkActionsContext from "@magicware/data-lists/DataListBulkActionsContext";
import { MenuAction } from "@magicware/data-lists/DataListContextMenu";
import { useTranslation } from "react-i18next";
import { useNewItemMenuAction } from "../../navigation/hooks";
import { useProjectSetupId } from "../../navigation/projectSetups";

export default function ConnectorCertificationListMenuContext(props: { children?: React.ReactNode }) {
    const { t } = useTranslation();
    const actions: MenuAction[] = [];
    const projectSetupId = useProjectSetupId();
    const addAction = useNewItemMenuAction(t("add-certification"), (n) =>
        n.projectSetups.detail.certifications.new.url(projectSetupId),
    );
    return (
        <DataListBulkActionsContext defaultAction={addAction} actions={actions}>
            {props.children}
        </DataListBulkActionsContext>
    );
}
