import tw from "twin.macro";
import { CircularProgress } from "@mui/material";
import { useFormState } from "react-final-form";
import { useTranslation } from "react-i18next";

export default function SubmitButton() {
    const { t } = useTranslation();
    const { submitting } = useFormState({ subscription: { submitting: true } });
    return (
        <button type="submit">
            <span tw="relative">
                <span css={[submitting && tw`invisible`]}> {t("log-in-button")}</span>
                {submitting && (
                    <span tw="absolute w-full left-0">
                        <CircularProgress size="16px" />
                    </span>
                )}
            </span>
        </button>
    );
}
