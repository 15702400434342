import { downloadFile } from "@magicware/utils/html/downloadFile";
import DownloadIcon from "@mui/icons-material/Download";
import { IconButton } from "@mui/material";

export interface DownloadIconButtonProps {
    urlOrBlob: string | Blob;
    name?: string;
}

export default function DownloadIconButton({ urlOrBlob, name }: DownloadIconButtonProps) {
    return (
        <IconButton
            size="small"
            onClick={(e) => {
                e.stopPropagation();
                downloadFile(urlOrBlob, name ?? "");
            }}
        >
            <DownloadIcon />
        </IconButton>
    );
}
