import * as yup from "yup";
import { DateFn } from "../DateFn";

/** Provede rozšíření yup o doplňkové funkce definované v yup.d.ts */
export function initYupExtensions() {
    const dateFromString: keyof yup.DateSchema = "fromString";
    yup.addMethod(yup.date, dateFromString, function () {
        return this.transform(function (_, originalValue) {
            if (typeof originalValue === "string") return DateFn.parse(originalValue);
            return new Date(NaN);
        }).typeError("Invalid Format");
    });

    const numberFromString: keyof yup.NumberSchema = "fromString";
    yup.addMethod(yup.number, numberFromString, function () {
        return this.typeError("Invalid Format");
    });

    const emailASCII: keyof yup.StringSchema = "emailASCII";
    yup.addMethod(yup.string, emailASCII, function (message: string) {
        return this.matches(/^[-._a-zA-Z0-9]+@[-._a-zA-Z0-9]{2,}$/, {
            message,
            name: emailASCII,
            excludeEmptyString: true,
        });
    });

    const endRange: keyof yup.DateSchema = "endRange";
    yup.addMethod(yup.date, endRange, function (rangeStartProp, message, missingStartMessage) {
        let result = this.min(yup.ref(rangeStartProp), message);
        if (missingStartMessage) result = result.oneOf([undefined], missingStartMessage);
        return result;
    });

    const files: keyof yup.AnyObjectSchema = "files";
    yup.addMethod(yup.object, files, function (options: yup.FilesOptions) {
        let result = yup.mixed().test({
            message: "Value is not a file.",
            test: (val: unknown) => {
                const fileList = val as FileList | null | undefined;
                return fileList ? typeof fileList.length === "number" : false;
            },
        });
        if (options.requiredMessage) {
            result = result.test({
                message: options.requiredMessage,
                test: (val: unknown) => {
                    const fileList = val as FileList | null | undefined;
                    return fileList && fileList.length > 0 ? true : false;
                },
            });
        }
        if (options.maxCount) {
            const maxCount = options.maxCount!.count;
            result = result.test({
                message: options.maxCount.message,
                test: (val: unknown) => {
                    const fileList = val as FileList | null | undefined;
                    if (!fileList) return true;
                    return fileList.length <= maxCount;
                },
            });
        }
        if (options.maxSize) {
            const maxBytes = options.maxSize.bytes;
            const singleFile = options.maxSize.singleFile;
            result = result.test({
                message: options.maxSize.message,
                test: (val: unknown) => {
                    const fileList = val as FileList | null | undefined;
                    if (!fileList) return true;
                    let total = 0;
                    for (let i = 0; i < fileList.length; i++) {
                        const file = fileList.item(i)!;
                        if (singleFile) {
                            if (file.size >= maxBytes) return false;
                        } else {
                            total += file.size;
                        }
                    }
                    return total <= maxBytes;
                },
            });
        }
        return result as any;
    });

    const checkName: keyof yup.BaseSchema = "check";
    yup.addMethod(yup.mixed, checkName, function (...args: any[]) {
        const idx = args.length === 3 ? 1 : 0;
        const isValidPredicate: (f: any) => boolean = args[idx];
        const message: string = args[idx + 1];
        return this.test({
            test: (_, ctx) => (ctx.parent !== undefined ? isValidPredicate(ctx.parent) : true),
            message: message,
        });
    });

    const ifName: keyof yup.BaseSchema = "if";
    yup.addMethod(yup.mixed, ifName, function (_f, ...args: any[]) {
        if (args.length == 2) {
            return this.when(args[0], args[1]);
        } else if (args.length == 3) {
            return this.when([args[0], args[1]], args[2]);
        } else if (args.length == 4) {
            return this.when([args[0], args[1], args[2]], args[3]);
        } else {
            throw new Error("Unexpected parameters of if method");
        }
    });

    const requiredIfName: keyof yup.BaseSchema = "requiredIf";
    yup.addMethod(yup.mixed, requiredIfName, function (_f, ...args: any[]) {
        if (args.length == 2) {
            return this.when(args[0], (val, sch) => {
                return val ? sch.required(args[1]) : sch;
            });
        } else if (args.length == 3) {
            return this.when(args[0], (val, sch) => {
                return args[1](val) ? sch.required(args[2]) : sch;
            });
        } else if (args.length == 4) {
            return this.when([args[0], args[1]], ((a: any, b: any, sch: yup.AnySchema) =>
                args[2](a, b) ? sch.required(args[3]) : sch) as any);
        } else if (args.length == 5) {
            return this.when([args[0], args[1], args[2]], ((a: any, b: any, c: any, sch: yup.AnySchema) =>
                args[3](a, b, c) ? sch.required(args[4]) : sch) as any);
        }
        throw new Error("Unexpected parameters of requiredIf method");
    });
}
