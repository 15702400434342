import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AsyncThunkStatus } from "@magicware/redux/thunks";
import { StoreAction, StoreDispatch } from "@magicware/redux/types";
import { DataDictionary } from "@magicware/utils/DataDictionary";
import mergeClasses from "@magicware/utils/mergeClasses";
import {
    Autocomplete,
    AutocompleteFreeSoloValueMapping,
    AutocompleteProps,
    CircularProgress,
    IconButton,
    TextField,
} from "@mui/material";
import { forwardRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import useControlId from "../hooks/useControlId";
import useDisabled from "../hooks/useDisabled";
import useFormAutocomplete, {
    AutocompleteOptionsProps,
    defaultFilter,
    FormAutocompleteProps as UseFormAutocompleteProps,
} from "../hooks/useFormAutocomplete";

export interface AsyncAutocompleteProps<TOption extends { id: number }> {
    selectOptions: (state: any) => DataDictionary<TOption, any> | undefined;
    selectAsyncState: (state: any) => { status: AsyncThunkStatus };
    createAsyncAction: () => StoreAction;
}

export interface AsyncAutocompletePropsWithFilter<TOption extends { id: number }, TFilter> {
    selectOptions: (state: any) => DataDictionary<TOption, TFilter> | undefined;
    selectAsyncState: (state: any, filter: TFilter) => { status: AsyncThunkStatus };
    createAsyncAction: (filter: TFilter) => StoreAction;
}

export interface FormAutocompleteProps<
    TValues,
    TOption extends { id: number },
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
> extends UseFormAutocompleteProps<TValues, TOption, Multiple, DisableClearable, FreeSolo>,
        Partial<AutocompleteProps<TOption, Multiple, DisableClearable, FreeSolo>> {
    label: string;
    searchIcon?: boolean;
    optionsLimit?: number;
    onOptionDetailClick?: (o: TOption) => void;
    getSelectedAdorment?: (o: TOption | AutocompleteFreeSoloValueMapping<FreeSolo>) => React.ReactNode | undefined;
}

/** Selector, který je možné napojit buď přímo na stejný typ jako je option (tzn. fíld je typu object) a nebo na IDčko optionu (fíld je typu number).
 * Pokud se napojuje na objekt, je nutné předat příznak @see FormAutocompleteProps.fullOption
 * Ve výchozím stavu se za nevybranou hodnotu považuje hodnota undefined. Pokud to má být místo toho null, je třeba předat příznak @see FormAutocompleteProps.nullAsUndefined
 * U hodnot typu number je ještě možné využít variantu, kdy jako nevybraná hodnota se může novažovat hodnota 0. V takovém případě je třeba předat příznak
 * @see FormAutocompleteProps.zeroAsUndefined
 */
export default function FormAutocomplete<
    TValues,
    TOption extends { id: number },
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
>({
    label,
    getSelectedAdorment,
    searchIcon = false,
    autoHighlight = true,
    nonSearchable,
    disabled,
    onOptionDetailClick,
    optionsLimit,
    ...props
}: FormAutocompleteProps<TValues, TOption, Multiple, DisableClearable, FreeSolo>) {
    disabled = useDisabled(disabled);
    const rndId = useControlId();

    const { getOptionLabel, textFieldProps, extraProps, ...autoCompleteProps } = useFormAutocomplete(props);

    let startAdornment: React.ReactNode | undefined = searchIcon ? (
        <FontAwesomeIcon icon={faSearch} tw="text-gray-400 ml-1" />
    ) : undefined;

    const openDetailIcon =
        onOptionDetailClick &&
        ((option: TOption) => (
            <IconButton
                size="small"
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onOptionDetailClick(option);
                }}
            >
                <FontAwesomeIcon icon={faPenToSquare} />
            </IconButton>
        ));

    let renderOption = props.renderOption;
    if (!renderOption) {
        if (openDetailIcon) {
            renderOption = (p, option) => (
                <li {...p} key={option.id}>
                    <div tw="flex w-full">
                        <span tw="flex-1">{getOptionLabel(option)}</span>
                        {openDetailIcon(option)}
                    </div>
                </li>
            );
        } else {
            renderOption = (p, option) => (
                <li {...p} key={option.id}>
                    {getOptionLabel(option)}
                </li>
            );
        }
    }

    let filterOptions = autoCompleteProps.filterOptions;
    if (optionsLimit) {
        const originalFilter = autoCompleteProps.filterOptions ?? defaultFilter;
        filterOptions = (...args) => {
            const result = originalFilter(...args);
            return result.length > optionsLimit ? result.slice(0, optionsLimit + 1) : result;
        };
    }

    return (
        <Autocomplete<TOption, Multiple, DisableClearable, FreeSolo>
            {...mergeClasses("group", extraProps)}
            {...autoCompleteProps}
            filterOptions={filterOptions}
            getOptionLabel={getOptionLabel}
            disabled={disabled}
            autoHighlight={autoHighlight}
            renderOption={renderOption}
            id={rndId}
            ListboxComponent={LimitedListboxComponent}
            ListboxProps={{ optionsLimit: optionsLimit } as any}
            renderInput={(params) => (
                <TextField
                    {...textFieldProps}
                    {...params}
                    label={label}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                    }}
                    InputProps={{
                        readOnly: nonSearchable,
                        ...params.InputProps,
                        startAdornment: params.InputProps.startAdornment ?? startAdornment,
                        endAdornment: (
                            <Fragment>
                                {autoCompleteProps.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {props.multiple !== true && autoCompleteProps.value && openDetailIcon && (
                                    <div tw="group-hover:visible invisible">
                                        {openDetailIcon(autoCompleteProps.value as any)}
                                    </div>
                                )}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

const LimitedListboxComponent = forwardRef<
    HTMLUListElement,
    React.HTMLAttributes<HTMLElement> & { optionsLimit?: number }
>(function LimitedListboxComponent({ optionsLimit, ...props }, ref) {
    let children = props.children;
    if (optionsLimit && props.children instanceof Array && props.children.length > optionsLimit) {
        children = (
            <Fragment>
                {props.children.slice(0, optionsLimit)}
                <div tw="mx-5">...</div>
            </Fragment>
        );
    }
    return (
        <ul {...props} ref={ref}>
            {children}
        </ul>
    );
});

export function createAsyncFormAutocomplete<TOption extends { id: number }>(
    asyncProps: AsyncAutocompleteProps<TOption>,
    getDefaultProps?: () => Partial<FormAutocompleteProps<never, TOption, never, never, never>>,
) {
    return function AsyncFormAutocomplete<
        TValues,
        Multiple extends boolean | undefined,
        DisableClearable extends boolean | undefined,
        FreeSolo extends boolean | undefined,
    >(
        props: Omit<
            FormAutocompleteProps<TValues, TOption, Multiple, DisableClearable, FreeSolo>,
            keyof AutocompleteOptionsProps<TOption>
        >,
    ) {
        const dispatch = useDispatch<StoreDispatch>();
        const reduxOptions = useSelector(asyncProps.selectOptions);
        let optionsLoadState = useSelector(asyncProps.selectAsyncState).status;
        const optionsDic = optionsLoadState === "success" ? reduxOptions : undefined;
        if (optionsLoadState === "success" && reduxOptions === undefined) optionsLoadState = "idle";
        const defaultProps = (getDefaultProps && getDefaultProps()) ?? {};
        return (
            <FormAutocomplete<TValues, TOption, Multiple, DisableClearable, FreeSolo>
                {...(defaultProps as any)}
                {...(props as FormAutocompleteProps<TValues, TOption, Multiple, DisableClearable, FreeSolo>)}
                optionsDic={optionsDic}
                optionsLoadStatus={optionsLoadState}
                onLoadOptions={() => dispatch(asyncProps.createAsyncAction())}
            />
        );
    };
}

export function createAsyncFormAutocompleteWithFilter<TOption extends { id: number }, TFilter>(
    asyncProps: AsyncAutocompletePropsWithFilter<TOption, TFilter>,
    getDefaultProps?: () => Partial<FormAutocompleteProps<never, TOption, never, never, never>>,
) {
    return function AsyncFormAutocomplete<
        TValues,
        Multiple extends boolean | undefined,
        DisableClearable extends boolean | undefined,
        FreeSolo extends boolean | undefined,
    >({
        filter,
        ...props
    }: Omit<
        FormAutocompleteProps<TValues, TOption, Multiple, DisableClearable, FreeSolo>,
        keyof AutocompleteOptionsProps<TOption>
    > & {
        filter: TFilter;
    }) {
        const dispatch = useDispatch<StoreDispatch>();
        const reduxOptions = useSelector(asyncProps.selectOptions);
        let optionsLoadState = useSelector((state) => asyncProps.selectAsyncState(state, filter)).status;
        const optionsDic = optionsLoadState === "success" ? reduxOptions : undefined;
        if (optionsLoadState === "success" && reduxOptions === undefined) optionsLoadState = "idle";
        const defaultProps = (getDefaultProps && getDefaultProps()) ?? {};
        return (
            <FormAutocomplete<TValues, TOption, Multiple, DisableClearable, FreeSolo>
                {...(defaultProps as any)}
                {...(props as FormAutocompleteProps<TValues, TOption, Multiple, DisableClearable, FreeSolo>)}
                optionsDic={optionsDic}
                optionsLoadStatus={optionsLoadState}
                onLoadOptions={() => dispatch(asyncProps.createAsyncAction(filter))}
            />
        );
    };
}
