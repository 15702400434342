import { addAsyncThunkReducers, AppAsyncThunkWithBehavior, AsyncSliceState, createAppAsyncThunk } from "./thunks";
import { appendNewEntities, createDataDictionary, DataDictionary } from "@magicware/utils/DataDictionary";
import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit";

type CodebooksOptions = {
    [p in string]: () => Promise<{ id: number }[]>;
};

type CodebooksWithFilterOptions = {
    [p in string]: (filter: any) => Promise<{ id: number }[]>;
};

type CodebooksWithEnsureOptions = {
    [p in string]: CodebookEnsureOptions;
};

type CodebookEnsureOptions = {
    load: () => Promise<{ id: number }[]>;
    ensure: (names: string[]) => Promise<{ id: number }[]>;
};

type ExtractCodebooksModel<T extends () => Promise<any[]>> = T extends () => Promise<infer Arr>
    ? Arr extends Array<infer T>
        ? T extends { id: number }
            ? T
            : never
        : never
    : never;

type ExtractCodebooksWithEnsureModel<T extends CodebookEnsureOptions> = T extends { load: infer LoadMethod }
    ? LoadMethod extends () => Promise<infer Arr>
        ? Arr extends Array<infer T>
            ? T extends { id: number }
                ? T
                : never
            : never
        : never
    : never;

type ExtractCodebooksWithFilterModel<T extends (filter: any) => Promise<any[]>> = T extends (
    filter: any,
) => Promise<infer Arr>
    ? Arr extends Array<infer T>
        ? T extends { id: number }
            ? T
            : never
        : never
    : never;

type ExtractCodebooksFilter<T extends (filter: any) => Promise<any[]>> = T extends (filter: infer F) => Promise<any[]>
    ? F
    : never;

type CodebooksState1<Opt extends CodebooksOptions> = {
    [P in string & keyof Opt]?: DataDictionary<ExtractCodebooksModel<Opt[P]>>;
};
type CodebooksWithEnsureState<Opt extends CodebooksWithEnsureOptions> = {
    [P in string & keyof Opt]?: DataDictionary<ExtractCodebooksWithEnsureModel<Opt[P]>>;
};
type CodebooksWithFilterState<Opt extends CodebooksWithFilterOptions> = {
    [P in string & keyof Opt]?: DataDictionary<ExtractCodebooksWithFilterModel<Opt[P]>, ExtractCodebooksFilter<Opt[P]>>;
};

type CodebooksAsyncActions<Opt extends CodebooksOptions> = {
    [P in string & keyof Opt as `load${Capitalize<P>}`]: AppAsyncThunkWithBehavior<
        "exclusive",
        ExtractCodebooksModel<Opt[P]>[]
    >;
};

type CodebooksWithEnsureAsyncActions<Opt extends CodebooksWithEnsureOptions> = {
    [P in string & keyof Opt as `load${Capitalize<P>}`]: AppAsyncThunkWithBehavior<
        "exclusive",
        ExtractCodebooksWithEnsureModel<Opt[P]>[]
    >;
};
type CodebooksWithEnsureAsyncActions2<Opt extends CodebooksWithEnsureOptions> = {
    [P in string & keyof Opt as `ensure${Capitalize<P>}`]: AppAsyncThunkWithBehavior<
        "volatile",
        ExtractCodebooksWithEnsureModel<Opt[P]>[],
        { names: string[] }
    >;
};

type CodebooksWithFilterAsyncActions<Opt extends CodebooksWithFilterOptions> = {
    [P in string & keyof Opt as `load${Capitalize<P>}`]: AppAsyncThunkWithBehavior<
        "exclusive",
        ExtractCodebooksWithFilterModel<Opt[P]>[],
        ExtractCodebooksFilter<Opt[P]>
    >;
};

export default function createCodebooksSlice<
    Options1 extends CodebooksOptions,
    Options2 extends CodebooksWithFilterOptions,
    Options3 extends CodebooksWithEnsureOptions,
>(
    options1: Options1,
    options2: Options2,
    options3: Options3,
    extraReducers?: (
        builder: ActionReducerMapBuilder<
            CodebooksState1<Options1> &
                CodebooksWithFilterState<Options2> &
                CodebooksWithEnsureState<Options3> &
                AsyncSliceState
        >,
    ) => void,
) {
    const thunks1: CodebooksAsyncActions<Options1> = {} as any;
    const thunks2: CodebooksWithFilterAsyncActions<Options2> = {} as any;
    const thunks3: CodebooksWithEnsureAsyncActions<Options3> & CodebooksWithEnsureAsyncActions2<Options3> = {} as any;
    for (const p in options1) {
        const asyncName = `load${p[0].toUpperCase()}${p.substring(1)}` as keyof typeof thunks1;
        const apiCall = options1[p];
        thunks1[asyncName] = createAppAsyncThunk(asyncName, "exclusive", apiCall, () => asyncName) as any;
    }
    for (const p in options2) {
        const asyncName = `load${p[0].toUpperCase()}${p.substring(1)}` as keyof typeof thunks2;
        const apiCall = options2[p];
        thunks2[asyncName] = createAppAsyncThunk(
            asyncName,
            "exclusive",
            apiCall,
            (f: any) => (f && JSON.stringify(f)) ?? "",
        ) as any;
    }
    for (const p in options3) {
        const loadAsyncName = `load${p[0].toUpperCase()}${p.substring(1)}` as keyof typeof thunks3;
        const ensureAsyncName = `ensure${p[0].toUpperCase()}${p.substring(1)}` as keyof typeof thunks3;
        const apis = options3[p];
        thunks3[loadAsyncName] = createAppAsyncThunk(loadAsyncName, "exclusive", apis.load, () => loadAsyncName) as any;
        thunks3[ensureAsyncName] = createAppAsyncThunk(ensureAsyncName, (args: { names: string[] }) =>
            apis.ensure(args.names),
        ) as any;
    }
    const thunks = { ...thunks1, ...thunks2, ...thunks3 };

    const asyncState: AsyncSliceState = { async: {} };
    const initialState = asyncState as AsyncSliceState &
        CodebooksState1<Options1> &
        CodebooksWithFilterState<Options2> &
        CodebooksWithEnsureState<Options3>;
    const slice = createSlice({
        name: "codebooks",
        initialState,
        reducers: {},
        extraReducers: (builder) => {
            if (extraReducers) extraReducers(builder);
            for (const p in options1) {
                const thunkName = `load${p[0].toUpperCase()}${p.substring(1)}` as keyof typeof thunks1;
                const thunk = thunks1[thunkName];
                addAsyncThunkReducers(builder, thunk as any, {
                    fulfilled: (state, action) => {
                        state[p] = createDataDictionary(action.payload) as any;
                    },
                });
            }
            for (const p in options2) {
                const thunkName = `load${p[0].toUpperCase()}${p.substring(1)}` as keyof typeof thunks2;
                const thunk = thunks2[thunkName];
                addAsyncThunkReducers(builder, thunk as any, {
                    fulfilled: (state, action) => {
                        state[p] = createDataDictionary(action.payload, action.meta.arg) as any;
                    },
                });
            }
            for (const p in options3) {
                const loadThunkName = `load${p[0].toUpperCase()}${p.substring(1)}` as keyof typeof thunks3;
                const loadThunk = thunks3[loadThunkName];
                addAsyncThunkReducers(builder, loadThunk as any, {
                    fulfilled: (state, action) => {
                        state[p] = createDataDictionary(action.payload) as any;
                    },
                });
                const ensureThunkName = `ensure${p[0].toUpperCase()}${p.substring(1)}` as keyof typeof thunks3;
                const ensureThunk = thunks3[ensureThunkName];
                addAsyncThunkReducers(builder, ensureThunk as any, {
                    fulfilled: (state, action: PayloadAction<{ id: number }[]>) => {
                        appendNewEntities(state[p], action.payload);
                    },
                });
            }
        },
    });

    return { slice, asyncActions: thunks };
}
