import { BASE_PATH } from "../services/globals";
import HomePage from "../components/homePage/HomePage";
import { allPages, buildNavigation } from "@magicware/dashboard/navigation/builder";
import PasswordResetStep1Page from "../components/passwords/PasswordResetStep1Page";
import PasswordResetStep2Page from "../components/passwords/PasswordResetStep2Page";
import PasswordChangePage from "../components/passwords/PasswordChangePage";
import { INavigatablePage } from "@magicware/dashboard/navigation/utils";
import { buildParameters } from "./parameters";
import { buildBrands } from "./brands";
import { buildProjectSetups } from "./projectSetups";
import { buildDevices } from "./devices";
import { buildParametersScopes } from "./parametersScopes";
import { buildConnectors } from "./connectors";
import { buildExperiences } from "./experiences";
import { buildApps } from "./baseApps";
import { buildLibraries } from "./libraries";
import { buildApiServerVersions } from "./apiServers";
import { buildApiServerInstances } from "./apiServerInstances";

export type Navigation = typeof navigation;

export const navigation = buildNavigation(BASE_PATH, (simple, pattern) => {
    return {
        home: simple({ url: "/overview", component: HomePage }),
        passwordReset: simple({ url: "/password-reset", component: PasswordResetStep1Page, unrestricted: true }),
        passwordRestore: simple({ url: "/password-restore", component: PasswordResetStep2Page, unrestricted: true }),
        profile: simple({ url: "/profile", component: PasswordChangePage }),
        parameters: buildParameters(simple, pattern),
        experiences: buildExperiences(simple, pattern),
        brands: buildBrands(simple, pattern),
        projectSetups: buildProjectSetups(simple, pattern),
        devices: buildDevices(simple, pattern),
        baseApps: buildApps(simple, pattern),
        libraries: buildLibraries(simple, pattern),
        parametersScopes: buildParametersScopes(simple, pattern),
        connectors: buildConnectors(simple, pattern),
        apiServerVersions: buildApiServerVersions(simple, pattern),
        apiServerInstances: buildApiServerInstances(simple, pattern),
    };
});

export const unrestrictedPages: INavigatablePage<any[]>[] = [navigation.passwordReset, navigation.passwordRestore];
export const restrictedPages = allPages.filter((p) => !unrestrictedPages.includes(p));
