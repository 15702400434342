import { FormSchema } from "@magicware/forms/builder";
import * as yup from "yup";
import { TranslateFunc } from "../../localization";

export interface NewPasswordChangeForm {
    newPassword: string;
    newPassword2: string;
}

export const newPasswordChangeFormSchema = (t: TranslateFunc): FormSchema<NewPasswordChangeForm> => ({
    newPassword: yup.string().min(12, t("min-password-length")).required(t("password-is-required")),
    newPassword2: yup
        .string()
        .required(t("password-confirmation-is-required"))
        .equals([yup.ref("newPassword")], t("passwords-must-match")),
});
