import { createEntityApi } from "@magicware/fetch-api/entities";
import { IdVersion } from "@magicware/fetch-api/types";
import { API_BASE_URL } from "../globals";
import { BaseAppSelectOption } from "./baseApps";
import { createFilesApi } from "./files";
import { LibraryVersionSelectOption } from "./libraryVersions";

export interface BaseAppVersionSelectOption {
    id: number;
    name: string;
    baseApp: BaseAppSelectOption;
}

export interface BaseAppVersionModelBase {
    name: string;
    notes?: string;
}

export interface BaseAppVersionEntity extends BaseAppVersionModelBase {}

export interface BaseAppVersionModelNew {
    name: string;
    baseAppId: number;
}
export interface BaseAppVersionModelUpdate extends BaseAppVersionEntity, IdVersion {
    libraryVersionIds: number[];
}
export interface BaseAppVersionModelDetail extends BaseAppVersionModelBase, IdVersion {
    libraryVersionList: LibraryVersionSelectOption[];
}

export interface BaseAppVersionModelList extends BaseAppVersionSelectOption {}
export interface BaseAppVersionFilter {
    name?: string;
    baseAppId?: number;
    appInstanceId?: number;
}

export const entityApi = createEntityApi<
    BaseAppVersionModelNew,
    BaseAppVersionModelUpdate,
    BaseAppVersionModelDetail,
    BaseAppVersionModelList,
    BaseAppVersionFilter
>("baseAppVersion", `${API_BASE_URL}registry/baseAppVersions`);

export const baseAppVersionsApi = {
    ...entityApi,
    files: createFilesApi("baseAppVersions"),
}
