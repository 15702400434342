import { Alert, AlertProps } from "@mui/material";
import Panel from "./Panel";

export default function PanelAlert(props: AlertProps) {
    return (
        <Panel tw="mb-6">
            <Alert {...props}>{props.children}</Alert>
        </Panel>
    );
}
