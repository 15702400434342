import { createEntityApi } from "@magicware/fetch-api/entities";
import { IdVersion } from "@magicware/fetch-api/types";
import { API_BASE_URL } from "../globals";
import { BrandSelectOption } from "./brands";
import { CodebookOptionModel } from "./codebooks";
import { createFilesApi } from "./files";
import { ParameterSelectOption } from "./parameters";

export interface DeviceSelectOption {
    id: number;
    name: string;
}

export interface DeviceModelBase {
    name: string;
    fullName?: string;
    notes?: string;
    certification?: string;
    url?: string;
    price?: number;
    isPriceHidden: boolean;
}
export interface DeviceEntity extends DeviceModelBase {
    brandId: number;
    parameterIds: number[];
    keywordIds: number[];
    currencyId?: number;
}

export interface DeviceModelNew extends DeviceEntity {}
export interface DeviceModelUpdate extends DeviceEntity, IdVersion {}
export interface DeviceModelDetail extends DeviceModelBase, IdVersion {
    brand: BrandSelectOption;
    keywordList: CodebookOptionModel[];
    parameterList: ParameterSelectOption[];
    currency?: CodebookOptionModel;
}
export interface DeviceModelList extends DeviceSelectOption {
    fullName?: string;
    brand: string;
    parameterList: ParameterSelectOption[];
}
export interface DeviceFilter {
    name?: string;
    brandId?: number[];
    parameterId?: number;
}

export const entityApi = createEntityApi<
    DeviceModelNew,
    DeviceModelUpdate,
    DeviceModelDetail,
    DeviceModelList,
    DeviceFilter
>("device", `${API_BASE_URL}registry/devices`);

export const devicesApi = {
    ...entityApi,
    files: createFilesApi("devices"),
};
