import TitleTabPage from "@magicware/dashboard/navigation/TitleTabPage";
import { useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { appInstanceThunks } from "../../redux/appInstances/appInstancesReducer";
import { RootState } from "../../redux/types";
import { useAppInstanceId } from "../../navigation/appInstances";

export default function AppInstanceTabPage() {
    const appInstanceId = useAppInstanceId();
    const api = useAutoDispatchAsyncThunk(appInstanceThunks.loadTabInfo, { appInstanceId });
    return (
        <TitleTabPage
            apiStatus={api.apiStatus}
            titleSelector={(state: RootState) => state.appInstances.tabInfo?.name}
        />
    );
}
