import tw from "twin.macro";
import Button, { ButtonProps } from "@mui/material/Button";

export interface PageLinkButtonProps {
    page: number;
    text?: React.ReactNode;
    current: number;
    onClick: (newPage: number) => void;
}

export default function PageLinkButton({
    page: p,
    text,
    current,
    onClick,
    ...props
}: PageLinkButtonProps & Omit<ButtonProps, "onClick" | "children">) {
    return (
        <Button
            key={p}
            tw="px-5 min-w-0"
            {...props}
            variant="text"
            onClick={() => onClick(p)}
            sx={p === current ? tw`font-bold` : undefined}
        >
            {text ?? p}
        </Button>
    );
}
