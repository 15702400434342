import { createEntityApi } from "@magicware/fetch-api/entities";
import { IdVersion } from "@magicware/fetch-api/types";
import { API_BASE_URL } from "../globals";
import { ConnectorVersionSelectOption } from "./connectorVersions";
import { DeviceSelectOption } from "./devices";
import { LibraryVersionSelectOption } from "./libraryVersions";
import { ParameterSelectOption } from "./parameters";

export interface ConnectorImplementationModelBase {
    notes?: string;
}

export interface ConnectorImplementationEntity {
    connectorVersionId: number;
    parameterIds: number[];
    deviceIds: number[];
}

export interface ConnectorImplementationModelNew extends ConnectorImplementationEntity {
    libraryVersionId: number;
}
export interface ConnectorImplementationModelUpdate extends ConnectorImplementationEntity, IdVersion {}
export interface ConnectorImplementationModelDetail extends ConnectorImplementationModelBase, IdVersion {
    parameterList: ParameterSelectOption[];
    deviceList: DeviceSelectOption[];
    libraryVersion: LibraryVersionSelectOption;
    connectorVersion: ConnectorVersionSelectOption;
}

export interface ConnectorImplementationModelList {
    id: number;
    libraryVersionId: number;
    connectorVersion: ConnectorVersionSelectOption;
}
export interface ConnectorImplementationFilter {
    connectorName?: string;
    libraryVersionId?: number;
}

export const entityApi = createEntityApi<
    ConnectorImplementationModelNew,
    ConnectorImplementationModelUpdate,
    ConnectorImplementationModelDetail,
    ConnectorImplementationModelList,
    ConnectorImplementationFilter
>("connectorImplementation", `${API_BASE_URL}registry/connectorImplementations`);

export const connectorImplementationsApi = entityApi;
