import { createEntityApi } from "@magicware/fetch-api/entities";
import { IdVersion } from "@magicware/fetch-api/types";
import { API_BASE_URL } from "../globals";
import { AppInstanceSelectOption } from "./appInstances";
import { BaseAppVersionSelectOption } from "./baseAppVersions";

export interface AppInstanceVersionSelectOption {
    id: number;
    name: string;
    appInstance: AppInstanceSelectOption;
}

export interface AppInstanceVersionModelBase {
    name: string;
    environment?: string;
    notes?: string;
}

export interface AppInstanceVersionEntity {
    baseAppVersionId?: number;
}

export interface AppInstanceVersionModelNew extends AppInstanceVersionEntity {
    appInstanceId: number;
}
export interface AppInstanceVersionModelUpdate extends AppInstanceVersionEntity, IdVersion {}
export interface AppInstanceVersionModelDetail extends AppInstanceVersionModelBase, IdVersion {
    baseAppVersion?: BaseAppVersionSelectOption;
}

export interface AppInstanceVersionModelList extends AppInstanceVersionSelectOption {
    baseAppVersion?: BaseAppVersionSelectOption;
}
export interface AppInstanceVersionFilter {
    name?: string;
    appInstanceId?: number;
}

export const entityApi = createEntityApi<
    AppInstanceVersionModelNew,
    AppInstanceVersionModelUpdate,
    AppInstanceVersionModelDetail,
    AppInstanceVersionModelList,
    AppInstanceVersionFilter
>("appInstanceVersion", `${API_BASE_URL}registry/appInstanceVersions`);

export const appInstanceVersionsApi = entityApi;
