import { faHashtag, faPlayCircle, faTabletScreenButton } from "@fortawesome/free-solid-svg-icons";
import { prepareBreadcrumb } from "@magicware/dashboard/navigation/breadcrumbs";
import { CreatePatternMethod, CreateSimpleMethod } from "@magicware/dashboard/navigation/builder";
import { prepareRootTab, prepareTab } from "@magicware/dashboard/navigation/tabs";
import { createUseIntParam } from "@magicware/dashboard/navigation/useIntParam";
import BaseAppDetailTabPage from "../components/baseApps/BaseAppDetailTabPage";
import BaseAppFilesTabPage from "../components/baseApps/BaseAppFilesTabPage";
import BaseAppNewPage from "../components/baseApps/BaseAppNewPage";
import BaseAppTabPage from "../components/baseApps/BaseAppTabPage";
import BaseAppVersionDetailTab from "../components/baseAppVersions/BaseAppVersionDetailTab";
import BaseAppVersionFilesTabPage from "../components/baseAppVersions/BaseAppVersionFilesTabPage";
import BaseAppVersionListTab from "../components/baseAppVersions/BaseAppVersionListTab";
import BaseAppVersionNewTab from "../components/baseAppVersions/BaseAppVersionNewTab";
import BaseAppVersionTabPage from "../components/baseAppVersions/BaseAppVersionTabPage";
import ConfigurationTabPage from "../components/configuration/ConfigurationTabPage";
import { buildAppInstances } from "./appInstances";
import { filesIcon } from "./files";

export const useBaseAppId = createUseIntParam("baseAppId");
export const useBaseAppVersionId = createUseIntParam("baseAppVersionId");

export const baseAppIcon = faPlayCircle;
export const appInstanceIcon = faTabletScreenButton;
export const baseAppVersionIcon = faHashtag;

export const buildApps = (simple: CreateSimpleMethod, pattern: CreatePatternMethod) => {
    let tabIndex = 1;
    return simple({
        url: "/baseApps",
        title: (t) => t("baseApps-title"),
        component: ConfigurationTabPage,
        breadcrumb: prepareBreadcrumb((t) => t("baseApps-breadcrumb")),

        new: simple({
            url: "/baseApps/new",
            component: BaseAppNewPage,
            breadcrumb: prepareBreadcrumb((t) => t("new-baseApp-breadcrumb")),
        }),
        detail: pattern({
            pattern: "/baseApps/:baseAppId",
            component: BaseAppTabPage,
            url: (baseAppId: number) => `/baseApps/${baseAppId}`,
            tab: prepareRootTab((t) => t("baseApp-detail-tab"), baseAppIcon, BaseAppDetailTabPage),
            breadcrumb: prepareBreadcrumb((t) => t("baseApp-detail-breadcrumb")),

            files: pattern({
                pattern: "/baseApps/:baseAppId/files",
                component: BaseAppTabPage,
                url: (baseAppId: number) => `/baseApps/${baseAppId}/files`,
                breadcrumb: prepareBreadcrumb((t) => t("files-breadcrumb")),
                tab: prepareTab(tabIndex++, (t) => t("files-tab"), filesIcon, BaseAppFilesTabPage),
            }),

            versions: pattern({
                pattern: "/baseApps/:baseAppId/versions",
                component: BaseAppTabPage,
                url: (baseAppId: number) => `/baseApps/${baseAppId}/versions`,
                breadcrumb: prepareBreadcrumb((t) => t("baseAppVersions-breadcrumb")),
                tab: prepareTab(tabIndex++, (t) => t("baseAppVersions-tab"), baseAppVersionIcon, BaseAppVersionListTab),

                new: pattern({
                    pattern: "/baseApps/:baseAppId/versions/new",
                    url: (baseAppId: number) => `/baseApps/${baseAppId}/versions/new`,
                    component: BaseAppTabPage,
                    breadcrumb: prepareBreadcrumb((t) => t("new-baseAppVersion-breadcrumb")),
                    tab: prepareRootTab((t) => t("new-baseAppVersion-title"), baseAppVersionIcon, BaseAppVersionNewTab),
                }),
                detail: pattern({
                    pattern: "/baseApps/:baseAppId/versions/:baseAppVersionId",
                    component: BaseAppVersionTabPage,
                    url: (baseAppId: number, baseAppVersionId: number) =>
                        `/baseApps/${baseAppId}/versions/${baseAppVersionId}`,
                    breadcrumb: prepareBreadcrumb((t) => t("baseAppVersion-detail-breadcrumb")),
                    tab: prepareRootTab(
                        (t) => t("baseAppVersion-detail-title"),
                        baseAppVersionIcon,
                        BaseAppVersionDetailTab,
                    ),

                    files: pattern({
                        pattern: "/baseApps/:baseAppId/versions/:baseAppVersionId/files",
                        component: BaseAppVersionTabPage,
                        url: (baseAppId: number, baseAppVersionId: number) =>
                            `/baseApps/${baseAppId}/versions/${baseAppVersionId}/files`,
                        breadcrumb: prepareBreadcrumb((t) => t("files-breadcrumb")),
                        tab: prepareTab(tabIndex++, (t) => t("files-tab"), filesIcon, BaseAppVersionFilesTabPage),
                    }),
                }),
            }),

            apps: buildAppInstances("baseApps", "baseAppId", BaseAppTabPage, tabIndex++, simple, pattern),
        }),
    });
};
