import red from "@mui/material/colors/red";
import createTheme from "@mui/material/styles/createTheme";

// A custom theme for this app
const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 640,
            md: 768,
            lg: 1024,
            xl: 1280,
            xl2: 1536,
        },
    },
    palette: {
        primary: {
            main: "#0054a6",
            light: "#5280d8",
            dark: "#002c76",
        },
        secondary: {
            main: "#e3f2fa",
            light: "#ffffff",
            dark: "#b1bfc7",
        },
        error: {
            main: red.A400,
        },
        background: {
            default: "#fff",
        },
    },
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                paper: {
                    boxShadow:
                        "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: "outlined",
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: "outlined",
            },
        },
        MuiButton: {
            defaultProps: {
                variant: "contained",
            },
            // styleOverrides: {
            //     root: {
            //         ...tw`rounded-xl`,
            //     },
            // },
        },
        MuiAlert: {
            defaultProps: {
                severity: "info",
            },
        },
    },
});

export default theme;
