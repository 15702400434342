import { ParameterAt } from "@magicware/utils/types";
import i18next, { Resource } from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next, TFunction } from "react-i18next";
import csGeneralJson from "./cs/general.json";
import enGeneralJson from "./en/general.json";

interface IResources extends Resource {
    cs: { translation: typeof csGeneralJson };
    // tím, že do en dáváme typ cs, tak máme zároveň i validaci, že nám žádný překlad v EN mutaci nechybí
    // nicméně klidně (pokud překlady dodává později někdo jiný), je možné tem dát typeof enGeneralJson,
    // a tím defacto jen vypneme kontrolu na chybějící překlady
    en: { translation: typeof enGeneralJson };
}

const resources: IResources = {
    cs: { translation: csGeneralJson },
    en: { translation: enGeneralJson },
};

export const languages = {
    cs: { label: "Čeština", short: "CZ", id: "cs" as const },
    en: { label: "English", short: "EN", id: "en" as const },
};

export type TranslateFunc = TFunction<"general">;

export const delayedTranslation: (key: ParameterAt<TranslateFunc, 0>) => string = (k) => k as string;

export function initLocalization() {
    i18next
        .use(I18nextBrowserLanguageDetector)
        .use(initReactI18next)
        .init({
            resources: resources,
            fallbackLng: "cs",
            debug: true,
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
        });
}

export const changeLanguage = (lng: keyof typeof languages) => {
    i18next.changeLanguage(lng);
};

/** Statická funkce na překlad bez použítí hooku useTranslation. Dá se používat tam, kde se vyvolává business logika
 *  mimo komponenty. Toto přímé volání narozdíl od useTranslation nevyvolává re-render komponent! */
export const staticTranslate = ((key: any, options?: any) => i18next.t(key, options)) as TranslateFunc;
