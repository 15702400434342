import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TabName } from "./utils";

export default function useTabNames() {
    const { t } = useTranslation();
    return useCallback(
        (name: TabName) => {
            if (typeof name === "function") return name(t);
            return name;
        },
        [t],
    );
}
