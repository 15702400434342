import { buildSearchForm } from "@magicware/forms/builder/searchForm";
import { SearchForm } from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import { useTranslation } from "react-i18next";
import { connectorCertificationThunks } from "../../redux/connectorCertifications/connectorCertificationsReducer";
import { connectorCertificationListForProjectSetup } from "../../redux/connectorCertifications/selectors";
import { RootState } from "../../redux/types";
import { FormConnectorsAutocomplete } from "../connectors/FormConnectorsAutocomplete";

const { f, useSearchForm } = buildSearchForm(
    connectorCertificationThunks.loadList,
    (state: RootState, projectSetupId?: number) =>
        connectorCertificationListForProjectSetup(state, projectSetupId)?.filter,
    (projectSetupId?: number) => ({ projectSetupId }),
);

export interface ConnectorCertificationSearchFormProps {
    projectSetupId?: number;
}

export default function ConnectorCertificationSearchForm(props: ConnectorCertificationSearchFormProps) {
    const { t } = useTranslation();
    const searchForm = useSearchForm(props.projectSetupId);
    return (
        <SearchForm {...searchForm}>
            <FormGrid tw="md:grid-cols-2">
                <FormConnectorsAutocomplete
                    f={f}
                    field="connectorId"
                    label={t("connector-label")}
                    searchIcon
                    filter={{}}
                />
            </FormGrid>
        </SearchForm>
    );
}
