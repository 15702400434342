import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import * as yup from "yup";
import { useFormThunk } from "../../redux/hooks";
import { Login } from "../../services/api/user";
import Form from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import FormSubmitButton from "@magicware/forms/components/FormSubmitButton";
import FormSubmitResult from "@magicware/forms/components/FormSubmitResult";
import FormTextField from "@magicware/forms/mui/FormTextField";
import { buildForm } from "@magicware/forms/builder";
import { uiThunks } from "../../redux/ui/uiReducer";

type TwoPhaseLoginFormModel = {
    twoPhaseAuthCode: string;
};

const { f, defaults, validationSchema } = buildForm<TwoPhaseLoginFormModel>()(() => ({
    props: {
        extra: [],
        remove: [],
    },
    schema: (_, t) => ({
        twoPhaseAuthCode: yup.string().required(t("2fa-code-is-required")),
    }),
}));

export interface TwoPhaseLoginFormProps {
    login: string;
    twoPhaseAuthToken: string;
    onCancel?: () => void;
}

export default function TwoPhaseLoginForm({ onCancel, ...props }: TwoPhaseLoginFormProps) {
    const { t } = useTranslation();
    const validationsMap: Login<typeof f>["ValidationMap"] = {
        messages: { InvalidLogin: t("2fa-code-is-not-valid") },
        autoMap: "capitalize",
    };
    const dispatchLogin = useFormThunk(uiThunks.login, validationsMap);
    const handleOnSubmit = (form: typeof f) =>
        dispatchLogin({ ...props, twoPhaseAuthCode: form.twoPhaseAuthCode.trim() });
    return (
        <Form initialValues={defaults} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
            <FormGrid>
                <Typography color="text.secondary">{t("2fa-code-sent-info-text")}</Typography>
                <FormTextField f={f} field="twoPhaseAuthCode" label={t("2fa-code-label")} />
                <FormSubmitResult />
            </FormGrid>
            <div tw="flex items-center justify-between mt-6">
                <FormSubmitButton tw="whitespace-nowrap" ignoreDirty>
                    {t("log-in-button")}
                </FormSubmitButton>
                <Button tw="text-right" variant="text" onClick={onCancel}>
                    {t("back-to-login-button")}
                </Button>
            </div>
        </Form>
    );
}
