import { createEntityAdapter, EntityState } from "@magicware/redux/entities";
import { anchoreThunks, AsyncSliceState, createAppAsyncThunk, addAsyncThunkReducers } from "@magicware/redux/thunks";
import { createSlice } from "@reduxjs/toolkit";
import {
    entityApi,
    ProjectSetupModelDetail,
    ProjectSetupFilter,
    ProjectSetupModelList,
    ProjectSetupTabInfo,
    projectSetupsApi,
} from "../../services/api/projectSetups";
import addVersionUpdateReducer from "../files/addVersionUpdateReducer";
import { RootState } from "../types";

export interface State
    extends EntityState<ProjectSetupModelDetail, ProjectSetupModelList, ProjectSetupFilter>,
        AsyncSliceState {
    tabInfo?: ProjectSetupTabInfo;
}

const initialState: State = { async: {} };

const adapter = createEntityAdapter("projectSetup", entityApi, ["name", "isOwn"], {
    onDetailUpdated: (state: State, payload) => {
        if (payload.name && state.tabInfo?.id === payload.id) state.tabInfo.name = payload.name;
    },
});

const extraThunks = {
    loadTabInfo: createAppAsyncThunk(
        "loadTabInfo",
        "exclusive",
        (arg: { projectSetupId: number }, thunkApi) =>
            projectSetupsApi.loadTabInfo(arg.projectSetupId, thunkApi.signal),
        (arg) => arg.projectSetupId.toString(),
    ),
};

const slice = createSlice({
    name: "projectSetups",
    initialState,
    reducers: adapter.getReducers(),
    extraReducers: (builder) => {
        adapter.addExtraReducers(builder);
        addVersionUpdateReducer(builder, projectSetupsApi.files);
        addAsyncThunkReducers(builder, extraThunks.loadTabInfo, {
            fulfilled: (state, action) => {
                state.tabInfo = action.payload;
            },
        });
    },
});

export const projectSetupThunks = anchoreThunks((state: RootState) => state.projectSetups, {
    ...extraThunks,
    ...adapter.thunks,
});

export const actions = slice.actions;
export const { updateDetail, clearList, removeListItems, updateListItems, setPage } = slice.actions;
export default slice.reducer;
