import { SearchFormPanel } from "@magicware/dashboard/Panel";
import DataList from "@magicware/data-lists/DataList";
import { FormStateApiContextProvider } from "@magicware/forms/components/FormStateApiContext";
import { useTranslation } from "react-i18next";
import { apiServerVersionThunks, setPage } from "../../redux/apiServerVersions/apiServerVersionsReducer";
import { RootState } from "../../redux/types";
import EntityListItemMenuContext from "../entities/EntityListItemMenuContext";
import ApiServerVersionListItem from "./ApiServerVersionListItem";
import ApiServerVersionListMenuContext from "./ApiServerVersionListMenuContext";
import ApiServerVersionSearchForm from "./ApiServerVersionSearchForm";

export default function ApiServerVersionListPage() {
    const { t } = useTranslation();
    return (
        <FormStateApiContextProvider>
            <SearchFormPanel>
                <ApiServerVersionSearchForm />
            </SearchFormPanel>
            <ApiServerVersionListMenuContext>
                <EntityListItemMenuContext
                    deleteSnackTitle={t("apiServerVersion-deletion")}
                    deleteThunk={apiServerVersionThunks.delete}
                >
                    <DataList
                        dataSelector={(state: RootState) => state.apiServerVersions.list}
                        setPageActionCreator={setPage}
                        emptyList={t("no-apiServerVersions-message")}
                    >
                        {(id) => <ApiServerVersionListItem appId={id} />}
                    </DataList>
                </EntityListItemMenuContext>
            </ApiServerVersionListMenuContext>
        </FormStateApiContextProvider>
    );
}
