import AsyncContent from "@magicware/dashboard/AsyncContent";
import { useTranslation } from "react-i18next";
import { useBrandId } from "../../navigation/brands";
import { brandThunks } from "../../redux/brands/brandsReducer";
import { useAppDispatch, useAppSelectorEnsure, useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { brandsApi } from "../../services/api/brands";
import BrandForm, { brandForm } from "./BrandForm";

const { useToFormFromModel } = brandForm.hooks;

export default function BrandDetailTabPage() {
    const api = useAutoDispatchAsyncThunk(brandThunks.load, useBrandId());
    return <AsyncContent {...api} component={BrandDetailTabPageLoaded} />;
}

function BrandDetailTabPageLoaded() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const brandDetail = useAppSelectorEnsure((state) => state.brands.detail);
    const initialValues = useToFormFromModel(brandDetail);
    const onSubmit = async (form: typeof brandForm.f) => {
        const result = await brandsApi.forms.update(brandForm.transform.formTo.update(form, brandDetail));
        return brandForm.apiUtils.handleUpdateEntityResult(dispatch, result, form);
    };
    return (
        <BrandForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            savePanel={{ successMessage: t("brand-has-been-saved") }}
        />
    );
}
