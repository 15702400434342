import { faStar, faMobileScreen } from "@fortawesome/free-solid-svg-icons";
import DataIcon from "@magicware/data-lists/DataIcon";
import DataListItem, { MainText, SecondaryText } from "@magicware/data-lists/DataListItem";
import { StyledComponentProps } from "@mui/material";
import { useAppInstaceNavigation } from "../../navigation/appInstances";
import { baseAppIcon } from "../../navigation/baseApps";
import { useNavigationClick } from "../../navigation/hooks";
import { useAppSelector } from "../../redux/hooks";

export interface AppInstanceListItemProps extends StyledComponentProps {
    appInstanceId: number;
    navigation: ReturnType<typeof useAppInstaceNavigation>;
}

export default function AppInstanceListItem({
    appInstanceId,
    navigation: [parentId, navigation],
    ...props
}: AppInstanceListItemProps) {
    const obj = useAppSelector((state) => state.appInstances.list?.byId[appInstanceId]);
    const editClickFactory = useNavigationClick();
    if (!obj) return null;
    return (
        <DataListItem
            {...props}
            data-id={obj.id}
            onClick={editClickFactory(() => navigation.detail.url(parentId, obj.id))}
        >
            <MainText tw="mr-10">{obj.name}</MainText>
            <div tw="flex items-center space-x-2">
                <SecondaryText>
                    <DataIcon icon={faMobileScreen} />
                    {obj.platform}
                </SecondaryText>
                {obj.baseApp && (
                    <SecondaryText>
                        <DataIcon icon={baseAppIcon} />
                        {obj.baseApp}
                    </SecondaryText>
                )}
                {obj.rating !== undefined && (
                    <SecondaryText>
                        <DataIcon icon={faStar} />
                        {obj.rating}
                    </SecondaryText>
                )}
            </div>
        </DataListItem>
    );
}
