import DataListItem, { MainText } from "@magicware/data-lists/DataListItem";
import { StyledComponentProps } from "@mui/material";
import { useNavigationClick } from "../../navigation/hooks";
import { useAppSelector } from "../../redux/hooks";

export interface ApiServerInstanceListItemProps extends StyledComponentProps {
    appId: number;
}

export default function ApiServerInstanceListItem({ appId, ...props }: ApiServerInstanceListItemProps) {
    const obj = useAppSelector((state) => state.apiServerInstances.list?.byId[appId]);
    const editClickFactory = useNavigationClick();
    if (!obj) return null;
    return (
        <DataListItem
            {...props}
            data-id={obj.id}
            onClick={editClickFactory((n) => n.apiServerInstances.detail.url(obj.id))}
        >
            <MainText tw="mr-10">{obj.name}</MainText>
        </DataListItem>
    );
}
