import { useSelector } from "react-redux";
import AsyncContent, { AsyncContentProps } from "../AsyncContent";
import PageContainerLoader from "../PageContainerLoader";
import { GenericTabPage } from "./GenericTabPage";
import PageHeader from "./PageHeader";

export interface TitleTabPageProps {
    apiStatus: AsyncContentProps["apiStatus"];
    titleSelector: (state: any) => string | undefined | null;
}

export default function TitleTabPage(props: TitleTabPageProps) {
    return <AsyncContent {...props} component={TitleTabPageLoaded} loaderComponent={PageContainerLoader} />;
}

function TitleTabPageLoaded({ titleSelector }: Pick<TitleTabPageProps, "titleSelector">) {
    const title = useSelector(titleSelector);
    return <GenericTabPage header={title && <PageHeader title={title} />} />;
}
