import { OnProgressFunc } from "@magicware/fetch-api/fetch";
import { SnackbarKey, SnackbarMessage } from "notistack";
import { renderUploadSnackContent } from "../FileProgressSnackContent";
import { useProgressApi } from "./useProgressApi";

export function useUploadApi<TParams extends any[], TResult>(
    apiCall: (onProgress: OnProgressFunc, ...rest: TParams) => Promise<TResult>,
    snackContent: (
        key: SnackbarKey,
        message: SnackbarMessage,
        cancel?: () => void,
    ) => React.ReactNode = renderUploadSnackContent,
) {
    return useProgressApi(apiCall, snackContent);
}
