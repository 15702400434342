import { projectSetupThunks } from "../../redux/projectSetups/projectSetupsReducer";
import { useAppDispatch, useAppSelectorEnsure, useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { projectSetupsApi } from "../../services/api/projectSetups";
import ProjectSetupForm, { projectSetupForm } from "./ProjectSetupForm";
import AsyncContent from "@magicware/dashboard/AsyncContent";
import { useProjectSetupId } from "../../navigation/projectSetups";
import { useTranslation } from "react-i18next";

const { useToFormFromModel } = projectSetupForm.hooks;

export default function ProjectSetupDetailTab() {
    const api = useAutoDispatchAsyncThunk(projectSetupThunks.load, useProjectSetupId());
    return <AsyncContent {...api} component={ProjectSetupDetailTabLoaded} />;
}

function ProjectSetupDetailTabLoaded() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const projectSetupDetail = useAppSelectorEnsure((state) => state.projectSetups.detail);
    const initialValues = useToFormFromModel(projectSetupDetail);
    const onSubmit = async (form: typeof projectSetupForm.f) => {
        const result = await projectSetupsApi.forms.update(
            projectSetupForm.transform.formTo.update(form, projectSetupDetail),
        );
        return projectSetupForm.apiUtils.handleUpdateEntityResult(dispatch, result, form);
    };
    return (
        <ProjectSetupForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            savePanel={{ successMessage: t("project-has-been-saved") }}
        />
    );
}
