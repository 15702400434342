import DataListItem, { MainText } from "@magicware/data-lists/DataListItem";
import { StyledComponentProps } from "@mui/material";
import { useNavigationClick } from "../../navigation/hooks";
import { useLibraryId } from "../../navigation/libraries";
import { useAppSelector } from "../../redux/hooks";

export interface ConnectorImplementationListItemProps extends StyledComponentProps {
    connectorImplementationId: number;
}

export default function ConnectorImplementationListItem({
    connectorImplementationId,
    ...props
}: ConnectorImplementationListItemProps) {
    const obj = useAppSelector((state) => state.connectorImplementations.list?.byId[connectorImplementationId]);
    const editClickFactory = useNavigationClick();
    const libraryId = useLibraryId();
    if (!obj) return null;
    return (
        <DataListItem
            {...props}
            data-id={obj.id}
            onClick={editClickFactory((n) =>
                n.libraries.detail.versions.detail.connectors.detail.url(libraryId, obj.libraryVersionId, obj.id),
            )}
        >
            <MainText tw="mr-10">
                {obj.connectorVersion.connector.name} - {obj.connectorVersion.name}
            </MainText>
        </DataListItem>
    );
}
