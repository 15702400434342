import { MutatingDots } from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import tw from "twin.macro";

export interface LoaderProps {
    modal?: boolean;
    center?: boolean;
    children?: React.ReactNode;
}

export default function Loader({ modal = false, center = true, children }: LoaderProps) {
    if (modal)
        return (
            <div tw="bg-black bg-opacity-5 absolute rounded-lg left-0 right-0 bottom-0 top-0 flex justify-center items-center">
                <div tw="relative bg-white bg-opacity-50  rounded-full">
                    <MutatingDots color="#00BFFF" height={100} width={100} />
                    <div tw="absolute bg-transparent w-full h-full mt-12 top-0 flex items-center justify-center">
                        {children ?? "LOADING..."}
                    </div>
                </div>
            </div>
        );
    return (
        <div css={[tw`relative mb-4`, center ? tw`flex h-full w-full items-center justify-center` : ""]}>
            <MutatingDots color="#00BFFF" height={100} width={100} />
            <div tw="absolute bg-transparent w-full h-full mt-12 top-0 flex items-center justify-center">
                {children ?? "LOADING..."}
            </div>
        </div>
    );
}
