import { Button, ButtonProps } from "@mui/material";
import { useForm } from "react-final-form";
import useDisabled from "../hooks/useDisabled";

export interface FormResetButtonProps {
    resetValue: any;
}

export default function FormResetButton({
    children,
    resetValue,
    disabled,
    ...props
}: FormResetButtonProps & ButtonProps) {
    disabled = useDisabled(disabled);
    const form = useForm();
    const handleReset = () => {
        form.reset(resetValue);
    };
    return (
        <Button type="button" variant="outlined" onClick={handleReset} {...props} disabled={disabled}>
            {children}
        </Button>
    );
}
