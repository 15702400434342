import { DateFn } from "@magicware/utils/DateFn";
import { RemoveOpt } from "@magicware/utils/types";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiError, isOldVersionError, isValidationError, OldVersionError } from "./errors";

export type OnProgressFunc = (percent: number) => void;

export type CanelableApiParams<TParams extends any[]> = TParams extends [...params: infer T, signal?: AbortSignal]
    ? RemoveOpt<T>
    : RemoveOpt<TParams>;

if (process.env.REACT_APP_VERSION) {
    axios.defaults.headers.post["X-AppVersion"] = process.env.REACT_APP_VERSION;
    axios.defaults.headers.put["X-AppVersion"] = process.env.REACT_APP_VERSION;
}

let g_handleOldVersion: undefined | ((err: OldVersionError) => any);

export function initReduxErrorsInterceptor(
    handleOldVersion: (err: OldVersionError) => any,
    handleUnauthenticated: () => any,
) {
    g_handleOldVersion = handleOldVersion;
    (axios.interceptors.response.use as any)(
        null,
        (error: any) => {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 401) handleUnauthenticated();
                else if (isValidationError(error.response?.data)) return Promise.reject(error.response?.data);
                else if (isOldVersionError(error)) handleOldVersion(error);
            }
            return Promise.reject(error);
        },
        { synchronous: true },
    );
}

function processResponse(response: AxiosResponse<any>) {
    const errorCode = response.headers["x-errorcode"];
    if (errorCode) {
        const error: ApiError = { errorCode, errorData: response.data };
        if (g_handleOldVersion && isOldVersionError(error)) g_handleOldVersion(error);
        else throw error;
    }
    if (response.status === 204) return;
    if (response.status === 200) {
        const contentType = response.headers["content-type"] as string | undefined;
        if (
            contentType &&
            contentType.startsWith("application/json") &&
            typeof response.data !== "object" &&
            typeof response.data !== "number"
        )
            throw new Error("Response is not a valid JSON object.");
        return response.data;
    }
}

export async function postData<TResponse = any>(
    url = "",
    data?: any | FormData,
    options?: AxiosRequestConfig,
): Promise<TResponse> {
    const response = await axios.post(url, data, options);
    return processResponse(response) as TResponse;
}

export async function postDataWithProgress<TResponse = any>(
    url: string,
    data: any | FormData,
    onProgress?: OnProgressFunc,
    options?: AxiosRequestConfig,
) {
    const response = await axios.post(url, data, {
        ...(options ?? {}),
        responseType: "json",
        onUploadProgress: getProgressEvent(onProgress),
    });
    return processResponse(response) as TResponse;
}

export async function putData<TResponse = any>(url = "", data = {}, options?: AxiosRequestConfig) {
    const response = await axios.put(url, data, options);
    return processResponse(response) as TResponse;
}

export async function getData<TResponse = any>(url: string, urlParamsRequest?: any, options?: AxiosRequestConfig) {
    url = getUrl(url, urlParamsRequest);
    const response = await axios.get(url, options);
    return processResponse(response) as TResponse;
}

export async function postForm<TResponse = any>(url: string, dataObject?: any, options?: AxiosRequestConfig) {
    let params: FormData | undefined = undefined;
    if (dataObject) {
        params = new FormData();
        appendParams(params, dataObject);
    }
    return postData<TResponse>(url, dataObject, options);
}

export function getUrl(url: string, urlParamsRequest?: any) {
    if (urlParamsRequest) {
        const params = new URLSearchParams();
        appendParams(params, urlParamsRequest);
        url = `${url}?${params.toString()}`;
    }
    return url;
}

function appendParams(params: { append: (key: string, val: string) => void }, rq: any) {
    for (const key in rq) {
        const val = rq[key];
        if (val) {
            if (val instanceof Array) params.append(key, val.join(","));
            else if (val instanceof Date) params.append(key, DateFn.f_yyyyMMdd(val));
            else params.append(key, val);
        }
    }
}

export async function getFileDownload(
    url: string,
    defaultFileName: string,
    onProgress?: OnProgressFunc,
    options?: AxiosRequestConfig,
) {
    const response = await axios.get(url, {
        ...(options ?? {}),
        responseType: "blob",
        onDownloadProgress: getProgressEvent(onProgress),
    });
    return processFileDownload(response, defaultFileName);
}

export async function postFileDownload(
    url: string,
    data: any,
    defaultFileName: string,
    onProgress?: OnProgressFunc,
    options?: AxiosRequestConfig,
) {
    const response = await axios.post(url, data, {
        ...(options ?? {}),
        responseType: "blob",
        onDownloadProgress: getProgressEvent(onProgress),
    });
    return processFileDownload(response, defaultFileName);
}

function processFileDownload(response: AxiosResponse<any>, defaultFileName: string) {
    let fileName: string | undefined;
    const contentDisposition = response.headers["content-disposition"] as string;
    if (contentDisposition) {
        const nameMatch = contentDisposition.match(/filename="(?<name>.*)"/);
        fileName = nameMatch?.groups && nameMatch.groups["name"];
    }
    return { fileName: fileName ?? defaultFileName, data: new Blob([processResponse(response)]) };
}

export async function postFile<TResponse = any>(
    url: string,
    file: File | Blob,
    onProgress?: OnProgressFunc,
    options?: AxiosRequestConfig & { fileName?: string },
) {
    const formData = new FormData();
    formData.append("file", file, options?.fileName);
    return postDataWithProgress<TResponse>(url, formData, onProgress, options);
}

function getProgressEvent(onProgress?: OnProgressFunc) {
    if (!onProgress) return undefined;
    return (ev: any) => onProgress && onProgress(ev.total && Math.floor((ev.loaded / ev.total) * 100));
}
