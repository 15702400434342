import { createEntityAdapter, EntityState } from "@magicware/redux/entities";
import { addAsyncThunkReducers, anchoreThunks, AsyncSliceState, createAppAsyncThunk } from "@magicware/redux/thunks";
import { appendNewEntities } from "@magicware/utils/DataDictionary";
import { createSlice } from "@reduxjs/toolkit";
import { BrandFilter, BrandModelDetail, BrandModelList, brandsApi, entityApi } from "../../services/api/brands";
import addVersionUpdateReducer from "../files/addVersionUpdateReducer";
import { RootState } from "../types";

export interface State extends EntityState<BrandModelDetail, BrandModelList, BrandFilter>, AsyncSliceState {}

const initialState: State = { async: {} };

const adapter = createEntityAdapter("brand", entityApi, ["name"]);

const extraThunks = {
    ensure: createAppAsyncThunk("ensure", (arg: { names: string[] }) => brandsApi.ensure(arg.names)),
};

const slice = createSlice({
    name: "brands",
    initialState,
    reducers: adapter.getReducers(),
    extraReducers: (builder) => {
        adapter.addExtraReducers(builder);
        addAsyncThunkReducers(builder, extraThunks.ensure, {
            fulfilled: (state, action) => appendNewEntities(state.list, action.payload),
        });
        addVersionUpdateReducer(builder, brandsApi.files);
    },
});

export const brandThunks = anchoreThunks((state: RootState) => state.brands, {
    ...extraThunks,
    ...adapter.thunks,
});

export const actions = slice.actions;
export const { updateDetail, clearList, removeListItems, updateListItems, setPage } = slice.actions;
export default slice.reducer;
