import { useUploadApi } from "@magicware/dashboard/hooks/useUploadApi";
import DataListBulkActionsContext, { getNewItemMenuAction } from "@magicware/data-lists/DataListBulkActionsContext";
import { MenuAction } from "@magicware/data-lists/DataListContextMenu";
import { OnProgressFunc } from "@magicware/fetch-api/fetch";
import { useRef } from "react";
import { fileThunks } from "../../redux/files/filesReducer";
import { useAppDispatch } from "../../redux/hooks";
import { FilesApi } from "../../services/api/files";

export interface FileListMenuContextProps {
    api: FilesApi;
    ownerId: number;
    children?: React.ReactNode;
}

export default function FileListMenuContext({ api, ownerId, children }: FileListMenuContextProps) {
    //const { t } = useTranslation();
    const actions: MenuAction[] = [];
    const inputRef = useRef<HTMLInputElement>(null);
    const addAction = getNewItemMenuAction("Add File", () => inputRef.current?.click());
    const dispatch = useAppDispatch();
    const dispatchUploadFile = (onProgress: OnProgressFunc, f: File) =>
        dispatch(
            fileThunks.newFile({
                api,
                onProgress,
                data: f,
                file: { fileName: f.name, isLogo: false, ownerId: ownerId },
            }),
        );
    const uploadFile = useUploadApi(dispatchUploadFile);
    const handleNewFile: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        if (!e.target.files) return;
        for (let i = 0; i < e.target.files.length; i++) {
            const f = e.target.files[i];
            uploadFile(f.name, f);
        }
        e.target.value = "";
    };
    return (
        <DataListBulkActionsContext defaultAction={addAction} actions={actions}>
            <input
                tw="hidden"
                ref={inputRef}
                type="file"
                multiple
                accept="image/jpeg,image/png,application/pdf,application/zip"
                onChange={handleNewFile}
            />
            {children}
        </DataListBulkActionsContext>
    );
}
