import TitleTabPage from "@magicware/dashboard/navigation/TitleTabPage";
import { useApiServerInstanceId } from "../../navigation/apiServerInstances";
import { useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { apiServerInstanceThunks } from "../../redux/apiServerInstances/apiServerInstancesReducer";
import { RootState } from "../../redux/types";

export default function ApiServerInstanceTabPage() {
    const apiServerInstanceId = useApiServerInstanceId();
    const api = useAutoDispatchAsyncThunk(apiServerInstanceThunks.load, apiServerInstanceId);
    return (
        <TitleTabPage
            apiStatus={api.apiStatus}
            titleSelector={(state: RootState) => state.apiServerInstances.detail?.name}
        />
    );
}
