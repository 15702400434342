import Panel from "@magicware/dashboard/Panel";
import { buildForm } from "@magicware/forms/builder";
import Form, { FormImplementationProps } from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import FormTextField from "@magicware/forms/mui/FormTextField";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { updateDetail } from "../../redux/libraryVersions/libraryVersionsReducer";
import { LibraryVersionModelDetail, LibraryVersionModelUpdate } from "../../services/api/libraryVersions";
import { FormParametersAutocomplete } from "../parameters/FormParametersAutocomplete";

export const libraryVersionForm = buildForm<LibraryVersionModelDetail>()(() => ({
    props: {
        remove: ["id", "version", "library"],
        extra: [],
    },
    transformations: {
        toForm: { fromModel: (h, m: LibraryVersionModelDetail) => h.fromModel(m) },
        formTo: {
            update: (h, f, m: LibraryVersionModelDetail): LibraryVersionModelUpdate => h.toUpdateEntity(f, m),
        },
    },
    updateReduxModel: updateDetail,
    schema: (_, t) => ({
        name: yup.string().required(t("name-is-required")),
        apiParameterList: yup.array().default([]),
        notes: yup.string(),
    }),
}));

const f = libraryVersionForm.f;

export default function LibraryVersionForm(props: FormImplementationProps<typeof f>) {
    const { t } = useTranslation();

    return (
        <Form validationSchema={libraryVersionForm.validationSchema} {...props}>
            <Panel>
                <FormGrid>
                    <div tw="flex items-center">
                        <FormTextField
                            tw="flex-1"
                            f={f}
                            field="name"
                            label={t("name-label")}
                            autoFocus={props.autoFocus}
                        />
                    </div>
                    <FormTextField f={f} field="notes" label={t("notes-label")} multiline />
                    <FormParametersAutocomplete
                        f={f}
                        field="apiParameterList"
                        label={t("supported-api-parameters-label")}
                        filter={{}}
                        multiple
                        fullOption
                    />
                </FormGrid>
            </Panel>
        </Form>
    );
}
