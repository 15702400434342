import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { getPasswordIsTooWeekMessage, userApi } from "../../services/api/user";
import { useAppSelector } from "../../redux/hooks";
import { NewPasswordChangeForm, newPasswordChangeFormSchema } from "./newPasswordChangeForm";
import { FORM_ERROR } from "final-form";
import { buildForm } from "@magicware/forms/builder";
import SimplePage from "@magicware/dashboard/navigation/SimplePage";
import Panel from "@magicware/dashboard/Panel";
import Form from "@magicware/forms/components/Form";
import FormTextField from "@magicware/forms/mui/FormTextField";
import FormSubmitResult from "@magicware/forms/components/FormSubmitResult";
import FormSubmitButton from "@magicware/forms/components/FormSubmitButton";

interface PasswordChangeForm extends NewPasswordChangeForm {
    oldPassword: string;
}

const { f, defaults, validationSchema } = buildForm<PasswordChangeForm>()(() => ({
    props: {
        extra: [],
        remove: [],
    },
    schema: (_, t) => ({
        ...newPasswordChangeFormSchema(t),
        oldPassword: yup.string().required(t("old-password-is-required")),
    }),
}));

export default function PasswordChangePage() {
    const { t } = useTranslation();
    const user = useAppSelector((state) => state.ui.loggedUser);
    if (!user) return null;
    const handleSubmit = (form: typeof f) => {
        const invokeApi = userApi.forms.changePassword.withServerErrors<typeof f>({
            messages: { PasswordIsTooWeek: (msg) => getPasswordIsTooWeekMessage(msg, t) },
            map: {
                NewPassword: "newPassword",
                OldPassword: "oldPassword",
                Login: FORM_ERROR,
            },
        });
        return invokeApi({ login: user!.name, oldPassword: form.oldPassword, newPassword: form.newPassword });
    };
    return (
        <SimplePage title={t("password-change-title")}>
            <Panel>
                <Form initialValues={defaults} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    <div tw="flex flex-col space-y-5 mb-5 max-w-md">
                        <FormTextField f={f} field="oldPassword" label={t("old-password-label")} type="password" />
                        <FormTextField f={f} field="newPassword" label={t("new-password-label")} type="password" />
                        <FormTextField
                            f={f}
                            field="newPassword2"
                            label={t("confirm-new-password-label")}
                            type="password"
                        />
                        <FormSubmitResult successMessage={t("password-has-been-changed-message")} />
                    </div>
                    <div tw="mt-10">
                        <FormSubmitButton>{t("save-changes-button")}</FormSubmitButton>
                    </div>
                </Form>
            </Panel>
        </SimplePage>
    );
}
