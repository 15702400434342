import AsyncContent from "@magicware/dashboard/AsyncContent";
import { useTranslation } from "react-i18next";
import { useBaseAppVersionId } from "../../navigation/baseApps";
import { baseAppVersionThunks } from "../../redux/baseAppVersions/baseAppVersionsReducer";
import { useAppDispatch, useAppSelectorEnsure, useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { baseAppVersionsApi } from "../../services/api/baseAppVersions";
import BaseAppVersionForm, { baseAppVersionForm } from "./BaseAppVersionForm";

const { useToFormFromModel } = baseAppVersionForm.hooks;

export default function BaseAppVersionDetailTab() {
    const api = useAutoDispatchAsyncThunk(baseAppVersionThunks.load, useBaseAppVersionId());
    return <AsyncContent {...api} component={BaseAppVersionDetailTabLoaded} />;
}

function BaseAppVersionDetailTabLoaded() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const baseAppVersionDetail = useAppSelectorEnsure((state) => state.baseAppVersions.detail);
    const initialValues = useToFormFromModel(baseAppVersionDetail);
    const onSubmit = async (form: typeof baseAppVersionForm.f) => {
        const result = await baseAppVersionsApi.forms.update(
            baseAppVersionForm.transform.formTo.update(form, baseAppVersionDetail),
        );
        return baseAppVersionForm.apiUtils.handleUpdateEntityResult(dispatch, result, form);
    };
    return (
        <BaseAppVersionForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            savePanel={{ successMessage: t("baseAppVersion-has-been-saved") }}
        />
    );
}
