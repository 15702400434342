import { faCircleNodes, faHashtag } from "@fortawesome/free-solid-svg-icons";
import { prepareBreadcrumb } from "@magicware/dashboard/navigation/breadcrumbs";
import { CreatePatternMethod, CreateSimpleMethod } from "@magicware/dashboard/navigation/builder";
import { prepareRootTab, prepareTab } from "@magicware/dashboard/navigation/tabs";
import { useIntParam } from "@magicware/dashboard/navigation/useIntParam";
import { navigation } from ".";
import AppInstanceConnectionDetailTab from "../components/appInstanceConnections/AppInstanceConnectionDetailTab";
import AppInstanceConnectionListTab from "../components/appInstanceConnections/AppInstanceConnectionListTab";
import AppInstanceConnectionNewTab from "../components/appInstanceConnections/AppInstanceConnectionNewTab";
import AppInstanceConnectionTabPage from "../components/appInstanceConnections/AppInstanceConnectionTabPage";
import AppInstanceDetailTab from "../components/appInstances/AppInstanceDetailTab";
import AppInstanceFilesTabPage from "../components/appInstances/AppInstanceFilesTabPage";
import AppInstanceListTab from "../components/appInstances/AppInstanceListTab";
import AppInstanceNewTab from "../components/appInstances/AppInstanceNewTab";
import AppInstanceTabPage from "../components/appInstances/AppInstanceTabPage";
import AppInstanceVersionDetailTab from "../components/appInstanceVersions/AppInstanceVersionDetailTab";
import AppInstanceVersionListTab from "../components/appInstanceVersions/AppInstanceVersionListTab";
import AppInstanceVersionNewTab from "../components/appInstanceVersions/AppInstanceVersionNewTab";
import AppInstanceVersionTabPage from "../components/appInstanceVersions/AppInstanceVersionTabPage";
import { baseAppIcon, useBaseAppId } from "./baseApps";
import { filesIcon } from "./files";
import { useProjectSetupId } from "./projectSetups";

export const useAppInstanceId = () => useIntParam("appInstanceId");
export const useAppInstanceVersionId = () => useIntParam("appInstanceVersionId");
export const useAppInstanceConnectionId = () => useIntParam("appInstanceConnectionId");

export const useAppInstaceNavigation = () => {
    const baseAppId = useBaseAppId(false);
    const projectSetupId = useProjectSetupId(!baseAppId);
    if (baseAppId) return [baseAppId, navigation.baseApps.detail.apps] as const;
    else return [projectSetupId!, navigation.projectSetups.detail.apps] as const;
};

export const appInstanceVersionIcon = faHashtag;
export const appInstanceConnectionIcon = faCircleNodes;

export const buildAppInstances = (
    parentUrl: string,
    parentIdParamName: string,
    parentTabPage: React.ComponentType<any>,
    tabIndex: number,
    _simple: CreateSimpleMethod,
    pattern: CreatePatternMethod,
) => {
    return pattern({
        pattern: `/${parentUrl}/:${parentIdParamName}/apps`,
        component: parentTabPage,
        url: (parentId: number) => `/${parentUrl}/${parentId}/apps`,
        breadcrumb: prepareBreadcrumb((t) => t("appInstances-breadcrumb")),
        tab: prepareTab(tabIndex++, (t) => t("appInstances-tab"), baseAppIcon, AppInstanceListTab),

        new: pattern({
            pattern: `/${parentUrl}/:${parentIdParamName}/apps/new`,
            url: (parentId: number) => `/${parentUrl}/${parentId}/apps/new`,
            component: parentTabPage,
            breadcrumb: prepareBreadcrumb((t) => t("new-appInstance-breadcrumb")),
            tab: prepareRootTab((t) => t("new-appInstance-title"), baseAppIcon, AppInstanceNewTab),
        }),
        detail: pattern({
            pattern: `/${parentUrl}/:${parentIdParamName}/apps/:appInstanceId`,
            component: AppInstanceTabPage,
            url: (parentId: number, appInstanceId: number) => `/${parentUrl}/${parentId}/apps/${appInstanceId}`,
            breadcrumb: prepareBreadcrumb((t) => t("appInstance-detail-breadcrumb")),
            tab: prepareRootTab((t) => t("appInstance-detail-title"), baseAppIcon, AppInstanceDetailTab),

            files: pattern({
                pattern: `/${parentUrl}/:${parentIdParamName}/apps/:appInstanceId/files`,
                component: AppInstanceTabPage,
                url: (parentId: number, appInstanceId: number) =>
                    `/${parentUrl}/${parentId}/apps/${appInstanceId}/files`,
                breadcrumb: prepareBreadcrumb((t) => t("files-breadcrumb")),
                tab: prepareTab(tabIndex++, (t) => t("files-tab"), filesIcon, AppInstanceFilesTabPage),
            }),

            versions: pattern({
                pattern: `/${parentUrl}/:${parentIdParamName}/apps/:appInstanceId/versions`,
                component: AppInstanceTabPage,
                url: (parentId: number, appInstanceId: number) =>
                    `/${parentUrl}/${parentId}/apps/${appInstanceId}/versions`,
                breadcrumb: prepareBreadcrumb((t) => t("appInstanceVersions-breadcrumb")),
                tab: prepareTab(
                    tabIndex++,
                    (t) => t("appInstanceVersions-tab"),
                    appInstanceVersionIcon,
                    AppInstanceVersionListTab,
                ),

                new: pattern({
                    pattern: `/${parentUrl}/:${parentIdParamName}/apps/:appInstanceId/versions/new`,
                    url: (parentId: number, appInstanceId: number) =>
                        `/${parentUrl}/${parentId}/apps/${appInstanceId}/versions/new`,
                    component: AppInstanceTabPage,
                    breadcrumb: prepareBreadcrumb((t) => t("new-appInstanceVersion-breadcrumb")),
                    tab: prepareRootTab(
                        (t) => t("new-appInstanceVersion-title"),
                        appInstanceVersionIcon,
                        AppInstanceVersionNewTab,
                    ),
                }),
                detail: pattern({
                    pattern: `/${parentUrl}/:${parentIdParamName}/apps/:appInstanceId/versions/:appInstanceVersionId`,
                    component: AppInstanceVersionTabPage,
                    url: (parentId: number, appInstanceId: number, appInstanceVersionId: number) =>
                        `/${parentUrl}/${parentId}/apps/${appInstanceId}/versions/${appInstanceVersionId}`,
                    breadcrumb: prepareBreadcrumb((t) => t("appInstanceVersion-detail-breadcrumb")),
                    tab: prepareRootTab(
                        (t) => t("appInstanceVersion-detail-title"),
                        appInstanceVersionIcon,
                        AppInstanceVersionDetailTab,
                    ),
                }),
            }),

            connections: pattern({
                pattern: `/${parentUrl}/:${parentIdParamName}/apps/:appInstanceId/connections`,
                component: AppInstanceTabPage,
                url: (parentId: number, appInstanceId: number) =>
                    `/${parentUrl}/${parentId}/apps/${appInstanceId}/connections`,
                breadcrumb: prepareBreadcrumb((t) => t("appInstanceConnections-breadcrumb")),
                tab: prepareTab(
                    tabIndex++,
                    (t) => t("appInstanceConnections-tab"),
                    appInstanceConnectionIcon,
                    AppInstanceConnectionListTab,
                ),

                new: pattern({
                    pattern: `/${parentUrl}/:${parentIdParamName}/apps/:appInstanceId/connections/new`,
                    url: (parentId: number, appInstanceId: number) =>
                        `/${parentUrl}/${parentId}/apps/${appInstanceId}/connections/new`,
                    component: AppInstanceTabPage,
                    breadcrumb: prepareBreadcrumb((t) => t("new-appInstanceConnection-breadcrumb")),
                    tab: prepareRootTab(
                        (t) => t("new-appInstanceConnection-title"),
                        appInstanceConnectionIcon,
                        AppInstanceConnectionNewTab,
                    ),
                }),
                detail: pattern({
                    pattern: `/${parentUrl}/:${parentIdParamName}/apps/:appInstanceId/connections/:appInstanceConnectionId`,
                    component: AppInstanceConnectionTabPage,
                    url: (parentId: number, appInstanceId: number, appInstanceConnectionId: number) =>
                        `/${parentUrl}/${parentId}/apps/${appInstanceId}/connections/${appInstanceConnectionId}`,
                    breadcrumb: prepareBreadcrumb((t) => t("appInstanceConnection-detail-breadcrumb")),
                    tab: prepareRootTab(
                        (t) => t("appInstanceConnection-detail-title"),
                        appInstanceConnectionIcon,
                        AppInstanceConnectionDetailTab,
                    ),
                }),
            }),
        }),
    });
};
