import { createAsyncFormAutocompleteWithFilter } from "@magicware/forms/mui/FormAutocomplete";
import { isString } from "@magicware/utils/isType";
import { brandThunks } from "../../redux/brands/brandsReducer";
import { AppDispatch, RootState } from "../../redux/types";
import { BrandSelectOption } from "../../services/api/brands";
import { createDefaultProps } from "../forms/formAutocomplete";

export const FormBrandsAutocomplete = createAsyncFormAutocompleteWithFilter(
    {
        selectOptions: (state: RootState) => state.brands.list,
        createAsyncAction: brandThunks.loadList,
        selectAsyncState: brandThunks.loadList.selectState,
    },
    createDefaultProps<BrandSelectOption>(
        (opt) => `${opt.name}`,
        (n, opt) => n.brands.detail.url(opt.id),
    ),
);

export interface BrandForm {
    brand?: BrandSelectOption | string;
}

export async function brandFormToEntity(dispatch: AppDispatch, form: BrandForm) {
    if (isString(form.brand)) {
        const newBrands = await dispatch(brandThunks.ensure({ names: [form.brand] })).unwrap();
        form.brand = newBrands[0];
    }
    return { brandId: form.brand?.id! };
}
