import AsyncContent from "@magicware/dashboard/AsyncContent";
import { useTranslation } from "react-i18next";
import { useAppInstanceVersionId } from "../../navigation/appInstances";
import { appInstanceVersionThunks } from "../../redux/appInstanceVersions/appInstanceVersionsReducer";
import { useAppDispatch, useAppSelectorEnsure, useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { appInstanceVersionsApi } from "../../services/api/appInstanceVersions";
import AppInstanceVersionForm, { appInstanceVersionForm } from "./AppInstanceVersionForm";

const { useToFormFromModel } = appInstanceVersionForm.hooks;

export default function AppInstanceVersionDetailTab() {
    const api = useAutoDispatchAsyncThunk(appInstanceVersionThunks.load, useAppInstanceVersionId());
    return <AsyncContent {...api} component={AppInstanceVersionDetailTabLoaded} />;
}

function AppInstanceVersionDetailTabLoaded() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const appInstanceVersionDetail = useAppSelectorEnsure((state) => state.appInstanceVersions.detail);
    const initialValues = useToFormFromModel(appInstanceVersionDetail);
    const onSubmit = async (form: typeof appInstanceVersionForm.f) => {
        const result = await appInstanceVersionsApi.forms.update(
            appInstanceVersionForm.transform.formTo.update(form, appInstanceVersionDetail),
        );
        return appInstanceVersionForm.apiUtils.handleUpdateEntityResult(dispatch, result, form);
    };
    return (
        <AppInstanceVersionForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            savePanel={{ successMessage: t("appInstanceVersion-has-been-saved") }}
        />
    );
}
