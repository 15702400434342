import { createContext, useContext, useRef, useState } from "react";

export interface SelectionContext<Item = any> {
    getIsEnabled: () => boolean;
    toggleIsEnabled: () => void;
    toggle: (item: Item | Item[], selected?: boolean) => void;
    getSelected: () => Item[];
    isSelected: (item: Item) => boolean;
}

const throwMissingContextError = () => {
    throw new Error("Missing SelectionContext");
};

const SelectedVolatileContext = createContext<any[]>([]);
const SelectionStaticContext = createContext<SelectionContext>({
    getIsEnabled: () => false,
    toggleIsEnabled: throwMissingContextError,
    getSelected: throwMissingContextError,
    toggle: throwMissingContextError,
    isSelected: throwMissingContextError,
});

export function SelectionContextProvider(props: {
    children?: React.ReactNode | undefined;
    initialSelection?: any[];
    defaultDisabled?: boolean;
}) {
    const [selectedItems, setSelectedItems] = useState<any[]>(() => props.initialSelection ?? []);
    const [isEnabled, setIsEnabled] = useState(props.defaultDisabled === true ? false : true);

    const dataRef = useRef<{ arr: any[]; set: Set<any>; isEnabled: boolean }>(undefined!);
    if (dataRef.current === undefined) {
        const arr = props.initialSelection ?? [];
        dataRef.current = { arr, set: new Set<any>(arr), isEnabled };
    }

    const ctxRef = useRef<SelectionContext>(undefined!);

    if (ctxRef.current === undefined) {
        ctxRef.current = {
            getIsEnabled: () => dataRef.current.isEnabled,
            toggleIsEnabled: () => {
                dataRef.current.isEnabled = !dataRef.current.isEnabled;
                ctxRef.current = { ...ctxRef.current };
                setIsEnabled(dataRef.current.isEnabled);
            },
            getSelected: () => dataRef.current.arr,
            isSelected: (i) => dataRef.current.set.has(i),
            toggle: (item, isSelected) => {
                const items: any[] = item instanceof Array ? item : [item];
                let hasChanged = false;
                for (let idx = 0; idx < items.length; idx++) {
                    const i = items[idx];
                    const currentIsSelected = dataRef.current.set.has(i);
                    const newIsSelected = isSelected ?? !currentIsSelected;
                    if (currentIsSelected !== newIsSelected) {
                        hasChanged = true;
                        if (newIsSelected) dataRef.current.set.add(i);
                        else dataRef.current.set.delete(i);
                    }
                }
                if (hasChanged) {
                    dataRef.current.arr = Array.from(dataRef.current.set);
                    setSelectedItems(dataRef.current.arr);
                }
            },
        };
    }
    return (
        <SelectionStaticContext.Provider value={ctxRef.current}>
            <SelectedVolatileContext.Provider value={selectedItems}>{props.children}</SelectedVolatileContext.Provider>
        </SelectionStaticContext.Provider>
    );
}

export function useSelectedItems<TItem = any>() {
    return useContext(SelectedVolatileContext) as TItem[];
}
export function useIsSelected<TItem = any>(item: TItem) {
    useContext(SelectedVolatileContext);
    return useSelection().isSelected(item);
}
export function useSelection<TItem = any>(): SelectionContext<TItem> {
    return useContext(SelectionStaticContext);
}
