import * as forms from "@magicware/forms/mui/FormAutocomplete";
import { navigation } from "../../navigation";
import { useNavigationClick } from "../../navigation/hooks";

export function createDefaultProps<TOption extends { id: number }>(
    getOptionLabel: (opt: TOption) => string,
    getDetailUrl?: (n: typeof navigation, opt: TOption) => string,
): () => Partial<forms.FormAutocompleteProps<never, TOption, never, never, never>> {
    return () => {
        const onOptionDetailClick = getDetailUrl && useNavigationClick(getDetailUrl);
        return {
            getOptionLabel,
            optionsLimit: 50,
            handleHomeEndKeys: false,
            onOptionDetailClick,
        };
    };
}
