import Panel from "@magicware/dashboard/Panel";
import { buildForm } from "@magicware/forms/builder";
import Form, { FormImplementationProps } from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import FormTextField from "@magicware/forms/mui/FormTextField";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { updateDetail } from "../../redux/connectorImplementations/connectorImplementationsReducer";
import {
    ConnectorImplementationModelDetail,
    ConnectorImplementationModelNew,
    ConnectorImplementationModelUpdate,
} from "../../services/api/connectorImplementations";
import { FormConnectorVersionsAutocomplete } from "../connectorVersions/FormConnectorVersionsAutocomplete";
import { FormDevicesAutocomplete } from "../devices/FormDevicesAutocomplete";
import { FormParametersAutocomplete } from "../parameters/FormParametersAutocomplete";

export const connectorImplementationForm = buildForm<ConnectorImplementationModelDetail>()(() => ({
    props: {
        remove: ["id", "version", "libraryVersion"],
        extra: [],
    },
    transformations: {
        toForm: { fromModel: (h, m: ConnectorImplementationModelDetail) => h.fromModel(m) },
        formTo: {
            new: (h, f, libraryVersionId): ConnectorImplementationModelNew => ({
                ...h.toNewEntity(f),
                libraryVersionId,
            }),
            update: (h, f, m: ConnectorImplementationModelDetail): ConnectorImplementationModelUpdate =>
                h.toUpdateEntity(f, m),
        },
    },
    updateReduxModel: updateDetail,
    schema: (_, t) => ({
        connectorVersion: yup.object().required(t("connector-is-required")),
        parameterList: yup.array().default([]),
        deviceList: yup.array().default([]),
        notes: yup.string(),
    }),
}));

const f = connectorImplementationForm.f;

export default function ConnectorImplementationForm(props: FormImplementationProps<typeof f>) {
    const { t } = useTranslation();

    return (
        <Form validationSchema={connectorImplementationForm.validationSchema} {...props}>
            <Panel>
                <FormGrid>
                    <FormConnectorVersionsAutocomplete
                        f={f}
                        field="connectorVersion"
                        label={t("connector-label")}
                        filter={{}}
                        fullOption
                    />
                    <FormParametersAutocomplete
                        f={f}
                        field="parameterList"
                        label={t("supported-parameters-label")}
                        filter={{}}
                        multiple
                        fullOption
                    />
                    <FormDevicesAutocomplete
                        f={f}
                        field="deviceList"
                        label={t("supported-devices-label")}
                        filter={{}}
                        multiple
                        fullOption
                    />
                    <FormTextField f={f} field="notes" label={t("notes-label")} multiline />
                </FormGrid>
            </Panel>
        </Form>
    );
}
