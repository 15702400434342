import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { navigation } from "../../navigation";
import { useFormThunk } from "../../redux/hooks";
import { Login } from "../../services/api/user";
import Form from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import FormSubmitButton from "@magicware/forms/components/FormSubmitButton";
import FormSubmitResult from "@magicware/forms/components/FormSubmitResult";
import TwoPhaseLoginForm from "./TwoPhaseLoginForm";
import { buildForm } from "@magicware/forms/builder";
import { uiThunks } from "../../redux/ui/uiReducer";
import FormTextField from "@magicware/forms/mui/FormTextField";
import ButtonLink from "@magicware/dashboard/navigation/ButtonLink";

type LoginFormModel = {
    login: string;
    password: string;
};

const { f, defaults, validationSchema } = buildForm<LoginFormModel>()(() => ({
    props: {
        extra: [],
        remove: [],
    },
    schema: (_, t) => ({
        login: yup.string().required(t("email-is-required")),
        password: yup.string(),
    }),
}));

export default function PasswordResetStep1Page() {
    const { t } = useTranslation();
    const validationsMap: Login<typeof f>["ValidationMap"] = {
        messages: {
            InvalidLogin: t("login-or-password-does-not-match-message"),
            LoginLocked: t("username-is-temporarily-locked-message"),
        },
        autoMap: "capitalize",
    };
    const dispatchLogin = useFormThunk(uiThunks.login, validationsMap);
    const [phaseTwoForm, setPhaseTwoForm] = useState<{ login: string; twoPhaseAuthToken: string }>();

    const handleOnSubmit = async (form: typeof f) => {
        const response = await dispatchLogin(form);
        if (response.success && response.data?.result === "2fa") {
            setPhaseTwoForm({ login: form.login, twoPhaseAuthToken: response.data.twoPhaseAuthToken });
        }
        return response;
    };

    if (phaseTwoForm) return <TwoPhaseLoginForm {...phaseTwoForm} onCancel={() => setPhaseTwoForm(undefined)} />;

    return (
        <Form initialValues={defaults} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
            <FormGrid>
                <FormTextField f={f} field="login" label={t("email-label")} name="login" autoComplete="login" />
                <FormTextField
                    f={f}
                    field="password"
                    label={t("password-label")}
                    type="password"
                    name="password"
                    autoComplete="password"
                />
                <FormSubmitResult />
            </FormGrid>
            <div tw="flex items-center justify-between mt-6">
                <FormSubmitButton tw="whitespace-nowrap" ignoreDirty>
                    {t("log-in-button")}
                </FormSubmitButton>
                <ButtonLink tw="text-right" variant="text" to={navigation.passwordReset.url()}>
                    {t("forgot-password-button")}
                </ButtonLink>
            </div>
        </Form>
    );
}
