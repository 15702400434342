import Loader from "./Loader";
import PageContainer from "./PageContainer";

export default function PageContainerLoader() {
    return (
        <PageContainer tw="pb-10 pt-5 bg-body">
            <Loader />
        </PageContainer>
    );
}
