import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TwCssProps } from "@magicware/types/twin";
import ClickStopDiv from "@magicware/utils/components/ClickStopDiv";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ButtonBase from "@mui/material/ButtonBase";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import { Fragment } from "react";
import tw from "twin.macro";
import { useOpenContextMenu } from "./DataListContextMenu";
import SelectionCheckBox from "./SelectionCheckBox";

export interface DataListItemProps extends TwCssProps {
    onClick?: () => void;
    selected?: boolean;
    editDisabled?: boolean;
    onRemoved?: () => void;
    onSelectionChanged?: (newValue: boolean) => void;
    selectionContextItem?: any;
}

export const MainText = tw.div`[font-size:large] [font-weight:bold]`;
export const SecondaryText = tw.div`[font-size:small] [font-weight:500]`;

const DataListItemContainer = tw.div`flex items-center bg-listitem-default rounded-xl p-4`;
const DataListItemCliclableContainer = tw(
    ButtonBase,
)`w-full text-left flex items-center bg-listitem-default rounded-xl p-4`;

export default function DataListItem({
    onClick,
    onRemoved,
    editDisabled,
    selected = false,
    children,
    onSelectionChanged,
    selectionContextItem,
    ...props
}: React.PropsWithChildren<DataListItemProps>) {
    const hanleOpenMenu = useOpenContextMenu();

    const content = (
        <Fragment>
            {onSelectionChanged && (
                <ClickStopDiv tw="mr-2">
                    <Checkbox checked={selected} onChange={(_, checked) => onSelectionChanged(checked)} />
                </ClickStopDiv>
            )}
            {selectionContextItem && (
                <ClickStopDiv tw="mr-2">
                    <SelectionCheckBox selectionItem={selectionContextItem} />
                </ClickStopDiv>
            )}
            {onRemoved && (
                <ClickStopDiv tw="mr-2">
                    <FontAwesomeIcon icon={faTrashAlt} onClick={onRemoved} tw="text-red-500" />
                </ClickStopDiv>
            )}
            <div tw="flex-1">{children}</div>
            {hanleOpenMenu && (
                <ClickStopDiv tw="ml-2">
                    <IconButton onClick={hanleOpenMenu}>
                        <MoreVertIcon />
                    </IconButton>
                </ClickStopDiv>
            )}
        </Fragment>
    );

    if (onClick && !editDisabled) {
        return (
            <DataListItemCliclableContainer {...props} component="div" focusRipple onClick={onClick}>
                {content}
            </DataListItemCliclableContainer>
        );
    }

    return <DataListItemContainer {...props}>{content}</DataListItemContainer>;
}
