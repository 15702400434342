import Panel from "@magicware/dashboard/Panel";
import { buildForm } from "@magicware/forms/builder";
import Form from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import useNewEntityOnSubmit from "@magicware/forms/hooks/useNewEntityOnSubmit";
import FormTextField from "@magicware/forms/mui/FormTextField";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useNavigationClickReplace } from "../../navigation/hooks";
import { useBaseAppId } from "../../navigation/baseApps";
import { clearList } from "../../redux/baseAppVersions/baseAppVersionsReducer";
import { BaseAppVersionModelNew, baseAppVersionsApi } from "../../services/api/baseAppVersions";

export const baseAppVersionNewForm = buildForm<BaseAppVersionModelNew>()(() => ({
    props: {
        remove: ["baseAppId"],
        extra: [],
    },
    transformations: {
        formTo: { new: (_, f, baseAppId: number): BaseAppVersionModelNew => ({ name: f.name, baseAppId }) },
    },
    schema: (_, t) => ({
        name: yup.string().required(t("name-is-required")),
    }),
}));

const {
    f,
    hooks: { useDefaults },
} = baseAppVersionNewForm;

export default function BaseAppVersionNewTab() {
    const { t } = useTranslation();
    const baseAppId = useBaseAppId();
    const onSubmit = useNewEntityOnSubmit(
        clearList,
        baseAppVersionsApi.forms.create,
        useNavigationClickReplace((n, newId) => n.baseApps.detail.versions.detail.url(baseAppId, newId)),
        (f: typeof baseAppVersionNewForm.f) => baseAppVersionNewForm.transform.formTo.new(f, baseAppId),
    );
    const initialValues = useDefaults();
    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            savePanel={{ buttonContent: t("create-new-version"), disableOnPristine: true }}
            validationSchema={baseAppVersionNewForm.validationSchema}
        >
            <Panel>
                <FormGrid>
                    <FormTextField f={f} field="name" label={t("name-label")} autoFocus />
                </FormGrid>
            </Panel>
        </Form>
    );
}
