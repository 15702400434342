import { useRef } from "react";
import { arrayContentEquals } from "../arrayContentEquals";

/** Funguje prakticky stejně jako useMemo, akorát s tím rozdílem, že využívá useRef,
 * takže je zajištěna i semantická spolehlivost životnosti hodnoty. Jinymí slovy,
 * u useMemo je riziko, že se "samovolně" změní hodnota i když dependencies zůstanou stejné.
 * U tohoto hooku se hodnota mění jen když se změní dependencies.
 * @param factory Metoda vyvolaná při prvním mountu komponenty pro vrácení hodnoty nebo pokažké, když se změní závislosti.
 * @param dependencies Pokud je undefined, hodnota se nastaví pouze při prvním mountu komponenty */
export default function useRefMemo<TResult>(factory: () => TResult, dependencies?: React.DependencyList) {
    const refInstance = useRef<{ deps?: React.DependencyList; value: TResult } | null>(null);
    if (!refInstance.current || !arrayContentEquals(refInstance.current.deps, dependencies))
        refInstance.current = { deps: dependencies, value: factory() };
    return refInstance.current.value;
}
