import { faRegistered } from "@fortawesome/free-solid-svg-icons";
import DataIcon from "@magicware/data-lists/DataIcon";
import DataListItem, { MainText, SecondaryText } from "@magicware/data-lists/DataListItem";
import ClickStopDiv from "@magicware/utils/components/ClickStopDiv";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../redux/hooks";
import DownloadIconButton from "./DownloadIconButton";

export interface FileListItemProps {
    fileId: number;
}

export default function FileListItem({ fileId, ...props }: FileListItemProps) {
    const { t } = useTranslation();
    const p = useAppSelector((state) => state.files.list?.byId[fileId]);
    if (!p) return null;
    return (
        <DataListItem {...props} data-id={p.id}>
            <div tw="flex items-center">
                {p.thumbUrl && (
                    <div tw="h-20 w-40">
                        <img tw="max-h-full max-w-full" src={p.thumbUrl} alt={p.name} />
                    </div>
                )}
                <div>
                    <div>
                        <MainText>{p.name}</MainText>
                    </div>
                    {p.isLogo && (
                        <SecondaryText>
                            <DataIcon icon={faRegistered} /> {t("logo-list-text")}
                        </SecondaryText>
                    )}
                </div>
                <div tw="ml-auto">
                    <ClickStopDiv tw="flex">
                        <DownloadIconButton urlOrBlob={p.url} name={p.name} />
                    </ClickStopDiv>
                </div>
            </div>
        </DataListItem>
    );
}
