import { Fragment } from "react";
import LanguageSwitch from "../../localization/LanguageSwitch";
//import { ReactComponent as LogoSvg } from "../images/logo_notext_color_min.svg";
import LogoSrc from "../images/logo.png";

const backgroundUrl = `url(/images/login_background.jpg)`;
//const backgroundUrl = `url(/images/login_background_${new Date().getSeconds() % 5}.jpg)`;

export default function LoginPageContainer(props: { children?: React.ReactNode }) {
    return (
        <Fragment>
            <div
                tw="fixed h-full w-full bg-cover [background-position:top center] [z-index:-1]"
                style={{ backgroundImage: backgroundUrl }}
            ></div>
            <div tw="flex items-center justify-center min-h-screen w-screen z-10">
                <div tw="w-full max-w-lg p-8 mt-8">
                    <div tw="shadow-lg rounded-2xl bg-white p-10 relative">
                        <div tw="absolute h-0 left-0 right-0 flex [top:-45px] [z-index:1500]">
                            <div tw="bg-white mx-auto [width:100px] [height:100px] flex items-center rounded-full">
                                <img src={LogoSrc} width={70} height={70} tw="mx-auto" alt="" />
                            </div>
                        </div>
                        <div tw="absolute right-2 top-2">
                            <LanguageSwitch />
                        </div>
                        <div tw="mt-6">{props.children}</div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
