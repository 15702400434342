import { createAsyncFormAutocompleteWithFilter } from "@magicware/forms/mui/FormAutocomplete";
import { apiServerVersionThunks } from "../../redux/apiServerVersions/apiServerVersionsReducer";
import { RootState } from "../../redux/types";
import { ApiServerVersionSelectOption } from "../../services/api/apiServerVersions";
import { createDefaultProps } from "../forms/formAutocomplete";

export const FormApiServerVersionsAutocomplete = createAsyncFormAutocompleteWithFilter(
    {
        selectOptions: (state: RootState) => state.apiServerVersions.list,
        createAsyncAction: apiServerVersionThunks.loadList,
        selectAsyncState: apiServerVersionThunks.loadList.selectState,
    },
    createDefaultProps<ApiServerVersionSelectOption>(
        (opt) => `${opt.name}`,
        (n, opt) => n.apiServerVersions.detail.url(opt.id),
    ),
);
