import useNewEntityOnSubmit from "@magicware/forms/hooks/useNewEntityOnSubmit";
import { useTranslation } from "react-i18next";
import { useNavigationClickReplace } from "../../navigation/hooks";
import { useLibraryId, useLibraryVersionId } from "../../navigation/libraries";
import { clearList } from "../../redux/connectorImplementations/connectorImplementationsReducer";
import { connectorImplementationsApi } from "../../services/api/connectorImplementations";
import ConnectorImplementationForm, { connectorImplementationForm } from "./ConnectorImplementationForm";

const { useDefaults } = connectorImplementationForm.hooks;

export default function ConnectorImplementationNewTab() {
    const { t } = useTranslation();
    const libraryId = useLibraryId();
    const libraryVersionId = useLibraryVersionId();
    const onSubmit = useNewEntityOnSubmit(
        clearList,
        connectorImplementationsApi.forms.create,
        useNavigationClickReplace((n) =>
            n.libraries.detail.versions.detail.connectors.url(libraryId, libraryVersionId),
        ),
        (f: typeof connectorImplementationForm.f) =>
            connectorImplementationForm.transform.formTo.new(f, libraryVersionId),
    );
    const initialValues = useDefaults();
    return (
        <ConnectorImplementationForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            autoFocus
            savePanel={{ buttonContent: t("create-new-connectorImplementation"), disableOnPristine: true }}
        />
    );
}
