import { apiServerInstanceThunks } from "../../redux/apiServerInstances/apiServerInstancesReducer";
import { useAppDispatch, useAppSelectorEnsure, useAutoDispatchAsyncThunk } from "../../redux/hooks";
import { apiServerInstancesApi } from "../../services/api/apiServerInstances";
import ApiServerInstanceForm, { apiServerInstanceForm, ApiServerInstanceFormOnSubmit } from "./ApiServerInstanceForm";
import AsyncContent from "@magicware/dashboard/AsyncContent";
import { useApiServerInstanceId } from "../../navigation/apiServerInstances";
import { useTranslation } from "react-i18next";

const { useToFormFromModel } = apiServerInstanceForm.hooks;

export default function ApiServerInstanceDetailTabPage() {
    const api = useAutoDispatchAsyncThunk(apiServerInstanceThunks.load, useApiServerInstanceId());
    return <AsyncContent {...api} component={ApiServerInstanceDetailTabPageLoaded} />;
}

function ApiServerInstanceDetailTabPageLoaded() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const apiServerInstanceDetail = useAppSelectorEnsure((state) => state.apiServerInstances.detail);
    const initialValues = useToFormFromModel(apiServerInstanceDetail);
    const onSubmit: ApiServerInstanceFormOnSubmit = async (form) => {
        const result = await apiServerInstancesApi.forms.update(
            await apiServerInstanceForm.transform.formTo.update(form, apiServerInstanceDetail),
        );
        return apiServerInstanceForm.apiUtils.handleUpdateEntityResult(dispatch, result, form);
    };
    return (
        <ApiServerInstanceForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            savePanel={{ successMessage: t("apiServerInstance-has-been-saved") }}
        />
    );
}
