import { IsAny } from "@reduxjs/toolkit/dist/tsHelpers";
import { FORM_ERROR, SubmissionErrors } from "final-form";
import { ApiDescription } from "./ApiDescription";
import {
    AnyValidationErrorMessage,
    getDefaultValidationMessageText,
    isValidationError,
    ValidationError,
    ValidationErrorItem,
    WellKnownValidationErrorMessage,
} from "./errors";

export const defaultValidationErrorMessage = "The form contains invalid data.";
export const defaultUnknownErrorMessage = "An unexpected error has occured.";

export type FormApiResult<TResult = any> =
    | { success: true; data: TResult; submitErrors?: undefined }
    | { success: false; submitErrors: SubmissionErrors };

export interface FormApiFunc<TResult, TParams extends any[]> {
    (...params: TParams): Promise<FormApiResult<TResult>>;
}

export interface FormApi<
    TResult,
    TParams extends any[],
    ValidationMessageBase extends AnyValidationErrorMessage = AnyValidationErrorMessage,
    ServerModelBase = any,
    AutoMap extends undefined | "one2one" | "capitalize" = undefined | "one2one" | "capitalize",
> extends FormApiFunc<TResult, TParams> {
    // /** Umožňuje nadefinovat mapování serverových validací do fomulářového objektu
    //  * @param apiErrorsMap Objekt definující jak se má výsledek serverové validace přeložit na jednotlivé textové zprávy a jejich napárování na formulářový model.
    //  * Pokud nějaká serverová chybová hláška nebude v tomto objektu namapována, neobjeví se u fíldu, ale mezi obecnými chybovými hláškami.
    //  */
    // withServerErrors: <
    //     FormValues = any,
    //     ValidationMessage extends ValidationMessageBase = ValidationMessageBase,
    //     ServerModel = ServerModelBase,
    // >(
    //     apiErrorsMap: ApiValidationToFormMap<FormValues, ValidationMessage, ServerModel, AutoMap>,
    //     ...params: TParams
    // ) => Promise<FormApiResult<TResult>>;

    /** Umožňuje nadefinovat mapování serverových validací do fomulářového objektu
     * @param apiErrorsMap Objekt definující jak se má výsledek serverové validace přeložit na jednotlivé textové zprávy a jejich napárování na formulářový model.
     * Pokud nějaká serverová chybová hláška nebude v tomto objektu namapována, neobjeví se u fíldu, ale mezi obecnými chybovými hláškami.
     */
    withServerErrors: <
        FormValues = any,
        ServerModel = ServerModelBase,
        ValidationMessage extends ValidationMessageBase = ValidationMessageBase,
    >(
        apiErrorsMap: ApiValidationToFormMap<FormValues, ValidationMessage, ServerModel, AutoMap>,
    ) => FormApiFunc<TResult, TParams>;
}

export function formApi<
    Api extends ApiDescription<
        Request,
        Response,
        ValidationMessageBase,
        any,
        AutoMap,
        ServerModelBase
    > = ApiDescription<any, any, any, any, any, any>,
    Request = Api["Request"],
    Response = Api["Response"],
    ValidationMessageBase extends AnyValidationErrorMessage = IsAny<
        Api["ValidationErrorMessage"],
        WellKnownValidationErrorMessage,
        Api["ValidationErrorMessage"]
    >,
    ServerModelBase = Api["ServerModel"],
    AutoMap extends undefined | "one2one" | "capitalize" = Api["AutoMap"],
    Params extends any[] = Parameters<Api["Invoke"]>,
>(apiCall: (...rest: Params) => Promise<Response>) {
    const invokeApi = async <
        FormValues,
        ValidationMessage extends ValidationMessageBase = ValidationMessageBase,
        ServerModel = ServerModelBase,
    >(
        apiErrorsMap: ApiValidationToFormMap<FormValues, ValidationMessage, ServerModel, AutoMap>,
        ...params: Params
    ): Promise<FormApiResult<Response>> => {
        try {
            const result = await apiCall(...params);
            return { success: true, data: result };
        } catch (error) {
            const submitErrors = handleFormApiError(error, apiErrorsMap);
            return { success: false, submitErrors };
        }
    };

    const result: FormApi<Response, Params, ValidationMessageBase, ServerModelBase, AutoMap> = (...params: Params) =>
        invokeApi({ map: {} }, ...params);

    result.withServerErrors =
        (apiErrorsMap) =>
        (...params: Parameters<typeof apiCall>) =>
            invokeApi(apiErrorsMap, ...params);

    //result.withServerErrors = invokeApi;
    //result.withServerErrors2 = undefined!;

    return result;
}

export function handleFormApiError<ValidationMessage extends AnyValidationErrorMessage = AnyValidationErrorMessage>(
    error: unknown,
    apiErrorsMap: ApiValidationToFormMap<any, ValidationMessage, any>,
) {
    const submitErrors: Record<string, string | string[] | undefined> = {};
    if (isValidationError(error)) {
        mapValidationErrors(submitErrors, error, apiErrorsMap);
        if (!submitErrors[FORM_ERROR])
            submitErrors[FORM_ERROR] = apiErrorsMap.validationError ?? defaultValidationErrorMessage;
    } else {
        submitErrors[FORM_ERROR] = apiErrorsMap.unknownError ?? defaultUnknownErrorMessage;
    }
    return submitErrors;
}

export type ValidationMessageMap<ValidationMessage extends AnyValidationErrorMessage = AnyValidationErrorMessage> = {
    [m in ValidationMessage["Type"]]?: string | ((mgs: ValidationMessage & { Type: m }) => string);
};

/** Objekt definující jak se má výsledek serverové validace přeložit na jednotlivé textové zprávy a jejich napárování na formulářový model. */
export type ApiValidationToFormMap<
    FormValues = any,
    ValidationMessage extends AnyValidationErrorMessage = AnyValidationErrorMessage,
    ServerModel = any,
    AutoMap extends undefined | "one2one" | "capitalize" = undefined | "one2one" | "capitalize",
> = {
    /** Slovník, kde klíčem je identifikátor typu validační message a hodnotou je přeložený text této zprávy  */
    messages?: ValidationMessageMap<ValidationMessage>;
    unknownError?: string;
    validationError?: string;
    /** Pokud je true, předpokládá se, že formulářovým modelem je přímo serverový model a není tedy nutné definovat mapování mezi serverovými property a formulářovými property */
    autoMap?: AutoMap;
    map?: {
        /** Slovník, kde klíčem je identifikátor položky serverového modelu a hodnotou je buď název property v modelu formuláře a nebo objekt s názvem a případně rozšiřujícím slovníkem. */
        [k in keyof ServerModel]?:
            | keyof FormValues
            | typeof FORM_ERROR
            | {
                  /** Název property formuláře */
                  key: keyof FormValues | typeof FORM_ERROR;
                  /** Rozšíření hlavního slovníku pro překlad validačních message na text pouze pro daný identifikátor serverového modelu. Jedná se o slovník, kde klíčem je identifikátor typu validační message a hodnotou je přeložený text této zprávy. */
                  messages: ValidationMessageMap<ValidationMessage>;
              };
    };
};

function mapValidationErrors<TForm>(
    submitErrors: Record<string, string | string[] | undefined>,
    apiResult: ValidationError,
    map: ApiValidationToFormMap<TForm, any, any>,
) {
    for (let i = 0; i < apiResult.Validation.length; i++) {
        const item = apiResult.Validation[i];
        if (item.KeyPath === null) {
            setSubmitError(submitErrors, FORM_ERROR, item, map.messages);
        } else {
            const fieldMap = map.map && map.map[item.KeyPath];
            if (typeof fieldMap === "object") {
                setSubmitError(submitErrors, fieldMap.key.toString(), item, map.messages, fieldMap.messages);
            } else if (fieldMap) {
                setSubmitError(submitErrors, fieldMap.toString(), item, map.messages);
            } else if (map.autoMap === "one2one") {
                setSubmitError(submitErrors, item.KeyPath, item, map.messages);
            } else if (map.autoMap === "capitalize") {
                setSubmitError(
                    submitErrors,
                    item.KeyPath[0].toLowerCase() + item.KeyPath.substring(1),
                    item,
                    map.messages,
                );
            } else {
                setSubmitError(submitErrors, FORM_ERROR, item, map.messages, undefined, `${item.KeyPath}: `);
            }
        }
    }
}

const emptyMessages: ValidationMessageMap = {};

function setSubmitError(
    submitErrors: Record<string, string | string[] | undefined>,
    key: string,
    validationItem: ValidationErrorItem,
    messages?: ValidationMessageMap,
    messages2?: ValidationMessageMap,
    messagePrefix = "",
) {
    if (!messages2) messages2 = emptyMessages;
    if (!messages) messages = emptyMessages;
    for (let i = 0; i < validationItem.Messages.length; i++) {
        const msg = validationItem.Messages[i];
        let text =
            messages2[msg.Type as keyof ValidationMessageMap] ??
            messages[msg.Type as keyof ValidationMessageMap] ??
            getDefaultValidationMessageText(msg);
        if (typeof text === "function") text = text(msg);
        setOrAddSubmitError(submitErrors, key, messagePrefix + text);
    }
}

function setOrAddSubmitError(
    submitErrors: Record<string, string | string[] | undefined>,
    key: string,
    message: string,
) {
    const currentValue = submitErrors[key];
    if (!currentValue) submitErrors[key] = message;
    else if (!(currentValue instanceof Array)) submitErrors[key] = [currentValue, message];
    else submitErrors[key] = [...currentValue, message];
}
