import { createAsyncFormAutocompleteWithFilter } from "@magicware/forms/mui/FormAutocomplete";
import { projectSetupThunks } from "../../redux/projectSetups/projectSetupsReducer";
import { RootState } from "../../redux/types";
import { ProjectSetupSelectOption } from "../../services/api/projectSetups";
import { createDefaultProps } from "../forms/formAutocomplete";

export const FormProjectSetupAutocomplete = createAsyncFormAutocompleteWithFilter(
    {
        selectOptions: (state: RootState) => state.projectSetups.list,
        createAsyncAction: projectSetupThunks.loadList,
        selectAsyncState: projectSetupThunks.loadList.selectState,
    },
    createDefaultProps<ProjectSetupSelectOption>(
        (opt) => `${opt.name}`,
        (n, opt) => n.projectSetups.detail.url(opt.id),
    ),
);
